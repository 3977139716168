define('afl/validators/password', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * Vérifie que la propriété correspond à une mot de passe valide.
   *
   * Exemples de mail valides : test123465@
   * Exemples de mail invalides : testtest, test, 123465
   */
  var isPasswordValid = function isPasswordValid(password) {
    var regex = /(((?=.*\d)(?=.*[a-z]))|((?=.*\d)(?=.*[A-Z]))|((?=.*[a-z])(?=.*[A-Z])))(?=.*[\W])/;
    return regex.test(password);
  };

  var Password = _base.default.extend({
    intl: Ember.inject.service(),

    validate: function validate(password, options) {
      if (Ember.isEmpty(options.requires) || !Ember.isEmpty(options.requires) && this.get(options.requires)) {
        if (!Ember.isEmpty(options.differentFrom) && password === this.get(options.differentFrom)) {
          return this.get('intl').t('validations.password', this.options);
        }

        if (!Ember.isEmpty(options.identicalTo) && password !== this.get(options.identicalTo)) {
          return this.get('intl').t('validations.identicalPassword', this.options);
        }

        if (Ember.isEmpty(password) || password.length < 8 || !isPasswordValid(password)) {
          return this.get('intl').t('validations.password', this.options);
        }
      }
      return true;
    }
  });

  Password.reopenClass({
    getDependentsFor: function getDependentsFor(attribute, options) {
      return [options.requires, options.differentFrom, options.identicalTo].filter(function (item) {
        return !Ember.isEmpty(item);
      });
    }
  });

  exports.default = Password;
});