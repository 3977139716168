define('afl/validators/email', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * Vérifie que la propriété correspond à une adresse email valide.
   *
   * Exemples de mail valides : test@test.com
   * Exemples de mail invalides : test@test
   */
  var isEmailValid = function isEmailValid(email) {
    var regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return regex.test(email);
  };

  exports.default = _base.default.extend({
    intl: Ember.inject.service(),

    validate: function validate(value) {
      if (!Ember.isEmpty(value)) {
        return isEmailValid(value) ? true : this.get('intl').getMessage('validations.email');
      } else {
        return true;
      }
    }
  });
});