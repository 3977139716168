define('afl/controllers/agency/entity/detail/user', ['exports', 'afl/mixins/injected-service'], function (exports, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_injectedService.default, {
    application: Ember.inject.controller(),
    currentPath: Ember.computed.alias('application.currentPath'),

    breadCrumb: Ember.computed('currentPath', function () {
      if (this.get('currentPath') === 'agency.entity.detail.user.index') {
        return null;
      }
      return this.get('intl').getMessage('breadcrumb.agency.entity.user.index');
    })
  });
});