define('afl/controllers/agency/task/index', ['exports', 'afl/transforms/localdate', 'afl/controllers/search', 'afl/mixins/injected-service', 'afl/mixins/has-permission', 'afl/mixins/search-collectivity', 'afl/adapters/application', 'afl/utils/afl-constants', 'ember-cli-file-saver/mixins/file-saver'], function (exports, _localdate, _search, _injectedService, _hasPermission, _searchCollectivity, _application, _aflConstants, _fileSaver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TaskFilter = Ember.Object.extend({
    minimumTermDate: null,
    maximumTermDate: null,
    interactionMethod: '',
    interactionType: '',
    interactionNature: '',
    interactionNatureIn: null,
    status: '',
    collectivitySiren: '',
    collectivityType: '',
    collectivityStatus: '',

    init: function init() {
      this._super.apply(this, arguments);
      if (Ember.isEmpty(this.interactionNatureIn)) {
        this.interactionNatureIn = [];
      }
    },

    toJson: function toJson() {
      return JSON.parse(JSON.stringify(this));
    }
  });

  var localDateTransform = new _localdate.default();

  exports.default = _search.default.extend(_fileSaver.default, _injectedService.default, _hasPermission.default, _searchCollectivity.default, {
    searchCollectivityCategoryFilter: Ember.computed.alias('task.collectivityType'),
    searchCollectivityIsFiltered: false,
    allCollectivities: true,
    selectedCollectivity: null,
    minimumTermDate: null,
    maximumTermDate: null,
    interactionMethods: (0, _aflConstants.default)('CRMInteractionMethod'),
    interactionTypes: (0, _aflConstants.default)('CRMInteractionType'),
    interactionNatures: null,
    taskStatuses: (0, _aflConstants.default)('CRMTaskStatus'),
    collectivityCategories: (0, _aflConstants.default)('CollectivityCategory'),
    collectivityStatuses: (0, _aflConstants.default)('CollectivityStatus'),
    dialogExport: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.interactionNatures = [];
    },

    shouldCollapse: Ember.computed('isInit', 'hasNoResult', function () {
      return this.get('isInit') || this.get('hasNoResult');
    }),

    selectedCollectivityChange: Ember.observer('selectedCollectivity', function () {
      var collectivity = this.get('selectedCollectivity');
      if (collectivity) {
        this.set('task.collectivityIdsIn', [collectivity.get('id')]);
      } else {
        this.set('task.collectivityIdsIn', null);
      }
    }),

    minimumTermDateChange: Ember.observer('minimumTermDate', function () {
      var minimumTermDate = this.get('minimumTermDate');
      if (minimumTermDate) {
        this.set('task.minimumTermDate', localDateTransform.serialize(minimumTermDate));
      } else {
        this.set('task.minimumTermDate', null);
      }
    }),

    maximumTermDateChange: Ember.observer('maximumTermDate', function () {
      var maximumTermDate = this.get('maximumTermDate');
      if (maximumTermDate) {
        this.set('task.maximumTermDate', localDateTransform.serialize(maximumTermDate));
      } else {
        this.set('task.maximumTermDate', null);
      }
    }),

    _resetFilter: function _resetFilter() {
      var withDefault = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      this.set('selectedCollectivity', null);
      this.set('minimumTermDate', null);
      this.set('maximumTermDate', null);
      this.set('task', TaskFilter.create());
      if (withDefault) {
        this.set('task.status', 'TO_PROCESS');
        this.set('task.interactionNatureIn', this.get('currentUser.groupsRef'));
      }
    },
    _completeFilter: function _completeFilter(filter) {
      if (!this.get('task')) {
        this.set('task', TaskFilter.create());
      }
      var task = this.get('task').toJson();
      Ember.merge(filter, task);
      return filter;
    },


    actions: {
      searchFunction: function searchFunction(searchParams) {
        var filter = this._completeFilter({
          page: searchParams.page - 1,
          size: searchParams.size,
          sort: searchParams.properties + ',' + searchParams.direction
        });
        this.set('model', this.store.query('crm-task', filter));
      },
      reset: function reset() {
        this._resetFilter();
      },


      dialogExport: function dialogExport() {
        this.toggleProperty('dialogExport');
      },

      exportResults: function exportResults() {
        var _this = this;

        var filter = (0, _application.jsonQueryString)(this._completeFilter({}));
        var queryParams = Ember.$.param(filter, true);

        Ember.$.ajax({
          type: 'GET',
          url: '/api/crmtasks/export?' + queryParams,
          dataType: 'blob', // or 'blob'
          headers: {
            'authorization': this.get('currentUser').getToken()
          },
          processData: false
        }).then(function (content) {
          _this.saveFileAs('liste-tache.csv', content);
        });
      }
    }
  });
});