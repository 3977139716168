define('afl/components/input/upload-file', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    name: null,

    actions: {
      uploadSuccess: function uploadSuccess(response) {
        this.get('model').set(this.get('name'), JSON.parse(response).ref);
      },

      uploadError: function uploadError() {
        Ember.get(this, 'toast').error(this.get('intl').getMessage('upload.error.defaultError'));
        this.get('model').set(this.get('name'), null);
      }
    }
  });
});