define('afl/controllers/collectivity/simulation/create', ['exports', 'afl/mixins/injected-service'], function (exports, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_injectedService.default, {
    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.collectivity.simulation.create');
    }),

    actions: {
      success: function success(object) {
        this.transitionToRoute('collectivity.simulation.detail', object);
      },

      cancel: function cancel() {
        this.transitionToRoute('collectivity.simulation.index');
      }
    }
  });
});