define('afl/routes/paying-agent/collectivity/index', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {

    activate: function activate() {
      var indexController = this.controllerFor('payingAgent.collectivity.index');
      var collectivity = this.modelFor('payingAgent.collectivity');

      indexController.set('collectivity', collectivity);

      this.store.query('aflUser', { collectivity: collectivity.get('id') }).then(function (aflUsers) {
        indexController.set('aflUsers', aflUsers.toArray());
      });

      this.store.query('news', {
        page: 1,
        size: 4,
        direction: 'DESC',
        state: 'PUBLISHED',
        properties: 'publicationBeginDate'
      }).then(function (news) {
        indexController.set('news', news.toArray());
      });
    }
  });
});