define('afl/components/entity/collectivity-space', ['exports', 'ember-cli-file-saver/mixins/file-saver'], function (exports, _fileSaver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_fileSaver.default, {
    currentUser: Ember.inject.service('current-user'),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),

    collectivity: null,
    aflUsers: null,
    news: null,
    isPayingAgent: false,

    collectivityChange: Ember.observer('collectivity', function () {
      this.loadDocuments();
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.loadDocuments();
    },


    adminAflUsers: Ember.computed('aflUsers', function () {
      var aflUsers = this.getWithDefault('aflUsers', []);
      return aflUsers.filter(function (user) {
        return user.get('isAdmin');
      });
    }),

    hasAdminAflUsers: Ember.computed('aflUsers', function () {
      return this.get('adminAflUsers').length > 0;
    }),

    otherAflUsers: Ember.computed('aflUsers', function () {
      var aflUsers = this.getWithDefault('aflUsers', []);
      return aflUsers.filter(function (user) {
        return !user.get('isAdmin');
      });
    }),

    hasOtherAflUsers: Ember.computed('aflUsers', function () {
      return this.get('otherAflUsers').length > 0;
    }),

    isAccessProfile: Ember.computed('collectivity.isMember', 'isPayingAgent', 'currentUser.{isAdmin,hasNoProfile}', function () {
      return this.get('collectivity.isMember') && !this.get('currentUser.isAdmin') && this.get('currentUser.hasNoProfile') && !this.get('isPayingAgent');
    }),

    loadDocuments: function loadDocuments() {
      if (this.get('collectivity')) {
        this.set('documents', this.get('store').query('document', {
          collectivity: this.get('collectivity.id'),
          page: 1,
          size: 10,
          direction: 'DESC',
          properties: 'editionDate',
          depositorTypes: ['AGENCY', 'SAB_PORTAL']
        }));
      }
    },
    getDocumentTypeLabel: function getDocumentTypeLabel(intl, doc) {
      if (doc.depositorType === 'SAB_PORTAL') {
        return intl.getEnum('enums.DocumentPieceType.' + doc.documentPieceType);
      }
      if (doc.type === 'OTHER') {
        return intl.getEnum('enums.DocumentType.' + doc.type) + ' - ' + doc.otherType;
      }
      return intl.getEnum('enums.DocumentType.' + doc.type);
    },


    actions: {
      download: function download(document) {
        var _this = this;

        // En local on récupère le proxy, sinon lien relatif sur le même domaine:port
        Ember.$.ajax({
          type: 'GET',
          url: '/api/download/document/collectivity/' + document.id,
          dataType: 'blob', // or 'blob'
          headers: {
            'authorization': this.get('currentUser').getToken()
          },
          processData: false
        }).then(function (content) {
          _this.saveFileAs(document.fileName, content);
        });
      }
    }
  });
});