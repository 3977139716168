define('afl/routes/agency/entity/detail/user/create', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      var param = this.paramsFor('agency.entity.detail'),
          type = void 0;
      switch (param.entity_type) {
        case 'collectivite':
          type = 'collectivity';
          break;
        case 'agence':
          type = 'agency';
          break;
        case 'agent-payeur':
          type = 'paying_agent';
          break;
        default:
          type = '';
      }
      return this.store.createRecord('aflUser', {
        entityType: type,
        entityId: param.entity_id
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.store.query('group', { entityType: model.get('entityType') }).then(function (groups) {
        controller.set('groups', groups.toArray());
      });
    },


    actions: {
      willTransition: function willTransition() {
        this.controller.get('model').deepRollback();
        return true;
      }
    }
  });
});