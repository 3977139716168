define('afl/utils/afl-constants', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = aflConstants;
  var AFL_ENTITY_TYPE = ['AGENCY', 'COLLECTIVITY', 'PAYING_AGENT'];

  var AFL_USER_STATUS = ['ENABLED', 'DISABLED', 'LOCKED', 'CLOSED', 'IN_PROGRESS'];

  var COLLECTIVITY_CATEGORY = ['CCOMAG', 'CCOMCO', 'CCOMUR', 'CCOMMU', 'CDEPAR', 'CMETRO', 'CREGIO', 'CSYNAG'];

  var COLLECTIVITY_STATUS = ['PRE_PROSPECT', 'PROSPECT', 'MEMBER', 'SLEEPING_MEMBER', 'ENDING_MEMBER'];

  var USER_ROLE = ['MAYOR', 'CHAIRMAN', 'DEPUTY_CHAIRMAN', 'DEPUTY_MAYOR', 'SERVICES_GENERAL_MANAGER', 'DEPUTY_GENERAL_MANAGER', 'OFFICE_DIRECTOR', 'ASSISTANT_ELECTED', 'SECRETARYSHIP', 'FINANCIAL_DIRECTOR', 'FINANCES', 'COMMUNICATION', 'LAWYER', 'CITY_COUNCILLOR', 'COMMUNITY_COUNCILLOR'];
  var CRM_INTERACTION_METHOD = ['MAIL', 'PHONE', 'PHYSICAL_ENCOUNTER', 'SOCIAL_NETWORKS'];
  var CRM_INTERACTION_TYPE = ['MAIL', 'PHONE', 'INDIVIDUAL_MEETING', 'COLLECTIVE_MEETING', 'CONFERENCE', 'SOCIAL_NETWORKS', 'COMPUTER_SUPPORT'];
  var CRM_ROLE = ['EXECUTIVE_BOARD', 'GENERAL_MEETINGS_HOLDER', 'GENERAL_MEETINGS_SUBSTITUTE', 'AUDIT_AND_RISK_COMMITTEE', 'CNRGE'];
  var CRM_ROLE_RESTRICTED = ['GENERAL_MEETINGS_HOLDER', 'GENERAL_MEETINGS_SUBSTITUTE'];
  var CRM_SUBSCRIPTION = ['CONFERENCE_CALL_INVITATION', 'EVENT_INVITATION', 'GENERAL_ASSEMBLY_CONVOCATION', 'BOARDS_OF_DIRECTORS_CONVOCATION', 'AUDIT_AND_RISK_COMMITTEE_CONVOCATION', 'CNRGE_CONVOCATION'];
  var CRM_TASK_STATUS = ['TO_PROCESS', 'CANCELED', 'DONE'];
  var POLITICALLY_EXPOSED_PERSON = ['FRENCH', 'FOREIGN', 'NO'];

  var COLLECTIVITY_DOCUMENT_TYPE = ['DELIBERATIONS', 'BUDGET_DOCUMENTS', 'CONTRACTUAL_PIECES', 'SIGNED_MANDATE', 'OTHER'];

  var COLLECTIVITY_DOCUMEN_PIECE_TYPE = ['DELCAP', 'POUSIG', 'BULSOU', 'ACADPA', 'CONSEQ', 'CONTPR', 'CNVGAR', 'DELGAR', 'DELEMP', 'MANDAT', 'LETENG', 'POUGAR', 'CONPOR', 'DECEMP', 'FICACI', 'IBAN', 'NOTFIN', 'CONPOS', 'ACADPS', 'BULSOS', 'CNVGAS', 'CONSES', 'CONTPS', 'OFFRE', 'AUTIDF', 'AUTCLI', 'AVIS_VERS_FONDS', 'AVIS_PRELEV', 'AVIS_ECHE', 'AVIS_IMPAYE', 'COM_ENGAG_LIGNE_TRESO', 'INTERET_RA', 'AVIS_RBT_LIGNE_TRESO', 'AVIS_TIRAGE_LIGNE_TRESO', 'INTERET_ET_CNU_LIGNE_TRESO', 'PENALITES_DE_RETARD', 'RELANCE_IMPAYE', 'MISE_EN_DEMEURE', 'MANDAT_RECURRENT', 'CONVENSION_SEPA', 'IBAC', 'AVIS_TRANCHE_AMORT', 'AVIS_TIRAGE_PHASE_MOB', 'AVIS_COM_ENGAG_MOB', 'INTERET_ET_CNU_MOB'];

  function aflConstants(enumType) {
    if (!enumType) {
      return [];
    } else {
      switch (enumType) {
        case 'AflEntityType':
          return AFL_ENTITY_TYPE.slice(0);
        case 'AflUserStatus':
          return AFL_USER_STATUS.slice(0);
        case 'CollectivityCategory':
          return COLLECTIVITY_CATEGORY.slice(0);
        case 'CollectivityStatus':
          return COLLECTIVITY_STATUS.slice(0);
        case 'UserRole':
          return USER_ROLE.slice(0);
        case 'CRMInteractionMethod':
          return CRM_INTERACTION_METHOD.slice(0);
        case 'CRMInteractionType':
          return CRM_INTERACTION_TYPE.slice(0);
        case 'CRMRole':
          return CRM_ROLE.slice(0);
        case 'CRMRoleRestricted':
          return CRM_ROLE_RESTRICTED.slice(0);
        case 'CRMSubscription':
          return CRM_SUBSCRIPTION.slice(0);
        case 'CRMTaskStatus':
          return CRM_TASK_STATUS.slice(0);
        case 'PoliticallyExposedPerson':
          return POLITICALLY_EXPOSED_PERSON.slice(0);
        case 'CollectivityDocumentType':
          return COLLECTIVITY_DOCUMENT_TYPE.slice(0);
        case 'CollectivityDocumentPieceType':
          return COLLECTIVITY_DOCUMEN_PIECE_TYPE.slice(0);
        default:
          return [];
      }
    }
  }
});