define('afl/models/library-document', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    category: (0, _emberCpValidations.validator)('presence', true),
    name: (0, _emberCpValidations.validator)('presence', true),
    fileSize: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      integer: true,
      allowString: true,
      gt: 0
    })],
    publicationEndDate: (0, _emberCpValidations.validator)('date', {
      after: 'model.publicationBeginDate'
    })
  });

  var LibraryDocument = _emberData.default.Model.extend(Validations, {
    category: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    fileInfo: _emberData.default.attr({
      fileRef: _emberData.default.attr('string'),
      fileName: _emberData.default.attr('string'),
      mimeType: _emberData.default.attr('string')
    }),
    fileSize: _emberData.default.attr('number'),
    creationDate: _emberData.default.attr('localdatetime'),
    publicationBeginDate: _emberData.default.attr('localdate'),
    publicationEndDate: _emberData.default.attr('localdate'),
    unsecured: _emberData.default.attr('boolean'),

    isPublic: Ember.computed('unsecured', function () {
      return this.get('unsecured');
    })
  });

  exports.default = LibraryDocument;
});