define('afl/routes/agency/entity/detail/user/index', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    setupController: function setupController() {
      // No-op dans ce setupController car on veut que le set du model soit fait par searchFunction
    },
    resetController: function resetController(controller /*, isExiting, transition*/) {
      controller.set('removeCollectivityModal', false);
    },


    actions: {
      filter: function filter(params) {
        this.controller.set('status', params.status);
        this.controller.send('searchFunction', {
          page: 1,
          size: this.controller.get('pageSize'),
          direction: this.controller.get('direction'),
          properties: this.controller.get('sortProperty')
        });
      },
      didTransition: function didTransition() {
        var searchController = this.controllerFor('agency.entity.detail.user.index'),
            entity = this.modelFor('agency.entity.detail');

        searchController.set('aflEntity', entity);
        if (entity.get('aflEntityType') === 'PAYING_AGENT') {
          searchController.set('sortProperty', 'corporateName');
          searchController.set('isUserTab', false);
        } else {
          searchController.set('sortProperty', 'user.login');
          searchController.set('isUserTab', true);
        }

        searchController.send('searchFunction', {
          page: 1,
          size: searchController.get('pageSize'),
          direction: searchController.get('direction'),
          properties: searchController.get('sortProperty')
        });
      }
    }
  });
});