define('afl/components/crm/collectivity-display', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CollectivitiesDisplay = Ember.Component.extend({
    store: Ember.inject.service(),

    tagName: 'i',
    init: function init() {
      this._super.apply(this, arguments);
      this.send('loadCollectivities');
    },


    collectivities: Ember.computed('contacts.{[],@each.collectivity}', function () {
      var contacts = this.get('contacts');
      if (contacts) {
        return contacts.map(function (e) {
          return Ember.get(e, 'collectivity');
        });
      } else {
        return [];
      }
    }),

    collectivitiesName: Ember.computed('collectivities.{[],@each.aflEntityName}', function () {
      var collectivities = this.get('collectivities');
      if (collectivities) {
        return collectivities.map(function (e) {
          return Ember.get(e, 'aflEntityName');
        });
      } else {
        return [];
      }
    }),

    actions: {
      loadCollectivities: function loadCollectivities() {
        var _this = this;

        this.get('store').query('crm-contact', {
          personIdsIn: [this.get('person.id')],
          page: 0,
          size: 1
        }).then(function (contacts) {
          Ember.Logger.info(contacts);
          _this.set('contacts', contacts);
        });
      }
    }
  });

  CollectivitiesDisplay.reopenClass({
    positionalParams: ['person']
  });

  exports.default = CollectivitiesDisplay;
});