define('afl/routes/paying-agent/collectivity/documents/deposited', ['exports', 'afl/mixins/authenticated-route-mixin', 'afl/mixins/injected-service'], function (exports, _authenticatedRouteMixin, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _injectedService.default, {
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('entityId', this.get('currentUser.entityId'));
      controller.set('collectivityId', this.modelFor('payingAgent.collectivity').id);
      controller.set('depositorTypes', ['COLLECTIVITY']);
    }
  });
});