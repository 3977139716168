define('afl/components/grid/sort-icon', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    propertyValue: '',
    propertyName: '',
    propertyDirection: '',

    tagName: 'span',

    indicatorAsc: Ember.computed('propertyName', 'propertyDirection', function () {
      return this.get('propertyName') === this.get('propertyValue') && this.get('propertyDirection') === true;
    }),

    indicatorDesc: Ember.computed('propertyName', 'propertyDirection', function () {
      return this.get('propertyName') === this.get('propertyValue') && this.get('propertyDirection') === false;
    }),

    indicatorBoth: Ember.computed('propertyName', function () {
      return this.get('propertyName') !== this.get('propertyValue');
    }),

    classNameBindings: [':fa', 'indicatorBoth:fa-sort', 'indicatorAsc:fa-sort-asc', 'indicatorDesc:fa-sort-desc']
  });
});