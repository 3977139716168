define('afl/routes/collectivity/funding-application/create', ['exports', 'afl/mixins/authenticated-route-mixin', 'afl/mixins/injected-service'], function (exports, _authenticatedRouteMixin, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _injectedService.default, {

    fromSimulation: 'collectivity.simulation.detail',
    replicatedProperties: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.replicatedProperties = ['fundingNature', 'amount', 'duration', 'amortisationFrequency', 'amortisationMethod', 'interestFrequency', 'rateType'];
    },
    model: function model() {
      var previousRoute = this.controllerFor('application').currentRouteName;
      var fundingApplication = this.store.createRecord('fundingApplication');

      if (previousRoute === this.fromSimulation) {
        var simulation = this.modelFor(this.fromSimulation);
        for (var i = 0; i < this.replicatedProperties.length; i++) {
          fundingApplication.set(this.replicatedProperties[i], simulation.get(this.replicatedProperties[i]));
        }
        fundingApplication.set('fundingNature', 'LONG_TERM');
        fundingApplication.set('beiIndicator', true);
        fundingApplication.set('budgetType', 'PRINCIPAL');
      }

      var collectivityId = this.get('currentUser.entityId');

      this.store.find('collectivity', collectivityId).then(function (collectivity) {
        fundingApplication.set('collectivity', collectivity);
      });

      this.store.find('aflUser', 'me').then(function (aflUser) {
        fundingApplication.set('aflUser', aflUser);
      });

      return fundingApplication;
    },
    activate: function activate() {
      this.controllerFor('collectivity.fundingApplication.create').set('showDialog', false);
    },


    actions: {
      willTransition: function willTransition() {
        this.controller.set('isLoading', false);
        this.controller.get('model').deepRollback();
        return true;
      }
    }
  });
});