define('afl/components/input/select-autocomplete-option', ['exports', 'ember-power-select/components/power-select/options'], function (exports, _options) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SelectAutocompleteOptionComponent = _options.default.extend({
    canLoadMore: false,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      this.$().on('scroll', function () {
        return _this.checkVisibility();
      });
    },
    checkVisibility: function checkVisibility() {
      if (this.checkInView(this.$().find('.ember-power-select-option--load-more'))) {
        this.get('loadMore')();
      }
    },
    checkInView: function checkInView(elem) {
      if (!elem.length) {
        return false;
      }

      var container = elem.parent();
      var contHeight = container.height();

      var elemTop = elem.offset().top - container.offset().top;
      var elemBottom = elemTop + elem.height();

      var isTotal = elemTop >= 0 && elemBottom <= contHeight;
      var isPart = elemTop < 0 && elemBottom > 0 || elemTop > 0 && elemTop <= container.height();

      return isTotal || isPart;
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.$().off('scroll');
    }
  });

  exports.default = SelectAutocompleteOptionComponent;
});