define('afl/models/upload-document', ['exports', 'ember-data', 'afl/mixins/injected-service'], function (exports, _emberData, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var UploadDocument = _emberData.default.Model.extend(_injectedService.default, {
    uploadDate: _emberData.default.attr('localdatetime'),
    fileSize: _emberData.default.attr('number'),
    dosInt: _emberData.default.attr('string'),
    seqPie: _emberData.default.attr('number'),
    fileRef: _emberData.default.attr('string'),
    fileName: _emberData.default.attr('string'),
    mimeType: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    aflEntity: _emberData.default.attr('number'),
    fileNumber: _emberData.default.attr('number'),
    uploadContext: _emberData.default.attr('string'),

    topic: Ember.computed('fileNumber', function () {
      if (this.get('fileNumber')) {
        return this.get('intl').getMessage('collectivity.documents.history.funding') + " " + this.get('fileNumber');
      }
      return this.get('intl').getMessage('collectivity.documents.history.tiers');
    })
  });

  exports.default = UploadDocument;
});