define('afl/services/polling', ['exports', 'afl/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    currentUser: Ember.inject.service('current-user'),

    shouldPollContent: false,
    subscriptions: Ember.ArrayProxy.create({
      content: Ember.A(),
      contains: function contains(topic) {
        return this.findBy('topic', topic) !== undefined;
      },
      addObject: function addObject(topic, controller, property) {
        this.get('content').pushObject({ topic: topic, controller: controller, property: property });
      }
    }),

    reset: function reset() {
      this.stopPolling();
      this.get('subscriptions').clear();
    },

    subscribe: function subscribe(topic, controller, property) {
      if (!this.get('subscriptions').contains(topic)) {
        this.get('subscriptions').addObject(topic, controller, property);
      }
    },

    unsubscribe: function unsubscribe(topic) {
      if (this.get('subscriptions').contains(topic)) {
        this.get('subscriptions').removeObject(this.get('subscriptions').findBy('topic', topic));
      }
    },

    startPolling: function startPolling() {
      if (!this.get('currentUser.isImpersonate')) {
        this.set('shouldPollContent', true);
      }
      if (!Ember.isEmpty(this.get('subscriptions'))) {
        Ember.run.later(this, this._pollContent, 10);
      }
    },

    stopPolling: function stopPolling() {
      this.set('shouldPollContent', false);
    },

    _pollContent: function _pollContent() {
      var _this = this;

      var data = this._transformSubscriptions();
      Ember.$.ajax({
        url: '/api/polling',
        type: 'POST',
        timeout: _environment.default.APP.badgesTimeout,
        data: JSON.stringify(data),
        dataType: 'json',
        contentType: 'application/json; charset=utf-8'
      }).then(function (response) {
        Ember.run(function () {
          response.forEach(function (element) {
            var targetElement = _this.get('subscriptions').findBy('topic', element.topicName);
            if (!targetElement.controller.get('isDestroying') && !targetElement.controller.get('isDestroyed')) {
              Ember.set(targetElement.controller, targetElement.property, element.content);
            }
          });
        });
      }).always(function () {
        Ember.run(function () {
          if (_this.get('shouldPollContent') && !Ember.testing) {
            Ember.run.later(_this, _this._pollContent, 3000);
          }
        });
      });
    },

    _transformSubscriptions: function _transformSubscriptions() {
      var pollingData = [];

      this.get('subscriptions').forEach(function (element) {
        pollingData.push({
          topicName: element.topic,
          content: Ember.get(element.controller, element.property)
        });
      });

      return pollingData;
    }
  });
});