define('afl/controllers/agency/task/detail/index', ['exports', 'afl/mixins/has-permission', 'afl/mixins/injected-service'], function (exports, _hasPermission, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_hasPermission.default, _injectedService.default, {
    cancelConfirmVisible: false,
    completeConfirmVisible: false,

    isToProcess: Ember.computed('model.status', function () {
      return this.get('model.status') === 'TO_PROCESS';
    }),
    taskContactUrl: Ember.computed('model.id', function () {
      return '/api/crmtasks/' + this.get('model.id') + '/contact';
    }),

    actions: {
      reload: function reload() {
        var _this = this;

        this.get('model').reload().then(function (model) {
          _this.send('updateAvailableContacts', model.get('contacts'));
        });
      },
      cancelTaskConfirm: function cancelTaskConfirm() {
        this.set('cancelConfirmVisible', true);
      },
      cancelTaskProceed: function cancelTaskProceed() {
        var _this2 = this;

        Ember.$.ajax({
          url: '/api/crmtasks/' + this.get('model.id') + '/status?status=CANCELED',
          type: 'PUT'
        }).then(function () {
          Ember.get(_this2, 'toast').success(_this2.get('intl').getMessage('crm.tasks.cancel.success'));
          _this2.send('reload');
        }).fail(function () {
          _this2.set('errorMessage', _this2.get('intl').getMessage('common.unknownError'));
        });
      },
      cancelTaskClose: function cancelTaskClose() {
        this.set('cancelConfirmVisible', false);
      },
      completeTaskConfirm: function completeTaskConfirm() {
        this.set('completeConfirmVisible', true);
      },
      completeTaskProceed: function completeTaskProceed() {
        var _this3 = this;

        Ember.$.ajax({
          url: '/api/crmtasks/' + this.get('model.id') + '/status?status=DONE',
          type: 'PUT'
        }).then(function () {
          Ember.get(_this3, 'toast').success(_this3.get('intl').getMessage('crm.tasks.complete.success'));
          _this3.send('reload');
        }).fail(function () {
          _this3.set('errorMessage', _this3.get('intl').getMessage('common.unknownError'));
        });
      },
      completeTaskClose: function completeTaskClose() {
        this.set('completeConfirmVisible', false);
      }
    }
  });
});