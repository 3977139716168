define('afl/validators/future', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * Vérifie que la propriété est une date et qu'elle est dans le future
   */
  var isDateValid = function isDateValid(theDate) {
    var computedTimestamp = Date.parse(theDate);
    if (isNaN(computedTimestamp) === true) {
      return false;
    }
    var computedDate = new Date(computedTimestamp);
    return computedDate > new Date();
  };

  var Future = _base.default.extend({
    intl: Ember.inject.service(),

    validate: function validate(value) {
      var theDate = value;
      if (!Ember.isEmpty(theDate)) {
        if (theDate.getFullYear() > 9999) {
          return this.get('intl').t('validations.notValidDate');
        } else if (!isDateValid(theDate)) {
          return this.get('intl').t('validations.future');
        }
      }
      return true;
    }
  });

  exports.default = Future;
});