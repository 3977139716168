define('afl/routes/agency/contact-request/index', ['exports', 'afl/mixins/injected-service', 'afl/mixins/authenticated-route-mixin'], function (exports, _injectedService, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_injectedService.default, _authenticatedRouteMixin.default, {
    resetController: function resetController(controller /*, isExiting, transition*/) {
      controller.set('dialogExport', false);
    },


    actions: {
      searchError: function searchError() {
        Ember.get(this, 'toast').error(this.get('intl').getMessage('agency.contact.searchForm.validation'));
      }
    }
  });
});