define('afl/controllers/collectivity/funding-application/being-analysed/index', ['exports', 'afl/controllers/search', 'afl/config/environment', 'afl/mixins/injected-service'], function (exports, _search, _environment, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _search.default.extend(_injectedService.default, {
    sortProperty: 'creationDate',
    ascending: false,
    isLongTerm: true,
    // masquage de la partie ligne de trésorerie sur demande client FPLAFLINT-1200
    isTreasuryLineEnable: _environment.default.APP.isTreasuryLineEnable,

    amountKey: Ember.computed('isLongTerm', function () {
      if (this.get('isLongTerm')) {
        return 'simulation.model.amount';
      } else {
        return 'simulation.model.ceiling';
      }
    }),

    actions: {
      searchFunction: function searchFunction(searchParams) {
        var nature = 'LONG_TERM';
        if (!this.get('isLongTerm')) {
          nature = 'TREASURY';
        }
        this.set('model', this.store.query('funding-application', {
          source: 'REMOTE',
          collectivity_id: this.get('currentUser.entityId'),
          nature: nature,
          page: searchParams.page,
          size: searchParams.size,
          direction: searchParams.direction,
          properties: searchParams.properties
        }));
      },

      toggleFundingNature: function toggleFundingNature() {
        this.toggleProperty('isLongTerm');
        this.send('updateFundApps');
      },

      updateFundApps: function updateFundApps() {
        this.send('reset');
        this.set('sortProperty', 'creationDate');
        this.set('ascending', false);
        this.send('search');
      }
    }
  });
});