define('afl/models/simulation', ['exports', 'ember-data', 'afl/config/environment', 'afl/mixins/injected-service', 'ember-cp-validations'], function (exports, _emberData, _environment, _injectedService, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    fundingNature: (0, _emberCpValidations.validator)('inclusion', {
      in: ['LONG_TERM', 'TREASURY']
    }),
    rateType: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.isLongTerm').volatile()
    }), (0, _emberCpValidations.validator)('inclusion', {
      in: ['FIXED', 'VARIABLE'],
      disabled: Ember.computed.not('model.isLongTerm').volatile()
    })],
    amortisationFrequency: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.isLongTerm').volatile()
    }), (0, _emberCpValidations.validator)('inclusion', {
      in: ['QUARTERLY', 'HALF_YEARLY', 'YEARLY'],
      disabled: Ember.computed.not('model.isLongTerm').volatile()
    })],
    amortisationMethod: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.isLongTerm').volatile()
    }), (0, _emberCpValidations.validator)('inclusion', {
      in: ['CONSTANT_MATURITY', 'STRAIGHT_LINE'],
      disabled: Ember.computed.not('model.isLongTerm').volatile()
    })],
    interestFrequency: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.isLongTerm').volatile()
    }), (0, _emberCpValidations.validator)('inclusion', {
      in: ['QUARTERLY', 'HALF_YEARLY', 'YEARLY'],
      disabled: Ember.computed.not('model.isLongTerm').volatile()
    })],
    amount: [(0, _emberCpValidations.validator)(function (amount, options, model) {
      if (!/^(-|\+)?(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d*)?$/.test(amount)) {
        return 'La valeur doit être un nombre';
      }

      if (model.get('isTreasury')) {
        if (amount < _environment.default.treasury.validation.minimumAmountTreasury) {
          return model.get('intl').getMessage(this.get('model.minAmountTreasuryKey'), {
            amountMin: _environment.default.treasury.validation.minimumAmountTreasury
          });
        }
        if (amount > _environment.default.treasury.validation.maximumAmountTreasury) {
          return model.get('intl').getMessage(this.get('model.maxAmountTreasuryKey'), {
            amountMax: _environment.default.treasury.validation.maximumAmountTreasury
          });
        }
      } else {
        if (amount <= _environment.default.simulation.validation.simulationFundingMinimumAmount) {
          return model.get('intl').getMessage(this.get('model.minAmountSimulationFundingKey'), {
            amountMin: _environment.default.simulation.validation.simulationFundingMinimumAmount
          });
        }
        if (amount >= _environment.default.simulation.validation.simulationFundingMaximumAmount) {
          return model.get('intl').getMessage(this.get('model.maxAmountSimulationFundingKey'), {
            amountMax: _environment.default.simulation.validation.simulationFundingMaximumAmount
          });
        }
      }
      return true;
    }, {
      dependentKeys: ['model.isTreasury']
    }), (0, _emberCpValidations.validator)('amount')],
    duration: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.isLongTerm').volatile()
    }), (0, _emberCpValidations.validator)('number', {
      gt: _environment.default.simulation.validation.simulationFundingMinimumDuration,
      lte: _environment.default.simulation.validation.simulationFundingMaximumDuration,
      integer: true,
      allowString: true
    })]
  });

  var Simulation = _emberData.default.Model.extend(Validations, _injectedService.default, {

    // Sert à prendre en compte les validations des classes filles
    mergedProperties: ['validations'],

    creationDate: _emberData.default.attr('localdatetime'),
    fundingNature: _emberData.default.attr('string', { defaultValue: '' }),
    amount: _emberData.default.attr('number'),
    duration: _emberData.default.attr('number'),
    amortisationDuration: _emberData.default.attr('number'),
    amortisationFrequency: _emberData.default.attr('string'),
    amortisationMethod: _emberData.default.attr('string'),
    interestFrequency: _emberData.default.attr('string'),
    rateType: _emberData.default.attr('string'),
    rateReference: _emberData.default.attr('string'),
    rateLabel: _emberData.default.attr('string'),
    margin: _emberData.default.attr('number'),
    rate: _emberData.default.attr('number'),
    collectivity: _emberData.default.belongsTo('collectivity', { async: true }),
    creator: _emberData.default.belongsTo('afl-entity', { async: true }),

    isFixedRate: Ember.computed('rateType', function () {
      return this.get('rateType') === 'FIXED';
    }),

    isLongTerm: Ember.computed('fundingNature', function () {
      return this.get('fundingNature') === 'LONG_TERM';
    }),

    isTreasury: Ember.computed('fundingNature', function () {
      return this.get('fundingNature') === 'TREASURY';
    }),

    rateRounded: Ember.computed('rate', function () {
      return +(Math.round(this.get('rate') + "e+4") + "e-4");
    }),

    marginRounded: Ember.computed('margin', function () {
      return +(Math.round(this.get('margin') + "e+4") + "e-4");
    })
  });

  Simulation.reopen({
    minAmountTreasuryKey: Ember.computed('type', function () {
      if (this.get('type') === 'REFUND') {
        return 'simulation.model.minimumAmountTreasury';
      }
      return 'simulation.model.minimumAmountTreasury';
    }),

    maxAmountTreasuryKey: Ember.computed('type', function () {
      if (this.get('type') === 'REFUND') {
        return 'simulation.model.maximumAmountTreasury';
      }
      return 'simulation.model.maximumAmountTreasury';
    }),

    minAmountSimulationFundingKey: Ember.computed('type', function () {
      if (this.get('type') === 'REFUND') {
        return 'simulation.model.simulationFundingMinimumAmount';
      }
      return 'simulation.model.simulationFundingMinimumAmount';
    }),

    maxAmountSimulationFundingKey: Ember.computed('type', function () {
      if (this.get('type') === 'REFUND') {
        return 'simulation.model.simulationFundingMaximumAmount';
      }
      return 'simulation.model.simulationFundingMaximumAmount';
    }),

    minAmountFundingKey: Ember.computed('type', function () {
      if (this.get('type') === 'REFUND') {
        return 'simulation.model.longTermFundingMinimumAmount';
      }
      return 'simulation.model.longTermFundingMinimumAmount';
    }),

    maxAmountFundingKey: Ember.computed('type', function () {
      if (this.get('type') === 'REFUND') {
        return 'simulation.model.longTermFundingMaximumAmount';
      }
      return 'simulation.model.longTermFundingMaximumAmount';
    })
  });

  exports.default = Simulation;
});