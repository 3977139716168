define('afl/components/funding/funding-links', ['exports', 'afl/mixins/has-permission'], function (exports, _hasPermission) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_hasPermission.default, {
    actions: {
      sendToInstructionDocuments: function sendToInstructionDocuments() {
        this.sendAction('instructionDocumentsAction');
      },
      sendToManagementDocuments: function sendToManagementDocuments() {
        this.sendAction('managementDocumentsAction');
      },
      sendToExportSchedule: function sendToExportSchedule() {
        this.sendAction('exportScheduleAction');
      }
    }
  });
});