define('afl/routes/agency/warnings', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.initWarningList();
      controller.set('editMode', false);
      controller.set('displaySaveModal', false);
      controller.set('displayNotifyContactsModal', false);
      controller.set('displayDeleteModal', false);
      controller.set('displayPublishModal', false);
      controller.set('displayStopPublishModal', false);
      controller.set('warning', null);
    }
  });
});