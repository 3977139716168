define('afl/controllers/collectivity/col-contact/detail/index', ['exports', 'afl/mixins/injected-service', 'afl/mixins/has-permission'], function (exports, _injectedService, _hasPermission) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_injectedService.default, _hasPermission.default, {
    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.collectivity.user.detail');
    }),

    profiles: Ember.computed('model.groups', function () {
      return this.getWithDefault('model.groups', []);
    })

  });
});