define('afl/routes/collectivity', ['exports', 'afl/mixins/authenticated-route-mixin', 'afl/mixins/has-permission'], function (exports, _authenticatedRouteMixin, _hasPermission) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _hasPermission.default, {
    polling: Ember.inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (this.get('hasMailboxAccess')) {
        this.get('polling').subscribe('mailbox/count', this.controller, 'unreadMessages');
      }

      if (!Ember.testing) {
        this.get('polling').startPolling();
      }
    },
    destroy: function destroy() {
      if (this.get('hasMailboxAccess')) {
        this.get('polling').unsubscribe('mailbox/count');
      }

      if (!Ember.testing) {
        this.get('polling').stopPolling();
      }
    }
  });
});