define('afl/controllers/cgu', ['exports', 'afl/mixins/injected-service'], function (exports, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_injectedService.default, {

    displayButtons: Ember.computed('model', function () {
      return this.get('currentUser.firstName') !== undefined && !this.get('currentUser.hasAcceptedTc');
    }),

    init: function init() {
      this._super.apply(this, arguments);
    },

    actions: {
      accept: function accept() {
        var _this = this;

        var model = this.get('model');
        model.set('hasAcceptedTc', true);
        Ember.$.ajax({
          url: '/api/aflusers/consent',
          type: 'PUT',
          data: JSON.stringify(model.serialize()),
          dataType: 'json',
          contentType: 'application/json'
        }).then(function (user) {
          _this.set('currentUser.hasAcceptedTc', user.hasAcceptedTc);
          _this.transitionToRoute(Ember.String.camelize(_this.get('currentUser.entityType').toLowerCase()));
        });
      }
    },

    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.cgu');
    })

  });
});