define('afl/models/audit-record', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    identifier: (0, _emberCpValidations.validator)('presence', true),
    originEntity: (0, _emberCpValidations.validator)('presence', true), //TODO DRX inclusion: { in: ['COLLECTIVITY', 'AGENCY'] }
    collectivityContext: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('siren')]
  });

  var AuditRecord = _emberData.default.Model.extend(Validations, {
    creationDate: _emberData.default.attr('localdatetime'),
    identifier: _emberData.default.attr('string'),
    collectivityContext: _emberData.default.attr('string'),
    originUser: _emberData.default.attr('string'),
    originEntity: _emberData.default.attr('string'),
    detailsRows: _emberData.default.attr('array'),
    event: _emberData.default.attr('string'),
    objectType: _emberData.default.attr('string'),
    displaySiren: Ember.computed('originEntity', function () {
      return this.get('originEntity') === 'COLLECTIVITY';
    })
  });

  exports.default = AuditRecord;
});