define('afl/routes/agency/report/detail/line', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    activate: function activate() {
      var searchController = this.controllerFor('agency.report.detail.line'),
          report = this.modelFor('agency.report.detail');

      searchController.set('report', report);
      searchController.send('searchFunction', {
        page: 1,
        size: searchController.get('pageSize'),
        direction: searchController.get('direction'),
        properties: searchController.get('sortProperty')
      });
    },
    setupController: function setupController() {
      // No-op dans ce setupController car on veut que le set du model soit fait par searchFunction
    },


    actions: {
      reload: function reload() {
        var params = this.paramsFor('agency.report.detail');

        this.controller.send('searchFunction', {
          page: 1,
          size: this.controller.get('pageSize'),
          direction: this.controller.get('direction'),
          properties: this.controller.get('sortProperty')
        });
        this.controller.set('report', this.store.find('userImport', params.report_id));
      }
    }
  });
});