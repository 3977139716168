define('afl/controllers/collectivity/simulation/detail', ['exports', 'afl/mixins/injected-service', 'afl/mixins/has-permission'], function (exports, _injectedService, _hasPermission) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_injectedService.default, _hasPermission.default, {
    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.collectivity.simulation.detail');
    }),

    plusSign: Ember.computed('model.margin', function () {
      return this.get('model.margin') > 0 ? "+" : "";
    })
  });
});