define('afl/controllers/paying-agent/collectivity/fundings/long-term/index', ['exports', 'afl/mixins/has-permission', 'afl/controllers/search'], function (exports, _hasPermission, _search) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _search.default.extend(_hasPermission.default, {
    sortProperty: 'fileNumber',
    ascending: false,
    collectivity: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.collectivity = {};
    },
    actions: {
      searchFunction: function searchFunction(searchParams) {
        var collectivityId = this.get('collectivity').get('id');

        this.set('model', this.store.query('loan', {
          collectivity_id: collectivityId,
          loan_type: 'LONG_TERM',
          page: searchParams.page,
          size: searchParams.size,
          direction: searchParams.direction,
          properties: searchParams.properties
        }));
      }
    }
  });
});