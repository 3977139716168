define('afl/controllers/agency/report/detail/line', ['exports', 'afl/controllers/search'], function (exports, _search) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _search.default.extend({
    sortProperty: 'lineNumber',
    report: null,

    reloadAvailable: Ember.computed('report.status', function () {
      var status = this.get('report.status');
      return status === 'CREATED' || status === 'IN_PROGRESS';
    }),

    actions: {
      searchFunction: function searchFunction(searchParams) {
        this.set('model', this.store.query('userImportDetail', {
          report: this.get('report.id'),
          page: searchParams.page,
          size: searchParams.size,
          direction: searchParams.direction,
          properties: searchParams.properties
        }));
      }
    }
  });
});