define('afl/controllers/contact/search', ['exports', 'afl/mixins/search-collectivity'], function (exports, _searchCollectivity) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_searchCollectivity.default, {
    collectivity: '',
    isPopinVisible: false,

    actions: {
      cancel: function cancel() {
        this.set('searchCollectivityCategoryFilter', '');
        this.transitionToRoute('index');
      },
      onSelectedCollectivity: function onSelectedCollectivity(collectivity) {
        if (!Ember.isEmpty(collectivity)) {
          this.send('select', collectivity);
        }
      }
    }
  });
});