define('afl/components/mailbox/message-list', ['exports', 'afl/mixins/injected-service', 'afl/mixins/has-permission', 'ember-cp-validations'], function (exports, _injectedService, _hasPermission, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    subjectModified: (0, _emberCpValidations.validator)('presence', true),
    subjectModifiedComplement: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.hasSubjectModifiedComplement').volatile()
    })
  });

  /**
   Ce composant permet d'afficher la liste des messages depuis la messagerie. Voici la liste des paramètres de ce composant:
  
   - `model`: la liste des messages à afficher
   - `searchAction`: l'action qui permet d'effectuer une recherche de messages
   - `newPageAction`: l'action qui permet d'effectuer le changement de page
   - `sortAction`: l'action qui permet d'effectuer le tri
   - `redirectToCollectivityAction`: l'action qui permet d'effectuer une redirection vers une collectivité
   - `replyAction`: l'action qui permet d'effectuer une réponse à un message
   - `ascending`: paramètre de pagination - triage ascendant ou descendant
   - `sortProperty`: paramètre de pagination - propriété sur laquelle le tri est effectuée
   - `isAgency`: si le composant est utilisé dans un contexte agence [default: false]
   - `pageSize`: paramètre de pagination - nombre d'élément par page
   - `subjects`: liste des sujets possibles pour un message
   */
  exports.default = Ember.Component.extend(Validations, _injectedService.default, _hasPermission.default, {
    isAgency: false,
    subjects: null,
    subjectModified: '',
    subjectModifiedComplement: '',
    hasSubjectModifiedComplement: false,
    errorMessage: '',

    // Variable temporaire, utilisée pour les actions sur un message
    tmpMessage: null,
    // Toggles des popups
    dialogMarkAsNotRead: false,
    dialogMarkAsTreated: false,
    dialogMarkAsNotTreated: false,
    dialogModifySubject: false,
    dialogModifySubjectDefer: null,
    dialogErase: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.tmpMessage = {};
    },

    isInit: Ember.computed('model.@each', function () {
      return !this.get('model').isFulfilled;
    }),

    hasNoResult: Ember.computed('model.@each', function () {
      return this.get('model').isFulfilled && Ember.isEmpty(this.get('model'));
    }),

    modelDidChange: Ember.observer('model.@each', function () {
      this.set('dialogMarkAsNotRead', false);
      this.set('dialogMarkAsTreated', false);
      this.set('dialogMarkAsNotTreated', false);
      this.set('dialogErase', false);
    }),

    isSortedBySendDate: Ember.computed('sortProperty', function () {
      var sortProperty = this.get('sortProperty');
      return sortProperty === 'message.sendDate';
    }),

    isSortedBySenderName: Ember.computed('sortProperty', function () {
      var sortProperty = this.get('sortProperty');
      return sortProperty === 'message.fromUser.aflEntity.aflEntityName';
    }),

    resetModifySubjectForm: Ember.observer('dialogModifySubject', function () {
      if (!this.get('dialogModifySubject')) {
        this.set('dialogModifySubjectDefer', null);
        this.set('subjectModified', '');
        this.set('subjectModifiedComplement', '');
        this.set('hasSubjectModifiedComplement', false);
      }
    }),

    //choix d'un nouvel objet du message (si autre, il faut entrer le détail)
    selectSubjectModified: Ember.observer('subjectModified', function () {
      if (this.get('subjectModified') === 'OTHER') {
        this.set('hasSubjectModifiedComplement', true);
      } else {
        this.set('hasSubjectModifiedComplement', false);
        this.set('subjectModifiedComplement', '');
      }
    }),

    // Mise à jour du statut d'un message, avec callback optionnel en cas de succès
    updateMsgStatus: function updateMsgStatus(message, status, onSuccess) {
      var _this = this;

      message.set('status', status);
      message.save().then(onSuccess).catch(function (errors) {
        Ember.Logger.error('Error updating message', errors);
        Ember.get(_this, 'toast').error(_this.get('intl').getMessage('mailbox.error.cannotUpdateMessage'));
        message.rollbackAttributes();
      });
    },

    actions: {
      sendSort: function sendSort(sortProperty) {
        this.sendAction('sortAction', sortProperty);
      },

      sendNewPage: function sendNewPage(params) {
        this.sendAction('newPageAction', params);
      },

      sendRedirectToCollectivity: function sendRedirectToCollectivity(id) {
        this.sendAction('redirectToCollectivityAction', id);
      },

      // actions popup
      showDialogMarkAsNotRead: function showDialogMarkAsNotRead(message) {
        this.set('tmpMessage', message);
        this.toggleProperty('dialogMarkAsNotRead');
      },
      showDialogMarkAsTreated: function showDialogMarkAsTreated(message) {
        this.set('tmpMessage', message);
        this.toggleProperty('dialogMarkAsTreated');
      },
      showDialogMarkAsNotTreated: function showDialogMarkAsNotTreated(message) {
        this.set('tmpMessage', message);
        this.toggleProperty('dialogMarkAsNotTreated');
      },
      showDialogModifySubject: function showDialogModifySubject(message) {
        this.set('tmpMessage', message);
        this.toggleProperty('dialogModifySubject');
      },
      showDialogErase: function showDialogErase(message) {
        this.set('tmpMessage', message);
        this.toggleProperty('dialogErase');
      },

      reply: function reply(message) {
        this.sendAction('replyAction', message);
      },

      markAsRead: function markAsRead(message) {
        if (message.get('canBeMarkedAsRead') && this.get('hasMailboxAccessForAction')) {
          // Change le statut et notifie l'application pour rafraîchir le badge de messages non lus
          this.updateMsgStatus(message, 'READ');
        }
      },

      markAsNotRead: function markAsNotRead() {
        var message = this.get('tmpMessage');

        if (message.get('canBeMarkedAsNotRead')) {
          this.updateMsgStatus(message, 'NOT_READ', function () {
            // Referme le message
            Ember.$('#msg_' + message.id).collapse('hide');
          });
        } else {
          Ember.Logger.error('Error updating message: cannot be marked as not read');
          Ember.get(this, 'toast').error(this.get('intl').getMessage('mailbox.error.cannotUpdateMessage'));
        }
      },

      markAsTreated: function markAsTreated() {
        var message = this.get('tmpMessage');

        if (message.get('canBeMarkedAsTreated')) {
          this.updateMsgStatus(message, 'TREATED');
        } else {
          Ember.Logger.error('Error updating message: cannot be marked as treated');
          Ember.get(this, 'toast').error(this.get('intl').getMessage('mailbox.error.cannotUpdateMessage'));
        }
      },

      markAsNotTreated: function markAsNotTreated() {
        var message = this.get('tmpMessage');

        if (message.get('canBeMarkedAsNotTreated')) {
          // Change le statut et notifie l'application pour rafraîchir le badge de messages non lus
          // (Non traité = lu)
          this.updateMsgStatus(message, 'READ');
        } else {
          Ember.Logger.error('Error updating message: cannot be marked as not treated');
          Ember.get(this, 'toast').error(this.get('intl').getMessage('mailbox.error.cannotUpdateMessage'));
        }
      },

      // action lors du clic sur le bouton 'confirmer' de la popin 'Modifier l'objet du message'
      validateModifySubjectForm: function validateModifySubjectForm(defer) {
        // on garde la propriete 'defer' de la popin pour la fermer ou la laisser ouverte selon le resultat de validation du formulaire
        this.set('dialogModifySubjectDefer', defer);
        // on soumet le formulaire de validation
        this.get('modifySubjectForm').send('submit');
      },

      // en cas d'echec sur le formulaire de validation
      modifySubjectFormValidationError: function modifySubjectFormValidationError() {
        // rejet de la validation -> on remet a disposition le bouton 'confirmer'
        this.get('dialogModifySubjectDefer').reject();
      },

      // confirmation du changement d'objet via la popin, on met à jour le message
      confirmModifiySubject: function confirmModifiySubject() {
        var _this2 = this;

        var message = this.get('tmpMessage');

        message.set('subject', this.get('subjectModified'));
        if (this.get('hasSubjectModifiedComplement')) {
          message.set('subjectComplement', this.get('subjectModifiedComplement'));
        } else {
          message.set('subjectComplement', '');
        }

        message.save().then(function () {
          _this2.sendAction('searchAction');
          Ember.get(_this2, 'toast').success(_this2.get('intl').getMessage('mailbox.success.messageSubjectUpdated'));
        }).catch(function (errors) {
          Ember.Logger.error('Error updating message', errors);
          Ember.get(_this2, 'toast').error(_this2.get('intl').getMessage('mailbox.error.cannotUpdateMessage'));
          message.rollbackAttributes();
        });

        this.get("dialogModifySubjectDefer").resolve();
      },

      erase: function erase() {
        var _this3 = this;

        var message = this.get('tmpMessage');

        if (message.get('canBeErased')) {
          message.destroyRecord().then(function () {
            Ember.get(_this3, 'toast').success(_this3.get('intl').getMessage('mailbox.success.messageErased'));
          }).catch(function (errors) {
            Ember.Logger.error("Error deleting message", errors);
            Ember.get(_this3, 'toast').error(_this3.get('intl').getMessage('mailbox.error.cannotEraseMessage'));
            message.rollbackAttributes();
          });
        } else {
          Ember.Logger.error('Error updating message: cannot be erased');
          Ember.get(this, 'toast').error(this.get('intl').getMessage('mailbox.error.cannotEraseMessage'));
        }
      },

      download: function download(message) {
        this.sendAction('downloadDocument', message);
      }
    }
  });
});