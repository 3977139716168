define('afl/routes/agency/person/detail/contact/index', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    activate: function activate() {
      var searchController = this.controllerFor('agency.person.detail.contact.index');
      var person = this.modelFor('agency.person.detail');

      searchController.set('person', person);
      searchController.send('searchFunction', {
        page: 1,
        size: searchController.get('pageSize'),
        direction: searchController.get('direction'),
        properties: searchController.get('sortProperty')
      });
    },
    setupController: function setupController() /*controller, model*/{
      // No-op dans ce setupController car on veut que le set du model soit fait par searchFunction
    },
    resetController: function resetController(controller /*, isExiting, transition*/) {
      controller.set('status', 'ACTIVE');
      controller.set('dialogExport', false);
    }
  });
});