define('afl/controllers/agency/documents/received', ['exports', 'afl/controllers/search', 'afl/mixins/injected-service', 'afl/mixins/has-permission', 'afl/utils/afl-constants'], function (exports, _search, _injectedService, _hasPermission, _aflConstants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _search.default.extend(_injectedService.default, _hasPermission.default, {
    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.agency.documents.received');
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('collectivityDocumentType', (0, _aflConstants.default)('CollectivityDocumentType'));
      this.set('depositorTypes', ['COLLECTIVITY']);
    }
  });
});