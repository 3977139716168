define('afl/routes/collectivity/account/edit', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service('current-user'),

    model: function model() {
      return this.store.findRecord('afl-user', this.get('currentUser.user.id'));
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.store.query('group', { entityType: model.get('entityType') }).then(function (groups) {
        controller.set('groups', groups.toArray());
      });
    }
  });
});