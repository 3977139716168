define('afl/components/input/group-checkbox', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    value: '',
    multiple: true,
    inputId: '',
    name: '',
    _resetNeeded: true,

    didInsertElement: function didInsertElement() {
      this.send('reset');
    },

    reset: Ember.observer('selectedElements.[]', function () {
      if (this.get('_resetNeeded')) {
        this.send('reset');
      }
    }),

    actions: {
      toggle: function toggle() {
        var selectedElements = this.get('selectedElements'),
            checked = this.$(':input').is(':checked');

        this.set('_resetNeeded', false);
        if (checked) {
          if (!this.get('multiple')) {
            selectedElements.clear();
          }
          selectedElements.push(this.get('value'));
        } else {
          selectedElements.splice(selectedElements.indexOf(this.get('value')), 1);
        }
        this.set('_resetNeeded', true);
      },
      reset: function reset() {
        var selectedElements = this.get('selectedElements'),
            checked = selectedElements.indexOf(this.get('value')) !== -1;

        this.$(':input').prop('checked', checked);
      }
    }
  });
});