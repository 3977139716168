define('afl/components/crm/add-comment', ['exports', 'afl/mixins/injected-service', 'ember-cp-validations', 'afl/config/environment', 'afl/tinymce/plugins/charcount'], function (exports, _injectedService, _emberCpValidations, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    commentContent: [(0, _emberCpValidations.validator)('presence', true)]
  });

  var CommentObject = Ember.Object.extend(Validations, {
    commentContent: ''
  });

  /* global tinymce:true */
  var AddComment = Ember.Component.extend(_injectedService.default, {
    tagName: '',
    commentTitle: 'crm.comments.create.title',
    contentLimit: _environment.default.commentCreate.contentLimit,

    newCommentAdded: function newCommentAdded() {
      return this;
    },

    targetQueryParams: Ember.computed('comment.commentContent', function () {
      var comment = this.get('comment.commentContent');
      return Ember.$.param({ comment: comment }, true);
    }).readOnly(),

    open: Ember.observer('addComment', function () {
      this.set('comment', CommentObject.create(Ember.getOwner(this).ownerInjection()));
      if (this.get('updatedComment')) {
        this.set('commentTitle', 'crm.comments.update.title');
        this.set('comment.commentContent', this.get('updatedComment').comment);
      } else {
        this.set('commentTitle', 'crm.comments.create.title');
      }
      tinymce.activeEditor.setContent(this.get('comment.commentContent'));
      this.set('errorMessage', '');
    }),

    actions: {
      close: function close() {
        this.set('addComment', false);
        this.set('didValidate', false);
      },
      formSubmit: function formSubmit(defer) {
        this.set('comment.commentContent', tinymce.activeEditor.getContent());
        this.get('commentForm').send('submit');
        this.set('formDefer', defer);
      },
      formError: function formError() {
        this.get('formDefer').reject();
      },
      formSuccess: function formSuccess() {
        var _this = this;

        if (tinymce.activeEditor.plugins.charcount.getCount() <= this.contentLimit) {
          this.set('comment.commentContent', this.get('comment.commentContent').replace('href="https://', 'href="https:\\/\\/').replace('href="http://', 'href="http:\\/\\/'));
          if (this.get('updatedComment')) {
            Ember.$.ajax({
              url: this.get('targetUrl') + '/' + this.get('updatedComment').uid + '/update',
              type: 'PUT',
              data: this.get('comment.commentContent'),
              contentType: 'text/plain'
            }).then(function () {
              Ember.get(_this, 'toast').success(_this.get('intl').getMessage('crm.comments.update.success'));
              _this.set('addComment', false);
              _this.set('didValidate', false);
              _this.get('formDefer').resolve();
              _this.get('newCommentAdded')();
            }, function () {
              Ember.get(_this, 'toast').error(_this.get('intl').getMessage('crm.comments.delete.failure'));
              _this.get('formDefer').reject();
            });
          } else {
            Ember.$.ajax({
              url: this.get('targetUrl'),
              type: 'PUT',
              data: this.get('comment.commentContent'),
              contentType: 'text/plain'
            }).then(function () {
              Ember.get(_this, 'toast').success(_this.get('intl').getMessage('crm.comments.create.success'));
              _this.set('addComment', false);
              _this.set('didValidate', false);
              _this.get('formDefer').resolve();
              _this.get('newCommentAdded')();
            }).fail(function () {
              _this.set('errorMessage', _this.get('intl').getMessage('crm.comments.create.failure'));
              _this.get('formDefer').reject();
            });
          }
        }
      },
      changeText: function changeText(body) {
        this.set('comment.commentContent', body);
      }
    }
  });

  exports.default = AddComment;
});