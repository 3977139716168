define('afl/routes/agency/contact/detail/edit', ['exports', 'afl/mixins/authenticated-route-mixin', 'afl/mixins/has-permission', 'afl/mixins/injected-service'], function (exports, _authenticatedRouteMixin, _hasPermission, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_injectedService.default, _authenticatedRouteMixin.default, _hasPermission.default, {
    model: function model() {
      var contact = this.modelFor('agency.contact.detail');
      if (this.get('hasUsersAccessForAction')) {
        return this.store.find('col-contact', contact.id);
      } else {
        return contact;
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (this.get('hasUsersAccessForAction')) {

        this.store.query('group', { entityType: model.get('entityType') }).then(function (groups) {
          controller.set('groups', groups.toArray());
        });
      }
    }
  });
});