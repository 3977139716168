define('afl/routes/application', ['exports', 'afl/mixins/injected-service', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _injectedService, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_injectedService.default, _applicationRouteMixin.default, {
    activate: function activate() {
      this.controllerFor('application').set('dialogLogout', false);
    },
    resetController: function resetController(controller /*, isExiting, transition*/) {
      controller.set('dialogLogout', false);
    },
    beforeModel: function beforeModel() {
      this.get('intl').setLocale('fr-fr');
      return this._loadSession();
    },
    sessionAuthenticated: function sessionAuthenticated() {
      this.controllerFor('index').set('captchaNeeded', false);
      this._loadSession(this._transition.bind(this));
    },
    _loadSession: function _loadSession() {
      var _this = this;

      var transition = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function () {
        return _this;
      };

      return this.get('currentUser').load(transition);
    },
    _transition: function _transition(user) {
      switch (user.get('entityType')) {
        case 'AGENCY':
          this.selfTransitionOrDefault('agency');
          break;
        case 'COLLECTIVITY':
          if (user.get('isProspect')) {
            this.selfTransitionOrDefault('collectivity.prospect');
          } else {
            this.selfTransitionOrDefault('collectivity');
          }
          break;
        case 'PAYING_AGENT':
          var collectivities = user.get('accountedCollectivitiesId');
          if (collectivities && collectivities.get('length') === 1) {
            this.selfTransitionOrDefault('payingAgent.collectivity', collectivities.get('firstObject'));
          } else {
            this.selfTransitionOrDefault('payingAgent');
          }
          break;
      }
    },
    selfTransitionOrDefault: function selfTransitionOrDefault(target) {
      var model = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      var attemptedTransition = this.get('session').get('attemptedTransition');
      if (attemptedTransition) {
        attemptedTransition.retry();
        this.get('session').set('attemptedTransition', null);
      } else {
        if (model) {
          this.transitionTo(target, model);
        } else {
          this.transitionTo(target);
        }
      }
    },


    actions: {
      sessionLogout: function sessionLogout() {
        this.get('currentUser').logout();
      },
      impersonate: function impersonate(user) {
        var _this2 = this;

        this.get('currentUser').impersonate(user.get('login'), function () {
          _this2.transitionTo('index');
        }, function (message) {
          Ember.get(_this2, 'toast').error(message);
        });
      },
      impersonateLogout: function impersonateLogout() {
        var _this3 = this;

        this.get('currentUser').impersonateLogout(function () {
          _this3.transitionTo('index');
        }, function (message) {
          Ember.get(_this3, 'toast').error(message);
        });
      },
      error: function error(err) {
        Ember.Logger.debug(err);
        if (err.errors && err.errors[0]) {
          var detail = err.errors[0].detail;
          Ember.get(this, 'toast').error(detail.message || err.status + ' ' + err.title);
        } else {
          Ember.get(this, 'toast').error(this.get('intl').getMessage('common.unknownError'));
        }
        this.transitionTo('index');
        return true;
      }
    }
  });
});