define('afl/controllers/agency/entity/create', ['exports', 'afl/mixins/injected-service'], function (exports, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_injectedService.default, {
    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.agency.entity.payingAgent.create');
    }),

    actions: {
      afterSuccess: function afterSuccess() {
        this.transitionToRoute('agency.entity.detail', this.get('model.type'), this.get('model.id'));
      },

      afterCancel: function afterCancel() {
        this.transitionToRoute('agency.entity');
      }
    }
  });
});