define('afl/routes/agency/collectivity/detail/simulation/create', ['exports', 'afl/mixins/authenticated-route-mixin', 'afl/mixins/injected-service'], function (exports, _authenticatedRouteMixin, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _injectedService.default, {
    model: function model() {
      var simulation = this.store.createRecord('simulation', {
        fundingNature: 'LONG_TERM',
        amortisationFrequency: 'QUARTERLY',
        amortisationMethod: 'CONSTANT_MATURITY',
        interestFrequency: 'QUARTERLY',
        rateType: 'FIXED'
      });
      this.store.find('aflEntity', this.get('currentUser.entityId')).then(function (entity) {
        simulation.set('creator', entity);
      });
      var collectivity = this.modelFor('agency.collectivity.detail');
      simulation.set('collectivity', collectivity);
      return simulation;
    },


    actions: {
      willTransition: function willTransition() {
        this.controller.set('isLoading', false);
        return true;
      }
    }
  });
});