define('afl/controllers/collectivity/fundings/mobilisation/detail-phase1', ['exports', 'afl/controllers/collectivity/fundings/treasury/detail'], function (exports, _detail) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _detail.default.extend({

    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.collectivity.fundings.mobilisation.detailPhase1');
    })
  });
});