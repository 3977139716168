define('afl/controllers/agency/event/detail/edit', ['exports', 'afl/mixins/search-collectivity', 'afl/mixins/search-user', 'afl/mixins/injected-service', 'afl/utils/afl-constants'], function (exports, _searchCollectivity, _searchUser, _injectedService, _aflConstants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_injectedService.default, _searchCollectivity.default, _searchUser.default, {
    contactRoles: (0, _aflConstants.default)('CRMRole'),
    interactionMethods: (0, _aflConstants.default)('CRMInteractionMethod'),
    interactionNatures: null,
    collectivityCategories: (0, _aflConstants.default)('CollectivityCategory'),
    users: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.interactionNatures = [];
      this.users = [];
    },

    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.agency.event.edit');
    }),

    actions: {
      saveEvent: function saveEvent() {
        var _this = this;

        this.get('model').save().then(function (event) {
          Ember.get(_this, 'toast').success(_this.get('intl').getMessage('crm.events.edit.success'));
          _this.transitionToRoute('agency.event.detail', event);
        }).catch(function (errors) {
          var error = errors.errors[0] || {},
              message = _this.get('intl').getMessage('crm.events.edit.error');

          if (error.detail && error.detail.message) {
            message = error.detail.message;
          }

          Ember.get(_this, 'toast').error(message);
        });
      },
      cancel: function cancel() {
        this.transitionToRoute('agency.event.detail');
      },
      validationErrors: function validationErrors() {
        Ember.get(this, 'toast').error(this.get('intl').getMessage('crm.events.edit.validation'));
      }
    }
  });
});