define('afl/routes/agency/funding-application/detail/edit', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      return this.modelFor('agency.fundingApplication.detail');
    },


    actions: {
      willTransition: function willTransition() {
        this.controller.set('isLoading', false);
        this.controller.get('model').deepRollback();
        return true;
      }
    }
  });
});