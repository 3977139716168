define('afl/controllers/application', ['exports', 'afl/mixins/injected-service'], function (exports, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_injectedService.default, {
    isMenuOpen: false,
    dialogLogout: false,
    isLoadingPage: true,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      if (Ember.testing) {
        this.set('isLoadingPage', false);
      } else {
        // Ajout des enums du back dans intl
        Ember.$.ajax({
          method: 'GET',
          url: '/api/enums'
        }).done(function (data) {
          Ember.run(function () {
            _this.get('intl').addTranslations('fr-fr', { 'enums': data });
          });
        }).always(function () {
          Ember.run(function () {
            _this.set('isLoadingPage', false);
          });
        });
      }
    },

    breadCrumb: Ember.computed('session.isAuthenticated', function () {
      // Display Home link only for non authenticated users
      if (!this.get('session.isAuthenticated')) {
        return this.get('intl').getMessage('breadcrumb.home');
      }
    }),

    isAgency: Ember.computed('currentPath', function () {
      return this.get('session.isAuthenticated') && this.currentPath && this.currentPath.indexOf('agency') === 0;
    }),

    isCollectivity: Ember.computed('currentPath', function () {
      return this.get('session.isAuthenticated') && this.currentPath && this.currentPath.indexOf('collectivity') === 0;
    }),

    isPayingAgent: Ember.computed('currentPath', function () {
      return this.get('session.isAuthenticated') && this.currentPath && this.currentPath.indexOf('payingAgent') === 0;
    }),

    doubleGrade: Ember.computed('currentUser.collectivity.grade', function () {
      var grade = this.get('currentUser.collectivity.grade');
      if (grade) {
        return Math.min(grade * 2, 12);
      }
      return 0;
    }),

    actions: {
      toggleMenu: function toggleMenu() {
        this.toggleProperty('isMenuOpen');
      },
      closeMenu: function closeMenu() {
        this.set('isMenuOpen', false);
      },
      logout: function logout() {
        this.toggleProperty('dialogLogout');
      },
      confirmLogout: function confirmLogout() {
        this.send('sessionLogout');
      }
    }
  });
});