define('afl/routes/agency/entity/create', ['exports', 'afl/mixins/injected-service', 'afl/mixins/authenticated-route-mixin'], function (exports, _injectedService, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_injectedService.default, _authenticatedRouteMixin.default, {
    model: function model() {
      return this.store.createRecord('payingAgent', { aflEntityType: 'PAYING_AGENT' });
    },


    actions: {
      validationErrors: function validationErrors() {
        Ember.get(this, 'toast').error(this.get('intl').getMessage('common.entity.payingAgentForm.error'));
      },
      willTransition: function willTransition() {
        this.controller.get('model').rollbackAttributes();
        return true;
      }
    }
  });
});