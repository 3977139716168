define('afl/components/menu/menu-agency', ['exports', 'afl/mixins/afl-menu', 'afl/mixins/has-permission'], function (exports, _aflMenu, _hasPermission) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_aflMenu.default, _hasPermission.default, {
    hasPendingContactRequests: Ember.computed('pendingContactRequests', function () {
      return this.get('pendingContactRequests') > 0;
    }),

    hasPendingFundingApplications: Ember.computed('pendingFundingApplications', function () {
      return this.get('pendingFundingApplications') > 0;
    }),

    hasUnreadMessages: Ember.computed('unreadMessages', function () {
      return this.get('unreadMessages') > 0;
    }),

    hasPendingTasks: Ember.computed('pendingTasks', function () {
      return this.get('pendingTasks') > 0;
    }),

    hasNotViewDocuments: Ember.computed('notViewDocumentNumber', function () {
      return this.get('notViewDocumentNumber') > 0;
    })
  });
});