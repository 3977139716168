define('afl/controllers/contact/form', ['exports', 'afl/utils/afl-constants'], function (exports, _aflConstants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    collectivity: null,
    responses: null,
    roleList: null,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.collectivity = {};
      this.responses = {};
      this.roleList = (0, _aflConstants.default)('UserRole');
      Ember.$.get('/api/contactrequests/responses').then(function (data) {
        Ember.run(function () {
          return _this.set('responses', data);
        });
      });
    },

    contactRequestResponses: Ember.computed('responses', function () {
      return Object.keys(this.get('responses'));
    }),

    responseNeededChanged: Ember.observer('model.response', function () {
      this.set('model.responseDetail', null);
      if (this.get('responses').hasOwnProperty(this.get('model.response'))) {
        this.set('model.responseNeeded', this.get('responses.' + this.get('model.response')));
      } else {
        this.set('model.responseNeeded', false);
      }
    }),

    actions: {
      changeHasAcceptedCgu: function changeHasAcceptedCgu() {
        this.get('model').toggleProperty('hasAcceptedTc');
      }
    }
  });
});