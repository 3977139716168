define('afl/controllers/collectivity/col-contact/create', ['exports', 'afl/controllers/search', 'afl/mixins/injected-service'], function (exports, _search, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _search.default.extend(_injectedService.default, {

    init: function init() {
      this._super.apply(this, arguments);
      this.groups = [];
    },

    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.collectivity.user.create');
    }),

    actions: {
      afterSuccess: function afterSuccess() {
        this.transitionToRoute('collectivity.colContact');
      }
    }
  });
});