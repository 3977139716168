define('afl/models/crm-person', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    civility: (0, _emberCpValidations.validator)('presence', true),
    firstName: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('word')],
    lastName: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('word')],
    email: (0, _emberCpValidations.validator)('email'),
    phoneNumber: (0, _emberCpValidations.validator)('phone'),
    mobileNumber: (0, _emberCpValidations.validator)('mobile'),
    pep: (0, _emberCpValidations.validator)('presence', true)
  });

  var CRMPerson = _emberData.default.Model.extend(Validations, {
    civility: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    phoneNumber: _emberData.default.attr('string'),
    mobileNumber: _emberData.default.attr('string'),
    pep: _emberData.default.attr('string', { defaultValue: 'NO' }),
    comments: _emberData.default.attr(),

    fullName: Ember.computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    })
  });

  exports.default = CRMPerson;
});