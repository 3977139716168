define('afl/components/collapse-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    intl: Ember.inject.service(),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      Ember.run.schedule('afterRender', this, function () {
        _this.set('content', Ember.$(_this.get('elementToCollapse')));
        _this.set('rendered', true);
        _this.toggleCollapsed();
      });
    },


    collapsedChange: Ember.observer('collapsed', function () {
      if (this.get('rendered')) {
        this.toggleCollapsed();
      }
    }),

    toggleCollapsed: function toggleCollapsed() {
      if (this.get('collapsed')) {
        this.get('content').slideUp(500);
        this.set('title', this.get('intl').t('component.collapse-button.show'));
      } else {
        this.get('content').slideDown(500);
        this.set('title', this.get('intl').t('component.collapse-button.hide'));
      }
    },


    actions: {
      onClick: function onClick() {
        this.toggleProperty('collapsed');
      }
    }
  });
});