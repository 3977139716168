define('afl/routes/collectivity/simulation/create', ['exports', 'afl/mixins/authenticated-route-mixin', 'afl/mixins/injected-service'], function (exports, _authenticatedRouteMixin, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _injectedService.default, {
    model: function model() {
      var simulation = this.store.createRecord('simulation', {
        fundingNature: 'LONG_TERM',
        amortisationFrequency: 'QUARTERLY',
        amortisationMethod: 'CONSTANT_MATURITY',
        interestFrequency: 'QUARTERLY',
        rateType: 'FIXED'
      });

      this.store.find('collectivity', this.get('currentUser.entityId')).then(function (collectivity) {
        simulation.set('collectivity', collectivity);
        simulation.set('creator', collectivity);
      });

      return simulation;
    },


    actions: {
      willTransition: function willTransition() {
        this.controller.set('isLoading', false);
        //      this.controller.get('model').rollbackAttributes();
        return true;
      }
    }
  });
});