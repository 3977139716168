define('afl/routes/collectivity/mailbox', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    setupController: function setupController(controller) {
      controller.send('search');
    },
    activate: function activate() {
      var searchController = this.controllerFor('collectivity.mailbox');
      Ember.$.get('/api/messages/subjects').then(function (data) {
        Ember.run(function () {
          searchController.set('subjects', data);
          searchController.send('search');
        });
      });
    },
    deactivate: function deactivate() {
      var searchController = this.controllerFor('collectivity.mailbox');
      searchController.set('showSendMessageForm', false);
    }
  });
});