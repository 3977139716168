define('afl/mixins/injected-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service('current-user'),
    toast: Ember.inject.service()
  });
});