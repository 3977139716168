define('afl/controllers/agency/event/detail', ['exports', 'afl/mixins/has-permission', 'afl/mixins/injected-service'], function (exports, _hasPermission, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_hasPermission.default, _injectedService.default, {
    breadCrumb: Ember.computed('model.title', function () {
      return this.get('model.title');
    })
  });
});