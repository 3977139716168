define('afl/models/afl-user', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    civility: (0, _emberCpValidations.validator)('presence', true),
    firstName: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('word')],
    lastName: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('word')],
    role: (0, _emberCpValidations.validator)('presence', true),
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('email')],
    phoneNumber: [(0, _emberCpValidations.validator)('phone')],
    mobileNumber: [(0, _emberCpValidations.validator)('mobile')],
    signBase64: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.isSigner').volatile(),
      message: "Un certificat RGS** en cours de validité est obligatoire afin d'attribuer le profil de signataire"
    })
  });

  var AflUser = _emberData.default.Model.extend(Validations, {
    civility: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    login: _emberData.default.attr('string'),
    role: _emberData.default.attr('string'),
    phoneNumber: _emberData.default.attr('string'),
    mobileNumber: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    creationDate: _emberData.default.attr('localdatetime'),
    lastStatusUpdate: _emberData.default.attr('localdatetime'),
    user: _emberData.default.attr('number'),
    groups: _emberData.default.hasMany('group'),
    subscriptions: _emberData.default.attr(),

    // signature elements
    signCommonName: _emberData.default.attr('string'),
    signGivenName: _emberData.default.attr('string'),
    signSurName: _emberData.default.attr('string'),
    signSerialNumber: _emberData.default.attr('string'),
    signExpirationDate: _emberData.default.attr('localdatetime'),
    signBase64: _emberData.default.attr('string'),

    // computed data from server
    entityType: _emberData.default.attr('string'),
    entityId: _emberData.default.attr('number'),
    isMember: _emberData.default.attr('boolean'),
    isProspect: _emberData.default.attr('boolean'),
    status: _emberData.default.attr('string'),
    rights: _emberData.default.attr('array'),
    isAdmin: _emberData.default.attr('boolean'),
    accountedCollectivitiesId: _emberData.default.attr('array'),
    isSigner: _emberData.default.attr('boolean'),
    isImpersonate: _emberData.default.attr('boolean'),
    originalUsername: _emberData.default.attr('string'),

    fullName: Ember.computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    }),

    presencePhone: Ember.computed('phoneNumber', function () {
      return !Ember.isEmpty(Ember.get(this, 'phoneNumber'));
    }),

    presenceMobile: Ember.computed('mobileNumber', function () {
      return !Ember.isEmpty(Ember.get(this, 'mobileNumber'));
    }),

    canSign: Ember.computed('signBase64', function () {
      return !Ember.isEmpty(Ember.get(this, 'signBase64'));
    }),

    isCollectivityUser: Ember.computed('entityType', function () {
      return this.get('entityType') === 'COLLECTIVITY';
    }),

    isAgencyUser: Ember.computed('entityType', function () {
      return this.get('entityType') === 'AGENCY';
    }),

    autoCompleteDisplay: Ember.computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    })
  });

  exports.default = AflUser;
});