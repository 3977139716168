define('afl/routes/password/otp', ['exports', 'afl/mixins/injected-service', 'afl/mixins/unauthenticated-route'], function (exports, _injectedService, _unauthenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_injectedService.default, _unauthenticatedRoute.default, {
    uid: '',
    passwordCreated: false,

    beforeModel: function beforeModel(transition) {
      this.set('uid', transition.queryParams.token_id);

      if (this.get('uid') === undefined || this.get('uid') === null) {
        Ember.get(this, 'toast').error(this.get('intl').getMessage('user.createForm.noToken'));
        this.transitionTo('index');
      }
    },
    activate: function activate() {
      var _this = this;

      Ember.$.get("/api/aflusers/otp", { token: this.get('uid') }).then(function (response) {
        Ember.run(function () {
          Ember.get(_this, 'toast').success(response.message);
        });
      }).fail(function (response) {
        Ember.run(function () {
          Ember.get(_this, 'toast').error(response.responseJSON.message);
          _this.transitionTo('index');
        });
      });
    },

    actions: {
      otpValidation: function otpValidation() {
        var _this2 = this;

        Ember.$.post("/api/aflusers/otp", { otp: this.controller.get('otp'), token: this.get('uid') }, function () {
          Ember.run(function () {
            _this2.controller.toggleProperty('isOtpValidated');
          });
        }).fail(function (response) {
          Ember.run(function () {
            Ember.get(_this2, 'toast').error(response.responseJSON.message);
          });
        });
      },
      otpValidationErrors: function otpValidationErrors() {
        Ember.get(this, 'toast').error(this.get('intl').getMessage('user.forgottenForm.invalid'));
      },
      updatePassword: function updatePassword() {
        var _this3 = this;

        Ember.$.post("/api/aflusers/otp/password", {
          password: this.controller.get('password'),
          confirmPassword: this.controller.get('confirmPassword'),
          uid: this.get('uid'),
          otp: this.controller.get('otp')
        }, function () {
          Ember.run(function () {
            Ember.get(_this3, 'toast').success(_this3.get('intl').getMessage('common.account.passwordForm.success'));
            _this3.transitionTo('index');
          });
        }).fail(function (response) {
          Ember.run(function () {
            Ember.get(_this3, 'toast').error(response.responseJSON.message);
          });
        });
      },
      updatePasswordErrors: function updatePasswordErrors() {
        Ember.get(this, 'toast').error(this.get('intl').getMessage('user.forgottenForm.invalid'));
      }
    }

  });
});