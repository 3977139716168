define('afl/models/user-import', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var UserImport = _emberData.default.Model.extend({
    fileName: _emberData.default.attr('string'),
    fileReceivedDate: _emberData.default.attr('localdatetime'),
    fileLastUpdatedDate: _emberData.default.attr('localdatetime'),
    status: _emberData.default.attr('string'),
    nbRequest: _emberData.default.attr('number'),
    nbCreation: _emberData.default.attr('number'),
    nbReject: _emberData.default.attr('number')
  });

  exports.default = UserImport;
});