define('afl/controllers/collectivity/user/index', ['exports', 'afl/controllers/search', 'afl/mixins/injected-service'], function (exports, _search, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _search.default.extend(_injectedService.default, {
    status: 'ENABLED',
    sortProperty: 'user.login',

    aflEntityId: Ember.computed('currentUser.entityId', function () {
      return this.get('currentUser.entityId');
    }),

    entityStatus: Ember.computed('currentUser.collectivity.status', function () {
      return this.get('currentUser.collectivity.status');
    }),

    aflEntityType: Ember.computed('currentUser.collectivity.aflEntityType', function () {
      return this.get('currentUser.collectivity.aflEntityType');
    }),

    actions: {
      searchFunction: function searchFunction(searchParams) {
        this.set('model', this.store.query('aflUser', {
          aflEntity: this.get('aflEntityId'),
          status: this.get('status'),
          page: searchParams.page,
          size: searchParams.size,
          direction: searchParams.direction,
          properties: searchParams.properties
        }));
      }
    }
  });
});