define('afl/controllers/agency/grid-rate/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    amortisationMethodValues: null,
    amortisationFrequencyValues: null,
    showRowsErrorMessage: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.amortisationMethodValues = ['CONSTANT_MATURITY', 'STRAIGHT_LINE'];
      this.amortisationFrequencyValues = ['MONTHLY', 'QUARTERLY', 'HALF_YEARLY', 'YEARLY'];
    },

    isLongTerm: Ember.computed('model', 'model.fundingNature', function () {
      return this.get('model.fundingNature') === 'LONG_TERM';
    })
  });
});