define('afl/routes/agency/grid-rate/edit', ['exports', 'afl/mixins/injected-service', 'afl/mixins/authenticated-route-mixin'], function (exports, _injectedService, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_injectedService.default, _authenticatedRouteMixin.default, {
    model: function model() {
      var ctrlIndex = this.controllerFor('agency.gridRate.index'),
          nature = ctrlIndex.get('isLongTerm') ? 'LONG_TERM' : 'TREASURY';

      return this.store.find('grid-rate', nature);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('showRowsErrorMessage', false);
    },


    actions: {
      validate: function validate() {
        if (!this.validateRows(this.currentModel.get('rows'))) {
          Ember.set(this.controller, 'showRowsErrorMessage', true);
        } else {
          Ember.set(this.controller, 'showRowsErrorMessage', false);
        }
      },
      submit: function submit() {
        var _this = this;

        this.currentModel.save().then(function () {
          Ember.get(_this, 'toast').success(_this.get('intl').getMessage('agency.admin.gridRate.editForm.success'));
          _this.transitionTo('agency.gridRate');
        }).catch(function () {
          Ember.get(_this, 'toast').error(_this.get('intl').getMessage('agency.admin.gridRate.editForm.error'));
        });
      },
      cancel: function cancel() {
        this.transitionTo('agency.gridRate.index');
      },
      validationErrors: function validationErrors() {
        Ember.get(this, 'toast').error(this.get('intl').getMessage('agency.admin.gridRate.editForm.validation'));
      },
      willTransition: function willTransition() {
        this.modelFor('agency.gridRate.edit').deepRollback();
        return true;
      }
    },

    getLengthNumber: function getLengthNumber(number) {
      return number.toString().replace('.', '').length;
    },
    validateRows: function validateRows(rows) {
      var isValid = true;
      if (!Ember.isEmpty(rows)) {
        rows.forEach(function (row) {

          // Vérification des labels des lignes
          if (Ember.get(row, 'position') !== 0) {
            var label = Ember.get(row, 'label');
            isValid &= !Ember.isEmpty(label) && label.length <= 30;
          }

          var cells = Ember.get(row, 'cells');
          cells.forEach(function (cell) {
            var value = Ember.get(cell, 'value');
            // Vérification des en-têtes
            if (Ember.get(row, 'position') === 0) {
              isValid &= !Ember.isEmpty(value) && value.length <= 10;
            } else {
              // Vérification des cellules qui ne sont pas des en-têtes
              if (!Ember.isEmpty(value) && /^(-|\+)?(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d*)?$/.test(value)) {
                if (value.toString().replace('.', '').length > 5) {
                  isValid = false;
                }
              } else {
                isValid = false;
              }
            }
          });
        });
      }

      return isValid;
    }
  });
});