define('afl/models/contact-request', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    civility: (0, _emberCpValidations.validator)('presence', true),
    lastName: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('word')],
    firstName: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('word')],
    role: (0, _emberCpValidations.validator)('presence', true),
    phoneNumber: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.notEmpty('model.mobileNumber').volatile()
    }), (0, _emberCpValidations.validator)('phone')],
    mobileNumber: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.notEmpty('model.phoneNumber').volatile()
    }), (0, _emberCpValidations.validator)('mobile')],
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('email')],
    response: (0, _emberCpValidations.validator)('presence', true),
    responseDetail: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.responseNeeded').volatile()
    }), (0, _emberCpValidations.validator)('length', {
      max: 254
    })],

    hasAcceptedTc: (0, _emberCpValidations.validator)("inclusion", {
      allowBlank: false,
      in: [true],
      message: 'Obligatoire',
      disabled: Ember.computed.equal('model.status', 'CREATED').volatile()

    })
  });

  var ContactRequest = _emberData.default.Model.extend(Validations, {
    civility: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    role: _emberData.default.attr('string'),
    phoneNumber: _emberData.default.attr('string'),
    mobileNumber: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    collectivity: _emberData.default.belongsTo('collectivity', { async: true }),
    status: _emberData.default.attr('string'),
    statusDate: _emberData.default.attr('localdatetime'),
    contactRequestDate: _emberData.default.attr('localdatetime'),
    response: _emberData.default.attr('string', { defaultValue: '' }),
    responseDetail: _emberData.default.attr('string'),
    responseNeeded: _emberData.default.attr('boolean'),
    hasAcceptedTc: _emberData.default.attr('boolean', { defaultValue: false }),

    isAccepted: Ember.computed('status', function () {
      return this.get('status') === 'ACCEPTED';
    }),

    isCreated: Ember.computed('status', function () {
      return this.get('status') === 'CREATED';
    }),

    presencePhone: Ember.computed('phoneNumber', function () {
      return !Ember.isEmpty(Ember.get(this, 'phoneNumber'));
    }),

    presenceMobile: Ember.computed('mobileNumber', function () {
      return !Ember.isEmpty(Ember.get(this, 'mobileNumber'));
    })
  });

  exports.default = ContactRequest;
});