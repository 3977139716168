define('afl/controllers/paying-agent', ['exports', 'afl/mixins/injected-service', 'afl/mixins/has-permission'], function (exports, _injectedService, _hasPermission) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_injectedService.default, _hasPermission.default, {
    application: Ember.inject.controller(),
    currentPath: Ember.computed.alias('application.currentPath')
  });
});