define('afl/routes/paying-agent/collectivity/fundings/treasury/detail/events', ['exports', 'afl/mixins/injected-service', 'afl/mixins/authenticated-route-mixin'], function (exports, _injectedService, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_injectedService.default, _authenticatedRouteMixin.default, {
    model: function model() {
      return Ember.A();
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('isMobilisation', false);
      controller.set('collectivityId', this.modelFor('payingAgent.collectivity').get('id'));
      controller.set('loan', this.modelFor('payingAgent.collectivity.fundings.treasury.detail'));
      controller.send('searchFunction', {
        page: 1,
        size: controller.get('pageSize'),
        direction: controller.get('direction'),
        properties: controller.get('sortProperty')
      });
    },

    actions: {
      error: function error() {
        Ember.get(this, 'toast').error(this.get('intl').getMessage('collectivity.fundings.noFundingApplication'));
      }
    }
  });
});