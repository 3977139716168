define('afl/controllers/collectivity/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    collectivity: null,
    aflUsers: null,
    news: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('aflUsers', []);
      this.set('news', []);
    }
  });
});