define('afl/routes/agency/task/detail/index', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      return this.modelFor('agency.task.detail');
    },
    resetController: function resetController(controller /*, isExiting, transition*/) {
      controller.set('cancelConfirmVisible', false);
      controller.set('completeConfirmVisible', false);
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);
      this.store.query('crm-contact', {
        collectivityIdsIn: [model.get('collectivity.id')]
      }).then(function (contacts) {
        _this.controller.set('collectivityContacts', contacts);
        _this.send('updateAvailableContacts', model.get('contacts'));
      });
    },


    actions: {
      updateAvailableContacts: function updateAvailableContacts(taskContacts) {
        this.controller.set('availableContacts', this.controller.get('collectivityContacts').filter(function (contact) {
          return !taskContacts.includes(contact);
        }));
      }
    }
  });
});