define('afl/routes/collectivity/prospect', ['exports', 'afl/mixins/authenticated-route-mixin', 'afl/mixins/injected-service'], function (exports, _authenticatedRouteMixin, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _injectedService.default, {
    model: function model() {
      var collectivityId = this.get('currentUser.entityId');
      return this.store.findOneQuery('collectivity', collectivityId, { withDocs: true, withContributions: true });
    }
  });
});