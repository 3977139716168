define('afl/mixins/afl-menu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    didRender: function didRender() {
      // deroule si nécessaire la categorie dans laquelle est l'utilisateur
      // ex: si l'utilisateur est sur 'Habilitations' > 'Entités', alors 'Habilitations' doit être déroulé
      var $activeElement = Ember.$('#menu .menu-links .collapse').find('.active');
      if ($activeElement.html() !== 'undefined') {
        var $categoryToCollapse = $activeElement.closest('.collapse');
        $categoryToCollapse.collapse('show');
      }
    }
  });
});