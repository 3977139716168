define('afl/routes/collectivity/funding-application/history/index', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    activate: function activate() {
      var searchController = this.controllerFor('collectivity.fundingApplication.history.index');
      searchController.send('searchFunction', {
        page: 1,
        size: searchController.get('pageSize'),
        direction: searchController.get('direction'),
        properties: searchController.get('sortProperty')
      });
    }
  });
});