define('afl/serializers/application', ['exports', 'ember-data', 'afl/config/environment'], function (exports, _emberData, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var serializer = _emberData.default.RESTSerializer.extend({});

  if (_environment.default.environment !== 'test') {

    serializer = _emberData.default.RESTSerializer.extend({
      serialize: function serialize(record, options) {
        options = options || { includeId: true };
        return this._super(record, options);
      },
      extractMeta: function extractMeta(store, type, payload) {
        var metadata = {};

        if (payload && type) {
          if (!Ember.isEmpty(payload.pageable)) {
            var _payload$pageable = payload.pageable,
                page = _payload$pageable.page,
                size = _payload$pageable.size,
                sort = _payload$pageable.sort;

            var total = payload.total;
            metadata.size = size;
            metadata.totalPages = Math.ceil(total / size);
            metadata.numberOfElements = total > size * (page + 1) ? size : total - size * page;
            metadata.number = page;
            metadata.totalElements = total;
            metadata.sort = sort;
            delete payload.total;
            delete payload.pageable.size;
            delete payload.pageable.page;
            delete payload.pageable.sort;
          }

          if (!Ember.isEmpty(payload.first)) {
            metadata.first = payload.first;
            delete payload.first;
          }
          if (!Ember.isEmpty(payload.last)) {
            metadata.last = payload.last;
            delete payload.last;
          }
          if (!Ember.isEmpty(payload.facets)) {
            metadata.facets = payload.facets;
            delete payload.facets;
          }
        }

        return metadata;
      },
      normalizeResponse: function normalizeResponse(store, type, payload, id, requestType) {
        var content = Ember.isArray(payload) ? [] : {};
        if (payload) {
          if (payload.content) {
            content = payload.content;
            delete payload.content;
          } else if (Ember.isArray(payload)) {
            content = content.concat(payload);
            payload = {};
          } else {
            for (var key in payload) {
              if (payload.hasOwnProperty(key)) {
                content[key] = payload[key];
                delete payload[key];
              }
            }
          }
          payload[type.modelName] = content;
        }
        return this._super(store, type, payload, id, requestType);
      },
      serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
        Ember.merge(hash, this.serialize(record, options));
      }
    });
  }

  exports.default = serializer;
});