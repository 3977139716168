define('afl/components/crm/contacts-display', ['exports', 'afl/mixins/injected-service', 'ember-cp-validations'], function (exports, _injectedService, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    selectedContact: (0, _emberCpValidations.validator)('presence', true)
  });
  var Contact = Ember.Object.extend(Validations, {
    selectedContact: null,
    toJson: function toJson() {
      return JSON.stringify(this);
    }
  });

  exports.default = Ember.Component.extend(_injectedService.default, {
    contactVisible: false,
    url: '',

    actions: {
      reload: function reload() {
        this.sendAction('reload');
      },
      addContact: function addContact() {
        if (this.get('availableContacts').length === 0) {
          this.set('noContact', true);
          return;
        }
        this.set('contactVisible', true);
        var contact = Contact.create(Ember.getOwner(this).ownerInjection());
        this.set('contact', contact);
      },
      contactClose: function contactClose() {
        this.set('contactVisible', false);
      },
      contactSubmit: function contactSubmit(contactDefer) {
        this.get('contactForm').send('submit');
        this.set('contactDefer', contactDefer);
      },
      contactError: function contactError() {
        this.get('contactDefer').reject();
      },
      contactSuccess: function contactSuccess() {
        var _this = this;

        Ember.$.ajax({
          url: this.get('url') + '/' + this.get('contact.selectedContact'),
          type: 'PUT'
        }).then(function () {
          Ember.get(_this, 'toast').success(_this.get('intl').getMessage('crm.contacts.association.addContact.contactAdded'));
          _this.get('contactDefer').resolve();
          _this.send('reload');
        }).fail(function (error) {
          if (error.responseJSON && error.responseJSON.message) {
            _this.set('errorMessage', error.responseJSON.message);
          } else {
            _this.set('errorMessage', _this.get('intl').getMessage('common.unknownError'));
          }
          _this.get('contactDefer').reject();
        });
      },
      removeContact: function removeContact(contact) {
        this.set('selectedContactRemove', contact);
        this.set('removeContact', true);
      },
      removeContactClose: function removeContactClose() {
        this.set('removeContact', false);
      },
      removeContactSubmit: function removeContactSubmit(contactDefer) {
        var _this2 = this;

        Ember.$.ajax({
          url: this.get('url') + '/' + this.get('selectedContactRemove.id'),
          type: 'DELETE'
        }).then(function () {
          Ember.get(_this2, 'toast').success(_this2.get('intl').getMessage('crm.contacts.association.removeContact.contactRemoved'));
          contactDefer.resolve();
          _this2.send('reload');
        }).fail(function (error) {
          if (error.responseJSON && error.responseJSON.message) {
            _this2.set('errorMessage', error.responseJSON.message);
          } else {
            _this2.set('errorMessage', _this2.get('intl').getMessage('common.unknownError'));
          }
          contactDefer.reject();
        });
      }
    }

  });
});