define('afl/validators/amount', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * Vérifie que la propriété correspond à une suite de chiffres (nombre entier)
   * ou correspond à un nombre avec 0, 1 ou 2 décimales.
   *
   * Exemples de montants valides : 0, 0., .1, 0.1, 0.10
   * Exemples de montants invalides : 0.000, 1.1000000
   */
  var isAmountValid = function isAmountValid(amount) {
    var regex = /^(\d+|\d*\.\d{0,2})$/;
    return regex.test(amount);
  };

  var Amount = _base.default.extend({
    intl: Ember.inject.service(),

    validate: function validate(amount) {
      if (!Ember.isEmpty(amount) && !isAmountValid(amount)) {
        return this.get('intl').t('validations.amount');
      }
      return true;
    }
  });

  exports.default = Amount;
});