define('afl/components/content/grid-rate', ['exports', 'afl/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),

    isLongTerm: true,
    hasRateAccessForAction: false,
    gridRate: null,
    isCollapsable: true,
    isOpen: true,
    // masquage de la partie ligne de trésorerie sur demande client FPLAFLINT-1200
    isTreasuryLineEnable: _environment.default.APP.isTreasuryLineEnable,

    init: function init() {
      this._super.apply(this, arguments);
      this.gridRate = {};
      this.send('updateGridRate');
    },

    actions: {
      toggleGridRate: function toggleGridRate() {
        this.toggleProperty('isLongTerm');
        this.send('updateGridRate');
      },

      updateGridRate: function updateGridRate() {
        if (this.get('isLongTerm')) {
          this.set('gridRate', this.get('store').find('grid-rate', 'LONG_TERM'));
        } else {
          this.set('gridRate', this.get('store').find('grid-rate', 'TREASURY'));
        }
      }
    }
  });
});