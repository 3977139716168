define('afl/helpers/datetime-display', ['exports', 'afl/transforms/localdate', 'afl/transforms/localdatetime'], function (exports, _localdate, _localdatetime) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ldTransform = new _localdate.default();
  var ldtTransform = new _localdatetime.default();

  /**
   Ce helper permet d'afficher une date à partir d'une chaine de caractère représentant une date.
   */
  exports.default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute: function compute(params) {
      var unformatted = params[0];
      if (!unformatted) {
        return '';
      }
      var transform = void 0;
      if (unformatted.indexOf('T') === -1) {
        // Date
        transform = ldTransform;
      } else {
        // Date time
        transform = ldtTransform;
      }
      return this.get('intl').getMessage('fmtDate', { date: transform.deserialize(unformatted) });
    }
  });
});