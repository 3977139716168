define('afl/controllers/agency/user/detail', ['exports', 'afl/mixins/injected-service'], function (exports, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_injectedService.default, {
    application: Ember.inject.controller('application'),
    currentPath: Ember.computed.alias('application.currentPath'),

    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.agency.user.detail');
    })
  });
});