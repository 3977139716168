define('afl/components/content/faq-question', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    htmlId: Ember.computed('model.{position,section,section.position}', function () {
      return 'faq_' + this.get('model.section.position') + '_' + this.get('model.position');
    }),

    htmlToggleId: Ember.computed('htmlId', function () {
      return '#' + this.get('htmlId');
    })
  });
});