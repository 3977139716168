define('afl/routes/agency/person/detail/contact/create', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      var person = this.modelFor('agency.person.detail');
      return this.store.createRecord('crm-contact', {
        person: person,
        isActive: true
      });
    },


    actions: {
      willTransition: function willTransition() {
        this.controller.get('model').rollbackAttributes();
        return true;
      }
    }
  });
});