define('afl/mixins/has-permission', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    currentUser: Ember.inject.service('current-user'),

    /**
     * Permet de savoir si l'utilisateur connecté peut accéder à l'espace Agence.
     *
     * Droit associé: ACCESS@agency
     */
    hasAgencyAccess: Ember.computed('currentUser.rights.@each', function () {
      var rights = this.get('currentUser.rights') || [];
      return rights.indexOf('ACCESS@agency') > -1;
    }),

    /**
     * Permet de savoir si l'utilisateur connecté peut accéder à l'espace Collectivité.
     *
     * Droit associé: ACCESS@collectivity
     */
    hasCollectivityAccess: Ember.computed('currentUser.rights.@each', function () {
      var rights = this.get('currentUser.rights') || [];
      return rights.indexOf('ACCESS@collectivity') > -1;
    }),

    /**
     * Permet de savoir si l'utilisateur connecté peut accéder à l'espace Agent Payeur.
     *
     * Droit associé: ACCESS@payingAgent
     */
    hasPayingAgentAccess: Ember.computed('currentUser.rights.@each', function () {
      var rights = this.get('currentUser.rights') || [];
      return rights.indexOf('ACCESS@payingAgent') > -1;
    }),

    /**
     * Permet de savoir si l'utilisateur connecté peut accéder à la gestion des utilisateurs.
     *
     * Droit associé: USERS@read
     */
    hasUsersAccess: Ember.computed('currentUser.rights.@each', function () {
      var rights = this.get('currentUser.rights') || [];
      return rights.indexOf('USERS@read') > -1;
    }),

    /**
     * Permet de savoir si l'utilisateur connecté peut effectuer des actions (création, modification, suppression)
     * dans la gestion des utilisateurs.
     *
     * Droit associé: USERS@write
     */
    hasUsersAccessForAction: Ember.computed('currentUser.rights.@each', function () {
      var rights = this.get('currentUser.rights') || [];
      return rights.indexOf('USERS@write') > -1;
    }),

    /**
     * Permet de savoir si l'utilisateur connecté peut accéder à la messagerie.
     *
     * Droit associé: MAILBOX@read
     */
    hasMailboxAccess: Ember.computed('currentUser.rights.@each', function () {
      var rights = this.get('currentUser.rights') || [];
      return rights.indexOf('MAILBOX@read') > -1;
    }),

    /**
     * Permet de savoir si l'utilisateur connecté peut effectuer des actions (création, modification, suppression)
     * dans la messagerie.
     *
     * Droit associé: MAILBOX@write
     */
    hasMailboxAccessForAction: Ember.computed('currentUser.rights.@each', function () {
      var rights = this.get('currentUser.rights') || [];
      return rights.indexOf('MAILBOX@write') > -1;
    }),

    /**
     * Permet de savoir si l'utilisateur connecté peut accéder à la bibliothèque.
     *
     * Droit associé: LIBRARY@read
     */
    hasLibraryAccess: Ember.computed('currentUser.rights.@each', function () {
      var rights = this.get('currentUser.rights') || [];
      return rights.indexOf('LIBRARY@read') > -1;
    }),

    /**
     * Permet de savoir si l'utilisateur connecté peut effectuer des actions (création, modification, suppression)
     * dans la bibliothèque.
     *
     * Droit associé: LIBRARY@write
     */
    hasLibraryAccessForAction: Ember.computed('currentUser.rights.@each', function () {
      var rights = this.get('currentUser.rights') || [];
      return rights.indexOf('LIBRARY@write') > -1;
    }),

    /**
     * Permet de savoir si l'utilisateur connecté peut accéder à l'administration de la grille de taux.
     *
     * Droit associé: RATE@read
     */
    hasRateAccess: Ember.computed('currentUser.rights.@each', function () {
      var rights = this.get('currentUser.rights') || [];
      return rights.indexOf('RATE@read') > -1;
    }),

    /**
     * Permet de savoir si l'utilisateur connecté peut effectuer des actions (création, modification, suppression)
     * dans l'administration de la grille de taux.
     *
     * Droit associé: RATE@write
     */
    hasRateAccessForAction: Ember.computed('currentUser.rights.@each', function () {
      var rights = this.get('currentUser.rights') || [];
      return rights.indexOf('RATE@write') > -1;
    }),

    /**
     * Permet de savoir si l'utilisateur connecté peut accéder à l'administration de contenu.
     *
     * Droit associé: CONTENT@read
     */
    hasContentAccess: Ember.computed('currentUser.rights.@each', function () {
      var rights = this.get('currentUser.rights') || [];
      return rights.indexOf('CONTENT@read') > -1;
    }),

    /**
     * Permet de savoir si l'utilisateur connecté peut effectuer des actions (création, modification, suppression)
     * dans l'administration de contenu.
     *
     * Droit associé: CONTENT@write
     */
    hasContentAccessForAction: Ember.computed('currentUser.rights.@each', function () {
      var rights = this.get('currentUser.rights') || [];
      return rights.indexOf('CONTENT@write') > -1;
    }),

    /**
     * Permet de savoir si l'utilisateur connecté peut accéder à la gestion des demandes de premier contact.
     *
     * Droit associé: CONTACT@read
     */
    hasContactAccess: Ember.computed('currentUser.rights.@each', function () {
      var rights = this.get('currentUser.rights') || [];
      return rights.indexOf('CONTACT@read') > -1;
    }),

    /**
     * Permet de savoir si l'utilisateur connecté peut effectuer des actions (création, modification, suppression)
     * dans la gestion des demandes de premier contact.
     *
     * Droit associé: CONTACT@write
     */
    hasContactAccessForAction: Ember.computed('currentUser.rights.@each', function () {
      var rights = this.get('currentUser.rights') || [];
      return rights.indexOf('CONTACT@write') > -1;
    }),

    /**
     * Permet de savoir si l'utilisateur connecté peut accéder au CRM
     *
     * Droit associé: CRM@read
     */
    hasCRMAccess: Ember.computed('currentUser.rights.@each', function () {
      var rights = this.get('currentUser.rights') || [];
      return rights.indexOf('CRM@read') > -1;
    }),

    /**
     * Permet de savoir si l'utilisateur connecté peut accéder au CRM
     *
     * Droit associé: CRM@write
     */
    hasCRMAccessForAction: Ember.computed('currentUser.rights.@each', function () {
      var rights = this.get('currentUser.rights') || [];
      return rights.indexOf('CRM@write') > -1;
    }),

    /**
     * Permet de savoir si l'utilisateur connecté peut accéder à aux financements.
     *
     * Droit associé: FUNDING@read
     */
    hasFundingAccess: Ember.computed('currentUser.rights.@each', function () {
      var rights = this.get('currentUser.rights') || [];
      return rights.indexOf('FUNDING@read') > -1;
    }),

    /**
     * Permet de savoir si l'utilisateur connecté peut effectuer des actions (création, modification, suppression)
     * dans les financements.
     *
     * Droit associé: FUNDING@write
     */
    hasFundingAccessForAction: Ember.computed('currentUser.rights.@each', function () {
      var rights = this.get('currentUser.rights') || [];
      return rights.indexOf('FUNDING@write') > -1;
    }),

    /**
     * Permet de savoir si l'utilisateur connecté peut accéder à aux simulations.
     *
     * Droit associé: SIM@read
     */
    hasSimulationAccess: Ember.computed('currentUser.rights.@each', function () {
      var rights = this.get('currentUser.rights') || [];
      return rights.indexOf('SIM@read') > -1;
    }),

    /**
     * Permet de savoir si l'utilisateur connecté peut effectuer des actions (création, modification, suppression)
     * dans les simulations.
     *
     * Droit associé: SIM@write
     */
    hasSimulationAccessForAction: Ember.computed('currentUser.rights.@each', 'isCollectivityEligible', function () {
      var rights = this.get('currentUser.rights') || [];
      return this.get('isCollectivityEligible') && rights.indexOf('SIM@write') > -1;
    }),

    /**
     * Permet de savoir si l'utilisateur connecté peut accéder à la souscription de prêts (demande de financement).
     *
     * Droit associé: FUNDAPP@read
     */
    hasFundAppAccess: Ember.computed('currentUser.rights.@each', function () {
      var rights = this.get('currentUser.rights') || [];
      return rights.indexOf('FUNDAPP@read') > -1;
    }),

    /**
     * Permet de savoir si l'utilisateur connecté peut effectuer des actions (création, modification, suppression)
     * dans la souscriptions de prêt (demande de financement).
     *
     * Droit associé: FUNDAPP@write
     */
    hasFundAppAccessForAction: Ember.computed('currentUser.rights.@each', 'isCollectivityEligible', function () {
      var rights = this.get('currentUser.rights') || [];
      return this.get('isCollectivityEligible') && rights.indexOf('FUNDAPP@write') > -1;
    }),

    /**
     * Permet de savoir si l'utilisateur connecté peut accéder à la gestion des documents.
     *
     * Droit associé: DOCS@read
     */
    hasDocsAccess: Ember.computed('currentUser.rights.@each', function () {
      var rights = this.get('currentUser.rights') || [];
      return rights.indexOf('DOCS@read') > -1;
    }),

    /**
     * Permet de savoir si l'utilisateur connecté peut effectuer des actions (création, modification, suppression)
     * dans la gestion des documents.
     *
     * Droit associé: DOCS@write
     */
    hasDocsAccessForAction: Ember.computed('currentUser.rights.@each', function () {
      var rights = this.get('currentUser.rights') || [];
      return rights.indexOf('DOCS@write') > -1;
    }),

    /**
     * Permet de savoir si l'utilisateur connecté peut accéder à la signature.
     *
     * Droit associé: SIGN@read
     */
    hasSignatureAccess: Ember.computed('currentUser.rights.@each', function () {
      var rights = this.get('currentUser.rights') || [];
      return rights.indexOf('SIGN@read') > -1;
    }),

    /**
     * Permet de savoir si l'utilisateur connecté peut effectuer des actions (création, modification, suppression)
     * dans la signature.
     *
     * Droit associé: SIGN@write
     */
    hasSignatureAccessForAction: Ember.computed('currentUser.rights.@each', function () {
      var rights = this.get('currentUser.rights') || [];
      return rights.indexOf('SIGN@write') > -1;
    }),

    /**
     * Permet de savoir si l'utilisateur connecté peut accéder à la partie Adhésion.
     *
     * Droit associé: PROSPECT@read
     */
    hasProspectAccess: Ember.computed('currentUser.rights.@each', function () {
      var rights = this.get('currentUser.rights') || [];
      return rights.indexOf('PROSPECT@read') > -1;
    }),

    /**
     * Permet de savoir si l'utilisateur connecté peut effectuer des actions (création, modification, suppression)
     * dans la partie Adhésion.
     *
     * Droit associé: PROSPECT@write
     */
    hasProspectAccessForAction: Ember.computed('currentUser.rights.@each', function () {
      var rights = this.get('currentUser.rights') || [];
      return rights.indexOf('PROSPECT@write') > -1;
    }),

    /**
     * Permet de savoir si l'utilisateur connecté peut accéder à la piste d'audit.
     *
     * Droit associé: AUDIT@read
     */
    hasAuditAccess: Ember.computed('currentUser.rights.@each', function () {
      var rights = this.get('currentUser.rights') || [];
      return rights.indexOf('AUDIT@read') > -1;
    }),

    /**
     * Permet de savoir si un utilisateur connecté a accès à l'une des fonctionnalité suivante:
     * - Administration de contenu
     * - Administration de la grille de taux
     * - Administration de la bibliotèque
     *
     * Droits associés: CONTENT@read, LIBRARY@read, RATE@read
     */
    hasAtLeastContentOrLibraryOrRateAccess: Ember.computed('hasContentAccess', 'hasLibraryAccess', 'hasRateAccess', function () {
      return this.get('hasContentAccess') || this.get('hasLibraryAccess') || this.get('hasRateAccess');
    }),

    /**
     * Permet de savoir si un utilisateur connecté a accès à l'une des fonctionnalité suivante:
     * - Premier contact
     * - CRM
     *
     * Droits associés: CONTENT@read, CRM@read
     */
    hasAtLeastCRMOrContactAccess: Ember.computed('hasContactAccess', 'hasCRMAccess', function () {
      return this.get('hasContactAccess') || this.get('hasCRMAccess');
    }),

    /**
     * Permet de savoir si la collectivité actuellement connecté est eligible. La collectivité n'est pas éligible si:
     * - Son statut est SLEEPING_MEMBER
     * - Son statut est MEMBER mais elle n'est pas eligible (attribut isEligible)
     */
    isCollectivityEligible: Ember.computed('currentUser.collectivity.{status,isEligible}', function () {
      var status = this.get('currentUser.collectivity.status') || null;
      var isEligible = this.get('currentUser.collectivity.isEligible') || false;

      if (status === 'SLEEPING_MEMBER') {
        return false;
      } else if (status === 'MEMBER' && !isEligible) {
        return false;
      }
      return true;
    })
  });
});