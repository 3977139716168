define('afl/components/input/input-date', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    inputDate: null,
    updateFromInputDate: false,
    updateFromValue: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('inputDate', copy(this.get('value')));
    },

    didInsertElement: function didInsertElement() {
      this._super();
      Ember.run.scheduleOnce('afterRender', this, this.afterRenderEvent);
    },

    afterRenderEvent: function afterRenderEvent() {
      this.$('.datepickerdiv input').each(Ember.run.bind(this, function (item, elem) {
        Ember.$(elem).datepicker().change(Ember.run.bind(this, function (item) {
          if (Ember.$(item.currentTarget).val() === "" && !this.get('isDestroyed')) {
            this.set('inputDate', null);
            this.set('value', null);
          }
        }));
      }));
    },

    onValueChange: Ember.observer('value', function () {
      var value = this.get('value'),
          updateFromInputDate = this.get('updateFromInputDate');

      if (value === null) {
        this.set('inputDate', null);
      } else if (value && value instanceof Date) {
        if (!updateFromInputDate) {
          // si l'appel de cette méthode ne correspond pas à une modification de inputDate
          // on met à jour inputDate
          this.set('updateFromValue', true);
          this.set('inputDate', copy(value));
        }
      }
      this.set('updateFromInputDate', false);
    }),

    onInputChange: Ember.observer('inputDate', function () {
      var pickedDate = this.get('inputDate'),
          updateFromValue = this.get('updateFromValue');

      if (pickedDate && pickedDate instanceof Date) {
        if (!updateFromValue) {
          this.set('updateFromInputDate', true);
          this.set('value', inUtcSameLocal(pickedDate));
        }
        this.set('updateFromValue', false);
      }
    })
  });


  function inUtcSameLocal(date) {
    var toReturn = copy(date);
    toReturn.setMinutes(toReturn.getMinutes() - toReturn.getTimezoneOffset());
    return toReturn;
  }

  // Copie pour ne pas binder directement les variables du controlleur aux variables du composant datepicker
  function copy(date) {
    return date instanceof Date ? new Date(date) : date;
  }
});