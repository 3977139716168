define('afl/mixins/authenticated-route-mixin', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'afl/mixins/injected-service'], function (exports, _authenticatedRouteMixin, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_authenticatedRouteMixin.default, _injectedService.default, {
    beforeModel: function beforeModel(transition) {
      if (this.get('session.isAuthenticated')) {
        var target = Ember.get(transition, 'targetName'),
            userType = Ember.String.camelize(this.get('currentUser.entityType').toLowerCase());
        // Les utilisateurs autre qu'AGENCE n'ayant pas donné leur consentement et n'étant pas en usurpation d'identité
        // doivent être redirigés vers la page cgu
        if (!this.get('currentUser.hasAcceptedTc') && !this.get('currentUser.isImpersonate') && this.get('currentUser.entityType') !== 'AGENCY') {
          if (target.indexOf('cgu') === 0) {
            return this._super.apply(this, arguments);
          } else {
            transition.abort();
            this.transitionTo('cgu');
          }
        } else {
          if (target.indexOf(userType) === 0) {
            return this._super.apply(this, arguments);
          } else {
            transition.abort();
            switch (this.get('currentUser.entityType')) {
              case 'AGENCY':
                this.transitionTo('agency');
                break;
              case 'COLLECTIVITY':
                if (this.get('currentUser.isProspect')) {
                  this.transitionTo('collectivity.prospect');
                } else {
                  this.transitionTo('collectivity');
                }
                break;
              case 'PAYING_AGENT':
                this.transitionTo('payingAgent');
                break;
            }
          }
        }
      } else {
        return this._super.apply(this, arguments);
      }
    }
  });
});