define('afl/transforms/localdate', ['exports', 'afl/transforms/localdatetime'], function (exports, _localdatetime) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _localdatetime.default.extend({

    deserialize: function deserialize(serialized) {
      var dateTime = this._super(serialized);

      if (dateTime instanceof Date) {
        var year = dateTime.getUTCFullYear();
        var month = dateTime.getUTCMonth();
        var day = dateTime.getUTCDate();
        return new Date(Date.UTC(year, month, day));
      }

      return dateTime;
    },

    serialize: function serialize(date) {
      var dateTime = this._super(date);

      if (typeof dateTime === 'string') {
        return dateTime.substring(0, dateLength);
      }

      return dateTime;
    }

  });


  var dateLength = 'yyyy-mm-DD'.length;
});