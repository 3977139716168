define('afl/controllers/paying-agent/collectivity/documents/deposited', ['exports', 'afl/controllers/search', 'afl/mixins/injected-service', 'afl/mixins/has-permission'], function (exports, _search, _injectedService, _hasPermission) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _search.default.extend(_injectedService.default, _hasPermission.default, {
    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.payingAgent.collectivity.documents.deposited');
    })
  });
});