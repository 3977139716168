define('afl/controllers/collectivity/documents/deposited', ['exports', 'afl/controllers/search', 'afl/mixins/injected-service', 'afl/mixins/has-permission', 'afl/utils/afl-constants'], function (exports, _search, _injectedService, _hasPermission, _aflConstants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _search.default.extend(_injectedService.default, _hasPermission.default, {
    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.collectivity.documents.deposited');
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('collectivityDocumentType', (0, _aflConstants.default)('CollectivityDocumentType'));
    },


    actions: {
      openCreateDocumentModal: function openCreateDocumentModal() {
        this.set('displayCreateDocumentModal', true);
        this.set('document', this.get('store').createRecord('document', {
          aflEntity: this.get('collectivityId')
        }));
      },
      createCollectivityDocument: function createCollectivityDocument(document) {
        var _this = this;

        var data = JSON.stringify(document.serialize());
        Ember.$.ajax({
          url: '/api/collectivities/fillDocument',
          type: 'POST',
          data: data,
          dataType: 'json',
          contentType: 'application/json; charset=utf-8'
        }).then(function () {
          _this.get('toast').success(_this.get('intl').getMessage('collectivityDocument.saveSuccess'));
          _this.set('displayCreateDocumentModal', false);
          _this.get('refreshList')();
        }).catch(function (error) {
          _this.get('toast').error(error.responseJSON.message);
        });
      }
    }
  });
});