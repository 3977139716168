define('afl/controllers/collectivity/prospect', ['exports', 'afl/mixins/injected-service', 'afl/mixins/upload-document'], function (exports, _injectedService, _uploadDocument) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_injectedService.default, _uploadDocument.default, {

    uploadingFile: false,
    documentSelected: null,
    uploadContext: 'ADHESION_PROCESS',
    file: null,

    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.collectivity.prospect');
    }),

    year: new Date().getFullYear(),

    isInProgress: Ember.computed('model.subscribeDocuments.@each', function () {
      var documents = this.get('model.subscribeDocuments').toArray();
      return documents.filter(function (document) {
        return !document.get('isUnreceived');
      }).length > 0;
    }),

    mandatoryDocuments: Ember.computed('model.subscribeDocuments.@each', function () {
      var documents = this.get('model.subscribeDocuments').toArray();
      return documents.filter(function (document) {
        return document.get('isMandatory');
      });
    }),

    optionalDocuments: Ember.computed('model.subscribeDocuments.@each', function () {
      var documents = this.get('model.subscribeDocuments').toArray();
      return documents.filter(function (document) {
        return !document.get('isMandatory');
      });
    }),

    hasNoDocument: Ember.computed('model.subscribeDocuments.@each', function () {
      return this.get('model.subscribeDocuments').toArray().length === 0;
    }),

    hasMandatoryDocuments: Ember.computed('mandatoryDocuments.@each', function () {
      return this.get('mandatoryDocuments').length > 0;
    }),

    hasOptionalDocuments: Ember.computed('optionalDocuments.@each', function () {
      return this.get('optionalDocuments').toArray().length > 0;
    }),

    leastTransfertDate: Ember.computed('sortedContributions', function () {
      if (this.get('sortedContributions').length > 0) {
        return this.get('sortedContributions')[0].get('transfertDate') + " : ";
      } else {
        return "";
      }
    }),

    sortedContributions: Ember.computed('model.contributions.@each', function () {
      return this.get('model.contributions').toArray().sort(function (o1, o2) {
        return o1.get('transfertDate').localeCompare(o2.get('transfertDate'));
      });
    }),

    actions: {

      uploadDocument: function uploadDocument(model) {
        this.set('uploadingFile', true);
        this.set('documentSelected', model);
      },

      closeUpload: function closeUpload() {
        this.set('uploadingFile', false);
      }
    }

  });
});