define('afl/models/funding-application', ['exports', 'ember-data', 'afl/config/environment', 'afl/models/simulation', 'ember-cp-validations'], function (exports, _emberData, _environment, _simulation, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    fundingNature: (0, _emberCpValidations.validator)('inclusion', {
      in: ['LONG_TERM', 'TREASURY', 'MOBILISATION']
    }),
    beiIndicator: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.isLongTerm').volatile()
    }), (0, _emberCpValidations.validator)('inclusion', {
      in: [true, false],
      disabled: Ember.computed.not('model.isLongTerm').volatile()
    })],
    settlementDate: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.isNotMobilisation').volatile()
    }), (0, _emberCpValidations.validator)('future', {
      disabled: Ember.computed.not('model.isNotMobilisation').volatile()
    }), (0, _emberCpValidations.validator)('date', {
      disabled: Ember.computed.not('model.isTreasury').volatile()
    })],
    endDate: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.isTreasury').volatile()
    }), (0, _emberCpValidations.validator)(function (value, options, model) {
      if (model.get('isTreasury')) {
        var settlementDate = model.get('settlementDate');
        if (!Ember.isEmpty(settlementDate)) {
          var endDate = model.get('endDate');
          var minDate = new Date(settlementDate);
          var maxDate = new Date(settlementDate);

          minDate.setDate(minDate.getDate() + _environment.default.fundingApplication.validation.endDateMinDays);
          maxDate.setDate(maxDate.getDate() + _environment.default.fundingApplication.validation.endDateMaxDays);
          if (endDate > maxDate || endDate < minDate) {
            return 'La durée de la ligne de trésorerie devra être comprise entre 3 mois et 364 jours';
          }
        }
      }
      return true;
    }, {
      dependentKeys: ['model.isTreasury', 'model.settlementDate', 'model.endDate']
    })],
    deadline: (0, _emberCpValidations.validator)(function (deadline, options, model) {
      if (deadline <= Date.now() && deadline != null) {
        return model.get('intl').t('simulation.model.deadline');
      }
      return true;
    }, {
      disabled: Ember.computed.not('model.deadline').volatile()
    }),
    budgetType: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.isLongTermOrMobilisation').volatile()
    }), (0, _emberCpValidations.validator)('inclusion', {
      in: ['PRINCIPAL', 'ANNEXED'],
      disabled: Ember.computed.not('model.isLongTermOrMobilisation').volatile()
    })],
    budgetName: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.isAnnexed').volatile()
    }),
    amount: [(0, _emberCpValidations.validator)(function (amount, options, model) {
      if (!/^(-|\+)?(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d*)?$/.test(amount)) {
        return 'La valeur doit être un nombre';
      }

      if (model.get('isTreasury')) {
        if (amount <= _environment.default.treasury.validation.minimumAmountTreasury) {
          return model.get('intl').getMessage(this.get('model.minAmountTreasuryKey'), {
            amountMin: _environment.default.treasury.validation.minimumAmountTreasury
          });
        }
        if (amount > _environment.default.treasury.validation.maximumAmountTreasury) {
          return model.get('intl').getMessage(this.get('model.maxAmountTreasuryKey'), {
            amountMax: _environment.default.treasury.validation.maximumAmountTreasury
          });
        }
      } else if (model.get('isLongTerm')) {
        if (amount <= _environment.default.fundingApplication.validation.longTermFundingMinimumAmount) {
          return model.get('intl').getMessage(this.get('model.minAmountFundingKey'), {
            amountMin: _environment.default.fundingApplication.validation.longTermFundingMinimumAmount
          });
        }
        if (amount > _environment.default.fundingApplication.validation.longTermFundingMaximumAmount) {
          return model.get('intl').getMessage(this.get('model.maxAmountFundingKey'), {
            amountMax: _environment.default.fundingApplication.validation.longTermFundingMaximumAmount
          });
        }
      }
      return true;
    }, {
      dependentKeys: ['model.isTreasury', 'model.isLongTerm']
    }), (0, _emberCpValidations.validator)('amount')],

    duration: (0, _emberCpValidations.validator)(function (duration, options, model) {
      if (model.get('isLongTermOrMobilisation')) {
        if (!/^[+\-]?\d+$/.test(duration)) {
          return model.get('intl').getMessage('validations.notAnInteger');
        }

        if (model.get('isLongTerm')) {
          if (duration <= _environment.default.fundingApplication.validation.longTermFundingMinimumDuration) {
            return model.get('intl').getMessage('validations.greaterThan', {
              gt: _environment.default.fundingApplication.validation.longTermFundingMinimumDuration
            });
          }
          if (duration >= _environment.default.fundingApplication.validation.longTermFundingMaximumDuration) {
            return model.get('intl').getMessage('validations.lessThanOrEqualTo', {
              lte: _environment.default.fundingApplication.validation.longTermFundingMaximumDuration
            });
          }
        } else if (model.get('isMobilisation')) {
          if (duration < _environment.default.fundingApplication.validation.mobilisationFundingMinimumDuration) {
            return model.get('intl').getMessage('validations.greaterThanOrEqualTo', {
              gte: _environment.default.fundingApplication.validation.mobilisationFundingMinimumDuration
            });
          }
          if (duration > _environment.default.fundingApplication.validation.mobilisationFundingMaximumDuration) {
            return model.get('intl').getMessage('validations.lessThanOrEqualTo', {
              lte: _environment.default.fundingApplication.validation.mobilisationFundingMaximumDuration
            });
          }
        }
      }
      return true;
    }, {
      dependentKeys: ['model.isLongTerm', 'model.isMobilisation']
    }),

    amortisationDuration: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.isMobilisation').volatile()
    }), (0, _emberCpValidations.validator)('number', {
      gte: _environment.default.fundingApplication.validation.mobilisationFundingMinimumAmortisation,
      lte: _environment.default.fundingApplication.validation.mobilisationFundingMaximumAmortisation,
      integer: true,
      allowString: true,
      disabled: Ember.computed.not('model.isMobilisation').volatile()
    })]

  });

  var FundingApplication = _simulation.default.extend(Validations, {

    //@formatter:off
    status: _emberData.default.attr('string'),
    statusDate: _emberData.default.attr('localdatetime'),
    beiIndicator: _emberData.default.attr('boolean'),
    settlementDate: _emberData.default.attr('localdatetime'),
    endDate: _emberData.default.attr('localdatetime'),
    budgetType: _emberData.default.attr('string'),
    budgetName: _emberData.default.attr('string'),
    refusalReason: _emberData.default.attr('string'),
    refusalReasonDetail: _emberData.default.attr('string'),
    sabProductCode: _emberData.default.attr('string'),
    sabObjectCode: _emberData.default.attr('string'),
    agence: _emberData.default.attr('number'),
    service: _emberData.default.attr('string'),
    subService: _emberData.default.attr('string'),
    fileNumber: _emberData.default.attr('number'),
    subscribeDocuments: _emberData.default.hasMany('subscribeDocument', { async: true }),
    aflUser: _emberData.default.belongsTo('aflUser', { async: true }),
    accepted: _emberData.default.attr('string'), // chaine de caratère pour accepter la sérialization null
    modified: _emberData.default.attr('boolean', { defaultValue: false }),
    deadline: _emberData.default.attr('localdatetime'),
    fileName: _emberData.default.attr('string'),
    //@formatter:on

    // computed
    isAnnexed: Ember.computed('budgetType', function () {
      return this.get('budgetType') === 'ANNEXED';
    }),

    isPrincipal: Ember.computed('budgetType', function () {
      return this.get('budgetType') === 'PRINCIPAL';
    }),

    isPrincipalChanged: Ember.observer('isPrincipal', function () {
      if (this.get('isPrincipal')) {
        this.set('budgetName', '');
      }
    }),

    isMobilisation: Ember.computed('fundingNature', function () {
      return this.get('fundingNature') === 'MOBILISATION';
    }),

    isNotMobilisation: Ember.computed('fundingNature', function () {
      return this.get('fundingNature') !== 'MOBILISATION';
    }),

    isLongTermOrMobilisation: Ember.computed('isLongTerm', 'isMobilisation', function () {
      return this.get('isLongTerm') || this.get('isMobilisation');
    })
  });

  exports.default = FundingApplication;
});