define('afl/controllers/agency/entity/detail/user/detail/edit', ['exports', 'afl/mixins/injected-service'], function (exports, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_injectedService.default, {
    groups: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.groups = [];
    },

    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.agency.entity.user.edit');
    }),

    actions: {
      afterSuccess: function afterSuccess() {
        this.transitionToRoute('agency.entity.detail.user.detail');
      }
    }
  });
});