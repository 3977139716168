define('afl/controllers/agency/user/index', ['exports', 'afl/mixins/has-permission', 'afl/config/environment', 'afl/controllers/search', 'afl/utils/afl-constants', 'ember-cli-file-saver/mixins/file-saver'], function (exports, _hasPermission, _environment, _search, _aflConstants, _fileSaver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _search.default.extend(_fileSaver.default, _hasPermission.default, {
    currentUser: Ember.inject.service('current-user'),
    name: '',
    entity: '',
    profile: '',
    statusIn: null,
    entities: (0, _aflConstants.default)('AflEntityType'),
    profiles: null,
    sortProperty: 'firstName',
    dialogExport: false,

    formats: _environment.default.uploadBatch.extensions,
    maxSize: _environment.default.uploadBatch.size,
    maxSizeInText: _environment.default.uploadBatch.sizeInText,
    bulkCreationStarted: false,
    statuses: (0, _aflConstants.default)('AflUserStatus'),

    init: function init() {
      this._super.apply(this, arguments);
      this.statusIn = ['ENABLED'];
      this.profiles = [];
    },

    isPayingAgentEntity: Ember.computed('entity', function () {
      return this.get('entity') === 'PAYING_AGENT';
    }),

    filteredProfiles: Ember.computed('entity', function () {
      var entity = this.get('entity');

      if (entity === '') {
        return this.get('profiles');
      }

      var pattern = /.*/;

      if (entity === 'AGENCY') {
        pattern = /^(AGENCY_|WORLDLINE).*$/;
      } else if (entity === 'COLLECTIVITY') {
        pattern = /^COLLECTIVITY_.*$/;
      }

      return this.get('profiles').filter(function (element) {
        return pattern.test(element.get('ref'));
      });
    }),

    resetProfile: Ember.observer('entity', function () {
      this.set('profile', '');
    }),

    actions: {
      searchFunction: function searchFunction(searchParams) {
        this.set('model', this.store.query('aflUser', {
          name: this.get('name'),
          entity: this.get('entity'),
          profile: this.get('profile'),
          statusIn: this.get('statusIn'),
          page: searchParams.page,
          size: searchParams.size,
          direction: searchParams.direction,
          properties: searchParams.properties
        }));
      },

      reset: function reset() {
        this._super();
        this.set('name', '');
        this.set('entity', '');
        this.set('profile', '');
        this.set('statusIn', ['ENABLED']);
        this.set('sortProperty', 'firstName');
        // Permet de ne plus afficher la liste des résultats
        this.set('model', []);
      },

      dialogExport: function dialogExport() {
        this.toggleProperty('dialogExport');
      },

      exportResults: function exportResults() {
        var _this = this;

        // En local on récupère le proxy, sinon lien relatif sur le même domaine:port
        var queryParams = Ember.$.param({
          name: this.get('name'),
          entityType: this.get('entity'),
          profile: this.get('profile'),
          statusIn: this.get('statusIn')
        });

        Ember.$.ajax({
          type: 'GET',
          url: '/api/aflusers/export?' + queryParams,
          dataType: 'blob', // or 'blob'
          headers: {
            'authorization': this.get('currentUser').getToken()
          },
          processData: false
        }).then(function (content) {
          _this.saveFileAs('liste-utilisateur.csv', content);
        });
      },

      startBulkCreation: function startBulkCreation() {
        this.set('bulkCreationStarted', true);
      },

      stopBulkCreation: function stopBulkCreation() {
        this.set('bulkCreationStarted', false);
      },

      uploadSuccess: function uploadSuccess(response) {
        this.set('bulkCreationStarted', false);
        this.transitionToRoute('agency.report.detail', JSON.parse(response).ref);
      },

      uploadError: function uploadError(errors) {
        Ember.get(this, 'toast').error(errors.response);
        Ember.Logger.error('Error uploading batch file', errors.response);
      }
    }
  });
});