define('afl/components/crm/comments-display', ['exports', 'afl/transforms/localdatetime', 'afl/adapters/application', 'afl/mixins/injected-service'], function (exports, _localdatetime, _application, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var transform = _localdatetime.default.create();

  var CommentsDisplay = Ember.Component.extend(_injectedService.default, {
    addComment: false,
    updatedComment: undefined,

    init: function init() {
      this._super.apply(this, arguments);
      this.additionalTitle = this.additionalTitle || undefined;
      Ember.defineProperty(this, 'comments', Ember.computed.alias('model.' + this.get('attribute')));
    },


    sortedComments: Ember.computed.sort('comments.[]', function (a, b) {
      var aDate = transform.deserialize(a.date);
      var bDate = transform.deserialize(b.date);
      return bDate.getTime() - aDate.getTime();
    }),

    url: Ember.computed('modelName', 'modelId', function () {
      return '/api/' + (0, _application.pathForType)(this.get('modelName')) + '/' + this.get('modelId') + '/comments';
    }).readOnly(),

    actions: {
      open: function open(comment) {
        this.set('updatedComment', comment);
        this.set('addComment', true);
      },
      newCommentAdded: function newCommentAdded() {
        this.get('newCommentAdded')();
      },
      delete: function _delete(comment) {
        var _this = this;

        Ember.$.ajax({
          url: this.get('url') + '/' + comment.uid + '/delete',
          type: 'DELETE'
        }).then(function () {
          Ember.get(_this, 'toast').success(_this.get('intl').getMessage('crm.comments.delete.success'));
          _this.get('newCommentAdded')();
        }, function () {
          Ember.get(_this, 'toast').success(_this.get('intl').getMessage('crm.comments.delete.failure'));
        });
      }
    }
  });

  CommentsDisplay.reopenClass({
    positionalParams: ['model', 'attribute']
  });

  exports.default = CommentsDisplay;
});