define('afl/models/faq-question', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FaqQuestion = _emberData.default.Model.extend({
    issue: _emberData.default.attr('string'),
    answer: _emberData.default.attr('string'),
    position: _emberData.default.attr('number'),
    section: _emberData.default.belongsTo('faqSection')
  });

  exports.default = FaqQuestion;
});