define('afl/routes/agency/mailbox', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      var searchController = this.controllerFor('agency.mailbox');

      return Ember.$.get('/api/messages/categoryIn').then(function (data) {
        Ember.run(function () {
          searchController.set('categoryIn', data);
          searchController.set('messageCategories', data);
        });
      });
    },
    activate: function activate() {
      var searchController = this.controllerFor('agency.mailbox');

      Ember.$.get('/api/messages/subjects').then(function (data) {
        Ember.run(function () {
          searchController.set('subjects', data);
        });
      });
    },
    deactivate: function deactivate() {
      var searchController = this.controllerFor('agency.mailbox');
      searchController.set('showSendMessageForm', false);
    }
  });
});