define('afl/components/document/document-global-view', ['exports', 'afl/mixins/has-permission', 'afl/mixins/injected-service', 'afl/components/grid/pagination-component', 'afl/mixins/search-collectivity', 'afl/utils/afl-constants', 'afl/transforms/localdatetime', 'ember-cli-file-saver/mixins/file-saver'], function (exports, _hasPermission, _injectedService, _paginationComponent, _searchCollectivity, _aflConstants, _localdatetime, _fileSaver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   Composant pour l'affichage des documents déposés. Il prend en paramètre:
   - `rootPageTitle`: path de la page, exemple : collectivity.documents.deposited
  
   Paramètre optionnel:
   - `collectivityId`: l'id de la collectivité sur laquelle le user est connecté
   - `sortProperty`: la propriété de tri [default: editionDate]
   - `sortDirection`: ordre de trie ASC : ascendant or DESC : descandant
   - `pageSize`: nombre d'element afficher par page
   - `viewEraseButton`: visibilité du bouton supprimer : true visible, false : invisible
   - `viewCollectivityCol` : visibilité de la colonne collectivité
   - `viewVisibilityCol` : visibilité de la colonne visibilité
   - `viewEraseButton` : visibilité du bouton supprimer dépendant de la propriété viewDocument
   - `viewEraseButtonMaster` : visibilité du bouton supprimer ne dépendant pas de la propriété viewDocument
   - `viewModifyButton` : visibilité du bouton Modifier
   - `viewFilter` : visibilité des filtres
   **/

  var ldtTransform = new _localdatetime.default();

  exports.default = _paginationComponent.default.extend(_fileSaver.default, _injectedService.default, _fileSaver.default, _hasPermission.default, _searchCollectivity.default, {
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    allCollectivities: true,
    sortProperty: 'editionDate',
    sortDirection: 'DESC',
    ascending: false,
    pageSize: 50,
    rootPageTitle: '',
    viewCollectivityCol: false,
    viewVisibilityCol: false,
    viewEraseButton: false,
    viewEraseButtonMaster: false,
    viewModifyButton: false,
    viewChangeVisibilityButton: false,
    viewBoldRow: false,
    viewFilter: false,
    viewDocumentChangeWithDownload: false,
    docFilter: null,
    selectedCollectivity: null,
    collectivityDocumentType: (0, _aflConstants.default)('CollectivityDocumentType'),
    collectivityDocumentPieceType: (0, _aflConstants.default)('CollectivityDocumentPieceType'),
    depositorTypes: null,
    collectivityId: null,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('model', Ember.A());
      this.set('rootPageTitle', this.get('rootPageTitle'));
      this.initFilter();
      if (!this.get('viewFilter')) {
        this.firstly();
      }
      Ember.run.schedule('afterRender', this, function () {
        _this.set('refreshList', _this.firstly.bind(_this));
      });
    },
    initFilter: function initFilter() {
      this.set('docFilter', {
        collectivityId: this.get('collectivityId'),
        depositorTypes: this.get('depositorTypes'),
        deposeDate: null,
        documentType: null,
        viewDocument: null
      });
    },


    shouldCollapse: Ember.computed('isInit', 'hasNoResult', function () {
      return this.get('isInit') || this.get('hasNoResult');
    }),

    resetFilter: function resetFilter() {
      this.initFilter();
    },
    getConcatPageTitle: function getConcatPageTitle(ter, title) {
      return ter + '.' + title;
    },
    getDocumentTypeLabel: function getDocumentTypeLabel(intl, doc) {
      if (doc.depositorType === 'SAB_PORTAL') {
        return intl.getEnum('enums.DocumentPieceType.' + doc.documentPieceType);
      }
      if (doc.type === 'OTHER') {
        return intl.getEnum('enums.DocumentType.' + doc.type) + ' - ' + doc.otherType;
      }
      return intl.getEnum('enums.DocumentType.' + doc.type);
    },
    getQuestionDialogConfirm: function getQuestionDialogConfirm(intl, docName) {
      return intl.getEnum('collectivity.documents.removeDocument.question') + ' : ' + docName;
    },
    getBoldFontWeight: function getBoldFontWeight(status_1, status_2) {
      return status_1 && !status_2;
    },
    shouldHideModify: function shouldHideModify(depositorType) {
      return depositorType === 'SAB_PORTAL';
    },
    firstly: function firstly() {
      var searchParams = {
        page: this.get('page'),
        size: this.get('pageSize'),
        direction: this.get('sortDirection'),
        properties: this.get('sortProperty')
      };
      this.send('searchFunction', searchParams);
    },
    updateViewDocument: function updateViewDocument(document, status) {
      Ember.$.ajax({
        type: 'PUT',
        url: '/api/documents/' + document.id + '?viewDocument=' + status
      }).then(function (doc) {
        document.set('viewDocument', doc.viewDocument);
        Ember.get(this, 'toast').success(this.get('intl').getMessage('common.documents.updateDocumentSucceed'));
      }.bind(this)).fail(function (response) {
        Ember.get(this, 'toast').error(response.responseJSON.message);
      }.bind(this));
    },


    actions: {
      searchFunction: function searchFunction(searchParams) {
        this.set('model', this.store.query('document', {
          collectivity: this.get('docFilter.collectivityId'),
          page: searchParams.page,
          size: searchParams.size,
          direction: searchParams.direction,
          properties: searchParams.properties,
          depositorTypes: this.get('docFilter.depositorTypes'),
          viewDocument: this.get('docFilter.viewDocument'),
          editionDate: ldtTransform.serialize(this.get('docFilter.deposeDate')),
          types: this.get('docFilter.documentType'),
          pieceTypes: this.get('docFilter.pieceTypes')
        }));
      },
      search: function search() {
        this.firstly();
      },
      searchError: function searchError() {
        Ember.get(this, 'toast').error(this.get('intl').getMessage('collectivityDocument.searchError'));
      },
      reset: function reset() {
        this._super();
        this.resetFilter();
      },
      download: function download(document, editDocumentView) {
        var _this2 = this;

        Ember.$.ajax({
          type: 'GET',
          url: '/api/download/document/collectivity/' + document.id,
          dataType: 'blob', // or 'blob'
          headers: {
            'authorization': this.get('currentUser').getToken()
          },
          processData: false
        }).then(function (content) {
          _this2.saveFileAs(document.fileName, content);
        });

        if (editDocumentView) {
          document.set('viewDocument', true);
        }
      },
      showModalEraseDocument: function showModalEraseDocument(doc) {
        this.set('docToDelete', doc);
        this.set('removingDocument', true);
      },
      closeEraseDocument: function closeEraseDocument() {
        this.set('removingDocument', false);
      },
      eraseDocument: function eraseDocument() {
        var rmDoc = this.get('removingDocument');
        var document = this.get('docToDelete');

        if (rmDoc && document.id != null) {
          Ember.$.ajax({
            type: 'DELETE',
            url: '/api/documents/' + document.id
          }).then(function () {
            this.get('firstly').bind(this)();
            Ember.get(this, 'toast').success(this.get('intl').getMessage('common.user.action.removeDocumentSuccess', {
              fileName: document.fileName
            }));
            this.send('closeEraseDocument');
          }.bind(this)).fail(function (response) {
            Ember.get(this, 'toast').error(response.responseJSON.message);
            this.get('firstly').bind(this)();
            this.send('closeEraseDocument');
          }.bind(this));
        }
      },
      openEditDocumentModal: function openEditDocumentModal(document) {
        this.set('editedDocument', document);
        this.set('displayEditDocumentModal', true);
      },
      editCollectivityDocument: function editCollectivityDocument(document) {
        var _this3 = this;

        var data = JSON.stringify(document.serialize());
        Ember.$.ajax({
          url: '/api/agencies/' + this.get('currentUser.entityId') + '/editDocument',
          type: 'PUT',
          data: data,
          dataType: 'json',
          contentType: 'application/json; charset=utf-8'
        }).then(function () {
          _this3.firstly();
          _this3.get('toast').success(_this3.get('intl').getMessage('collectivityDocument.editSuccess'));
          _this3.set('displayEditDocumentModal', false);
        }).catch(function (error) {
          _this3.get('toast').error(error.responseJSON.message);
        });
      },
      markAsView: function markAsView(document) {
        if (!document.get('viewDocument')) {
          this.updateViewDocument(document, true);
        } else {
          Ember.Logger.error('Error updating document: cannot be marked as read');
          Ember.get(this, 'toast').error(this.get('intl').getMessage('common.documents.cannotUpdateDocument'));
        }
      },
      markAsNotView: function markAsNotView(document) {
        if (document.get('viewDocument')) {
          this.updateViewDocument(document, false);
        } else {
          Ember.Logger.error('Error updating document: cannot be marked as not read');
          Ember.get(this, 'toast').error(this.get('intl').getMessage('common.documents.cannotUpdateDocument'));
        }
      },
      onSelectedType: function onSelectedType() {
        this.set('docFilter.pieceTypes', null);
      },
      onSelectedPieceType: function onSelectedPieceType() {
        this.set('docFilter.documentType', null);
      }
    }
  });
});