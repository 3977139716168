define('afl/components/menu/menu-paying-agent', ['exports', 'afl/mixins/afl-menu', 'afl/mixins/has-permission', 'afl/config/environment'], function (exports, _aflMenu, _hasPermission, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_aflMenu.default, _hasPermission.default, {
    session: Ember.inject.service(),

    // masquage de la partie ligne de trésorerie sur demande client FPLAFLINT-1200
    isTreasuryLineEnable: _environment.default.APP.isTreasuryLineEnable,

    showCollectivityListLink: Ember.computed('currentPath', function () {
      var currentPath = this.get('currentPath');
      return this.get('session.isAuthenticated') && currentPath && currentPath.indexOf('payingAgent.collectivity') === 0;
    }),

    libraryLink: Ember.computed('showCollectivityListLink', function () {
      return this.get('showCollectivityListLink') ? 'payingAgent.collectivity.library' : 'payingAgent.library';
    }),

    newsLink: Ember.computed('showCollectivityListLink', function () {
      return this.get('showCollectivityListLink') ? 'payingAgent.collectivity.news' : 'payingAgent.news';
    }),

    faqLink: Ember.computed('showCollectivityListLink', function () {
      return this.get('showCollectivityListLink') ? 'payingAgent.collectivity.faq' : 'payingAgent.faq';
    })
  });
});