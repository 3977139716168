define('afl/router', ['exports', 'afl/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    // Demande de premier contact
    this.route('contact');

    // Gestion du mot de passe
    this.route('password', { path: 'motdepasse' }, function () {
      // Oublié
      this.route('forgotten', { path: '/oublie' });
      // Création du mot de passe
      this.route('otp', { path: '/code' });
    });

    this.route('cgu', { path: 'cgu' }, function () {});

    // Collectivité
    this.route('collectivity', { path: '/collectivite' }, function () {
      // Adhésion
      this.route('prospect', { path: 'adhesion' });
      // Simulation
      this.route('simulation', function () {
        this.route('detail', { path: '/:simulation_id' });
        this.route('create', { path: '/nouvelle' });
      });
      // Demande de financement
      this.route('fundingApplication', { path: '/demande' }, function () {
        this.route('create', { path: '/nouvelle' });
        this.route('history', { path: '/historique' }, function () {
          this.route('detail', { path: '/:funding_id' });
        });
        this.route('beingAnalysed', { path: '/encours' }, function () {
          this.route('detail', { path: '/:fundingApplication_id' });
        });
      });
      // Financement
      this.route('fundings', { path: '/pret' }, function () {
        this.route('longTerm', { path: '/long-terme' }, function () {
          this.route('detail', { path: '/:longTerm_id/:loan_number' });
        });
        this.route('treasury', { path: '/tresorerie' }, function () {
          this.route('detail', { path: '/:treasury_id/:loan_number' }, function () {
            this.route('events', { path: '' });
          });
        });
        this.route('mobilisation', { path: '/mobilisation' }, function () {
          this.route('detailPhase1', { path: '/phase/mobilisation/:treasury_id/:loan_number' }, function () {
            this.route('events', { path: '' });
          });
          this.route('detailPhase2', { path: '/phase/consolidation/:longTerm_id/:loan_number' });
        });
      });

      // Document
      this.route('documents', { path: '/documents' }, function () {
        this.route('received', { path: 'recus' });
        this.route('deposited', { path: 'deposes' });
        this.route('signature', { path: ':docRef/signature' });
      });

      // Utilisateurs
      this.route('user', { path: '/utilisateur' }, function () {
        this.route('detail', { path: '/:user_id' }, function () {
          this.route('edit');
        });
        this.route('create', { path: '/nouveau' });
      });

      this.route('colContact', { path: '/personnes' }, function () {
        this.route('detail', { path: '/:id' }, function () {
          this.route('edit');
        });
        this.route('create', { path: '/nouveau' });
      });

      this.route('payingAgent', { path: '/agentComptable' }, function () {});

      // Autre - Bibliothèque
      this.route('library', { path: 'bibliotheque' });
      // Autre - Actualité
      this.route('news', { path: 'actualites' }, function () {
        this.route('detail', { path: '/:news_id' });
      });
      // Autre - FAQ
      this.route('faq');

      // Profil
      this.route('account', { path: 'profil' }, function () {
        this.route('edit');
        this.route('password', { path: 'motdepasse' });
      });
      // Abonnements
      this.route('subscriptions', { path: 'abonnements' });
      // Messagerie
      this.route('mailbox', { path: 'messagerie' });
    });

    // Agence
    this.route('agency', { path: '/agence' }, function () {
      // Administration du contenu - Actualité
      this.route('news', { path: '/actualites' }, function () {
        this.route('create', { path: '/nouvelle' });
        this.route('detail', { path: '/:news_id' }, function () {
          this.route('edit');
        });
      });
      // Administration du contenu - Grille de taux
      this.route('gridRate', { path: 'grilledetaux' }, function () {
        this.route('edit');
      });
      this.route('library', { path: '/bibliotheque' }, function () {
        this.route('create', { path: '/nouveau' });
      });
      // Premiers contacts
      this.route('contactRequest', { path: 'identifiant' }, function () {
        this.route('detail', { path: '/:contactRequest_id' }, function () {
          this.route('edit');
        });
      });
      this.route('warnings', { path: 'avertissements' });
      // CRM
      this.route('person', { path: 'personne' }, function () {
        this.route('create', { path: '/nouvelle' });
        this.route('detail', { path: '/:person_id' }, function () {
          this.route('contact', { path: '' }, function () {
            this.route('create', { path: 'contact/nouveau' });
            this.route('detail', { path: 'contact/:contact_id' }, function () {
              this.route('edit');
            });
          });
          this.route('edit');
        });
      });
      this.route('contact', { path: 'contact' }, function () {
        this.route('detail', { path: '/:contact_id' }, function () {
          this.route('edit');
        });
      });
      this.route('event', { path: 'evenement' }, function () {
        this.route('create', { path: '/nouvel' });
        this.route('detail', { path: '/:event_id' }, function () {
          this.route('edit');
        });
      });
      this.route('task', { path: 'tache' }, function () {
        this.route('create', { path: '/nouvelle' });
        this.route('detail', { path: '/:task_id' }, function () {
          this.route('edit');
        });
      });
      this.route('collectivity', { path: 'collectivite' }, function () {
        this.route('detail', { path: '/:collectivity_id' }, function () {
          // Hack spécifique pour faire que /:entity_type/:entity_id descende jusqu'a cette route
          this.route('contact', { path: '' });
          this.route('user', { path: 'utilisateur' }, function () {
            this.route('detail', { path: '/:user_id' }, function () {});
          });
          this.route('event', { path: 'evenement' });
          this.route('task', { path: 'tache' });
          this.route('simulation', { path: 'simulation' }, function () {
            this.route('detail', { path: '/:simulation_id' });
            this.route('create', { path: '/nouvelle' });
          });
        });
      });

      // Demandes de financement
      this.route('fundingApplication', { path: 'financement' }, function () {
        this.route('detail', { path: '/:funding_id' }, function () {
          this.route('edit');
        });
      });
      // Habilitation - Entités
      this.route('entity', { path: 'entite' }, function () {
        this.route('detail', { path: '/:entity_type/:entity_id' }, function () {
          // Hack spécifique pour faire que /:entity_type/:entity_id descende jusqu'a cette route
          this.route('user', { path: '' }, function () {
            this.route('detail', { path: 'utilisateur/:user_id' }, function () {
              this.route('edit');
            });
            this.route('create', { path: 'utilisateur/nouveau' });
          });
          this.route('contact');
          this.route('event', { path: 'evenement' });
          this.route('task', { path: 'tache' });
        });
        // Agent payeur création
        this.route('create', { path: 'agent-payeur/nouveau' });
        // modification d'un agent payeur
        this.route('edit', { path: 'agent-payeur/:entity_id/edit' });
      });
      // Profil
      this.route('account', { path: 'profil' }, function () {
        this.route('edit');
        this.route('password', { path: 'motdepasse' });
      });
      // Habilitation - Utilisateurs
      this.route('user', { path: 'utilisateur' }, function () {
        this.route('detail', { path: '/:user_id' }, function () {
          this.route('edit');
        });
      });
      // Habilitation - Rapport d'intégration
      this.route('report', { path: 'rapport' }, function () {
        this.route('detail', { path: '/:report_id' }, function () {
          this.route('line', { path: '' });
        });
      });
      // Document
      this.route('documents', { path: '/documents' }, function () {
        this.route('received', { path: 'recus' });
        this.route('transmitted', { path: 'transmis' });
      });
      // Piste d'audit
      this.route('audit', { path: 'audit' }, function () {
        this.route('detail', { path: '/:event_id' });
      });
      // Messagerie
      this.route('mailbox', { path: 'messagerie' });
      // Actualité
      this.route('allNews', { path: 'news' }, function () {
        this.route('detail', { path: '/:news_id' });
      });
    });

    // Agent Payeur
    this.route('payingAgent', { path: 'agentpayeur' }, function () {
      // Profil
      this.route('account', { path: 'profil' }, function () {
        this.route('edit');
        this.route('password', { path: 'motdepasse' });
      });
      // Abonnements
      this.route('subscriptions', { path: 'abonnements' });
      // Collectivité
      this.route('collectivity', { path: 'collectivite/:collectivity_id' }, function () {
        // Financement
        this.route('fundings', { path: '/pret' }, function () {
          this.route('longTerm', { path: '/long-terme' }, function () {
            this.route('detail', { path: '/:longTerm_id/:loan_number' });
          });
          this.route('treasury', { path: '/tresorerie' }, function () {
            this.route('detail', { path: '/:treasury_id/:loan_number' }, function () {
              this.route('events', { path: '' });
            });
          });
          this.route('mobilisation', { path: '/mobilisation' }, function () {
            this.route('detailPhase1', { path: '/phase/mobilisation/:treasury_id/:loan_number' }, function () {
              this.route('events', { path: '' });
            });
            this.route('detailPhase2', { path: '/phase/consolidation/:longTerm_id/:loan_number' });
          });
        });
        // Document
        this.route('documents', { path: '/documents' }, function () {
          this.route('received', { path: '/recus' });
          this.route('deposited', { path: '/deposes' });
        });
        // Autre - Bibliothèque
        this.route('library', { path: 'bibliotheque' });
        // Autre - Actualité
        this.route('news', { path: 'actualites' }, function () {
          this.route('detail', { path: '/:news_id' });
        });
        // Autre - FAQ
        this.route('faq');
      });
      // Autre - Bibliothèque
      this.route('library', { path: 'bibliotheque' });
      // Autre - Actualité
      this.route('news', { path: 'actualites' }, function () {
        this.route('detail', { path: '/:news_id' });
      });
      // Autre - FAQ
      this.route('faq');
    });
  });

  Router.reopen({
    didTransition: function didTransition() {
      this._super.apply(this, arguments);
      Ember.$("html, body").animate({ scrollTop: 0 }, "fast");
      return true;
    }
  });

  exports.default = Router;
});