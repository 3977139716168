define('afl/controllers/collectivity/news/index', ['exports', 'afl/controllers/search'], function (exports, _search) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _search.default.extend({

    filterProperty: 'PUBLISHED',
    sortProperty: 'publicationBeginDate',
    direction: 'DESC',

    actions: {
      searchFunction: function searchFunction(searchParams) {
        this.set('model', this.store.query('news', {
          page: searchParams.page,
          size: searchParams.size,
          direction: this.get('direction'),
          state: this.get('filterProperty'),
          properties: this.get('sortProperty')
        }));
      }
    }
  });
});