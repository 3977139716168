define('afl/controllers/agency/funding-application/detail/index', ['exports', 'afl/mixins/injected-service', 'afl/mixins/has-permission', 'ember-cp-validations'], function (exports, _injectedService, _hasPermission, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    refusalReason: (0, _emberCpValidations.validator)('presence', true),
    refusalReasonDetail: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.hasRefusalReasonDetail').volatile()
    })
  });
  exports.default = Ember.Controller.extend(Validations, _injectedService.default, _hasPermission.default, {
    dialogProcess: false,
    dialogRefuse: false,
    dialogDelete: false,
    refusalReason: '',
    refusalReasonDetail: '',
    hasRefusalReasonDetail: false,
    errorMessage: '',
    dialogDefer: null,
    refuseForm: null,

    modelDidChange: Ember.observer('model.id', function () {
      this.set('dialogProcess', false);
      this.set('dialogRefuse', false);
    }),

    isOpen: Ember.computed('model.status', function () {
      return this.get('model.status') === 'CREATED';
    }),

    refusalReasons: Ember.computed(function () {
      if (this.get('model.fundingNature') === 'LONG_TERM') {
        return ['INCORRECT_FORM', 'BORROWING_LIMIT_YEAR_REACHED', 'OTHER'];
      }
      return ['INCORRECT_FORM', 'OTHER'];
    }),

    refusalReasonChange: Ember.observer('refusalReason', function () {
      if (this.get('refusalReason') === 'OTHER') {
        this.set('hasRefusalReasonDetail', true);
      } else {
        this.set('hasRefusalReasonDetail', false);
        this.set('refusalReasonDetail', '');
      }
    }),

    actions: {
      // affichage de la popin pour traiter la demande
      process: function process() {
        this.toggleProperty('dialogProcess');
      },

      // confirmation du traitement de la demande via la popin
      confirmProcess: function confirmProcess(defer) {
        this.set('errorMessage', '');
        this.get('model').set('accepted', true);
        Ember.get(this, 'toast').success(this.get('intl').getMessage('agency.fundingApplication.detail.dialogForm.processSuccess'));
        this.send('update', defer);
      },

      // affichage de la popin pour refuser la demande
      refuse: function refuse() {
        this.toggleProperty('dialogRefuse');
      },

      // fermeture de la popin de refus de demande
      closeDialogRefuse: function closeDialogRefuse() {
        // on reinitialise le motif et le detail du motif
        this.set('hasRefusalReasonDetail', false);
        this.set('refusalReason', '');
        this.set('refusalReasonDetail', '');
      },

      // action lors du clic sur le bouton 'confirmer' de la popin de refus de financement
      validateRefuseForm: function validateRefuseForm(defer) {
        // on garde la propriete 'defer' de la popin pour la fermer ou la laisser ouverte selon le resultat de validation du formulaire
        this.set('dialogDefer', defer);
        Ember.get(this, 'toast').success(this.get('intl').getMessage('agency.fundingApplication.detail.dialogForm.refuseSuccess'));
        // on soumet le formulaire de validation
        this.get('refuseForm').send('submit');
      },

      // en cas d'echec sur le formulaire de validation
      refuseFormValidationError: function refuseFormValidationError() {
        // rejet de la validation -> on remet a disposition le bouton 'confirmer'
        this.get('dialogDefer').reject();
      },

      // confirmation du refus de la demande via la popin
      confirmRefuse: function confirmRefuse() {
        this.set('errorMessage', '');
        this.get('model').set('accepted', false);
        this.get('model').set('refusalReason', this.get('refusalReason'));
        this.get('model').set('refusalReasonDetail', this.get('refusalReasonDetail'));
        this.send('update', this.get('dialogDefer'));
      },

      deletion: function deletion() {
        this.toggleProperty('dialogDelete');
      },

      confirmDeletion: function confirmDeletion(defer) {
        Ember.Logger.debug("confirmDeletion action called", defer);
        this.set('errorMessage', '');
        this.send('delete', defer);
      }
    }
  });
});