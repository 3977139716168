define('afl/controllers/agency/entity/detail', ['exports', 'afl/mixins/has-permission', 'afl/mixins/injected-service'], function (exports, _hasPermission, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_hasPermission.default, _injectedService.default, {
    application: Ember.inject.controller(),
    currentPath: Ember.computed.alias('application.currentPath'),

    dialogDeactivateEntity: false,
    dialogActivateEntity: false,
    dialogCloseEntity: false,

    breadCrumb: Ember.computed('model.aflEntityName', function () {
      return this.get('model.aflEntityName');
    }),

    isCollectivity: Ember.computed('model.aflEntityType', function () {
      return this.get('model.aflEntityType') === 'COLLECTIVITY';
    }),

    isPayingAgent: Ember.computed('model.aflEntityType', function () {
      return this.get('model.aflEntityType') === 'PAYING_AGENT';
    }),

    isPayingAgentOrCollectivity: Ember.computed('isPayingAgent', 'isCollectivity', function () {
      return this.get('isPayingAgent') || this.get('isCollectivity');
    }),

    isEntityActive: Ember.computed('model.aflEntityStatus', function () {
      return this.get('model.aflEntityStatus') === 'ENABLED';
    }),

    isEntityInactive: Ember.computed('model.aflEntityStatus', function () {
      return this.get('model.aflEntityStatus') === 'DISABLED';
    }),

    isEntityNotClosed: Ember.computed('model.aflEntityStatus', function () {
      return this.get('model.aflEntityStatus') !== 'CLOSED';
    }),

    _changeEntityStatus: function _changeEntityStatus(status, message) {
      var _this = this;

      var model = this.get('model');
      model.set('aflEntityStatus', status);
      model.save().then(function () {
        Ember.get(_this, 'toast').success(message);
      }).catch(function (errors) {
        var error = errors.errors[0] || {},
            message = _this.get('intl').getMessage('agency.entity.update.error');
        if (error.detail && error.detail.message) {
          message = error.detail.message;
        }
        Ember.get(_this, 'toast').error(message);
        model.rollbackAttributes();
      });
    },

    actions: {
      toggleDeactivateEntity: function toggleDeactivateEntity() {
        this.toggleProperty('dialogDeactivateEntity');
      },

      deactivateEntity: function deactivateEntity() {
        this._changeEntityStatus('DISABLED', this.get('intl').getMessage('agency.entity.update.successDisable'));
      },

      toggleActivateEntity: function toggleActivateEntity() {
        this.toggleProperty('dialogActivateEntity');
      },

      activateEntity: function activateEntity() {
        this._changeEntityStatus('ENABLED', this.get('intl').getMessage('agency.entity.update.successEnable'));
      },

      toggleCloseEntity: function toggleCloseEntity() {
        this.toggleProperty('dialogCloseEntity');
      },

      closeEntity: function closeEntity() {
        var _this2 = this;

        Ember.$.ajax({
          type: 'PUT',
          url: '/api/payingagents/' + this.get('model.id') + '/close'
        }).done(function () {
          Ember.run(function () {
            _this2.get('model').reload().then(function () {
              _this2.transitionToRoute('agency.entity.index');
              Ember.get(_this2, 'toast').success(_this2.get('intl').getMessage('agency.entity.close.success'));
            });
          });
        }).fail(function (reason) {
          Ember.run(function () {
            if (reason.status === 400) {
              Ember.get(_this2, 'toast').error(reason.responseJSON.message);
            } else {
              Ember.get(_this2, 'toast').error(_this2.get('intl').getMessage('agency.entity.close.error'));
            }
          });
        });
      }
    }
  });
});