define('afl/controllers/collectivity/user/create', ['exports', 'afl/mixins/injected-service', 'afl/mixins/has-permission'], function (exports, _injectedService, _hasPermission) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_injectedService.default, _hasPermission.default, {
    groups: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.groups = [];
    },

    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.collectivity.user.create');
    }),

    actions: {
      afterSuccess: function afterSuccess() {
        this.transitionToRoute('collectivity.user');
      }
    }
  });
});