define('afl/components/document/documents-to-sign', ['exports', 'afl/mixins/has-permission', 'afl/components/grid/pagination-component', 'afl/mixins/upload-document', 'afl/utils/afl-constants'], function (exports, _hasPermission, _paginationComponent, _uploadDocument, _aflConstants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _paginationComponent.default.extend(_hasPermission.default, _uploadDocument.default, {
    entityId: null,
    sortProperty: 'editionDate',
    ascending: false,
    // on change le page size pour que le composant des documents à signer ne soit pas trop grand
    sizes: null,
    pageSize: 6,
    store: Ember.inject.service(),
    file: null,
    uploadContext: 'OUT_OF_PROCESS',

    init: function init() {
      this._super.apply(this, arguments);
      this.set('collectivityDocumentType', (0, _aflConstants.default)('CollectivityDocumentType'));
      if (Ember.isEmpty(this.size)) {
        this.set('sizes', [6, 10, 20]);
      }
      this.send('searchFunction', {
        page: 1,
        size: this.get('pageSize'),
        direction: this.get('direction'),
        properties: this.get('sortProperty')
      });
    },


    actions: {
      searchFunction: function searchFunction(searchParams) {
        this.set('model', this.get('store').query('document', {
          collectivity: this.get('entityId'),
          signatureState: 'NEEDED',
          page: searchParams.page,
          size: searchParams.size,
          direction: searchParams.direction,
          properties: searchParams.properties
        }));
      },
      uploadDocument: function uploadDocument() {
        this.set('uploadingFile', true);
        this.set('document', this.get('store').createRecord('document'));
      },
      closeUpload: function closeUpload() {
        this.set('uploadingFile', false);
      },
      validationErrors: function validationErrors() {
        this.get('toast').error(this.get('intl').getMessage('collectivityDocument.validationError'));
      },
      saveCollectivityDocument: function saveCollectivityDocument() {
        var _this = this;

        var data = JSON.stringify(this.get('document').serialize());
        Ember.$.ajax({
          url: '/api/collectivities/fileDocument/' + this.get('entityId'),
          type: 'POST',
          data: data,
          dataType: 'json',
          contentType: 'application/json; charset=utf-8'
        }).then(function () {
          _this.get('toast').success(_this.get('intl').getMessage('collectivityDocument.saveSuccess'));
          _this.set('uploadingFile', false);
        }).catch(function (error) {
          _this.get('toast').error(error.responseJSON.message);
        });
      }
    }
  });
});