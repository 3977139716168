define('afl/routes/agency/user/detail/edit', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      return this.modelFor('agency.user.detail');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.store.query('group', { entityType: model.get('entityType') }).then(function (groups) {
        controller.set('groups', groups.toArray());
      });
    },


    actions: {
      willTransition: function willTransition() {
        this.controller.get('model').deepRollback();
        return true;
      }
    }
  });
});