define('afl/models/grid-rate', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    fundingNature: (0, _emberCpValidations.validator)('inclusion', {
      in: ['LONG_TERM', 'TREASURY']
    }),
    amortisationMethod: (0, _emberCpValidations.validator)('inclusion', {
      in: ['CONSTANT_MATURITY', 'STRAIGHT_LINE']
    }),
    repaymentFrequency: (0, _emberCpValidations.validator)('inclusion', {
      in: ['MONTHLY', 'QUARTERLY', 'HALF_YEARLY', 'YEARLY']
    }),
    averageScore: [(0, _emberCpValidations.validator)('number', {
      gte: 1,
      lte: 7,
      allowString: true
    }), (0, _emberCpValidations.validator)(function (value) {
      return value && !/^[0-9](\.[0-9]{0,2})?$/.test(value) ? 'La notation ne peut pas avoir plus de 2 décimales' : true;
    })],
    duration: (0, _emberCpValidations.validator)('length', {
      max: 3
    }),
    disclaimer: (0, _emberCpValidations.validator)('length', {
      max: 1000
    })
  });

  var GridRate = _emberData.default.Model.extend(Validations, {
    fundingNature: _emberData.default.attr('string'),
    amortisationMethod: _emberData.default.attr('string'),
    repaymentFrequency: _emberData.default.attr('string'),
    referenceRateLabel: _emberData.default.attr('string'),
    averageScore: _emberData.default.attr('number'),
    duration: _emberData.default.attr('number'),
    disclaimer: _emberData.default.attr('string'),
    lastUpdate: _emberData.default.attr('localdatetime'),
    rateLastUpdate: _emberData.default.attr('localdatetime'),
    rows: _emberData.default.hasMany('gridRateRow'),
    enabled: _emberData.default.attr('boolean'),

    isLongTerm: Ember.computed('fundingNature', function () {
      return this.get('fundingNature') === 'LONG_TERM';
    }),
    lastRateUpdateFormattedDate: Ember.computed('lastUpdate', function () {
      var date = new Date(this.get('rateLastUpdate'));
      return date.toLocaleDateString(['fr-FR'], {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
    }),
    hRows: Ember.computed('rows.@each.position', function () {
      return this.get('rows').filter(function (item) {
        return item.get('position') === 0;
      });
    }),
    bRows: Ember.computed('rows.@each.position', function () {
      return this.get('rows').filter(function (item) {
        return item.get('position') !== 0;
      });
    })
  });

  exports.default = GridRate;
});