define('afl/controllers/collectivity/fundings/long-term/detail', ['exports', 'afl/mixins/injected-service', 'ember-cli-file-saver/mixins/file-saver'], function (exports, _injectedService, _fileSaver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_fileSaver.default, _injectedService.default, {
    entityId: null,
    isTiersPage: false,
    currentFundingId: null,
    isManagementMode: false,
    isMember: false,

    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.collectivity.fundings.longTerm.detail');
    }),

    actions: {
      download: function download() {
        var _this = this;

        var exportResultsUrl = '/api/loans/' + this.get('model.id') + '/' + this.get('model.loanNumber') + '/schedule?collectivity=' + this.get('entityId');

        Ember.$.ajax({
          type: 'GET',
          url: exportResultsUrl,
          dataType: 'blob', // or 'blob'
          headers: {
            'authorization': this.get('currentUser').getToken()
          },
          processData: false
        }).then(function (content) {
          _this.saveFileAs('tableau-amortissement.xls', content);
        });
      }
    }
  });
});