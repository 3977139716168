define('afl/controllers/agency', ['exports', 'afl/mixins/injected-service', 'afl/mixins/has-permission'], function (exports, _injectedService, _hasPermission) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_injectedService.default, _hasPermission.default, {
    pendingContactRequests: null,
    pendingFundingApplications: null,
    unreadMessages: null,

    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.agency.index');
    })
  });
});