define('afl/routes/agency/funding-application/index', ['exports', 'afl/mixins/injected-service', 'afl/mixins/authenticated-route-mixin'], function (exports, _injectedService, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_injectedService.default, _authenticatedRouteMixin.default, {

    actions: {
      searchError: function searchError() {
        this.flashMessage('error', this.get('intl').getMessage('agency.contact.searchForm.validation'));
      }
    }
  });
});