define('afl/routes/cgu', ['exports', 'afl/mixins/has-permission'], function (exports, _hasPermission) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_hasPermission.default, {
    model: function model() {
      if (this.get('currentUser.user')) {
        return this.store.findRecord('afl-user', this.get('currentUser.user.id'));
      }
      return null;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
    },
    destroy: function destroy() {},


    actions: {
      logout: function logout() {
        this.get('currentUser').logout();
      }
    }
  });
});