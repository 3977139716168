define('afl/models/warning', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;


  var Validations = (0, _emberCpValidations.buildValidations)({
    message: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 500
    })],
    startDate: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)(function (value, options, model) {
      if (value && model.get('endDate')) {
        return model.get('startDate') <= model.get('endDate') ? true : 'La date de début doit être inférieur ou égale à la date de début';
      }
      return true;
    })],
    endDate: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)(function (value, options, model) {
      if (model.get('startDate') && value) {
        return model.get('startDate') <= value ? true : 'La date de fin doit être supérieur ou égale à la date de début';
      }
      return true;
    })]
  });

  exports.default = Model.extend(Validations, {
    message: attr('string'),
    startDate: attr('localdatetime'),
    endDate: attr('localdatetime'),
    published: attr('boolean'),

    isObsolete: Ember.computed('endDate', function () {
      return this.get('endDate') < new Date();
    })
  });
});