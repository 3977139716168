define('afl/controllers/password/forgotten', ['exports', 'afl/mixins/injected-service', 'ember-cp-validations'], function (exports, _injectedService, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('email')]
  });
  exports.default = Ember.Controller.extend(Validations, _injectedService.default, {
    email: '',

    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.password.forgotten');
    }),

    actions: {
      resetPassword: function resetPassword() {
        var _this = this;

        Ember.$.post("/api/aflusers/password", {
          userEmail: this.get('email')
        }, function () {
          Ember.get(_this, 'toast').success(_this.get('intl').getMessage('user.forgottenForm.success'));
        }).fail(function (response) {
          switch (response.status) {
            case 404:
              Ember.get(_this, 'toast').error(_this.get('intl').getMessage('user.forgottenForm.userNotFound'));
              break;
            default:
              Ember.get(_this, 'toast').error(_this.get('intl').getMessage('user.forgottenForm.technicalError'));
              break;
          }
        });
      },

      resetPasswordError: function resetPasswordError() {
        Ember.get(this, 'toast').error(this.get('intl').getMessage('user.forgottenForm.invalid'));
      }
    }
  });
});