define('afl/controllers/agency/collectivity/detail/user/index', ['exports', 'afl/mixins/injected-service', 'afl/controllers/search', 'afl/mixins/has-permission'], function (exports, _injectedService, _search, _hasPermission) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _search.default.extend(_injectedService.default, _hasPermission.default, {
    sortProperty: 'user.login',
    aflEntity: null,
    status: 'ENABLED',

    collectivityCategory: '',
    siren: '',
    corporateName: '',

    actions: {
      searchFunction: function searchFunction(searchParams) {
        this.set('model', this.store.query('aflUser', {
          aflEntity: this.get('aflEntity.id'),
          status: this.get('status'),
          page: searchParams.page,
          size: searchParams.size,
          direction: searchParams.direction,
          properties: searchParams.properties
        }));
      }
    }
  });
});