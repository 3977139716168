define('afl/routes/agency/funding-application/detail', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      return this.store.findOneQuery('fundingApplication', params.funding_id, {
        source: 'LOCAL'
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('model', this.store.findOneQuery('fundingApplication', model.id, {
        source: 'LOCAL'
      }));
    }
  });
});