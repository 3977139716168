define('afl/controllers/paying-agent/index', ['exports', 'afl/controllers/search', 'afl/mixins/injected-service', 'afl/mixins/search-collectivity'], function (exports, _search, _injectedService, _searchCollectivity) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _search.default.extend(_injectedService.default, _searchCollectivity.default, {

    collectivity: '',
    sortProperty: 'corporateName',
    direction: 'ASC',

    init: function init() {
      this._super.apply(this, arguments);
    },

    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.payingAgent.index');
    }),

    actions: {
      searchFunction: function searchFunction(searchParams) {
        this.set('model', this.store.query('collectivity', {
          category: '',
          query: '',
          page: searchParams.page,
          size: searchParams.size,
          direction: this.get('direction'),
          properties: this.get('sortProperty')
        }));
      },

      onSelectedCollectivity: function onSelectedCollectivity(collectivity) {
        if (!Ember.isEmpty(collectivity)) {
          this.transitionToRoute('payingAgent.collectivity', collectivity);
        }
      }
    }
  });
});