define('afl/components/document/document-table-row', ['exports', 'afl/mixins/injected-service', 'afl/mixins/has-permission', 'ember-cli-file-saver/mixins/file-saver'], function (exports, _injectedService, _hasPermission, _fileSaver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_fileSaver.default, _injectedService.default, _hasPermission.default, {

    isDocToSign: false,
    tagName: 'div',
    hasDocumentName: true,

    mimeIcon: Ember.computed('model.mimeType', function () {
      return 'icon-' + this.get('model.mimeType').toLowerCase();
    }),

    altDlTxt: Ember.computed('model.{mimeType,fileSize}', function () {
      var size = this.get('model.fileSize') / 1024,
          mime = this.get('model.mimeType');
      return this.get('intl').getMessage('collectivity.documents.downloadAlt') + " (" + mime + ", " + size + "" + this.get('intl').getMessage("collectivity.documents.sizeMb") + ")";
    }),

    actions: {
      download: function download() {
        var _this = this;

        // En local on récupère le proxy, sinon lien relatif sur le même domaine:port

        Ember.$.ajax({
          type: 'GET',
          url: '/api/download/document/collectivity/' + this.get('model.id'),
          dataType: 'blob', // or 'blob'
          headers: {
            'authorization': this.get('currentUser').getToken()
          },
          processData: false
        }).then(function (content) {
          _this.saveFileAs(_this.get('model.fileName'), content);
        });
      }
    }
  });
});