define('afl/routes/agency/contact-request/detail', ['exports', 'afl/mixins/injected-service', 'afl/mixins/authenticated-route-mixin'], function (exports, _injectedService, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_injectedService.default, _authenticatedRouteMixin.default, {
    model: function model(params) {
      return this.store.find('contact-request', params.contactRequest_id);
    },
    activate: function activate() {
      this.controllerFor('agency.contactRequest.detail').set('dialogAccept', false);
      this.controllerFor('agency.contactRequest.detail').set('dialogFinalize', false);
      this.controllerFor('agency.contactRequest.detail').set('dialogRefuse', false);
    },
    resetController: function resetController(controller /*, isExiting, transition*/) {
      controller.set('dialogAccept', false);
      controller.set('dialogRefuse', false);
      controller.set('dialogFinalize', false);
    },


    actions: {
      proceed: function proceed() {
        var _this = this;

        this.controller.set('model.status', 'ACCEPTED');
        this.controller.get('model').save().then(function () {
          var civility = _this.controller.get('model.civility');
          var firstName = _this.controller.get('model.firstName');
          var lastName = _this.controller.get('model.lastName');
          Ember.get(_this, 'toast').success(_this.get('intl').getMessage('agency.contact.contactForm.successAccept', {
            civility: _this.get('intl').getEnum('enums.Civility.' + civility),
            firstName: firstName,
            lastName: lastName
          }));
          _this.transitionTo('agency.contactRequest.detail');
        }).catch(function (errors) {
          var error = errors.errors[0] || {},
              message = _this.get('intl').getMessage('agency.contact.contactForm.error');
          if (error.detail && error.detail.message) {
            message = error.detail.message;
          }
          Ember.get(_this, 'toast').error(message);
          _this.controller.get('model').deepRollback();
        });
      },
      refuse: function refuse() {
        var _this2 = this;

        this.controller.set('model.status', 'REFUSED');
        this.controller.get('model').save().then(function () {
          Ember.get(_this2, 'toast').success(_this2.get('intl').getMessage('agency.contact.contactForm.successRefuse'));
          _this2.transitionTo('agency.contactRequest.index');
        }).catch(function () {
          Ember.get(_this2, 'toast').error(_this2.get('intl').getMessage('agency.contact.contactForm.error'));
        });
      },
      finalize: function finalize() {
        var _this3 = this;

        this.controller.set('model.status', 'CLOSED');
        this.controller.get('model').save().then(function () {
          Ember.get(_this3, 'toast').success(_this3.get('intl').getMessage('agency.contact.contactForm.successFinalize'));
          _this3.transitionTo('agency.contactRequest.index');
        }).catch(function () {
          Ember.get(_this3, 'toast').error(_this3.get('intl').getMessage('agency.contact.contactForm.error'));
        });
      }
    }
  });
});