define('afl/routes/collectivity/funding-application/being-analysed/detail', ['exports', 'afl/mixins/injected-service', 'afl/mixins/authenticated-route-mixin'], function (exports, _injectedService, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_injectedService.default, _authenticatedRouteMixin.default, {
    model: function model(params) {
      return this.store.findOneQuery('fundingApplication', params.fundingApplication_id, {
        source: 'REMOTE',
        collectivity_id: this.get('currentUser.entityId')
      });
    },

    actions: {
      error: function error(_error) {
        if (_error && _error.status === 500) {
          // FPLAFLINT-683 Affichage d'un message plus générique
          Ember.get(this, 'toast').error(this.get('intl').getMessage('simulation.noFundingApplication'));
          return this.transitionTo('index');
        }
        return true;
      }
    }
  });
});