define('afl/components/crm/role-edition', ['exports', 'afl/mixins/injected-service', 'afl/mixins/has-permission', 'afl/utils/afl-constants', 'afl/transforms/localdatetime', 'moment', 'ember-cp-validations'], function (exports, _injectedService, _hasPermission, _aflConstants, _localdatetime, _moment, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var localDateTimeTransform = _localdatetime.default.create();

  var Validations = (0, _emberCpValidations.buildValidations)({
    'role.role': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.roleVisible').volatile()
    }),
    'role.roleStartDate': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.roleVisible').volatile()
    }),
    'subscription.subscription': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.subscriptionVisible').volatile()
    }),
    'subscription.subscriptionStartDate': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.subscriptionVisible').volatile()
    })
  });

  var Role = Ember.Object.extend({
    roleStartDate: Ember.computed.alias('startDate'),

    role: '',
    startDate: null,
    endDate: null,

    toJson: function toJson() {
      return JSON.stringify({
        role: this.get('role'),
        startDate: this.get('startDate'),
        endDate: this.get('endDate')
      });
    }
  });

  var Subscription = Ember.Object.extend({
    subscriptionStartDate: Ember.computed.alias('startDate'),
    subscription: '',
    startDate: null,
    endDate: null,

    toJson: function toJson() {
      return JSON.stringify({
        subscription: this.get('subscription'),
        startDate: this.get('startDate'),
        endDate: this.get('endDate')
      });
    }
  });

  var RoleEdition = Ember.Component.extend(Validations, _injectedService.default, _hasPermission.default, {
    isValid: true,
    documentValidityObserver: Ember.observer('isRestricted', 'document.fileRef', function () {
      if (this.get('isRestricted') && this.get('document')) {
        var _this = this;
        this.get('document').validate().then(function () {
          _this.set('isValid', _this.get('document.validations.isValid'));
        });
      } else {
        this.set('isValid', true);
      }
    }),
    isRestricted: false,
    store: Ember.inject.service(),
    crmRoles: Ember.computed('isRestricted', function () {
      if (this.get('isRestricted')) {
        return (0, _aflConstants.default)('CRMRoleRestricted');
      } else {
        return (0, _aflConstants.default)('CRMRole');
      }
    }),
    timeFormat: 'YYYY-MM-DDTHH:mm:ss',
    roleStartDate: null,
    roleEndDate: null,
    subscriptionStartDate: null,
    subscriptionEndDate: null,
    crmSubscriptions: (0, _aflConstants.default)('CRMSubscription'),
    hasRoleAccessForAction: Ember.computed('hasCRMAccessForAction', 'hasUsersAccessForAction', function () {
      return this.get('hasCRMAccessForAction') || this.get('hasUsersAccessForAction');
    }),

    roleStartDateChange: Ember.observer('roleStartDate', function () {
      var roleStartDate = this.get('roleStartDate');
      if (roleStartDate) {
        this.set('role.startDate', (0, _moment.default)(roleStartDate).format(this.get('timeFormat')));
      } else {
        this.set('role.startDate', null);
      }
    }),

    roleEndDateChange: Ember.observer('roleEndDate', function () {
      var roleEndDate = this.get('roleEndDate');
      if (roleEndDate) {
        this.set('role.endDate', (0, _moment.default)(roleEndDate).format(this.get('timeFormat')));
      } else {
        this.set('role.endDate', null);
      }
    }),

    subscriptionStartDateChange: Ember.observer('subscriptionStartDate', function () {
      var subscriptionStartDate = this.get('subscriptionStartDate');
      if (subscriptionStartDate) {
        this.set('subscription.startDate', (0, _moment.default)(subscriptionStartDate).format(this.get('timeFormat')));
      } else {
        this.set('subscription.startDate', null);
      }
    }),

    subscriptionEndDateChange: Ember.observer('subscriptionEndDate', function () {
      var subscriptionEndDate = this.get('subscriptionEndDate');
      if (subscriptionEndDate) {
        this.set('subscription.endDate', (0, _moment.default)(subscriptionEndDate).format(this.get('timeFormat')));
      } else {
        this.set('subscription.endDate', null);
      }
    }),

    _isActive: function _isActive(object) {
      var now = new Date();
      var start = object.startDate;
      start = start ? localDateTimeTransform.deserialize(start) : now;
      var end = object.endDate;
      end = end ? localDateTimeTransform.deserialize(end) : now;
      return start.getTime() <= now.getTime() && end.getTime() >= now.getTime();
    },


    requestMethod: Ember.computed('edition', function () {
      return this.get('edition') ? 'PUT' : 'POST';
    }),

    _submitRole: function _submitRole() {
      var _this2 = this;

      Ember.$.ajax({
        url: '/api/' + this.get('endpoint') + '/' + this.get('model.id') + '/roles',
        type: this.get('requestMethod'),
        data: this.get('role').toJson(),
        dataType: 'json',
        contentType: 'application/json'
      }).then(function () {
        if (_this2.get('edition')) {
          Ember.get(_this2, 'toast').success(_this2.get('intl').getMessage('crm.contacts.edit.roleUpdated'));
        } else {
          Ember.get(_this2, 'toast').success(_this2.get('intl').getMessage('crm.contacts.edit.roleAdded'));
        }

        _this2.set('didValidateRole', false);
        _this2.get('roleDefer').resolve();
        _this2.send('reload');
      }).fail(function (error) {
        if (error.responseJSON && error.responseJSON.message) {
          _this2.set('errorMessage', error.responseJSON.message);
        } else {
          _this2.set('errorMessage', _this2.get('intl').getMessage('common.unknownError'));
        }
        _this2.get('roleDefer').reject();
      });
    },

    actions: {
      reload: function reload() {
        this.get('model').reload();
      },
      addRole: function addRole() {
        this.set('errorMessage', '');
        this.set('roleVisible', true);
        this.set('edition', false);
        this.set('role', Role.create(Ember.getOwner(this).ownerInjection()));
        this.set('roleStartDate', null);
        this.set('roleEndDate', null);
        if (this.get('isRestricted')) {
          this.set('document', this.get('store').createRecord('document', {
            aflEntity: this.get('currentUser.entityId'),
            type: 'DELIBERATIONS'
          }));
        }
      },
      editRole: function editRole(role) {
        this.set('errorMessage', '');
        this.set('roleVisible', true);
        this.set('edition', true);
        this.set('role', Role.create(Ember.getOwner(this).ownerInjection(), {
          role: role.role
        }));
        this.set('roleStartDate', localDateTimeTransform.deserialize(role.startDate));
        this.set('roleEndDate', localDateTimeTransform.deserialize(role.endDate));
      },
      roleClose: function roleClose() {
        this.set('roleVisible', false);
        this.set('didValidateRole', false);
      },
      roleSubmit: function roleSubmit(defer) {
        this.get('roleForm').send('submit');
        this.set('roleDefer', defer);
      },
      roleError: function roleError() {
        this.get('roleDefer').reject();
      },
      roleSuccess: function roleSuccess() {
        var _this3 = this;

        if (this.get('isRestricted') && !this.get('edition')) {
          var data = JSON.stringify(this.get('document'));
          Ember.$.ajax({
            url: '/api/collectivities/fillDocument',
            type: 'POST',
            data: data,
            dataType: 'json',
            contentType: 'application/json; charset=utf-8'
          }).then(function () {
            _this3._submitRole();
          }).fail(function (error) {
            if (error.responseJSON && error.responseJSON.message) {
              _this3.set('errorMessage', error.responseJSON.message);
            } else {
              _this3.set('errorMessage', _this3.get('intl').getMessage('common.unknownError'));
            }
            _this3.get('roleDefer').reject();
          });
        } else {
          this._submitRole();
        }
      },
      addSubscription: function addSubscription() {
        this.set('errorMessage', '');
        this.set('subscriptionVisible', true);
        this.set('edition', false);
        this.set('subscription', Subscription.create(Ember.getOwner(this).ownerInjection()));
        this.set('subscriptionStartDate', null);
        this.set('subscriptionEndDate', null);
      },
      editSubscription: function editSubscription(subscription) {
        this.set('errorMessage', '');
        this.set('subscriptionVisible', true);
        this.set('edition', true);
        this.set('subscription', Subscription.create(Ember.getOwner(this).ownerInjection(), {
          subscription: subscription.subscription
        }));
        this.set('subscriptionStartDate', localDateTimeTransform.deserialize(subscription.startDate));
        this.set('subscriptionEndDate', localDateTimeTransform.deserialize(subscription.endDate));
      },
      subscriptionClose: function subscriptionClose() {
        this.set('subscriptionVisible', false);
        this.set('didValidateSubscription', false);
      },
      subscriptionSubmit: function subscriptionSubmit(defer) {
        this.get('subscriptionForm').send('submit');
        this.set('subscriptionDefer', defer);
      },
      subscriptionError: function subscriptionError() {
        this.get('subscriptionDefer').reject();
      },
      subscriptionSuccess: function subscriptionSuccess() {
        var _this4 = this;

        Ember.$.ajax({
          url: '/api/crmcontacts/' + this.get('model.id') + '/subscriptions',
          type: this.get('requestMethod'),
          data: this.get('subscription').toJson(),
          dataType: 'json',
          contentType: 'application/json'
        }).then(function () {
          if (_this4.get('edition')) {
            Ember.get(_this4, 'toast').success(_this4.get('intl').getMessage('crm.contacts.edit.subscriptionUpdated'));
          } else {
            Ember.get(_this4, 'toast').success(_this4.get('intl').getMessage('crm.contacts.edit.subscriptionAdded'));
          }

          _this4.set('didValidateSubscription', false);
          _this4.get('subscriptionDefer').resolve();
          _this4.send('reload');
        }).fail(function (error) {
          if (error.responseJSON && error.responseJSON.message) {
            _this4.set('errorMessage', error.responseJSON.message);
          } else {
            _this4.set('errorMessage', _this4.get('intl').getMessage('common.unknownError'));
          }
          _this4.get('subscriptionDefer').reject();
        });
      }
    }
  });

  exports.default = RoleEdition;
});