define('afl/mixins/upload-document-sign', ['exports', 'afl/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    intl: Ember.inject.service(),

    formats: _environment.default.uploadDocument.extensions,
    maxSize: _environment.default.uploadDocument.size,
    maxSizeInText: _environment.default.uploadDocument.sizeInText,
    uploadSuccessMessage: null,
    uploadErrorMessage: null,

    actions: {
      uploadSuccess: function uploadSuccess(response) {
        // Info sur le fichier
        var fileRef = JSON.parse(response).ref;
        // Call Another function in signature
        this.send('sendManualSignAction', fileRef);
      },
      sendSignFileSuccessCallback: function sendSignFileSuccessCallback() {
        this.set('file', null);
        this.set('uploadSuccessMessage', this.get('intl').getMessage('upload.uploadSuccess'));
      },
      sendSignFileErrorCallback: function sendSignFileErrorCallback() {
        this.set('file', null);
        this.set('uploadErrorMessage', this.get('intl').getMessage('sign.manualSignError'));
      },
      uploadError: function uploadError(errors) {
        this.set('uploadErrorMessage', errors.response);
        Ember.Logger.error('Error uploading Doc', errors.response);
      }
    }
  });
});