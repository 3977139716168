define('afl/components/input/select-autocomplete', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SelectAutocompleteComponent = Ember.Component.extend({
    intl: Ember.inject.service(),
    triggerClass: 'form-control',
    loadMore: true,
    loading: false,
    allowClear: true,
    term: '',
    page: 1,
    options: Ember.A(),

    valueChange: Ember.observer('value', function () {
      if (Ember.isEmpty(this.get('value'))) {
        this.set('selected', null);
      }
    }),

    init: function init() {
      this.set('searchMessage', this.get('intl').t('component.select-autocomplete.search'));
      this.set('loadingMessage', this.get('intl').t('component.select-autocomplete.loading'));
      this.set('noMatchesMessage', this.get('intl').t('component.select-autocomplete.no-results'));
      if (!Ember.isEmpty(this.get('class'))) {
        this.set('triggerClass', this.get('triggerClass').concat(' ').concat(this.get('class')));
      }
      if (Ember.isEmpty(this.get('onchange'))) {
        this.set('onchange', this.defaultOnchange.bind(this));
      }
      this.onSearch('');
      this._super.apply(this, arguments);
    },
    defaultOnchange: function defaultOnchange(selected) {
      this.set('selected', selected);
      if (Ember.isEmpty(selected)) {
        this.set('value', null);
      } else {
        this.set('value', Ember.isEmpty(this.get('field')) ? selected : Ember.get(selected, this.get('field')));
      }
      if (this.get('onSelected')) {
        this.get('onSelected')(selected);
      }
    },


    loadMoreTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var _this = this;

      var queryParams;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              this.set('loading', true);
              queryParams = {
                page: this.get('page'),
                term: this.get('term')
              };
              _context.prev = 2;
              _context.next = 5;
              return this.get('query')(queryParams).then(function (result) {
                _this.set('loading', false);
                _this.set('loadMore', result.more);
                _this.set('page', _this.get('page') + 1);
                if (Ember.isEmpty(_this.get('options'))) {
                  _this.set('options', result.data);
                } else if (!Ember.isEmpty(_this.get('options.content'))) {
                  _this.get('options.content').pushObjects(result.data.content);
                } else {
                  _this.get('options').pushObjects(result.data);
                }
              });

            case 5:
              _context.next = 10;
              break;

            case 7:
              _context.prev = 7;
              _context.t0 = _context['catch'](2);

              this.sendAction('fail', _context.t0);

            case 10:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[2, 7]]);
    })).drop(),

    onSearch: function onSearch(term) {
      this.set('term', term);
      this.set('page', 1);
      this.set('options', Ember.A());
      this.loadMoreTask.perform();
    },


    actions: {
      loadMoreTask: function loadMoreTask() {
        this.loadMoreTask.perform();
      },
      search: function search(term) {
        this.onSearch(term);
      }
    }
  });

  exports.default = SelectAutocompleteComponent;
});