define('afl/controllers/agency/mailbox', ['exports', 'afl/mixins/injected-service', 'afl/controllers/search', 'afl/mixins/search-collectivity', 'afl/mixins/has-permission', 'ember-cli-file-saver/mixins/file-saver'], function (exports, _injectedService, _search, _searchCollectivity, _hasPermission, _fileSaver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _search.default.extend(_injectedService.default, _searchCollectivity.default, _fileSaver.default, _hasPermission.default, {
    sortProperty: 'message.sendDate',
    ascending: false,
    categoryIn: null,
    subjectFilter: '',
    subjects: null,
    collectivityFilter: '',
    messageDirection: '',
    messageDirections: null,
    status: '',
    statuses: null,
    fundingApplicationRefFilter: '',
    isStatusSelectDisabled: false,

    // new message attributes
    showSendMessageForm: false,
    hasReadOnlyFields: false,
    collectivity: null,
    subject: '',
    subjectComplement: '',
    fundingApplicationRef: '',
    messageContent: '',

    messageCategories: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.categoryIn = [];
      this.subjects = [];
      this.messageDirections = ['OUTGOING', 'INCOMING'];
      this.statuses = ['NOT_READ', 'READ', 'TREATED'];
      this.messageCategories = [];
      this.set('searchCollectivityIsFiltered', false);
    },


    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.agency.mailbox');
    }),

    shouldCollapse: Ember.computed('isInit', 'hasNoResult', function () {
      return this.get('isInit') || this.get('hasNoResult');
    }),

    onMessageDirectionSelectChange: Ember.observer('messageDirection', function () {
      var direction = this.get('messageDirection');
      var isStatusSelectDisabled = this.get('isStatusSelectDisabled');

      // si l'utilisateur filtre par messages émis,
      // on désactive le filtre par statut de message
      if (direction === 'OUTGOING') {
        this.set('status', '');
        this.set('isStatusSelectDisabled', true);
      } else if (isStatusSelectDisabled) {
        this.set('isStatusSelectDisabled', false);
      }
    }),

    reset: Ember.observer('showSendMessageForm', function () {
      if (!this.get('showSendMessageForm')) {
        this.set('collectivity', '');
        this.set('subject', '');
        this.set('subjectComplement', '');
        this.set('fundingApplicationRef', '');
        this.set('messageContent', '');
        this.set('hasReadOnlyFields', false);
        this.set('hasSubjectComplement', false);
        this.set('document', null);
      }
    }),

    _saveMessage: function _saveMessage(message) {
      var _this = this;

      message.save().then(function () {
        _this.set('showSendMessageForm', false);
        _this.send('search');
        Ember.get(_this, 'toast').success(_this.get('intl').getMessage('mailbox.sendMessageForm.success'));
      }).catch(function (errors) {
        Ember.Logger.error('Error during saving new message', errors);
        Ember.get(_this, 'toast').error(_this.get('intl').getMessage('mailbox.sendMessageForm.error'));
        message.rollbackAttributes();
      });
    },

    actions: {
      sendMessage: function sendMessage(message) {
        var _this2 = this;

        var newMessage = this.store.createRecord('message', {
          collectivityId: message.collectivity.id,
          subject: message.subject,
          subjectComplement: message.subjectComplement,
          fundingApplicationRef: message.fundingApplicationRef,
          messageContent: message.messageContent
        });

        var jsonDoc = void 0;

        if (message.document) {
          message.document.set('aflEntity', message.collectivity.id);
          jsonDoc = JSON.stringify(message.document.serialize());
          Ember.$.ajax({
            url: '/api/agencies/' + this.get('currentUser.entityId') + '/fillDocument',
            type: 'POST',
            data: jsonDoc,
            dataType: 'json',
            contentType: 'application/json; charset=utf-8'
          }).then(function (document) {
            newMessage.set('documentId', document.id);
            _this2._saveMessage(newMessage);
          }).catch(function (error) {
            _this2.get('toast').error(error.responseJSON.message);
          });
        } else {
          this._saveMessage(newMessage);
        }
      },

      searchFunction: function searchFunction(searchParams) {
        this.set('model', this.store.query('message', {
          subject: this.get('subjectFilter'),
          categoryIn: this.get('categoryIn'),
          collectivityId: this.get('collectivityFilter.id'),
          messageDirection: this.get('messageDirection'),
          status: this.get('status'),
          fundingApplicationRef: this.get('fundingApplicationRefFilter'),
          page: searchParams.page,
          size: searchParams.size,
          direction: searchParams.direction,
          properties: searchParams.properties
        }));
      },

      reset: function reset() {
        var _this3 = this;

        this.set('subjectFilter', '');
        this.set('collectivityFilter', '');
        this.set('searchCollectivityCategoryFilter', '');
        this.set('messageDirection', '');
        this.set('status', '');
        this.set('fundingApplicationRefFilter', '');
        Ember.$.get('/api/messages/categoryIn').then(function (data) {
          Ember.run(function () {
            return _this3.set('categoryIn', data);
          });
        });
      },

      redirectToCollectivity: function redirectToCollectivity(id) {
        this.transitionToRoute('agency.entity.detail', 'collectivite', id);
      },

      reply: function reply(message) {
        var _this4 = this;

        this.store.find('collectivity', message.get('collectivityId')).then(function (collectivity) {
          _this4.set('collectivity', collectivity);
          _this4.set('subject', message.get('subject'));
          _this4.set('subjectComplement', message.get('subjectComplement'));
          _this4.set('fundingApplicationRef', message.get('fundingApplicationRef'));
          _this4.set('messageContent', '');
          _this4.set('hasReadOnlyFields', true);
          _this4.set('showSendMessageForm', true);
          // scroll au top de la page pour rendre visible le formulaire d'envoi de message
          window.scrollTo(0, 0);
        });
      },

      downloadDocument: function downloadDocument(message) {
        var _this5 = this;

        Ember.$.ajax({
          type: 'GET',
          url: '/api/download/document/collectivity/' + message.documentId,
          dataType: 'blob', // or 'blob'
          headers: {
            'authorization': this.get('currentUser').getToken()
          },
          processData: false
        }).then(function (content) {
          _this5.saveFileAs(message.documentName, content);
        });
      }
    }
  });
});