define('afl/models/paying-agent', ['exports', 'ember-data', 'afl/models/afl-entity', 'ember-cp-validations'], function (exports, _emberData, _aflEntity, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    corporateName: (0, _emberCpValidations.validator)('presence', true),
    address1: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 38
    })],
    address2: (0, _emberCpValidations.validator)('length', {
      max: 38
    }),
    address3: (0, _emberCpValidations.validator)('length', {
      max: 38
    }),
    address4: (0, _emberCpValidations.validator)('length', {
      max: 38
    }),
    postalCode: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      integer: true,
      allowString: true
    }), (0, _emberCpValidations.validator)('length', {
      is: 5
    })],
    city: (0, _emberCpValidations.validator)('presence', true)
  });

  var PayingAgent = _aflEntity.default.extend(Validations, {
    corporateName: _emberData.default.attr('string'),
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    address3: _emberData.default.attr('string'),
    address4: _emberData.default.attr('string'),
    postalCode: _emberData.default.attr('string'),
    city: _emberData.default.attr('string')
  });

  exports.default = PayingAgent;
});