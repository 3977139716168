define('afl/validators/word', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * Vérifie que la propriété correspond à une valeur sans caractères spéciaux en dehors de '-{espace}.
   *
   * Exemple de nom valides : test
   * Exemple de nom invalide : test5$
   */
  var isWordValid = function isWordValid(word) {
    var regex = /^[A-Za-zÀ-ÿ- ']*$/;
    return regex.test(word);
  };

  exports.default = _base.default.extend({
    intl: Ember.inject.service(),

    validate: function validate(value) {
      if (!Ember.isEmpty(value)) {
        return isWordValid(value) ? true : this.get('intl').getMessage('validations.name');
      } else {
        return true;
      }
    }
  });
});