define('afl/components/input/input-select-enum', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),

    enumValues: null,
    enumType: null,
    selectedValue: null,
    withBlank: false,
    blankLabel: '',
    inputName: Ember.computed.reads('enumType'),
    inputId: Ember.computed.reads('enumType'),
    inputClass: 'form-control',
    disabled: false,
    sorted: true,
    excluded: null,

    selected: Ember.computed('selectedValue', {
      get: function get() {
        var type = this.get('enumType'),
            value = this.get('selectedValue') || '',
            label = value !== '' ? this.get('intl').getEnum('enums.' + type + '.' + value) : this.get('blankLabel');
        return { value: value, label: label };
      },
      set: function set(key, value) {
        if (value.value) {
          this.set('selectedValue', value.value);
        } else {
          this.set('selectedValue', '');
        }
        if (this.get('onSelected')) {
          this.get('onSelected')(value);
        }
        return value;
      }
    }),

    selection: Ember.computed.reads('selected'),

    content: Ember.computed('enumValues.@each', 'enumType', 'withBlank', 'blankLabel', function () {
      var _this = this;

      var enumValues = this.get('enumValues') || [],
          type = this.get('enumType'),
          sorted = this.get('sorted'),
          excluded = this.get('excluded') || [];

      var content = [];

      enumValues.forEach(function (item) {
        if (!excluded.includes(item)) {
          content.push({
            value: item,
            label: _this.get('intl').getEnum('enums.' + type + '.' + item)
          });
        }
      });

      if (sorted) {
        content = content.sort(function (o1, o2) {
          return o1.label.localeCompare(o2.label);
        });
      }

      if (this.get('withBlank')) {
        content.unshift({
          value: '',
          label: this.get('blankLabel')
        });
      }

      return content;
    })
  });
});