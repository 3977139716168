define('afl/routes/paying-agent/collectivity/fundings/treasury/detail', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      return this.store.findOneQuery('loan', params.treasury_id, { collectivity_id: this.modelFor('payingAgent.collectivity').get('id'), loan_number: params.loan_number });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var collectivityId = this.modelFor('payingAgent.collectivity').get('id');
      var isCollectivityMember = this.modelFor('payingAgent.collectivity').get('isCollectivityMember');
      controller.set('isMobilisation', false);
      controller.set('collectivityId', collectivityId);
      controller.set('isMember', isCollectivityMember);
      controller.set('currentFundingId', model.get('id'));
    }
  });
});