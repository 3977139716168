define('afl/routes/paying-agent/collectivity/documents/history', ['exports', 'afl/mixins/authenticated-route-mixin', 'afl/mixins/injected-service'], function (exports, _authenticatedRouteMixin, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _injectedService.default, {
    model: function model() {
      var documentHistoryController = this.controllerFor('payingAgent.collectivity.documents.history');
      documentHistoryController.set('entityId', this.modelFor('payingAgent.collectivity').get('id'));
      return documentHistoryController.send('searchFunction', {
        collectivity: this.get('currentUser.entityId'),
        historySize: documentHistoryController.get('historySize'),
        page: 1,
        size: 50,
        direction: 'DESC',
        properties: 'uploadDate'
      });
    }
  });
});