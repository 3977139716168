define('afl/helpers/enum-display', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute: function compute(params, hash) {
      var intl = this.get('intl'),
          value = params[0],
          type = hash.type;

      if (value) {
        if (type) {
          return intl.getEnum('enums.' + type + '.' + value);
        } else {
          return value;
        }
      } else {
        return "";
      }
    }
  });
});