define('afl/services/subscription', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    /**
     * Renvoie une promesse qui retourne l'array d'abonnements associé au type d'entité passé en paramètre
     * et construit à partir des <notificationPreferences>
     *
     * @param notificationPreferences: {name: String, label: String, subscribed: boolean}
     *    les préférences d'abonnements de l'utilisateur
     * @param entityType: String
     *    le type d'entité
     */
    retrieveSubscriptionByEntityType: function retrieveSubscriptionByEntityType(user) {
      var _this = this;

      return Ember.$.get('/api/notifications/values', {
        entityType: user.get('entityType')
      }).then(function (notifications) {
        return _this._getSubscriptionsModel(user.get('subscriptions'), notifications);
      });
    },


    /**
     * Renvoie un array contenant des objets d'abonnements à des notifications, au format :
     * {
     *    enumValue: nom du champ au format 'enum'
     *    notification: label de la notification (français)
     *    subscribed: booléen indiquant si l'abonnement est souscrit
     * }
     *
     * @param notificationPreferences : les préférences de notification
     * @param notifications           : l'ensemble des notifications de l'objet à construire
     * @private
     */
    _getSubscriptionsModel: function _getSubscriptionsModel(notificationPreferences, notifications) {
      var _this2 = this;

      return notifications.map(function (notification) {
        return {
          enumValue: notification.name,
          notification: notification.label,
          subscribed: _this2._isNotificationSubscribed(notificationPreferences, notification.name)
        };
      });
    },


    /**
     *  Retourne TRUE si <notificationName> est une notification souscrite dans l'array de préférences
     *  passé en paramètre (<notificationPreferences>).
     *
     *  Une notification absente dans les préférences est considérée comme souscrite, par défaut.
     *
     * @param notificationPreferences : Array d'objet {name, _, subscribed} représentant les préférences de notifications
     * @param notificationName        : string contenant la valeur au format 'enum' de la notification
     * @returns subscribed : boolean
     * @private
     */
    _isNotificationSubscribed: function _isNotificationSubscribed(notificationPreferences, notificationName) {
      if (!notificationPreferences.map(function (preference) {
        return preference.notification;
      }).includes(notificationName)) {
        return true;
      }
      return notificationPreferences.filter(function (preference) {
        return preference.notification === notificationName;
      })[0].subscribed;
    }
  });
});