define('afl/mixins/account-detail', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    intl: Ember.inject.service(),

    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.account.index');
    })
  });
});