define('afl/components/funding/fundapp-form', ['exports', 'afl/mixins/injected-service', 'afl/config/environment'], function (exports, _injectedService, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_injectedService.default, {
    model: null,
    isSimulation: true,
    isEdition: false,
    isAgency: false,
    needConfirmation: false,
    submitKey: 'common.validate',
    validationKey: '',
    successKey: '',
    errorKey: '',
    formats: _environment.default.fundingApplicationUploadDocument.extensions,
    maxSize: _environment.default.fundingApplicationUploadDocument.size,
    maxSizeInText: _environment.default.fundingApplicationUploadDocument.sizeInText,
    showConfirmation: false,
    isLoading: false,
    amortisationFrequencies: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.amortisationFrequencies = ['QUARTERLY', 'HALF_YEARLY', 'YEARLY'];
    },

    // masquage de la partie ligne de trésorerie sur demande client FPLAFLINT-1200
    fundingNatures: Ember.computed(function () {
      if (_environment.default.APP.isTreasuryLineEnable) {
        return ['LONG_TERM', 'TREASURY', 'MOBILISATION'];
      }
      return ['LONG_TERM', 'MOBILISATION'];
    }),

    interestFrequencies: Ember.computed('model.amortisationFrequency', 'isConstant', function () {
      if (!this.get('isConstant')) {
        if (this.get('model.amortisationFrequency') === 'QUARTERLY') {
          return ['QUARTERLY'];
        } else if (this.get('model.amortisationFrequency') === 'HALF_YEARLY') {
          return ['QUARTERLY', 'HALF_YEARLY'];
        }
      }
      return ['QUARTERLY', 'HALF_YEARLY', 'YEARLY'];
    }),

    isConstant: Ember.computed('model.amortisationMethod', function () {
      return this.get('model.amortisationMethod') === 'CONSTANT_MATURITY';
    }),

    updateInterestFrequency: Ember.observer('model.amortisationFrequency', 'isConstant', function () {
      if (this.get('isConstant')) {
        this.set('model.interestFrequency', this.get('model.amortisationFrequency'));
      }
    }),

    updateInterestFrequencies: Ember.observer('interestFrequencies', 'isConstant', function () {
      if (!this.get('isConstant') && !this.get('model.modified')) {
        this.set('model.interestFrequency', this.get('model.amortisationFrequency'));
      }
    }),

    isFundingNatureEditable: Ember.computed('isSimulation', 'isEdition', function () {
      return !this.get('isEdition') && !this.get('isSimulation');
    }),

    isFunding: Ember.computed('isSimulation', function () {
      return !this.get('isSimulation');
    }),

    amountKey: Ember.computed('model.{isLongTerm,isTreasury}', function () {
      if (this.get('model.isLongTerm')) {
        return 'simulation.amount';
      } else if (this.get('model.isTreasury')) {
        return 'simulation.ceiling';
      } else {
        return 'simulation.amount';
      }
    }),

    // Si la nature de financement change, on réinitialise les attributs du modèle au valeur par défaut
    // de la nouvelle nature de financement
    fundingNatureChanged: Ember.observer('model.fundingNature', function () {
      var fundingNature = this.get('model.fundingNature'),
          model = this.get('model');

      if (fundingNature === 'LONG_TERM') {
        model.set('amortisationFrequency', 'QUARTERLY');
        model.set('amortisationMethod', 'CONSTANT_MATURITY');
        model.set('interestFrequency', 'QUARTERLY');
        model.set('rateType', 'FIXED');
        model.set('beiIndicator', false);
        model.set('budgetType', 'PRINCIPAL');
      } else if (fundingNature === 'TREASURY') {
        model.set('amortisationFrequency', null);
        model.set('amortisationMethod', null);
        model.set('interestFrequency', null);
        model.set('rateType', null);
        model.set('beiIndicator', null);
        model.set('budgetType', null);
      } else if (fundingNature === 'MOBILISATION') {
        model.set('budgetType', 'PRINCIPAL');
        model.set('amortisationDuration', null);
      }

      model.set('duration', null);
      model.set('amount', null);
      model.set('settlementDate', null);
      model.set('endDate', null);
      model.set('deadline', null);
      model.set('fileName', null);
    }),

    isVariableRateType: Ember.computed('model.rateType', function () {
      if (this.get('model.rateType') === 'VARIABLE') {
        this.set('model.amortisationMethod', 'STRAIGHT_LINE');
        return true;
      }
      return false;
    }),

    actions: {
      formSuccess: function formSuccess() {
        if (this.get('needConfirmation')) {
          this.toggleProperty('showConfirmation');
        } else {
          this.send('saveFunding');
        }
      },

      formError: function formError() {
        Ember.get(this, 'toast').error(this.get('intl').getMessage(this.get('validationKey')));
      },

      saveFunding: function saveFunding() {
        var _this = this;

        var model = this.get('model'),
            isEdition = this.get('isEdition');

        this.set('isLoading', true);
        if (isEdition) {
          model.set('modified', true);
        }
        model.save().then(function (object) {
          Ember.run.next(_this, function () {
            Ember.get(this, 'toast').success(this.get('intl').getMessage(this.get('successKey')));
            this.sendAction('onSuccess', object);
            this.set('isLoading', false);
          });
        }).catch(function (errors) {
          var error = errors.errors[0] || {};
          var message = _this.get('intl').getMessage(_this.get('errorKey'));

          if (error.detail && error.detail.message) {
            message = error.detail.message;
          }

          Ember.get(_this, 'toast').error(message);
          _this.set('isLoading', false);
        });
      },

      cancel: function cancel() {
        this.sendAction('onCancel');
      }
    }
  });
});