define('afl/routes/paying-agent/collectivity/fundings/mobilisation/index', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      // Initialise le model à un tableau vide
      return Ember.A();
    },
    setupController: function setupController(controller) {
      controller.set('collectivity', this.modelFor('payingAgent.collectivity'));

      // Le controlleur va mettre à jour le model
      controller.send('searchFunction', {
        page: 1,
        size: controller.get('pageSize'),
        direction: controller.get('direction'),
        properties: controller.get('sortProperty')
      });
    }
  });
});