define('afl/mixins/news-actions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    selectedNews: null,
    dialogStopPublish: false,
    dialogDeleteNews: false,
    dialogPublishNews: false,

    actions: {
      stopPublishToggle: function stopPublishToggle(news) {
        this.toggleProperty('dialogStopPublish');
        this.set('selectedNews', news);
      },
      deleteNewsToggle: function deleteNewsToggle(news) {
        this.toggleProperty('dialogDeleteNews');
        this.set('selectedNews', news);
      },
      publishNewsToggle: function publishNewsToggle(news) {
        this.toggleProperty('dialogPublishNews');
        this.set('selectedNews', news);
      }
    },

    modelDidChange: Ember.observer('model.@each', function () {
      this.set('dialogDeleteNews', false);
      this.set('dialogPublishNews', false);
      this.set('dialogStopPublish', false);
      this.set('dialogExport', false);
    })
  });
});