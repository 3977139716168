define('afl/models/grid-rate-cell', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var GridRateCell = _emberData.default.Model.extend({
    value: _emberData.default.attr('string'),
    position: _emberData.default.attr('number'),
    row: _emberData.default.belongsTo('gridRateRow', { async: false })
  });

  exports.default = GridRateCell;
});