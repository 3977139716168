define('afl/routes/agency/person/detail/edit', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      return this.modelFor('agency.person.detail');
    },


    actions: {
      willTransition: function willTransition() {
        this.controller.get('model').rollbackAttributes();
        return true;
      }
    }
  });
});