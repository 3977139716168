define('afl/controllers/contact/summary', ['exports', 'afl/mixins/injected-service'], function (exports, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_injectedService.default, {
    askCallback: false,

    actions: {
      askCallback: function askCallback() {
        var _this = this;

        this.set('askCallback', true);
        Ember.Logger.debug("Asking callback for contact request", this.get('model.id'));

        Ember.$.ajax({
          url: "/api/contactrequests/" + this.get('model.id') + "/callback",
          type: "PUT"
        }).then(function () {
          Ember.get(_this, 'toast').success(_this.get('intl').getMessage('collectivity.preProspect.summaryForm.callback'));
        }).fail(function (error) {
          _this.set('askCallback', false);
          Ember.get(_this, 'toast').error(error.responseJSON.message);
        });
      }
    }
  });
});