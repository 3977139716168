define('afl/components/funding/funding-document-list-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: 'li',
    hasManagement: false,
    fundingId: null,
    classNames: ['funding-app-file-number-element'],
    classNameBindings: ['isActive'],
    closeFolder: true,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      Ember.$.get('/api/documents/funding/management', {
        collectivity: this.get('entityId'),
        fileNumber: this.get('fundingId')
      }).then(function (data) {
        Ember.run(function () {
          if (Ember.testing && typeof data === 'string') {
            // A modification on pretender (or fakexmlrequest) need that any response must be a string...
            // The problem is that this string is not correctly transform for this case.
            // Therefore, we do the conversion manually
            data = JSON.parse(data);
          }
          _this.set('hasManagement', data);
        });
      });
    },

    isActive: Ember.computed(function () {
      if (this.get('isCurrentFundingApp')) {
        return 'active';
      }
    }),

    isCurrentFundingApp: Ember.computed('currentFundingId', 'fundingId', function () {
      return this.get('currentFundingId') === this.get('fundingId');
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.run.scheduleOnce('afterRender', this, this.afterRenderEvent);
    },

    afterRenderEvent: function afterRenderEvent() {
      if (this.get('isCurrentFundingApp')) {
        var $categoryToCollapse = Ember.$('#doc-' + this.get('fundingId')).parent().find('ul');
        $categoryToCollapse.collapse('show');
        if (this.get('currentManagementMode')) {
          this.resetFieldClass('doc-management', this.get('fundingId'));
        } else {
          this.resetFieldClass('doc-instruction', this.get('fundingId'));
        }
      }
    },

    resetFieldClass: function resetFieldClass(elemClass, fundingId) {
      this.sendAction('resetActiveElement');
      Ember.$('#doc-' + fundingId).find('a.' + elemClass).addClass('active');
      Ember.$('#doc-' + fundingId).parent().addClass('active');
    },

    actions: {

      seeMore: function seeMore(fundingId) {
        if (Ember.$('.see-more-icon.doc-' + fundingId).attr("aria-expanded") === "false") {
          this.send('seeGestion', fundingId);
          this.set('closeFolder', false);
        } else {
          this.set('closeFolder', true);
        }
      },

      seeGestion: function seeGestion(fundingId) {
        if (this.get('hasManagement')) {
          Ember.get(this, 'setFundingId')(fundingId);
          Ember.get(this, 'setManagementMode')(true);
          this.sendAction('resetModel');
          this.resetFieldClass('doc-management', fundingId);
        } else {
          this.send('seeInstruction', fundingId);
        }
      },

      seeInstruction: function seeInstruction(fundingId) {
        Ember.get(this, 'setFundingId')(fundingId);
        Ember.get(this, 'setManagementMode')(false);
        this.sendAction('resetModel');
        this.resetFieldClass('doc-instruction', fundingId);
      }
    }
  });
});