define('afl/routes/contact', ['exports', 'afl/mixins/injected-service', 'afl/mixins/unauthenticated-route'], function (exports, _injectedService, _unauthenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_injectedService.default, _unauthenticatedRoute.default, {
    activate: function activate() {
      var controller = this.controllerFor('contact');
      controller.set('currentStep', 'SEARCH');
    },
    renderTemplate: function renderTemplate() {
      this.render();

      this.render('contact.search', {
        into: 'contact',
        outlet: 'content'
      });
    },


    actions: {
      select: function select(collectivity) {
        if (collectivity.get('isMember')) {
          // si la collectivité est déjà membre, on affiche une popin d'information
          this.controllerFor('contact.search').set('isPopinVisible', true);
        } else {
          // sinon, on redirige vers le formulaire de contact
          this.controller.set('currentStep', 'FORM');
          var formController = this.controllerFor('contact.form');

          formController.set('model', this.store.createRecord('contactRequest', {
            collectivity: collectivity
          }));

          formController.set('collectivity', collectivity);

          this.render('contact.form', {
            into: 'contact',
            outlet: 'content'
          });
        }
      },
      saveContact: function saveContact() {
        var _this = this;

        this.controllerFor('contact.form').get('model').save().then(function (contactRequest) {
          _this.controller.set('currentStep', 'SUMMARY');
          var summaryController = _this.controllerFor('contact.summary');
          summaryController.set('model', contactRequest);
          _this.render('contact.summary', {
            into: 'contact',
            outlet: 'content'
          });
        }).catch(function (errors) {
          var error = errors.errors[0] || {};
          var msg = '';

          if (error.status === 409) {
            msg = error.detail.message;
          } else {
            var errs = error.detail.message.split(";");
            msg = _this.get('intl').getMessage('collectivity.preProspect.contactForm.error');

            errs.forEach(function (error) {
              if (error !== "") {
                var typeError = error.split(":");
                var champ = typeError[0].trim();

                if (champ === "fixedNumber") {
                  msg += " " + _this.get('intl').getMessage('validations.phone');
                } else if (champ === "mobileNumber") {
                  msg += " " + _this.get('intl').getMessage('validations.mobile');
                }
              }
            });
          }

          Ember.get(_this, 'toast').error(msg);
        });
      },
      cancelContact: function cancelContact() {
        this.controller.set('currentStep', 'SEARCH');
        this.render('contact.search', {
          into: 'contact',
          outlet: 'content'
        });
      },
      validationErrors: function validationErrors() {
        Ember.get(this, 'toast').error(this.get('intl').getMessage('collectivity.preProspect.contactForm.validation'));
      }
    }

  });
});