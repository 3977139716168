define('afl/routes/collectivity/paying-agent', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      return this.store.queryRecord('paying-agent', { collectivity: this.get('currentUser.entityId') }).then(function (res) {
        return res;
      }, function () {
        // Do nothing if paying agent is not found
      });
    }
  });
});