define('afl/controllers/agency/person/detail/contact/detail/index', ['exports', 'afl/mixins/injected-service', 'afl/mixins/has-permission'], function (exports, _injectedService, _hasPermission) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_injectedService.default, _hasPermission.default, {

    timeFormat: 'YYYY-MM-DDTHH:mm:ss',

    actions: {
      reload: function reload() {
        this.get('model').reload();
      }
    }
  });
});