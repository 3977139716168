define('afl/components/grid/pagination-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    page: 1,
    pageSize: 50,
    ascending: true,
    sortProperty: '',
    sizes: null,
    meta: Ember.computed.alias('model.content.meta'),
    totalPages: Ember.computed.alias('meta.totalPages'),
    totalElements: Ember.computed.alias('meta.totalElements'),

    init: function init() {
      this._super.apply(this, arguments);
      if (Ember.isEmpty(this.size)) {
        this.sizes = [10, 20, 50];
      }
    },

    direction: Ember.computed('ascending', function () {
      return this.get('ascending') ? 'ASC' : 'DESC';
    }),

    isInit: Ember.computed('model.@each', function () {
      return !this.get('model').isFulfilled;
    }),

    hasNoResult: Ember.computed('model.@each', function () {
      return this.get('model').isFulfilled && Ember.isEmpty(this.get('model'));
    }),

    sendSearchAction: function sendSearchAction(page, pageSize) {
      this.send('searchFunction', {
        page: page,
        size: pageSize,
        direction: this.get('direction'),
        properties: this.get('sortProperty')
      });
    },

    hasManyPages: Ember.computed('totalPages', function () {
      return this.get('totalPages') > 1;
    }),

    actions: {
      newPage: function newPage(params) {
        this.sendSearchAction(params.page, params.size);
      },

      sort: function sort(sortProperty) {
        if (this.get('sortProperty') === sortProperty) {
          // Si c'est la même propriété sur laquelle on effectue le tri, on change le sens
          this.toggleProperty('ascending');
        } else {
          // Si c'est une nouvelle, on remet le sens par défaut et on met à jour la propriété
          this.set('ascending', true);
          this.set('sortProperty', sortProperty);
        }

        this.sendSearchAction(1, this.get('pageSize'));
      }
    }
  });
});