define('afl/models/faq-section', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FaqSection = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    position: _emberData.default.attr('number'),
    questions: _emberData.default.hasMany('faqQuestion')
  });

  exports.default = FaqSection;
});