define('afl/formats', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    date: {
      'time-style': {
        hour: 'numeric',
        minute: 'numeric'
      },
      'date-style': {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      },
      'year-style': {
        year: 'numeric'
      }
    },
    number: {
      EUR: { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 },
      USD: { style: 'currency', currency: 'USD' },
      percent: { style: 'percent', maximumFractionDigits: 7 },
      grade: { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 },
      round: { style: 'decimal', maximumFractionDigits: 0 }
    },
    time: {
      hhmmss: {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }
    }
  };
});