define('afl/controllers/agency/report/index', ['exports', 'afl/controllers/search'], function (exports, _search) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _search.default.extend({
    sortProperty: 'fileReceivedDate',
    ascending: false,

    actions: {
      searchFunction: function searchFunction(searchParams) {
        this.set('model', this.store.query('userImport', {
          page: searchParams.page,
          size: searchParams.size,
          direction: searchParams.direction,
          properties: searchParams.properties
        }));
      }
    }
  });
});