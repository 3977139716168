define('afl/services/validations/messages', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    intl: Ember.inject.service(),

    render: function render(attribute, context) {
      return this.get('intl').getMessage("validations." + attribute, context);
    }
  });
});