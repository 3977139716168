define('afl/components/info-dialog', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    isVisible: false,

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('isVisible') === undefined) {
        this.set('isVisible', false);
      }
    },


    actions: {
      close: function close() {
        this.toggleProperty('isVisible');
      }
    }
  });
});