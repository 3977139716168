define('afl/routes/index', ['exports', 'afl/mixins/unauthenticated-route'], function (exports, _unauthenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRoute.default, {
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.initWarningList();
    }
  });
});