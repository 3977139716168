define('afl/validators/phone', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function isFixedNumber(phone) {
    // National : 0 [ni 0 ni 6 ni 7] + 8 chiffres
    // International Métropole : [+ ou 00] 33 [ni 6 ni 7] + 8 chiffres
    // International DOM-TOM   : [+ ou 00] [code pays DOM/TOM] de 6 a 9 chiffres
    var regex = /^(((0|(\+|00)((33)))[1-589][0-9]{8})|(\+|00)(262|269|508|590|594|596|681|687|689)[0-9]{6,9})$/;
    return regex.test(phone);
  }

  exports.default = _base.default.extend({
    intl: Ember.inject.service(),

    validate: function validate(value) {
      if (!Ember.isEmpty(value)) {
        return isFixedNumber(value) ? true : this.get('intl').getMessage('validations.phone');
      } else {
        return true;
      }
    }
  });
});