define('afl/components/confirm-dialog', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    isVisible: false,

    useSpinner: false,

    isProceeding: false,

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('isVisible') === undefined) {
        this.set('isVisible', false);
      }
    },


    actions: {
      close: function close() {
        this.toggleProperty('isVisible');
        this.sendAction('close');
        this.set('isProceeding', false);
      },
      proceed: function proceed() {
        if (this.get('isProceeding')) {
          return;
        }

        if (this.get('useSpinner')) {
          this.toggleProperty('isProceeding');
          this.sendAction('proceed', this.defer());
        } else {
          this.toggleProperty('isVisible');
          this.sendAction('proceed');
        }
      }
    },

    defer: function defer() {
      var _this = this;

      return {
        resolve: function resolve() {
          _this.set('isProceeding', false);
          _this.set('isVisible', false);
        },
        reject: function reject() {
          _this.set('isProceeding', false);
          _this.set('isVisible', true);
        }
      };
    }
  });
});