define('afl/routes/collectivity/documents/signature', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    activate: function activate() {
      var docRef = this.paramsFor('collectivity.documents.signature').docRef;
      var docCtrl = this.controllerFor('collectivity.documents.signature');
      docCtrl.set('docRef', docRef);

      Ember.$.get("/mvc/signature/" + docRef + "/setup", function (response) {
        Ember.run(function () {
          // Pour les tests en attendant le certif RGS. ANN RSA : response.certSerial = "5677734639455792282";
          docCtrl.set('setupResponse', response);
          docCtrl.set('setupSuccess', true);
        });
      }).fail(function (response) {
        Ember.Logger.error(response);
        docCtrl.set('warningMsgKey', 'sign.setupError');
        docCtrl.set('setupSuccess', false);
      });
    },
    resetController: function resetController(controller) {
      controller.set('signatureDone', false);
    },


    actions: {
      willTransition: function willTransition() {
        this.controller.set('warningMsgKey', null);
        this.controller.send('reset');
      }
    }

  });
});