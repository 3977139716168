define('afl/routes/agency/entity/detail', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      var type = '';
      switch (params.entity_type) {
        case 'collectivite':
          type = 'collectivity';
          break;
        case 'agence':
          type = 'agency';
          break;
        case 'agent-payeur':
          type = 'paying_agent';
          break;
        default:
          type = '';
      }
      return this.store.find(type, params.entity_id);
    },
    resetController: function resetController(controller /*, isExiting, transition*/) {
      controller.set('dialogDeactivateEntity', false);
      controller.set('dialogActivateEntity', false);
      controller.set('dialogCloseEntity', false);
    }
  });
});