define('afl/mixins/unauthenticated-route', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'afl/mixins/injected-service'], function (exports, _unauthenticatedRouteMixin, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_unauthenticatedRouteMixin.default, _injectedService.default, {
    beforeModel: function beforeModel(transition) {
      if (this.get('session.isAuthenticated')) {
        transition.abort();

        switch (this.get('currentUser.entityType')) {
          case 'AGENCY':
            this.transitionTo('agency');
            break;
          case 'COLLECTIVITY':
            if (this.get('currentUser.isProspect')) {
              this.transitionTo('collectivity.prospect');
            } else {
              this.transitionTo('collectivity');
            }
            break;
          case 'PAYING_AGENT':
            this.transitionTo('payingAgent');
            break;
        }
      }
    }
  });
});