define('afl/routes/agency/event/index', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.store.query('group', {
        entityType: 'AGENCY',
        filtered: false
      }).then(function (groups) {
        controller.set('interactionNatures', groups);
      });
    },
    resetController: function resetController(controller) {
      controller._resetFilter();
      controller.set('dialogExport', false);
    }
  });
});