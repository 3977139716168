define('afl/components/entity/entity-detail', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    isCollectivity: Ember.computed('model.aflEntityType', function () {
      return this.get('model.aflEntityType') === 'COLLECTIVITY';
    }),

    payingAgent: Ember.computed('model.aflEntityType', function () {
      if (this.get('model.aflEntityType') === 'COLLECTIVITY' && this.get('model.payingAgentId')) {
        return this.store.find('paying-agent', this.get('model.payingAgentId'));
      }
      return null;
    }),

    isPayingAgent: Ember.computed('model.aflEntityType', function () {
      return this.get('model.aflEntityType') === 'PAYING_AGENT';
    }),

    isEntityDetailPath: Ember.computed('currentPath', function () {
      var currentPath = this.get('currentPath');
      return currentPath === 'agency.entity.detail.user.index' || currentPath === 'agency.entity.detail.contact' || currentPath === 'agency.entity.detail.event' || currentPath === 'agency.entity.detail.task';
    })
  });
});