define('afl/routes/agency/collectivity/detail', ['exports', 'afl/mixins/injected-service', 'afl/mixins/authenticated-route-mixin'], function (exports, _injectedService, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_injectedService.default, _authenticatedRouteMixin.default, {
    controllerName: 'agency.entity.detail',
    model: function model(params) {
      return this.store.find('collectivity', params.collectivity_id);
    }
  });
});