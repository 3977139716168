define('afl/controllers/agency/entity/index', ['exports', 'afl/controllers/search', 'afl/mixins/has-permission', 'afl/utils/afl-constants', 'ember-cp-validations', 'afl/mixins/search-collectivity'], function (exports, _search, _hasPermission, _aflConstants, _emberCpValidations, _searchCollectivity) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    aflEntityType: (0, _emberCpValidations.validator)('inclusion', {
      in: (0, _aflConstants.default)('AflEntityType'),
      disabled: Ember.computed.bool('model.aflEntityTypeIsEmpty').volatile()
    }),
    siren: (0, _emberCpValidations.validator)('siren'),
    category: (0, _emberCpValidations.validator)('inclusion', {
      in: (0, _aflConstants.default)('CollectivityCategory'),
      disabled: Ember.computed.bool('model.categoryIsEmpty').volatile()
    })
  });

  exports.default = _search.default.extend(_hasPermission.default, Validations, _searchCollectivity.default, {
    collectivityCategories: (0, _aflConstants.default)('CollectivityCategory'),
    collectivityStatuses: (0, _aflConstants.default)('CollectivityStatus'),
    aflEntityTypes: (0, _aflConstants.default)('AflEntityType'),

    aflEntityType: 'COLLECTIVITY',
    aflEntityName: null,
    siren: null,
    searchCollectivityIsFiltered: false,
    allCollectivities: true,
    selectedCollectivity: null,
    category: '',
    status: '',

    aflEntityTypeIsEmpty: Ember.computed('aflEntityType', function () {
      return Ember.isEmpty(this.get('aflEntityType'));
    }),

    categoryIsEmpty: Ember.computed('category', function () {
      return Ember.isEmpty(this.get('category'));
    }),

    notCollectivity: Ember.computed('aflEntityType', function () {
      return this.get('aflEntityType') !== 'COLLECTIVITY';
    }),

    resetFields: Ember.observer('notCollectivity', function () {
      if (this.get('notCollectivity')) {
        this.set('category', '');
        this.set('siren', '');
        this.set('status', '');
        this.set('selectedCollectivity', null);
      }
    }),

    selectedCollectivityChange: Ember.observer('selectedCollectivity', function () {
      var collectivity = this.get('selectedCollectivity');
      if (collectivity) {
        this.set('siren', collectivity.get('siren'));
      } else {
        this.set('siren', null);
      }
    }),

    actions: {
      searchFunction: function searchFunction(searchParams) {
        this.set('model', this.store.query('aflEntity', {
          aflEntityType: this.get('aflEntityType'),
          aflEntityName: this.get('aflEntityName'),
          status: this.get('status'),
          siren: this.get('siren'),
          category: this.get('category'),
          page: searchParams.page,
          size: searchParams.size
        }));
      },

      reset: function reset() {
        this._super();
        this.set('aflEntityType', '');
        this.set('aflEntityName', null);
        this.set('status', '');
        this.set('siren', null);
        this.set('category', '');
        this.set('selectedCollectivity', null);
        this.set('model', []);
      }
    }
  });
});