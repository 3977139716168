define('afl/routes/agency/task/index', ['exports', 'afl/mixins/injected-service', 'afl/mixins/authenticated-route-mixin'], function (exports, _injectedService, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_injectedService.default, _authenticatedRouteMixin.default, {
    setupController: function setupController(controller /*, model*/) {
      // No-op dans ce setupController car on veut que le set du model soit fait par searchFunction
      this.store.query('group', {
        entityType: 'AGENCY',
        filtered: false
      }).then(function (groups) {
        controller.set('interactionNatures', groups);
      });
    },
    resetController: function resetController(controller /*, isExiting, transition*/) {
      controller._resetFilter(true);
      controller.set('dialogExport', false);
    }
  });
});