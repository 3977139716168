define('afl/helpers/news-image-url', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    currentUser: Ember.inject.service('current-user'),

    compute: function compute(params) {
      return '/api/download/image/' + params[0] + '?authorization=' + this.get('currentUser').getToken();
    }
  });
});