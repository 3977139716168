define('afl/controllers/agency/entity/detail/user/index', ['exports', 'afl/mixins/injected-service', 'afl/controllers/search', 'afl/mixins/search-collectivity'], function (exports, _injectedService, _search, _searchCollectivity) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _search.default.extend(_injectedService.default, _searchCollectivity.default, {
    sortProperty: 'user.login',
    aflEntity: null,
    status: 'ENABLED',
    isUserTab: true,
    isAddingCollectivity: false,
    removeCollectivityModal: false,

    collectivity: null,
    tmpCollectivity: null,

    collectivityCategory: '',
    siren: '',
    corporateName: '',

    collectivityIsSelected: Ember.observer('collectivity', function () {
      var collectivity = this.get('collectivity');
      if (collectivity !== null && collectivity !== '') {
        this.send('addCollectivity', collectivity);
      }
      this.set('collectivity', '');
    }),

    canAddAdminRights: Ember.computed('aflEntity.{aflEntityType,isCollectivityMember}', function () {
      var aflEntityType = this.get('aflEntity.aflEntityType'),
          isCollectivityMember = this.get('aflEntity.isCollectivityMember');

      return aflEntityType === 'COLLECTIVITY' && !!isCollectivityMember || aflEntityType === 'PAYING_AGENT';
    }),

    canRemoveAdminRights: Ember.computed('aflEntity.aflEntityType', function () {
      return this.get('aflEntity.aflEntityType') !== 'AGENCY';
    }),

    isPayingAgent: Ember.computed('aflEntity.aflEntityType', function () {
      return this.get('aflEntity.aflEntityType') === 'PAYING_AGENT';
    }),

    isCollectivity: Ember.computed('aflEntity.aflEntityType', function () {
      return this.get('aflEntity.aflEntityType') === 'COLLECTIVITY';
    }),

    shouldDisplayUserTab: Ember.computed('aflEntity.aflEntityType', 'isUserTab', function () {
      return this.get('aflEntity.aflEntityType') !== 'PAYING_AGENT' || this.get('isUserTab');
    }),

    isEntityNotClosed: Ember.computed('aflEntity.aflEntityStatus', function () {
      return this.get('aflEntity.aflEntityStatus') !== 'CLOSED';
    }),

    _doAssociationAction: function _doAssociationAction(request, successMessage) {
      var _this = this;

      request.then(function () {
        Ember.run(function () {
          _this.set('isAddingCollectivity', false);
          _this.set('collectivityCategory', '');
          _this.send('searchFunction', {
            page: 1,
            size: _this.get('pageSize'),
            direction: _this.get('direction'),
            properties: _this.get('sortProperty')
          });
          Ember.get(_this, 'toast').success(successMessage);
        });
      }).fail(function (response) {
        Ember.run(function () {
          Ember.get(_this, 'toast').error(response.responseJSON.message);
        });
      });
    },

    actions: {
      searchFunction: function searchFunction(searchParams) {
        if (this.get('isUserTab')) {
          this.set('model', this.store.query('aflUser', {
            aflEntity: this.get('aflEntity.id'),
            status: this.get('status'),
            page: searchParams.page,
            size: searchParams.size,
            direction: searchParams.direction,
            properties: searchParams.properties
          }));
        } else {
          this.set('model', this.store.query('collectivity', {
            payingAgent: this.get('aflEntity.id'),
            category: this.get('collectivityCategory'),
            siren: this.get('siren'),
            corporateName: this.get('corporateName'),
            page: searchParams.page,
            size: searchParams.size,
            direction: searchParams.direction,
            properties: searchParams.properties
          }));
        }
      },

      toggleTab: function toggleTab() {
        this.toggleProperty('isUserTab');
        this.send('reset');
      },

      toggleAddCollectivity: function toggleAddCollectivity() {
        this.toggleProperty('isAddingCollectivity');
        this.set('searchCollectivityCategoryFilter', '');
      },

      filterCollectivities: function filterCollectivities() {
        this.send('searchFunction', {
          page: 1,
          size: this.get('pageSize'),
          direction: this.get('direction'),
          properties: this.get('sortProperty')
        });
      },

      reset: function reset() {
        this._super();
        this.set('collectivityCategory', '');
        this.set('siren', '');
        this.set('corporateName', '');
        if (this.get('isUserTab')) {
          this.set('sortProperty', 'user.login');
        } else {
          this.set('sortProperty', 'corporateName');
        }
        this.set('model', []);
        this.sendSearchAction(1, this.get('pageSize'));
      },

      addCollectivity: function addCollectivity(collectivity) {
        this._doAssociationAction(Ember.$.ajax({
          type: 'PUT',
          url: '/api/payingagents/' + this.get('aflEntity.id') + '/collectivity/' + collectivity.get('id')
        }), this.get('intl').getMessage('payingAgent.entity.detail.addSuccess', {
          collectivity: collectivity.get('aflEntityName'),
          payingAgent: this.get('aflEntity.aflEntityName')
        }));
      },

      removeCollectivity: function removeCollectivity() {
        var collectivity = this.get('tmpCollectivity');
        this.set('tmpCollectivity', null);
        this._doAssociationAction(Ember.$.ajax({
          type: 'DELETE',
          url: '/api/payingagents/' + this.get('aflEntity.id') + '/collectivity/' + collectivity.get('id')
        }), this.get('intl').getMessage('payingAgent.entity.detail.removeSuccess', {
          collectivity: collectivity.get('aflEntityName'),
          payingAgent: this.get('aflEntity.aflEntityName')
        }));
      },

      showRemoveCollectivity: function showRemoveCollectivity(collectivity) {
        this.set('tmpCollectivity', collectivity);
        this.toggleProperty('removeCollectivityModal');
      }
    }
  });
});