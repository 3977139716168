define('afl/routes/paying-agent/collectivity/fundings/mobilisation/detail-phase1', ['exports', 'afl/mixins/authenticated-route-mixin', 'afl/mixins/injected-service'], function (exports, _authenticatedRouteMixin, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _injectedService.default, {
    templateName: 'payingAgent.collectivity.fundings.treasury.detail',

    model: function model(params) {
      return this.store.findOneQuery('loan', params.treasury_id, { collectivity_id: this.modelFor('payingAgent.collectivity').get('id'), loan_number: params.loan_number });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('isMobilisation', true);
      controller.set('collectivityId', this.modelFor('payingAgent.collectivity').get('id'));
      controller.set('currentFundingId', model.get('id'));
      controller.set('isMember', this.get('currentUser.collectivity.isCollectivityMember'));
    },


    actions: {
      sendMessage: function sendMessage() {
        var model = this.modelFor('collectivity.fundings.treasury.detail');
        this.transitionTo('collectivity.mailbox').then(function (route) {
          route.controller.set('showSendMessageForm', true);
          route.controller.set('subject', 'ST_FUNDING_MGMT');
          route.controller.set('fundingApplicationRef', model.get('fileNumber'));
        });
      }
    }
  });
});