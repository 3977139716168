define('afl/components/input/upload-component', ['exports', 'afl/mixins/injected-service'], function (exports, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_injectedService.default, {
    uploadUrl: '/api/upload',
    formats: 'pdf',
    maxSize: '10240kb',
    maxSizeInText: '10mo',
    runtimes: 'html5 html4',
    uploadFileKey: 'upload.files',
    uploadTitleKey: 'upload.title',
    uploadFileTitleKey: 'upload.uploadFileTitle',
    uploaderClassName: 'btn btn-primary',
    mode: 'standard',
    model: null,
    enableUploadInfo: true,
    waitingValidation: false,
    displayInModal: false,
    file: null,
    errorModalMessage: null,
    successModalMessage: null,
    showPrintNotice: false,

    displayUploadInfo: Ember.computed('enableUploadInfo', 'waitingValidation', 'file', function () {
      if (!this.get('waitingValidation') && this.get('enableUploadInfo') && this.get('file') !== null) {
        return true;
      }
      return false;
    }),

    displayMessage: function displayMessage(message) {
      if (this.get('displayInModal')) {
        this.set('errorModalMessage', message);
      } else {
        Ember.get(this, 'toast').error(message);
      }
    },

    modalTitle: Ember.computed('mode', function () {
      if (this.get('mode') === 'signature') {
        return this.get('intl').getMessage('upload.signatureUploadModalTitle');
      } else {
        if (this.get('mode') === 'outProcess') {
          return this.get('intl').getMessage('upload.uploadDocumentTitle');
        } else {
          return this.get('intl').getMessage('upload.uploadModalTitle', {
            fileName: this.get('model.name')
          });
        }
      }
    }),

    fileSelected: Ember.computed('file', function () {
      return this.get('file') !== null;
    }),

    checkFileType: function checkFileType(file) {
      this.set('errorModalMessage', null);
      var error = file.error;
      if (error) {
        Ember.Logger.error(error);

        switch (error.code) {
          case -600:
            this.displayMessage(this.get('intl').getMessage('upload.error.fileSize', { size: this.get('maxSizeInText') }));
            break;
          case -601:
            this.displayMessage(this.get('intl').getMessage('upload.error.fileType', { extension: this.get('formats').toUpperCase() }));
            break;
          default:
            this.displayMessage(this.get('intl').getMessage('upload.error.defaultError'));
        }
        return false;
      }
      return true;
    },

    uploadFile: function uploadFile(file) {
      var _this = this;

      // Ajout du header text/plain pour le support IE9 afin de ne pas download le resultat de la réponse de l'API
      this.set('errorModalMessage', null);
      file.upload({
        url: this.get('uploadUrl'),
        headers: {
          'X-Auth-Token': 'Bearer ' + this.get('session.data.authenticated.token'),
          Accept: 'text/plain'
        }
      }).then(function (response) {
        _this.sendAction('uploadSuccess', response.body.replace(/<[^>]*>/g, ""), _this.get('model'), _this.get('mode') === 'outProcess');
      }, function (errors) {
        Ember.get(_this, 'file').uploader.files.forEach(function (f) {
          Ember.get(_this, 'file').uploader.removeFile(f);
        });
        Ember.Logger.info('Error when uploading file', JSON.stringify(errors));
        // Gestion des cas d'erreurs pour ie9 en attente de réponse du ticket sur github
        if (errors.response === null) {
          errors.response = _this.get('intl').getMessage('upload.error.defaultError');
        }
        _this.set('file', null);
        _this.sendAction('uploadError', errors);
      });
    },

    actions: {
      uploadFileAction: function uploadFileAction(file) {
        var _this2 = this;

        Ember.Logger.debug('Upload file');

        if (this.checkFileType(file)) {
          this.set('file', file);
          if (!this.get('displayInModal')) {
            this.uploadFile(this.get('file'));
          } else {
            this.get('file').uploader.files.forEach(function (file) {
              if (file !== _this2.get('file').file) {
                _this2.get('file').uploader.removeFile(file);
              }
            });
            this.set('waitingValidation', true);
          }
        } else {
          file.uploader.files.forEach(function (file) {
            file.uploader.removeFile(file);
          });
        }
      },

      upload: function upload() {
        this.set('waitingValidation', false);
        this.uploadFile(this.get('file'));
      },

      closeModal: function closeModal() {
        var _this3 = this;

        if (this.get('waitingValidation')) {
          this.get('file').uploader.files.forEach(function (file) {
            _this3.get('file').uploader.removeFile(file);
          });
        }
        this.sendAction('closeModalAction');
        this.set('errorModalMessage', null);
        this.set('successModalMessage', null);
        this.set('file', null);
      },

      download: function download() {
        Ember.Logger.debug("downloadAction in Upload Component");
        this.sendAction('downloadAction');
      }
    }
  });
});