define('afl/controllers/agency/person/detail', ['exports', 'afl/mixins/has-permission', 'afl/mixins/injected-service'], function (exports, _hasPermission, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_hasPermission.default, _injectedService.default, {
    application: Ember.inject.controller(),

    breadCrumb: Ember.computed('model.fullName', function () {
      return this.get('model.fullName');
    }),

    edition: Ember.computed('application.currentRouteName', function () {
      return this.get('application.currentRouteName') === 'agency.person.detail.edit';
    }),

    actions: {
      reload: function reload() {
        this.get('model').reload();
      }
    }
  });
});