define('afl/routes/paying-agent', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {

    actions: {
      didTransition: function didTransition() {
        this.get('currentUser').set('collectivity', null);
      }
    }
  });
});