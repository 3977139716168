define('afl/components/crm/role-display', ['exports', 'afl/transforms/localdatetime'], function (exports, _localdatetime) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var transform = _localdatetime.default.create();

  var RoleDisplay = Ember.Component.extend({
    tagName: 'li',
    positionalParams: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.positionalParams = ['role'];
    },

    isActive: Ember.computed('role.{startDate,endDate}', function () {
      var now = new Date();
      var start = this.get('role.startDate');
      start = start ? transform.deserialize(start) : now;
      var end = this.get('role.endDate');
      end = end ? transform.deserialize(end) : now;
      return start.getTime() <= now.getTime() && end.getTime() >= now.getTime();
    })
  });

  exports.default = RoleDisplay;
});