define('afl/routes/agency/news/index', ['exports', 'afl/mixins/injected-service', 'afl/mixins/authenticated-route-mixin'], function (exports, _injectedService, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_injectedService.default, _authenticatedRouteMixin.default, {

    // récupère les actualités
    activate: function activate() {
      var searchController = this.controllerFor('agency.news.index');
      searchController.send('searchFunction', {
        page: 1,
        size: searchController.get('pageSize'),
        direction: searchController.get('direction'),
        properties: searchController.get('sortProperty')
      });

      searchController.set('dialogDeleteNews', false);
      searchController.set('dialogPublishNews', false);
      searchController.set('dialogStopPublish', false);
      searchController.set('dialogExport', false);
    },
    resetController: function resetController(controller) {
      controller.set('dialogStopPublish', false);
      controller.set('dialogDeleteNews', false);
      controller.set('dialogPublishNews', false);
    },
    changeStatus: function changeStatus(status, successMessage, errorMessage) {
      var _this = this;

      var news = this.controller.get('selectedNews');
      Ember.set(news, 'status', status);
      news.save().then(function () {
        Ember.get(_this, 'toast').success(_this.get('intl').getMessage(successMessage));
        _this.controller.send('searchFunction', {
          page: 1,
          size: _this.controller.get('pageSize'),
          direction: _this.controller.get('direction'),
          properties: _this.controller.get('sortProperty')
        });
      }).catch(function () {
        news.rollbackAttributes();
        Ember.get(_this, 'toast').error(_this.get('intl').getMessage(errorMessage));
      });
    },


    actions: {
      //méthode de filtre des actualités
      stateFilter: function stateFilter(filterProperty) {
        this.controller.set('filterProperty', filterProperty);
        this.controller.send('searchFunction', {
          page: 1,
          size: this.controller.get('pageSize'),
          direction: this.controller.get('direction'),
          properties: this.controller.get('sortProperty')
        });
      },


      //méthode d'arret de publication d'une actualité
      stopPublish: function stopPublish() {
        this.changeStatus('CREATED', 'agency.admin.news.successStop', 'agency.admin.news.errorStop');
      },


      //méthode de suppression d'une actualité
      deleteNews: function deleteNews() {
        this.changeStatus('DELETED', 'agency.admin.news.successDelete', 'agency.admin.news.errorDelete');
        this.transitionTo('agency.news.index');
      },


      //méthode de publication d'une actualité
      publishNews: function publishNews() {
        this.transitionTo('agency.news.index');
        this.changeStatus('PUBLISHED', 'agency.admin.news.successPublish', 'agency.admin.news.errorPublish');
      }
    }
  });
});