define('afl/routes/agency/account/index', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service('current-user'),

    model: function model() {
      return this.store.findRecord('afl-user', this.get('currentUser.user.id'));
    }
  });
});