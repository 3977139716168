define('afl/components/funding/treasury-request', ['exports', 'afl/mixins/injected-service', 'afl/config/environment', 'ember-cp-validations'], function (exports, _injectedService, _environment, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    date: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)(function (value, options, model) {
      if (value <= model.get('dateMin')) {
        return model.get('intl').getMessage(model.get('dateMinMessageKey'));
      }

      if (value >= model.get('dateMax')) {
        return model.get('intl').getMessage('collectivity.fundings.treasury.refund.dateMinValidation');
      }
      return true;
    }, {
      dependentKeys: ['model.dateMin', 'model.dateMax'],
      disabled: Ember.computed.bool('model.isRefund')
    })],
    amount: [(0, _emberCpValidations.validator)(function (value, options, model) {
      if (value < model.get('amountMin')) {
        return model.get('intl').getMessage(model.get('minAmountMessageKey'), {
          amountMin: model.get('amountMin')
        });
      }
      if (value > model.get('amountMax')) {
        return model.get('intl').getMessage(model.get('maxAmountMessageKey'), {
          amountMax: model.get('amountMax')
        });
      }
      return true;
    }, {
      dependentKeys: ['model.amountMin', 'model.amountMax']
    }), (0, _emberCpValidations.validator)('amount'), (0, _emberCpValidations.validator)('presence', true)],
    token: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.dialogRequest').volatile()
    }),
    otp: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.dialogRequest').volatile()
    })
  });

  /**
   * Ce composant permet d'afficher le formulaire de demande de tirage/remboursement
   *
   * Paramètre du composant :
   * `showForm`: Le boolean qui permet d'afficher ou non le formulaire
   * `todayDate`: La date du jour
   * `submitFormAction`: L'action associée au moment de la validation du formulaire
   * `cancelFormAction`: L'action associée au moment de l'annulation du formulaire
   * `dialogId`: L'id de la boite de dialogue de confirmation
   * `showDisclaimer`: Le boolean qui affiche ou non le disclaimer
   * `title`: Le titre du formulaire
   * `dialogTitle`: Le titre de la boite de dialogue de confirmation
   * `dialogBody`: Le texte de la boite de dialogue de confirmation
   * `model`: Le model associé a la requète
   * `type`: Le type de demande (remboursement ou tirage)
   */
  exports.default = Ember.Component.extend(_injectedService.default, Validations, {
    date: null,
    amount: null,
    token: null,
    otp: null,
    dialogRequest: false,
    todayDate: null,
    fundingMin: 0,

    minAmountMessageKey: Ember.computed('type', function () {
      if (this.get('type') === 'REFUND') {
        return 'collectivity.fundings.treasury.refund.minAmountValidation';
      }
      return 'collectivity.fundings.treasury.drawing.minAmountValidation';
    }),

    maxAmountMessageKey: Ember.computed('type', function () {
      if (this.get('type') === 'REFUND') {
        return 'collectivity.fundings.treasury.refund.maxAmountValidation';
      }
      return 'collectivity.fundings.treasury.drawing.maxAmountValidation';
    }),

    remainingAmount: Ember.computed('model.{remainingAmount,amount}', function () {
      var remaining = this.get('model.remainingAmount');
      var available = this.get('model.amount');
      return available - remaining > 0 ? available - remaining : 0;
    }),

    amountMin: Ember.computed('model.{remainingAmount,amount}', 'type', function () {
      if (this.get('type') === 'DRAWING') {
        return Math.min(this.fundingMin, this.get('remainingAmount'));
      } else {
        return Math.min(this.fundingMin, this.get('model.remainingAmount'));
      }
    }),

    amountMax: Ember.computed('model.{remainingAmount,amount}', 'type', function () {
      if (this.get('type') === 'DRAWING') {
        return this.get('remainingAmount');
      } else {
        return this.get('model.remainingAmount');
      }
    }),

    dateMin: Ember.computed('model.fundsAvailable', 'todayDate', 'isRefund', function () {
      // La date min est la date de disponibilité des fonds si elle est dans le futur, sinon c'est la date du jour
      var limitHourAtZone = new Date();
      limitHourAtZone.setHours(_environment.default.treasury.drawing.hourLimit, 0, 0, 0);
      var currentHourAtZone = new Date().getUTCHours();
      if (this.get('model.fundsAvailable') > this.get('todayDate')) {
        return this.get('model.fundsAvailable');
      } else if (this.get('isRefund') || !this.get('isRefund') && currentHourAtZone < limitHourAtZone.getUTCHours()) {
        if (this.get('isMobilisation')) {
          return new Date(this.get('todayDate').getTime() + _environment.default.treasury.mobilisation.beforeLimit);
        }
        return new Date(this.get('todayDate').getTime() + _environment.default.treasury.drawing.beforeLimit);
      } else if (!this.get('isRefund') && currentHourAtZone >= limitHourAtZone.getUTCHours()) {
        if (this.get('isMobilisation')) {
          return new Date(this.get('todayDate').getTime() + _environment.default.treasury.mobilisation.afterLimit);
        }

        return new Date(this.get('todayDate').getTime() + _environment.default.treasury.drawing.afterLimit);
      }
    }),

    dateMax: Ember.computed('model.loanEnd', function () {
      return this.get('model.loanEnd');
    }),

    didInsertElement: function didInsertElement() {
      var todayDate = new Date();
      todayDate.setHours(0, 0, 0, 0);
      this.set('todayDate', todayDate);
    },


    isRefund: Ember.computed('type', function () {
      return this.get('type') === 'REFUND';
    }),

    dateMinMessageKey: Ember.computed('type', function () {
      if (this.get('isRefund')) {
        return 'collectivity.fundings.treasury.refund.dateMinValidation';
      } else {
        return 'collectivity.fundings.treasury.drawing.dateMinValidation';
      }
    }),

    tokenNature: Ember.computed('type', 'isMobilisation', function () {
      if (this.get('isMobilisation')) {
        if (this.get('isRefund')) {
          return 'PAPM_REFUND';
        } else {
          return 'PAPM_DRAWING';
        }
      } else {
        return this.get('type');
      }
    }),

    resetForm: Ember.observer('showForm', function () {
      this.set('date', null);
      this.set('amount', null);
      this.set('otp', null);
      this.set('token', null);
      this.set('dialogErrorMessage', null);
    }),

    actions: {
      submitForm: function submitForm() {
        this.toggleProperty('dialogRequest');
        this.sendAction('submitFormAction', {
          date: this.get('date'),
          amount: this.get('amount'),
          token: this.get('token'),
          otp: this.get('otp')
        });
      },
      validateForm: function validateForm() {
        var _this = this;

        Ember.$.get('/api/loans/otp', { tokenNature: this.get('tokenNature') }, function (token) {
          Ember.run(function () {
            _this.toggleProperty('dialogRequest');
            _this.set('token', token.message);
            Ember.get(_this, 'toast').success(_this.get('intl').getMessage('password.otp.new'));
          });
        }).fail(function (error) {
          _this.toggleProperty('dialogRequest');
          Ember.get(_this, 'toast').error(error.responseJSON.message);
        });
      },
      cancelForm: function cancelForm() {
        this.sendAction('cancelFormAction');
      },
      validationErrors: function validationErrors() {
        Ember.get(this, 'toast').error(this.get('intl').getMessage('collectivity.fundings.treasury.drawing.validations'));
      },
      closeModal: function closeModal() {
        this.set('otp', null);
        this.set('token', null);
        this.set('dialogErrorMessage', null);
        this.toggleProperty('dialogRequest');
      },
      dialogError: function dialogError() {
        this.set('dialogErrorMessage', this.get('intl').getMessage('collectivity.fundings.treasury.drawing.otpMissing'));
      },
      askOtp: function askOtp() {
        var _this2 = this;

        Ember.$.get('/api/loans/otp', { tokenNature: this.get('tokenNature') }, function (token) {
          Ember.run(function () {
            _this2.set('token', token.message);
            Ember.get(_this2, 'toast').success(_this2.get('intl').getMessage('password.otp.new'));
          });
        }).fail(function (error) {
          Ember.get(_this2, 'toast').error(error.responseJSON.message);
        });
      }
    }
  });
});