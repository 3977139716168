define('afl/mixins/search-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    actions: {
      searchUsers: function searchUsers(query) {
        return this.get('store').query('afl-user', {
          name: query.term,
          page: query.page,
          entity: 'AGENCY',
          autocomplete: true
        }).then(function (data) {
          return {
            more: query.page < data.meta.totalPages,
            data: data
          };
        });
      }
    }
  });
});