define('afl/routes/agency/task/create', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      return this.store.createRecord('crm-task', {
        collectivity: this.get('collectivity')
      });
    },
    beforeModel: function beforeModel(transition) {
      var _this = this;

      if (transition.queryParams.collectivity) {
        this.store.find('collectivity', transition.queryParams.collectivity).then(function (collectivity) {
          _this.set('collectivity', collectivity);
        });
      } else {
        this.set('collectivity', null);
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.store.query('group', {
        entityType: 'AGENCY',
        filtered: false
      }).then(function (groups) {
        controller.set('interactionNatures', groups);
      });
    },


    actions: {
      willTransition: function willTransition() {
        this.controller.get('model').rollbackAttributes();
        return true;
      }
    }
  });
});