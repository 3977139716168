define('afl/models/agency', ['exports', 'afl/models/afl-entity'], function (exports, _aflEntity) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Agency = _aflEntity.default.extend({});

  exports.default = Agency;
});