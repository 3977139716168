define('afl/mixins/change-password', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    oldPassword: (0, _emberCpValidations.validator)('password'),
    newPassword: (0, _emberCpValidations.validator)('password', {
      differentFrom: 'model.oldPassword',
      identicalTo: 'model.newPasswordConfirmation'
    }),
    newPasswordConfirmation: (0, _emberCpValidations.validator)('password', {
      differentFrom: 'model.oldPassword',
      identicalTo: 'model.newPassword'
    })
  });

  /**
   * Mixin regroupant les fonctinnalités d'affichage du détail utilisateur
   */
  exports.default = Ember.Mixin.create(Validations, {
    intl: Ember.inject.service(),
    toast: Ember.inject.service(),

    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',

    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.account.password');
    }),

    actions: {
      updatePassword: function updatePassword() {
        var _this = this;

        Ember.$.post("/api/aflusers/me/password", {
          oldPassword: this.get('oldPassword'),
          newPassword: this.get('newPassword'),
          newPasswordConfirmation: this.get('newPasswordConfirmation')
        }, function () {
          Ember.run(function () {
            Ember.get(_this, 'toast').success(_this.get('intl').getMessage('common.account.passwordForm.success'));
            Ember.set(_this, 'oldPassword', '');
            Ember.set(_this, 'newPassword', '');
            Ember.set(_this, 'newPasswordConfirmation', '');
            _this.transitionToRoute(_this.get('targetRoute'));
          });
        }).fail(function (response) {
          Ember.run(function () {
            Ember.get(_this, 'toast').error(response.responseJSON.message);
          });
        });
      },
      validationErrors: function validationErrors() {
        Ember.get(this, 'toast').error(this.get('intl').getMessage('common.account.passwordForm.validation'));
      },
      cancel: function cancel() {
        this.transitionToRoute(this.get('targetRoute'));
      }
    }
  });
});