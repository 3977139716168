define('afl/routes/paying-agent/collectivity/documents/received', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('entityId', this.get('currentUser.entityId'));
      controller.set('collectivityId', this.modelFor('payingAgent.collectivity').id);
      controller.set('depositorTypes', ['AGENCY', 'SAB_PORTAL']);
    }
  });
});