define('afl/components/input/input-field', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var InputFieldComponent = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: 'div',
    fieldClass: 'form-group',
    classNameBindings: ['showErrorMessage:has-error', 'showWarningMessage:has-warning'],

    compactField: false,
    isCheckBox: false,
    didValidate: false,
    enableIntl: true,
    helpMessage: null,
    labelClass: 'control-label',
    inputClass: 'input',
    showErrorMessage: Ember.computed.and('didValidate', 'validation.isInvalid'),
    hasNoErrorMessage: Ember.computed.not('showErrorMessage'),
    showHelpMessage: Ember.computed.and('hasNoErrorMessage', 'helpMessage'),

    init: function init() {
      this._super.apply(this, arguments);
      var attribute = this.get('attribute');
      this.set('value', Ember.computed.alias('model.' + attribute));
      Ember.defineProperty(this, 'validation', Ember.computed.alias('model.validations.attrs.' + attribute));
      this.set('classNames', [this.get('fieldClass'), this.get('attribute')]);
      if (this.get('enableIntl')) {
        this.set('intlLabel', this.get('intl').t(this.get('label')));
      }
    }
  });

  InputFieldComponent.reopenClass({
    positionalParams: ['model', 'attribute']
  });

  exports.default = InputFieldComponent;
});