define('afl/mixins/search-collectivity', ['exports', 'afl/utils/afl-constants'], function (exports, _aflConstants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    searchCollectivityCategoriesFilter: (0, _aflConstants.default)('CollectivityCategory'),
    searchCollectivityCategoryFilter: '',
    searchCollectivityIsFiltered: true,
    allCollectivities: false,

    actions: {
      searchCollectivities: function searchCollectivities(query) {
        return this.get('store').query('collectivity', {
          category: this.get('searchCollectivityCategoryFilter'),
          query: query.term,
          page: query.page,
          isFiltered: this.get('searchCollectivityIsFiltered'),
          allCollectivities: this.get('allCollectivities')
        }).then(function (data) {
          return {
            data: data,
            more: query.page < data.meta.totalPages
          };
        });
      }
    }
  });
});