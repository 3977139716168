define('afl/components/funding/fundapp-detail', ['exports', 'ember-cli-file-saver/mixins/file-saver'], function (exports, _fileSaver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_fileSaver.default, {
    model: null,
    isAgency: false,
    currentUser: Ember.inject.service('current-user'),

    amountKey: Ember.computed('model.{isLongTerm,isTreasury}', function () {
      if (this.get('model.isLongTerm')) {
        return 'simulation.amount';
      } else if (this.get('model.isTreasury')) {
        return 'simulation.ceiling';
      } else {
        return 'simulation.amount';
      }
    }),

    actions: {
      download: function download() {
        var _this = this;

        // En local on récupère le proxy, sinon lien relatif sur le même domaine:port
        Ember.$.ajax({
          type: 'GET',
          url: '/api/download/document/funding/' + this.get('model.id'),
          dataType: 'blob', // or 'blob'
          headers: {
            'authorization': this.get('currentUser').getToken()
          },
          processData: false
        }).then(function (content) {
          _this.saveFileAs(_this.get('model.fileName'), content);
        });
      }
    }
  });
});