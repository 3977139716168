define('afl/components/news/news-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    detailRoute: '',
    isRootAgency: false,

    hasNoResult: Ember.computed('model.@each', function () {
      return Ember.isEmpty(this.get('model')) && this.get('model').isFulfilled;
    }),

    actions: {
      newPage: function newPage(parameters) {
        this.sendAction('newPageAction', parameters);
      }
    }
  });
});