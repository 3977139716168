define('afl/models/crm-task', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    termDate: (0, _emberCpValidations.validator)('presence', true),
    interactionMethod: (0, _emberCpValidations.validator)('presence', true),
    interactionType: (0, _emberCpValidations.validator)('presence', true),
    interactionNature: (0, _emberCpValidations.validator)('presence', true),
    collectivityId: (0, _emberCpValidations.validator)('presence', true)
  });

  var CRMTask = _emberData.default.Model.extend(Validations, {
    termDate: _emberData.default.attr('localdate'),
    realisationDate: _emberData.default.attr('localdatetime'),
    interactionMethod: _emberData.default.attr('string'),
    interactionType: _emberData.default.attr('string'),
    interactionNature: _emberData.default.attr('string'),
    status: _emberData.default.attr('string', { defaultValue: 'TO_PROCESS' }),
    collectivity: _emberData.default.belongsTo('collectivity'),
    contacts: _emberData.default.hasMany('crm-contact'),
    comments: _emberData.default.attr(),

    collectivityId: Ember.computed.alias('collectivity.id'),

    expired: Ember.computed('termDate', 'status', function () {
      var _getProperties = this.getProperties('termDate', 'status'),
          termDate = _getProperties.termDate,
          status = _getProperties.status;

      if (status === 'TO_PROCESS') {
        return termDate.getTime() < new Date().getTime();
      }
      return false;
    })
  });

  exports.default = CRMTask;
});