define('afl/controllers/agency/person/index', ['exports', 'afl/controllers/search', 'afl/mixins/injected-service', 'afl/mixins/has-permission', 'afl/mixins/search-collectivity', 'afl/adapters/application', 'afl/utils/afl-constants', 'ember-cli-file-saver/mixins/file-saver'], function (exports, _search, _injectedService, _hasPermission, _searchCollectivity, _application, _aflConstants, _fileSaver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PersonFilter = Ember.Object.extend({
    firstName: '',
    lastName: '',
    email: '',

    toJson: function toJson() {
      return JSON.parse(JSON.stringify(this));
    }
  });

  var ContactFilter = Ember.Object.extend({
    email: '',
    function: '',
    role: '',
    subscription: '',
    status: '',
    collectivitySiren: '',
    collectivityType: '',
    collectivityStatus: '',

    toJson: function toJson() {
      return JSON.parse(JSON.stringify(this));
    }
  });

  exports.default = _search.default.extend(_fileSaver.default, _injectedService.default, _hasPermission.default, _searchCollectivity.default, {
    person: PersonFilter.create(),
    contact: ContactFilter.create(),
    searchType: 'crm-person',

    searchCollectivityCategoryFilter: Ember.computed.alias('contact.collectivityType'),
    searchCollectivityIsFiltered: false,
    dialogExport: false,
    allCollectivities: true,
    selectedCollectivity: null,
    userRoles: (0, _aflConstants.default)('UserRole'),
    crmRoles: (0, _aflConstants.default)('CRMRole'),
    crmSubscriptions: (0, _aflConstants.default)('CRMSubscription'),
    collectivityCategories: (0, _aflConstants.default)('CollectivityCategory'),
    collectivityStatuses: (0, _aflConstants.default)('CollectivityStatus'),
    statuses: Ember.computed(function () {
      var statuses = [];
      statuses.push({
        value: 'ACTIVE',
        label: this.get('intl').getMessage('crm.contacts.active')
      });
      statuses.push({
        value: 'INACTIVE',
        label: this.get('intl').getMessage('crm.contacts.inactive')
      });
      return statuses;
    }),

    shouldCollapse: Ember.computed('isInit', 'hasNoResult', function () {
      return this.get('isInit') || this.get('hasNoResult');
    }),

    isPeople: Ember.computed('searchType', function () {
      return this.get('searchType') === 'crm-person';
    }),

    selectedCollectivityChange: Ember.observer('selectedCollectivity', function () {
      var collectivity = this.get('selectedCollectivity');
      if (collectivity) {
        this.set('contact.collectivityIdsIn', [collectivity.get('id')]);
      } else {
        this.set('contact.collectivityIdsIn', null);
      }
    }),

    resultTitleKey: Ember.computed('isPeople', function () {
      if (this.get('isPeople')) {
        return 'crm.people.title';
      } else {
        return 'crm.contacts.title';
      }
    }),

    _emberType: Ember.computed('isPeople', function () {
      if (this.get('isPeople')) {
        return 'crmpeople';
      } else {
        return 'crmcontacts';
      }
    }),

    _resetFilter: function _resetFilter() {
      this.set('selectedCollectivity', null);
      this.set('person', PersonFilter.create());
      this.set('contact', ContactFilter.create());
    },
    _completeFilter: function _completeFilter(filter) {
      var person = this.get('person').toJson();
      var contact = this.get('contact').toJson();
      this._mergeFilters(filter, person, contact);
      return filter;
    },
    _mergeFilters: function _mergeFilters(filter, person, contact) {
      if (Ember.$.isEmptyObject(contact)) {
        this.set('searchType', 'crm-person');
        Ember.merge(filter, person);
      } else {
        contact.searchBean = person;
        Ember.merge(filter, contact);
        this.set('searchType', 'crm-contact');
      }
    },
    _resetController: function _resetController() {
      this._resetFilter();
      this.set('dialogExport', false);
    },


    actions: {
      searchFunction: function searchFunction(searchParams) {
        var filter = this._completeFilter({
          page: searchParams.page - 1,
          size: searchParams.size,
          sort: searchParams.properties + ',' + searchParams.direction
        });
        this.set('model', this.store.query(this.get('searchType'), filter));
      },
      reset: function reset() {
        this._super();
        this._resetController();
      },


      dialogExport: function dialogExport() {
        this.toggleProperty('dialogExport');
      },

      exportResults: function exportResults() {
        var _this = this;

        var filter = (0, _application.jsonQueryString)(this._completeFilter({}));
        var queryParams = Ember.$.param(filter, true);
        var type = this.get('_emberType');

        Ember.$.ajax({
          type: 'GET',
          url: '/api/' + type + '/export?' + queryParams,
          dataType: 'arraybuffer', // or 'blob'
          headers: {
            'authorization': this.get('currentUser').getToken()
          },
          processData: false
        }).then(function (content) {
          _this.saveFileAs('liste-personne.csv', content);
        });
      }
    }
  });
});