define('afl/mixins/upload-document', ['exports', 'afl/config/environment', 'afl/mixins/injected-service'], function (exports, _environment, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * Mixin regroupant les fonctionnalités de dépose de document
   */

  function extractDocType(name) {
    var reg = new RegExp("x$", "g");
    return name.split(".").pop().replace(reg, "");
  }

  exports.default = Ember.Mixin.create(_injectedService.default, {
    formats: _environment.default.uploadDocument.extensions,
    maxSize: _environment.default.uploadDocument.size,
    maxSizeInText: _environment.default.uploadDocument.sizeInText,
    uploadSuccessMessage: null,
    uploadErrorMessage: null,
    uploadContext: null,

    actions: {
      uploadSuccess: function uploadSuccess(response, model, isOutProcess) {
        var _this = this;

        var store = this.get('store');
        // Info sur le fichier
        var fileSize = parseInt(JSON.parse(response).size / 1024);

        var uploadDoc = void 0;

        uploadDoc = store.createRecord('document', {
          type: 'OTHER',
          otherType: 'Pièce justificative',
          fileName: JSON.parse(response).fileName,
          mimeType: extractDocType(JSON.parse(response).fileName),
          fileRef: JSON.parse(response).ref,
          fileSize: fileSize,
          aflEntity: this.get('currentUser.entityId'),
          fileNumber: model.fileNumber
        });

        var data = JSON.stringify(uploadDoc.serialize());

        Ember.$.ajax({
          url: '/api/collectivities/fillDocument',
          type: 'POST',
          data: data,
          dataType: 'json',
          contentType: 'application/json; charset=utf-8'
        }).then(function () {
          _this.set('file', null);
          _this.set('uploadSuccessMessage', _this.get('intl').getMessage('upload.uploadSuccess'));
          if (!isOutProcess) {
            model.set('sendingInProgress', true);
          }
        }).catch(function (errors) {
          Ember.Logger.error('Error saving upload Doc', errors);
          _this.set('file', null);
          uploadDoc.rollbackAttributes();
          _this.set('uploadErrorMessage', _this.get('intl').getMessage('upload.error.cannotSaveDocumentMessage'));
        });
      },
      uploadError: function uploadError(errors) {
        this.set('uploadErrorMessage', errors.response);
        Ember.Logger.error('Error uploading Doc', errors.response);
      }
    }
  });
});