define('afl/routes/agency/person/index', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    resetController: function resetController(controller /*, isExiting, transition*/) {
      controller._resetController();
    }
  });
});