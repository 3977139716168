define('afl/routes/agency/index', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    activate: function activate() {
      var indexController = this.controllerFor('agency.index');
      indexController.set('model', this.store.query('news', {
        page: 1,
        size: 4,
        direction: 'DESC',
        state: 'PUBLISHED',
        properties: 'publicationBeginDate'
      }));
    }
  });
});