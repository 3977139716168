define('afl/models/loan-event', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var LoanEvent = _emberData.default.Model.extend({
    eventType: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    maturityNumber: _emberData.default.attr('number'),
    requestDate: _emberData.default.attr('localdatetime'),
    issueDate: _emberData.default.attr('localdatetime'),
    accountingDate: _emberData.default.attr('localdatetime'),
    refundAmount: _emberData.default.attr('number'),
    balanceAfterTransaction: _emberData.default.attr('number'),
    transactionDate: _emberData.default.attr('localdatetime')
  });

  exports.default = LoanEvent;
});