define('afl/controllers/agency/contact-request/index', ['exports', 'afl/transforms/localdate', 'afl/controllers/search', 'afl/mixins/has-permission', 'ember-cli-file-saver/mixins/file-saver', 'afl/mixins/search-collectivity'], function (exports, _localdate, _search, _hasPermission, _fileSaver, _searchCollectivity) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var localDateTransform = new _localdate.default();

  exports.default = _search.default.extend(_fileSaver.default, _hasPermission.default, _searchCollectivity.default, {
    currentUser: Ember.inject.service('current-user'),
    contactRequestDateStart: null,
    contactRequestDateEnd: null,
    statusIn: null,
    searchCollectivityIsFiltered: false,
    allCollectivities: true,
    selectedCollectivity: null,
    sortProperty: 'contactRequestDate',
    dialogExport: false,
    statuses: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('corporateName', null);
      this.statusIn = ['CREATED'];
      this.statuses = ['CREATED', 'ACCEPTED', 'REFUSED', 'CLOSED'];
    },

    contactRequestLocalDateStart: Ember.computed('contactRequestDateStart', function () {
      return localDateTransform.serialize(this.get('contactRequestDateStart'));
    }),

    contactRequestLocalDateEnd: Ember.computed('contactRequestDateEnd', function () {
      return localDateTransform.serialize(this.get('contactRequestDateEnd'));
    }),

    selectedCollectivityChange: Ember.observer('selectedCollectivity', function () {
      var collectivity = this.get('selectedCollectivity');
      if (collectivity) {
        this.set('corporateName', collectivity.get('corporateName'));
      } else {
        this.set('corporateName', null);
      }
    }),

    actions: {
      searchFunction: function searchFunction(searchParams) {
        this.set('model', this.store.query('contactRequest', {
          contactRequestDateStart: this.get('contactRequestLocalDateStart'),
          contactRequestDateEnd: this.get('contactRequestLocalDateEnd'),
          corporateName: this.get('corporateName'),
          statusIn: this.get('statusIn'),
          page: searchParams.page,
          size: searchParams.size,
          direction: searchParams.direction,
          properties: searchParams.properties
        }));
      },

      reset: function reset() {
        this._super();
        this.set('contactRequestDateStart', null);
        this.set('contactRequestDateEnd', null);
        this.set('statusIn', ['CREATED']);
        this.set('sortProperty', 'contactRequestDate');
        this.set('selectedCollectivity', null);
        this.set('corporateName', null);
        // Permet de ne plus afficher la liste des résultats
        this.set('model', []);
      },

      dialogExport: function dialogExport() {
        this.toggleProperty('dialogExport');
      },

      exportResults: function exportResults() {
        var _this = this;

        // En local on récupère le proxy, sinon lien relatif sur le même domaine:port
        var queryParams = Ember.$.param({
          contactRequestDateStart: this.get('contactRequestLocalDateStart'),
          contactRequestDateEnd: this.get('contactRequestLocalDateEnd'),
          contactRequestStatus: this.get('statusIn'),
          corporateName: this.get('corporateName')
        });

        Ember.$.ajax({
          type: 'GET',
          url: '/api/contactrequests/export?' + queryParams,
          dataType: 'blob', // or 'blob'
          headers: {
            'authorization': this.get('currentUser').getToken()
          },
          processData: false
        }).then(function (content) {
          _this.saveFileAs('liste-demande-contact.csv', content);
        });
      }
    }
  });
});