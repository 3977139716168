define('afl/theme', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var isNonLinkHeaderTarget = function isNonLinkHeaderTarget(target) {
    var header = Ember.$('.header').get(0);
    return (header === target || Ember.$.contains(header, target)) && target.tagName !== "A";
  };

  var isNonLinkMenuTarget = function isNonLinkMenuTarget(target) {
    var menu = Ember.$('#menu').get(0);
    return menu && (menu === target || Ember.$.contains(menu, target)) && target.tagName !== "A";
  };

  var isSubMenuLink = function isSubMenuLink($target) {
    var element = Ember.$($target);
    return $target && element.attr('data-toggle') && element.attr('data-toggle') === 'collapse';
  };

  var isNonImplemented = function isNonImplemented($target) {
    return $target && $target.hasClass('non-implemented');
  };

  var interceptNonImplemented = function interceptNonImplemented(event) {
    if (event && event.target && isNonImplemented(Ember.$(event.target))) {
      event.stopPropagation();
      event.preventDefault();
      alert('Non implémenté dans cette version');
    }
  };

  exports.default = {
    init: function init() {
      Ember.$('body.afl-application').bind("click", function (event) {
        // ignore clicks on header (except from links)
        // ignore clicks on menu (except from links)
        if (!(isNonLinkHeaderTarget(event.target) || isNonLinkMenuTarget(event.target) || isSubMenuLink(event.target))) {
          Ember.$('#menu').collapse('hide');
          if (window.Afl) {
            window.Afl.__container__.lookup('controller:application').send('closeMenu');
          }
        }

        interceptNonImplemented(event);
      });
    }
  };
});