define('afl/controllers/agency/news/detail/index', ['exports', 'afl/mixins/has-permission', 'afl/mixins/news-actions', 'afl/mixins/injected-service'], function (exports, _hasPermission, _newsActions, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_injectedService.default, _hasPermission.default, _newsActions.default, {
    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.collectivity.news.detail');
    })
  });
});