define('afl/components/user/user-status', ['exports', 'afl/mixins/injected-service', 'afl/mixins/has-permission'], function (exports, _injectedService, _hasPermission) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_injectedService.default, _hasPermission.default, {
    DISABLED: 'DISABLED',
    ENABLED: 'ENABLED',
    CLOSED: 'CLOSED',
    LOCKED: 'LOCKED',
    UNLOCKED: 'UNLOCKED',

    // toggles des popups
    dialogUnlock: false,
    dialogEnable: false,
    dialogDisable: false,
    dialogClose: false,

    isDisabled: Ember.computed('model.status', function () {
      return this.get('model.status') === this.DISABLED;
    }),

    isEnabled: Ember.computed('model.status', function () {
      return this.get('model.status') === this.ENABLED;
    }),

    isClosed: Ember.computed('model.status', function () {
      return this.get('model.status') === this.CLOSED;
    }),

    isLocked: Ember.computed('model.status', function () {
      return this.get('model.status') === this.LOCKED;
    }),

    isSameUser: Ember.computed('currentUser.user.id', 'model.id', function () {
      return this.get('currentUser.user.id') === this.get('model.id');
    }),

    canChangeStatus: Ember.computed('hasUsersAccessForAction', 'isClosed', 'isSameUser', function () {
      return this.get('hasUsersAccessForAction') && !this.get('isClosed') && !this.get('isSameUser');
    }),

    modelDidChange: Ember.observer('model.id', function () {
      this.set('dialogUnlock', false);
      this.set('dialogEnable', false);
      this.set('dialogDisable', false);
      this.set('dialogClose', false);
    }),

    /**
     * @Private
     */
    _changeStatus: function _changeStatus(status, successMessage) {
      var _this = this;

      Ember.$.post('/api/aflusers/' + this.get('model.id'), {
        status: status
      }).then(function () {
        Ember.run(function () {
          _this.get('model').reload().then(function () {
            Ember.get(_this, 'toast').success(_this.get('intl').getMessage('common.user.action.' + successMessage));
          });
        });
      }).fail(function (response) {
        Ember.run(function () {
          var errorMessage = 'unknownError';
          if ([401, 404, 501].indexOf(response.status) > -1) {
            errorMessage = 'user.action.' + response.status;
          }
          Ember.get(_this, 'toast').error(_this.get('intl').getMessage('common.' + errorMessage));
        });
      });
    },

    actions: {
      // actions popup
      showLock: function showLock() {
        this.toggleProperty('dialogUnlock');
      },
      showEnable: function showEnable() {
        this.toggleProperty('dialogEnable');
      },
      showDisable: function showDisable() {
        this.toggleProperty('dialogDisable');
      },
      showClose: function showClose() {
        this.toggleProperty('dialogClose');
      },

      // actions requête
      unlockUser: function unlockUser() {
        this._changeStatus(this.UNLOCKED, 'userUnlocked');
      },
      enableUser: function enableUser() {
        this._changeStatus(this.ENABLED, 'userEnabled');
      },
      disableUser: function disableUser() {
        this._changeStatus(this.DISABLED, 'userDisabled');
      },
      closeUser: function closeUser() {
        this._changeStatus(this.CLOSED, 'userClosed');
      }

    }

  });
});