define('afl/components/crm/interaction-nature-display', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var InteractionNatureDisplay = Ember.Component.extend({
    store: Ember.inject.service(),

    positionalParams: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.positionalParams = ['ref'];
      this.send('loadGroup');
    },


    actions: {
      loadGroup: function loadGroup() {
        var _this = this;

        this.get('store').queryRecord('group', { ref: this.get('ref') }).then(function (group) {
          return _this.set('group', group);
        });
      }
    }
  });

  exports.default = InteractionNatureDisplay;
});