define('afl/controllers/collectivity/documents/signature', ['exports', 'afl/mixins/injected-service', 'afl/config/environment', 'afl/mixins/upload-document-sign', 'afl/mixins/has-permission', 'ember-cli-file-saver/mixins/file-saver'], function (exports, _injectedService, _environment, _uploadDocumentSign, _hasPermission, _fileSaver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_fileSaver.default, _injectedService.default, _hasPermission.default, _uploadDocumentSign.default, {

    file: null,

    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.collectivity.documents.signature');
    }),

    downloadHost: _environment.default.backUrl,

    // Etape de signature
    signatureStep: 0,

    // Référence du document a signer
    docRef: undefined,
    // Nom du document a signer
    docName: undefined,
    // Chemin d'accès au PDF
    pdfUrl: undefined,

    // Uploading Modale Visible
    uploadingFile: false,
    signatureDone: false,

    // Clé pour l'erreur de signature
    warningMsgKey: null,

    // Affichage des boutons

    showCancel: Ember.computed('signatureDone', function () {
      return !this.get('signatureDone');
    }),

    // Etapes
    signatureInProgress: Ember.computed('signatureStep', function () {
      var currentStep = this.get('signatureStep');
      return currentStep > 0 && currentStep < 5;
    }),

    // Model de document pour signature manuscrite
    documentSelected: Ember.computed('setupDone', function () {

      var docModel = {};
      // var docModel = this.store.find('document', this.get('docRef'));

      docModel.name = this.get('docName');
      docModel.docRef = this.get('docRef');

      return docModel;
    }),

    onSetupResponse: Ember.observer('setupResponse', function () {
      var _this = this;

      var response = this.get('setupResponse');

      Ember.run(function () {
        Ember.Logger.log('setupResponse : ', response);
        _this.set('docName', response.docName);
        _this.set('pdfUrl', response.docPath);
        _this.set('setupDone', true);
      });
    }),

    warning: Ember.observer('warningMsgKey', function () {
      if (this.get('warningMsgKey') !== null) {
        Ember.get(this, 'toast').error(this.get('intl').getMessage(this.get('warningMsgKey')));
      }
    }),

    actions: {
      // Download du PDF a Signer
      downloadAction: function downloadAction() {
        var _this2 = this;

        Ember.Logger.debug("downloadAction in signature.js");
        var docDownloadBaseUrl = this.get('downloadHost') + '/api/download/document/collectivity/';

        Ember.$.ajax({
          type: 'GET',
          url: docDownloadBaseUrl + this.get('docRef') + '/',
          dataType: 'blob', // or 'blob'
          headers: {
            'authorization': this.get('currentUser').getToken()
          },
          processData: false
        }).then(function (content) {
          _this2.saveFileAs(_this2.get('docRef') + '.pdf', content);
        });
      },

      // Ouverture du popup de signature manuscrite
      openManualSign: function openManualSign() {
        this.set('uploadingFile', true);
      },

      // Fermeture du popup de signature manuscrite
      closeManualSign: function closeManualSign() {
        this.set('uploadingFile', false);
      },

      sendManualSignAction: function sendManualSignAction(ref) {
        var _this3 = this;

        Ember.Logger.info('sendManualSign ', ref);

        Ember.$.post("/mvc/signature/" + this.get('docRef') + "/manualSignature", { ref: ref }, function () {
          Ember.Logger.log("sendManualSign DONE");
          _this3.set('signatureDone', true);
          _this3.send('sendSignFileSuccessCallback');
        }).fail(function (response) {
          Ember.Logger.log("Error", response.responseJSON.message);
          _this3.send('sendSignFileErrorCallback');
        });
      }
    }

  });
});