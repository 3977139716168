define('afl/routes/collectivity/index', ['exports', 'afl/mixins/authenticated-route-mixin', 'afl/config/environment', 'afl/mixins/injected-service'], function (exports, _authenticatedRouteMixin, _environment, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _injectedService.default, {
    setupController: function setupController(controller) {
      var collectivityId = this.get('currentUser.entityId');

      this.store.find('collectivity', collectivityId).then(function (collectivity) {
        controller.set('collectivity', collectivity);
      });

      this.store.query('aflUser', { collectivity: collectivityId }).then(function (aflUsers) {
        controller.set('aflUsers', aflUsers.toArray());
      });

      this.store.query('news', {
        page: 1,
        size: _environment.default.collectivityNews.size,
        direction: 'DESC',
        state: 'PUBLISHED',
        properties: 'publicationBeginDate'
      }).then(function (news) {
        controller.set('news', news.toArray());
      });
    }
  });
});