define('afl/controllers/password/otp', ['exports', 'afl/mixins/injected-service', 'ember-cp-validations'], function (exports, _injectedService, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    otp: (0, _emberCpValidations.validator)('presence', true),
    password: (0, _emberCpValidations.validator)('password', {
      requires: 'model.isOtpValidated'
    }),
    confirmPassword: (0, _emberCpValidations.validator)('password', {
      identicalTo: 'model.password',
      requires: 'model.isOtpValidated'
    })
  });
  exports.default = Ember.Controller.extend(Validations, _injectedService.default, {
    password: '',
    confirmPassword: '',
    isOtpValidated: false,

    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.password.passwordCreation');
    })
  });
});