define('afl/routes/agency/audit/index', ['exports', 'afl/mixins/injected-service', 'afl/mixins/authenticated-route-mixin'], function (exports, _injectedService, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_injectedService.default, _authenticatedRouteMixin.default, {
    activate: function activate() {
      var searchController = this.controllerFor('agency.audit.index');
      Ember.$.get('/api/auditrecords/events').then(function (data) {
        Ember.run(function () {
          searchController.set('events', data);
        });
      });
      Ember.$.get('/api/auditrecords/types').then(function (data) {
        Ember.run(function () {
          searchController.set('types', data);
        });
      });

      searchController.set('dialogExport', false);
    },


    actions: {
      searchError: function searchError() {
        Ember.get(this, 'toast').error(this.get('intl').getMessage('agency.audit.searchForm.validation'));
      }
    }
  });
});