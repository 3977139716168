define('afl/controllers/paying-agent/collectivity/documents/history', ['exports', 'afl/mixins/injected-service', 'afl/mixins/has-permission', 'afl/config/environment'], function (exports, _injectedService, _hasPermission, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_injectedService.default, _hasPermission.default, {

    historySize: _environment.default.uploadDocument.historySize,

    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.collectivity.documents.history');
    }),

    actions: {
      searchFunction: function searchFunction(searchParams) {
        this.set('model', this.store.query('uploadDocument', {
          collectivity: this.get('entityId'),
          historySize: this.get('historySize'),
          page: searchParams.page,
          size: searchParams.size,
          direction: searchParams.direction,
          properties: searchParams.properties
        }));
      }
    }
  });
});