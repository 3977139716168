define('afl/routes/collectivity/subscriptions', ['exports', 'afl/mixins/authenticated-route-mixin', 'afl/mixins/injected-service'], function (exports, _authenticatedRouteMixin, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_injectedService.default, _authenticatedRouteMixin.default, {
    subscription: Ember.inject.service(),

    model: function model() {
      return this.get('currentUser.user');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.get('subscription').retrieveSubscriptionByEntityType(model).then(function (notifications) {
        controller.set('notifications', notifications);
      });
    }
  });
});