define('afl/adapters/application', ['exports', 'ember-inflector', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'lodash', 'afl/config/environment', 'ember-simple-auth-token/mixins/token-authorizer'], function (exports, _emberInflector, _emberData, _dataAdapterMixin, _lodash, _environment, _tokenAuthorizer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.jsonQueryString = jsonQueryString;
  exports.pathForType = pathForType;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function jsonQueryString(data) {
    return _lodash.default.transform(data, function (result, value, key) {
      if (value instanceof Array) {
        result[key] = value.join(',');
      } else if (value instanceof Object) {
        _lodash.default.keysIn(value).forEach(function (e) {
          result[key + '.' + e] = value[e];
        });
      } else {
        result[key] = value;
      }
    });
  }

  function pathForType(type) {
    return (0, _emberInflector.pluralize)(type.replace(/-/g, '').toLowerCase());
  }

  var adapter = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, _tokenAuthorizer.default, {

    host: _environment.default.host,
    namespace: _environment.default.namespace,

    pathForType: pathForType,
    findMany: function findMany(store, type, ids) {
      return this.ajax(this.buildURL(type.typeKey), 'GET', { data: { ids: ids } });
    },

    normalizeErrorResponse: function normalizeErrorResponse(status, headers, payload) {
      if (payload && (typeof payload === 'undefined' ? 'undefined' : _typeof(payload)) === 'object' && payload.errors) {
        return payload.errors;
      } else {
        return [{
          status: status,
          title: "The backend responded with an error",
          detail: payload
        }];
      }
    },


    ajaxOptions: function ajaxOptions(url, type, options) {
      var optionsCopy = void 0;
      if (type === 'GET' && options && options.data) {
        optionsCopy = {};
        optionsCopy.data = jsonQueryString(options.data);
      } else {
        optionsCopy = options;
      }
      return this._super(url, type, optionsCopy);
    }
  });

  exports.default = adapter;
});