define('afl/controllers/collectivity/mailbox', ['exports', 'afl/mixins/injected-service', 'afl/controllers/search', 'afl/mixins/has-permission', 'ember-cli-file-saver/mixins/file-saver'], function (exports, _injectedService, _search, _hasPermission, _fileSaver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _search.default.extend(_injectedService.default, _fileSaver.default, _hasPermission.default, {
    subjectFilter: '',
    subjects: null,

    // new message attributes
    showSendMessageForm: false,
    hasReadOnlyFields: false,
    subject: '',
    subjectComplement: '',
    fundingApplicationRef: '',
    messageContent: '',

    init: function init() {
      this._super.apply(this, arguments);
      this.subjects = [];
    },

    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.collectivity.mailbox');
    }),

    onSubjectSelectChange: Ember.observer('subjectFilter', function () {
      this.send('search');
    }),

    reset: Ember.observer('showSendMessageForm', function () {
      if (!this.get('showSendMessageForm')) {
        this.set('collectivity', '');
        this.set('subject', '');
        this.set('subjectComplement', '');
        this.set('fundingApplicationRef', '');
        this.set('messageContent', '');
        this.set('hasReadOnlyFields', false);
        this.set('hasSubjectComplement', false);
        this.set('document', null);
      }
    }),

    _saveMessage: function _saveMessage(message) {
      var _this = this;

      message.save().then(function () {
        _this.set('showSendMessageForm', false);
        _this.send('search');
        Ember.get(_this, 'toast').success(_this.get('intl').getMessage('mailbox.sendMessageForm.success'));
      }).catch(function (errors) {
        Ember.Logger.error('Error during saving new message', errors);
        Ember.get(_this, 'toast').error(_this.get('intl').getMessage('mailbox.sendMessageForm.error'));
        message.rollbackAttributes();
      });
    },

    actions: {
      searchFunction: function searchFunction(searchParams) {
        this.set('model', this.store.query('message', {
          subject: this.get('subjectFilter'),
          page: searchParams.page,
          size: searchParams.size
        }));
      },

      downloadDocument: function downloadDocument(message) {
        var _this2 = this;

        Ember.$.ajax({
          type: 'GET',
          url: '/api/download/document/collectivity/' + message.documentId,
          dataType: 'blob', // or 'blob'
          headers: {
            'authorization': this.get('currentUser').getToken()
          },
          processData: false
        }).then(function (content) {
          _this2.saveFileAs(message.documentName, content);
        });
      },

      sendMessage: function sendMessage(message) {
        var _this3 = this;

        var newMessage = this.store.createRecord('message', {
          subject: message.subject,
          subjectComplement: message.subjectComplement,
          fundingApplicationRef: message.fundingApplicationRef,
          messageContent: message.messageContent
        });

        if (message.document) {
          message.document.set('aflEntity', this.get('currentUser.entityId'));
          var jsonDoc = JSON.stringify(message.document.serialize());
          Ember.$.ajax({
            url: '/api/collectivities/fillDocument',
            type: 'POST',
            data: jsonDoc,
            dataType: 'json',
            contentType: 'application/json; charset=utf-8'
          }).then(function (document) {
            newMessage.set('documentId', document.id);
            _this3._saveMessage(newMessage);
          }).catch(function (error) {
            _this3.get('toast').error(error.responseJSON.message);
          });
        } else {
          this._saveMessage(newMessage);
        }
      },

      reply: function reply(message) {
        this.set('subject', message.get('subject'));
        this.set('subjectComplement', message.get('subjectComplement'));
        this.set('fundingApplicationRef', message.get('fundingApplicationRef'));
        this.set('messageContent', '');
        this.set('hasReadOnlyFields', true);
        this.set('showSendMessageForm', true);
        // scroll au top de la page pour rendre visible le formulaire d'envoi de message
        window.scrollTo(0, 0);
      }
    }
  });
});