define('afl/routes/agency/all-news/index', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    activate: function activate() {
      var newsController = this.controllerFor('agency.allNews.index');
      newsController.send('searchFunction', {
        page: 1,
        size: newsController.get('pageSize')
      });
    }
  });
});