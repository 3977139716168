define('afl/components/mailbox/message-create', ['exports', 'afl/mixins/injected-service', 'afl/config/environment', 'afl/mixins/search-collectivity', 'ember-cp-validations', 'afl/mixins/has-permission'], function (exports, _injectedService, _environment, _searchCollectivity, _emberCpValidations, _hasPermission) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    subject: (0, _emberCpValidations.validator)('presence', true),
    collectivity: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.isAgency').volatile()
    }),
    subjectComplement: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.hasSubjectComplement').volatile()
    }),
    messageContent: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: _environment.default.messageCreate.contentLimit
    })]
  });

  /**
   Ce composant permet d'afficher le formulaire de nouveau message depuis la messagerie. Voici la liste des paramètres de ce composant:
  
   - `isAgency`: si le composant est utilisé dans un contexte agence [default: false]
   - `hasReadOnlyFields`: si le composant doit afficher certains champ en lecture seule [default: false]
   - `sendMessageAction`: l'action qui permet d'effectuer l'envoi d'un message
   - `showSendMessageForm`: si le composant doit afficher le formulaire (sinon juste le bouton 'Nouveau message') [default: false]
   - `subjects`: liste des sujets possibles pour un message
   - `collectivity`: la collectivité destinataire si elle doit être pré-remplie
   - `subject` : l'objet du message si il doit être pré-rempli
   - `subjectComplement` : le détail de l'objet si il doit être pré-rempli
   - `fundingApplicationRef` : la référence de financement pour le message si elle doit être pré-remplie
   - `messageContent` : le contenu du message si il doit être pré-rempli
   */
  exports.default = Ember.Component.extend(Validations, _injectedService.default, _searchCollectivity.default, _hasPermission.default, {
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    isAgency: false,
    showSendMessageForm: false,
    hasSubjectComplement: false,
    hasReadOnlyFields: false,
    contentLimit: _environment.default.messageCreate.contentLimit,

    // form attributes
    collectivity: '',
    subject: '',
    subjectComplement: '',
    fundingApplicationRef: '',
    messageContent: '',

    init: function init() {
      this._super.apply(this, arguments);
      this.set('searchCollectivityIsFiltered', false);
    },

    isSubjectsLoaded: Ember.computed('subjects', function () {
      return !Ember.isEmpty(this.get('subjects'));
    }),

    shouldDisplayFundingRef: Ember.computed('subject', function () {
      return ['APPLICATION', 'ST_FUNDING_MGMT', 'LT_FUNDING_MGMT'].indexOf(this.get('subject')) >= 0;
    }),

    // choix de l'objet du message (si autre, il faut entrer le détail)
    subjectChange: Ember.observer('subject', function () {
      if (this.get('subject') === 'OTHER') {
        this.set('hasSubjectComplement', true);
      } else {
        this.set('hasSubjectComplement', false);
        this.set('subjectComplement', '');
      }
    }),

    resetFundingApplicationRef: Ember.observer('shouldDisplayFundingRef', function () {
      if (!this.get('shouldDisplayFundingRef')) {
        this.set('fundingApplicationRef', '');
      }
    }),

    actions: {
      newMessage: function newMessage() {
        this.set('showSendMessageForm', true);
        this.set('document', null);
      },

      createCollectivityDocument: function createCollectivityDocument() {
        this.set('displayCreateDocumentModal', false);
      },
      closeCollectivityDocument: function closeCollectivityDocument(document) {
        document.destroyRecord();
        this.set('document', null);
      },
      openCreateDocumentModal: function openCreateDocumentModal() {
        this.set('displayCreateDocumentModal', true);
        this.set('document', this.get('store').createRecord('document', {
          aflEntity: this.get('currentUser.entityId')
        }));
      },


      validationErrors: function validationErrors() {
        Ember.get(this, 'toast').error(this.get('intl').getMessage('mailbox.sendMessageForm.validation'));
      },

      // envoi du message au controlleur
      sendMessage: function sendMessage() {
        this.sendAction('sendMessageAction', {
          collectivity: this.get('collectivity'),
          subject: this.get('subject'),
          subjectComplement: this.get('subjectComplement'),
          fundingApplicationRef: this.get('fundingApplicationRef'),
          messageContent: this.get('messageContent'),
          document: this.get('document')
        });
      },

      cancel: function cancel() {
        this.set('showSendMessageForm', false);
      }
    }
  });
});