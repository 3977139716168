define('afl/routes/paying-agent/collectivity/document', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {

    model: function model() {
      // Initialise le model à un tableau vide
      return Ember.A();
    }

  });
});