define('afl/components/user/user-list', ['exports', 'afl/mixins/injected-service', 'afl/mixins/has-permission', 'ember-cli-file-saver/mixins/file-saver'], function (exports, _injectedService, _hasPermission, _fileSaver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_fileSaver.default, _injectedService.default, _hasPermission.default, {
    // Paramètre du composant étant initialisé par défaut
    canAddAdminRights: false,
    canRemoveAdminRights: false,
    // Variable temporaire, utilisé pour les actions définir/supprimer un administrateur
    tmpUser: null,
    // Variable gérant l'affichage (ou non) de la modal de confirmation pour définir un admin
    addAdminModal: false,
    // Variable gérant l'affichage (ou non) de la modal de confirmation pour supprimer un admin
    removeAdminModal: false,
    // Variable gérant l'affichage (ou non) de la modal pour l'export des utilisateurs
    dialogExport: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.tmpUser = {};
    },

    isInit: Ember.computed('model.@each', function () {
      return !this.get('model').isFulfilled;
    }),

    hasNoResult: Ember.computed('model.@each', function () {
      return this.get('model').isFulfilled && Ember.isEmpty(this.get('model'));
    }),

    // Série de propriété calculer pour l'affichage des filtres
    isAll: Ember.computed('status', function () {
      return Ember.isEmpty(this.get('status'));
    }),
    isEnabled: Ember.computed('status', function () {
      return this.get('status') === 'ENABLED';
    }),
    isDisabled: Ember.computed('status', function () {
      return this.get('status') === 'DISABLED';
    }),
    isLocked: Ember.computed('status', function () {
      return this.get('status') === 'LOCKED';
    }),
    isClosed: Ember.computed('status', function () {
      return this.get('status') === 'CLOSED';
    }),
    isInProgress: Ember.computed('status', function () {
      return this.get('status') === 'IN_PROGRESS';
    }),

    canCreateUser: Ember.computed('entityStatus', 'aflEntityType', 'aflEntityStatus', function () {
      if (this.get('aflEntityType') === 'AGENCY') {
        return true;
      } else {
        return this.get('entityStatus') !== 'PRE_PROSPECT' && this.get('entityStatus') !== 'PROSPECT' && this.get('aflEntityStatus') !== 'CLOSED';
      }
    }),

    doAdminAction: function doAdminAction(request, successMessage) {
      var self = this,
          user = this.get('tmpUser');

      request.then(function () {
        Ember.run(function () {
          user.reload().then(function () {
            Ember.get(self, 'toast').success(successMessage);
          });
        });
      }).fail(function (response) {
        Ember.run(function () {
          Ember.get(self, 'toast').error(response.responseJSON.message);
        });
      });
    },

    modelDidChange: Ember.observer('model.@each', function () {
      this.set('addAdminModal', false);
      this.set('removeAdminModal', false);
    }),

    actions: {
      filter: function filter(status) {
        this.sendAction('filterAction', {
          status: status
        });
      },

      sendSort: function sendSort(sortProperty) {
        this.sendAction('sortAction', sortProperty);
      },

      sendNewPage: function sendNewPage(params) {
        this.sendAction('newPageAction', params);
      },

      showMakeAdmin: function showMakeAdmin(user) {
        this.set('tmpUser', user);
        this.toggleProperty('addAdminModal');
      },

      confirmMakeAdmin: function confirmMakeAdmin() {
        this.doAdminAction(Ember.$.post("/api/aflusers/" + this.get('tmpUser.id') + "/admin"), this.get('intl').getMessage('common.user.action.makeAdminSuccess', {
          gender: this.get('tmpUser.civility'),
          user: this.get('tmpUser.fullName')
        }));
      },

      showRemoveAdmin: function showRemoveAdmin(user) {
        this.set('tmpUser', user);
        this.toggleProperty('removeAdminModal');
      },

      impersonate: function impersonate(user) {
        this.sendAction('impersonate', user);
      },


      confirmRemoveAdmin: function confirmRemoveAdmin() {
        this.doAdminAction(Ember.$.ajax({
          type: 'DELETE',
          url: '/api/aflusers/' + this.get('tmpUser.id') + '/admin'
        }), this.get('intl').getMessage('common.user.action.removeAdminSuccess', {
          gender: this.get('tmpUser.civility'),
          user: this.get('tmpUser.fullName')
        }));
      },

      dialogExport: function dialogExport() {
        this.toggleProperty('dialogExport');
      },

      exportResults: function exportResults() {
        var _this = this;

        // En local on récupère le proxy, sinon lien relatif sur le même domaine:port
        var exportResultsUrl = '/api/aflusers/export?entityId=' + this.get('aflEntityId');
        var status = this.get('status');
        if (status !== undefined) {
          exportResultsUrl += '&statusIn' + this.get('status');
        }

        Ember.$.ajax({
          type: 'GET',
          url: exportResultsUrl,
          dataType: 'blob', // or 'blob'
          headers: {
            'authorization': this.get('currentUser').getToken()
          },
          processData: false
        }).then(function (content) {
          _this.saveFileAs("liste-utilisateur.csv", content);
        });
      }
    }
  });
});