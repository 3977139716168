define('afl/components/input/input-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    content: null,
    optionValuePath: 'value',
    optionLabelPath: 'label',
    withBlank: false,
    selection: null,

    inputId: Ember.computed.reads('elementId'),
    inputName: Ember.computed.reads('elementId'),
    inputClass: Ember.computed.reads('elementId'),
    disabled: false,

    action: function action() {
      return this;
    },

    values: Ember.computed('content.@each', 'withBlank', function () {
      var values = [];
      if (this.get('withBlank')) {
        values.push({});
      }
      this.get('content').forEach(function (item) {
        values.push(item);
      });
      return values;
    }),

    _selection: Ember.computed.reads('selection'),

    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('content')) {
        this.set('content', []);
      }
    },

    actions: {
      change: function change() {
        var selectEl = this.$('select')[0],
            selectedIndex = selectEl.selectedIndex,
            values = this.get('values'),
            selection = void 0,
            changeCallback = this.get('action');
        if (values.objectAt) {
          selection = values.objectAt(selectedIndex);
        } else {
          selection = values[selectedIndex];
        }
        this.set('_selection', selection);
        changeCallback(selection);
      }
    }
  });
});