define('afl/controllers/paying-agent/collectivity/fundings/mobilisation/index', ['exports', 'afl/mixins/has-permission', 'afl/controllers/search', 'afl/mixins/injected-service'], function (exports, _hasPermission, _search, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _search.default.extend(_hasPermission.default, _injectedService.default, {
    sortProperty: 'fileNumber',
    ascending: false,

    actions: {
      searchFunction: function searchFunction(searchParams) {
        this.set('modelPhase1', this.store.query('loan', {
          collectivity_id: this.get('collectivity').get('id'),
          loan_type: 'MOBILISATION_PHASE1',
          page: searchParams.page,
          size: searchParams.size,
          direction: searchParams.direction,
          properties: searchParams.properties
        }));

        this.set('modelPhase2', this.store.query('loan', {
          collectivity_id: this.get('collectivity').get('id'),
          loan_type: 'MOBILISATION_PHASE2',
          page: searchParams.page,
          size: searchParams.size,
          direction: searchParams.direction,
          properties: searchParams.properties
        }));
      }
    }
  });
});