define('afl/controllers/paying-agent/collectivity/fundings/treasury/detail', ['exports', 'afl/mixins/injected-service'], function (exports, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_injectedService.default, {
    collectivityId: '',
    isTiersPage: false,
    currentFundingId: null,
    isManagementMode: true,
    isMember: true,

    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.collectivity.fundings.treasury.detail');
    })
  });
});