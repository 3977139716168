define('afl/services/store', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Store.extend({
    findOneQuery: function findOneQuery(type, id, query) {
      var store = this;
      var adapter = store.adapterFor(type);
      var serializer = store.serializerFor(type);
      var typeClass = store.modelFor(type);
      var url = adapter.buildURL(type, id);
      var ajaxPromise = adapter.ajax(url, 'GET', { data: query });

      return ajaxPromise.then(function (rawPayload) {
        var extractedPayload = serializer.normalizeResponse(store, typeClass, rawPayload, id, 'findRecord');
        return store.push(extractedPayload);
      });
    }
  });
});