define('afl/services/intl', ['exports', 'ember-intl/services/intl'], function (exports, _intl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _intl.default.extend({
    getMessage: function getMessage(key, context) {
      var message = this.findTranslationByKey(key);
      if (context) {
        return this.formatMessage(message, context);
      } else {
        return message;
      }
    },

    getEnum: function getEnum(key) {
      if (!Ember.testing) {
        return this.getMessage(key);
      } else {
        return key;
      }
    }
  });
});