define('afl/controllers/agency/funding-application/index', ['exports', 'afl/transforms/localdate', 'afl/controllers/search'], function (exports, _localdate, _search) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var localDateTransform = new _localdate.default();

  exports.default = _search.default.extend({
    fundingApplicationDateStart: null,
    fundingApplicationDateEnd: null,
    fundingNatureIn: null,
    sortProperty: 'creationDate',
    fundingNatures: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.fundingNatureIn = ['LONG_TERM', 'TREASURY', 'MOBILISATION'];
      this.fundingNatures = ['LONG_TERM', 'TREASURY', 'MOBILISATION'];
    },

    fundingApplicationLocalDateStart: Ember.computed('fundingApplicationDateStart', function () {
      return localDateTransform.serialize(this.get('fundingApplicationDateStart'));
    }),

    fundingApplicationLocalDateEnd: Ember.computed('fundingApplicationDateEnd', function () {
      return localDateTransform.serialize(this.get('fundingApplicationDateEnd'));
    }),

    actions: {
      searchFunction: function searchFunction(searchParams) {
        this.set('model', this.store.query('funding-application', {
          fundingApplicationDateStart: this.get('fundingApplicationLocalDateStart'),
          fundingApplicationDateEnd: this.get('fundingApplicationLocalDateEnd'),
          fundingNatureIn: this.get('fundingNatureIn'),
          page: searchParams.page,
          size: searchParams.size,
          direction: searchParams.direction,
          properties: searchParams.properties
        }));
      },

      reset: function reset() {
        this._super();
        this.set('sortProperty', 'creationDate');
        this.set('fundingApplicationDateStart', null);
        this.set('fundingApplicationDateEnd', null);
        this.set('fundingNatureIn', ['LONG_TERM', 'TREASURY', 'MOBILISATION']);
      }
    }
  });
});