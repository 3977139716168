define('afl/components/user/user-detail', ['exports', 'afl/mixins/injected-service', 'afl/mixins/has-permission'], function (exports, _injectedService, _hasPermission) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_injectedService.default, _hasPermission.default, {
    DISABLED: 'DISABLED',
    ENABLED: 'ENABLED',
    CLOSED: 'CLOSED',
    LOCKED: 'LOCKED',
    UNLOCKED: 'UNLOCKED',

    // configuration du composant
    userEditRoute: '',
    adminEdition: false,
    displayProfileContent: true,

    // valeur pour gérer l'affichage dans le partial user
    hideLogin: true,

    // toggles des popups
    dialogUnlock: false,
    dialogEnable: false,
    dialogDisable: false,
    dialogClose: false,
    addAdminModal: false,
    removeAdminModal: false,

    profiles: Ember.computed('model.groups', function () {
      return this.getWithDefault('model.groups', []);
    }),

    isDisabled: Ember.computed('model.status', function () {
      return this.get('model.status') === this.DISABLED;
    }),

    isEnabled: Ember.computed('model.status', function () {
      return this.get('model.status') === this.ENABLED;
    }),

    isClosed: Ember.computed('model.status', function () {
      return this.get('model.status') === this.CLOSED;
    }),

    isLocked: Ember.computed('model.status', function () {
      return this.get('model.status') === this.LOCKED;
    }),

    isSameUser: Ember.computed('currentUser.user.id', 'model.id', function () {
      return this.get('currentUser.user.id') === this.get('model.id');
    }),

    canChangeStatus: Ember.computed('hasUsersAccessForAction', 'isClosed', 'isSameUser', function () {
      return this.get('hasUsersAccessForAction') && !this.get('isClosed') && !this.get('isSameUser');
    }),

    canRemoveAdminProfile: Ember.computed('adminEdition', 'model.entityType', function () {
      return this.get('adminEdition');
    }),

    canAddAdminProfile: Ember.computed('adminEdition', 'model.{entityType,isMember}', function () {
      var entityType = this.get('model.entityType'),
          isMember = this.get('model.isMember');

      return this.get('adminEdition') && (entityType === 'COLLECTIVITY' && !!isMember || entityType === 'AGENCY');
    }),

    modelDidChange: Ember.observer('model.id', function () {
      this.set('dialogUnlock', false);
      this.set('dialogEnable', false);
      this.set('dialogDisable', false);
      this.set('dialogClose', false);
    }),

    /**
     * @Private
     */
    _changeStatus: function _changeStatus(status, successMessage) {
      var _this = this;

      // Clear SignData
      Ember.Logger.log("ChangeStatus");

      Ember.$.post('/api/aflusers/' + this.get('model.id'), {
        status: status
      }).then(function () {
        Ember.run(function () {
          _this.get('model').reload().then(function () {
            Ember.get(_this, 'toast').success(_this.get('intl').getMessage('common.user.action.' + successMessage));
          });
        });
      }).fail(function (response) {
        Ember.run(function () {
          var errorMessage = 'unknownError';
          if ([401, 404, 501].indexOf(response.status) > -1) {
            errorMessage = 'user.action.' + response.status;
          }
          Ember.get(_this, 'toast').error(_this.get('intl').getMessage('common.' + errorMessage));
        });
      });
    },

    _doAdminAction: function _doAdminAction(request, successMessage) {
      var _this2 = this;

      request.then(function () {
        Ember.run(function () {
          _this2.get('model').reload().then(function () {
            Ember.get(_this2, 'toast').success(successMessage);
          });
        });
      }).fail(function (response) {
        Ember.run(function () {
          Ember.get(_this2, 'toast').error(response.responseJSON.message);
        });
      });
    },

    actions: {
      // actions popup
      showLock: function showLock() {
        this.toggleProperty('dialogUnlock');
      },
      showEnable: function showEnable() {
        this.toggleProperty('dialogEnable');
      },
      showDisable: function showDisable() {
        this.toggleProperty('dialogDisable');
      },
      showClose: function showClose() {
        this.toggleProperty('dialogClose');
      },
      showMakeAdmin: function showMakeAdmin() {
        this.toggleProperty('addAdminModal');
      },
      showRemoveAdmin: function showRemoveAdmin() {
        this.toggleProperty('removeAdminModal');
      },

      // actions requête
      unlockUser: function unlockUser() {
        this._changeStatus(this.UNLOCKED, 'userUnlocked');
      },
      enableUser: function enableUser() {
        this._changeStatus(this.ENABLED, 'userEnabled');
      },
      disableUser: function disableUser() {
        this._changeStatus(this.DISABLED, 'userDisabled');
      },
      closeUser: function closeUser() {
        this._changeStatus(this.CLOSED, 'userClosed');
      },

      impersonate: function impersonate(user) {
        this.sendAction('impersonate', user);
      },


      confirmMakeAdmin: function confirmMakeAdmin() {
        this._doAdminAction(Ember.$.post("/api/aflusers/" + this.get('model.id') + "/admin"), this.get('intl').getMessage('common.user.action.makeAdminSuccess', {
          gender: this.get('model.civility'),
          user: this.get('model.fullName')
        }));
      },

      confirmRemoveAdmin: function confirmRemoveAdmin() {
        this._doAdminAction(Ember.$.ajax({
          type: 'DELETE',
          url: '/api/aflusers/' + this.get('model.id') + '/admin'
        }), this.get('intl').getMessage('common.user.action.removeAdminSuccess', {
          gender: this.get('model.civility'),
          user: this.get('model.fullName')
        }));
      },

      resetPassword: function resetPassword(user) {
        var _this3 = this;

        Ember.$.post("/api/aflusers/password", {
          userEmail: user.email
        }, function () {
          Ember.get(_this3, 'toast').success(_this3.get('intl').getMessage('user.forgottenForm.success'));
        }).fail(function (response) {
          switch (response.status) {
            default:
              Ember.get(_this3, 'toast').error(_this3.get('intl').getMessage('user.forgottenForm.technicalError'));
              break;
          }
        });
      }
    }

  });
});