define('afl/controllers/agency/audit/index', ['exports', 'afl/transforms/localdate', 'afl/controllers/search', 'afl/utils/afl-constants', 'ember-cli-file-saver/mixins/file-saver', 'afl/mixins/search-collectivity'], function (exports, _localdate, _search, _aflConstants, _fileSaver, _searchCollectivity) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var localDateTransform = new _localdate.default();

  exports.default = _search.default.extend(_fileSaver.default, _searchCollectivity.default, {
    currentUser: Ember.inject.service('current-user'),
    identifier: '',
    objectType: '',
    entity: '',
    eventType: '',
    originUser: '',
    searchCollectivityIsFiltered: false,
    allCollectivities: true,
    selectedCollectivity: null,
    auditEventDateStart: null,
    auditEventDateEnd: null,
    entities: (0, _aflConstants.default)('AflEntityType'),
    events: null,
    types: null,
    sortProperty: 'creationDate',
    ascending: false,
    dialogExport: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('siren', null);
      this.events = [];
      this.types = [];
    },

    auditEventLocalDateStart: Ember.computed('auditEventDateStart', function () {
      return localDateTransform.serialize(this.get('auditEventDateStart'));
    }),

    auditEventLocalDateEnd: Ember.computed('auditEventDateEnd', function () {
      return localDateTransform.serialize(this.get('auditEventDateEnd'));
    }),

    shouldCollapse: Ember.computed('isInit', 'hasNoResult', function () {
      return this.get('isInit') || this.get('hasNoResult');
    }),

    selectedCollectivityChange: Ember.observer('selectedCollectivity', function () {
      var collectivity = this.get('selectedCollectivity');
      if (collectivity) {
        this.set('siren', collectivity.get('siren'));
      } else {
        this.set('siren', null);
      }
    }),

    actions: {
      searchFunction: function searchFunction(searchParams) {
        this.set('model', this.store.query('auditRecord', {
          identifier: this.get('identifier'),
          objectType: this.get('objectType'),
          entity: this.get('entity'),
          eventType: this.get('eventType'),
          originUser: this.get('originUser'),
          siren: this.get('siren'),
          auditEventDateStart: this.get('auditEventLocalDateStart'),
          auditEventDateEnd: this.get('auditEventLocalDateEnd'),
          page: searchParams.page,
          size: searchParams.size,
          direction: searchParams.direction,
          properties: searchParams.properties,
          sortProperty: 'creationDate',
          ascending: false
        }));
      },

      reset: function reset() {
        this._super();
        this._resetController();
      },

      dialogExport: function dialogExport() {
        this.toggleProperty('dialogExport');
      },

      exportResults: function exportResults() {
        var _this = this;

        // En local on récupère le proxy, sinon lien relatif sur le même domaine:port
        var queryParams = Ember.$.param({
          identifier: this.get('identifier'),
          objectType: this.get('objectType'),
          entity: this.get('entity'),
          eventType: this.get('eventType'),
          originUser: this.get('originUser'),
          siren: this.get('siren'),
          auditEventDateStart: this.get('auditEventLocalDateStart'),
          auditEventDateEnd: this.get('auditEventLocalDateEnd')
        });

        Ember.$.ajax({
          type: 'GET',
          url: '/api/auditrecords/export?' + queryParams,
          dataType: 'blob', // or 'blob'
          headers: {
            'authorization': this.get('currentUser').getToken()
          },
          processData: false
        }).then(function (content) {
          _this.saveFileAs("liste-evenement-audit.csv", content);
        });
      }
    },

    _resetController: function _resetController() {
      this.set('identifier', '');
      this.set('objectType', '');
      this.set('entity', '');
      this.set('eventType', '');
      this.set('originUser', '');
      this.set('auditEventDateStart', null);
      this.set('auditEventDateEnd', null);
      this.set('sortProperty', 'creationDate');
      this.set('ascending', false);
      this.set('dialogExport', false);
      this.set('selectedCollectivity', null);
      this.set('siren', null);
      // Permet de ne plus afficher la liste des résultats
      this.set('model', []);
    }
  });
});