define('afl/components/news/news-form', ['exports', 'afl/mixins/injected-service', 'afl/config/environment'], function (exports, _injectedService, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_injectedService.default, {
    model: null,
    validationKey: '',
    successKey: '',
    errorKey: '',
    previewDisplay: false,
    newsContentLimit: _environment.default.newsCreate.contentLimit,
    formats: _environment.default.newsUpload.extensions,
    maxSize: _environment.default.newsUpload.size,
    maxSizeInText: _environment.default.newsUpload.sizeInText,

    actions: {
      previewToggle: function previewToggle() {
        this.toggleProperty('previewDisplay');
      },

      saveNews: function saveNews() {
        var _this = this;

        if (tinymce.activeEditor.plugins.charcount.getCount() <= this.newsContentLimit) {
          this.get('model').save().then(function (object) {
            Ember.run.next(_this, function () {
              Ember.get(this, 'toast').success(this.get('intl').getMessage(this.get('successKey')));
              this.sendAction('onSuccess', object);
            });
          }).catch(function (errors) {
            Ember.Logger.error('Error when saving news', errors);
            Ember.get(_this, 'toast').error(_this.get('intl').getMessage(_this.get('errorKey')));
          });
        } else {
          this.send('formError');
        }
      },

      formError: function formError() {
        Ember.get(this, 'toast').error(this.get('intl').getMessage(this.get('validationKey')));
      },

      cancel: function cancel() {
        this.sendAction('onCancel');
      }
    }
  });
});