define('afl/components/entity/paying-agent-create', ['exports', 'afl/mixins/injected-service'], function (exports, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_injectedService.default, {

    successMessageKey: '',
    errorMessageKey: '',
    validationMessageKey: '',

    actions: {
      save: function save() {
        var _this = this;

        this.get('model').save().then(function () {
          Ember.get(_this, 'toast').success(_this.get('intl').getMessage(_this.get('successMessageKey')));
          _this.sendAction('afterSuccess');
        }).catch(function (errors) {
          var error = errors.errors[0] || {},
              message = _this.get('intl').getMessage(_this.get('errorMessageKey'));

          if (error.detail && error.detail.message) {
            message = error.detail.message;
          }

          Ember.get(_this, 'toast').error(message);
        });
      },

      cancel: function cancel() {
        this.sendAction('afterCancel');
      },

      validationErrors: function validationErrors() {
        Ember.get(this, 'toast').error(this.get('intl').getMessage(this.get('validationMessageKey')));
      }
    }
  });
});