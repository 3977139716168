define('afl/controllers/agency/contact/detail', ['exports', 'afl/mixins/injected-service', 'afl/mixins/has-permission'], function (exports, _injectedService, _hasPermission) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_injectedService.default, _hasPermission.default, {

    breadCrumb: Ember.computed('model.fullName', function () {
      return this.get('model.fullName');
    })

  });
});