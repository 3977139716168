define('afl/initializers/afl-model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {

    name: 'Afl-model',

    initialize: function initialize() {

      _emberData.default.Model.reopen({
        /*
         * Permet de récupérer tous les objets du modèle (récupére également les relations fils du modèle)
         */
        getAllElements: function getAllElements(elements) {
          var self = this;
          elements.push(self);

          self.eachRelationship(function (key, relationship) {
            var child = self.get(key);

            if (relationship.kind === 'belongsTo' && child && elements.indexOf(child) === -1 && child.get('content')) {
              child.get('content').getAllElements(elements);
            } else if (relationship.kind === 'hasMany') {
              child.filter(function (element) {
                return elements.indexOf(element) === -1;
              }).forEach(function (element) {
                element.getAllElements(elements);
              });
            }
          });
        },

        deepRollback: function deepRollback() {
          var deepElements = [];
          this.getAllElements(deepElements);

          deepElements.filter(function (element) {
            return element.get('hasDirtyAttributes');
          }).forEach(function (element) {
            element.rollbackAttributes();
          });
        }

      });
    }
  };
});