define('afl/services/current-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    polling: Ember.inject.service(),

    firstName: Ember.computed.alias('user.firstName'),
    lastName: Ember.computed.alias('user.lastName'),
    entityType: Ember.computed.alias('user.entityType'),
    entityId: Ember.computed.alias('user.entityId'),
    rights: Ember.computed.alias('user.rights'),
    isProspect: Ember.computed.alias('user.isProspect'),
    isAdmin: Ember.computed.alias('user.isAdmin'),
    isAgencyUser: Ember.computed.alias('user.isAgencyUser'),

    isCollectivityUser: Ember.computed('user.entityType', function () {
      return this.get('user.entityType') === 'COLLECTIVITY';
    }),

    hasNoProfile: Ember.computed('user.{groups,groups.[]}', function () {
      var groups = this.get('user.groups') || Ember.A();
      return groups.toArray().length === 0;
    }),

    groupsRef: Ember.computed('user.{groups,groups.[]}', function () {
      var groups = this.get('user.groups') || Ember.A();
      var refs = groups.map(function (g) {
        return Ember.get(g, 'ref');
      });
      if (this.get('isAdmin')) {
        if (this.get('isAgencyUser')) {
          refs.push('AGENCY_DSI');
        } else {
          refs.push('COLLECTIVITY_ADMIN');
        }
      }
      return refs;
    }),

    load: function load() {
      var _this = this;

      var success = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function () {
        return _this;
      };

      if (!Ember.testing) {
        if (this.get('session.isAuthenticated')) {
          var token = this.get('session.data.authenticated.token');
          var jwt = Ember.getOwner(this).lookup('authenticator:custom');
          var data = jwt.getTokenData(token);

          this._initCurrentUser(Ember.Object.create(data['usr-data']));
        }
      }
      success(this.get('user'));
    },
    loadCollectivity: function loadCollectivity() {
      var _this2 = this;

      if (this.get('session.isAuthenticated') && this.get('isCollectivityUser')) {
        return this.get('store').find('collectivity', this.get('user.entityId')).then(function (collectivity) {
          _this2.set('collectivity', collectivity);
        });
      }
    },


    /**
     * Refresh the user token if the session is authenticated.
     */
    refresh: function refresh() {
      if (this.get('session.isAuthenticated')) {
        Ember.debug('Refreshing user token');

        var token = this.get('session.data.authenticated.token'),
            authenticator = Ember.getOwner(this).lookup('authenticator:custom');

        authenticator.refreshAccessToken(token);
      }
    },
    logout: function logout() {
      this.get('session').invalidate();
      if (Ember.testing) {
        this.set('session.isAuthenticated', false);
      }
    },
    impersonate: function impersonate(username, success, failure) {
      var _this3 = this;

      Ember.$.get("/api/aflusers/impersonate", {
        username: username
      }).then(function (token) {

        var authenticator = Ember.getOwner(_this3).lookup('authenticator:custom');
        authenticator.refreshAccessToken(token.token).then(function (token) {
          this.get('polling').reset();
          var data = authenticator.getTokenData(token.token);
          this._initCurrentUser(Ember.Object.create(data['usr-data']));
          success(this.get('user'));
        }.bind(_this3));
      }).fail(function (error) {
        failure(error.responseJSON.message);
      });
    },
    impersonateLogout: function impersonateLogout(success, failure) {
      var _this4 = this;

      Ember.$.get("/api/aflusers/impersonate/logout").then(function (token) {
        var authenticator = Ember.getOwner(_this4).lookup('authenticator:custom');
        authenticator.refreshAccessToken(token.token).then(function (token) {
          this.get('polling').reset();
          var data = authenticator.getTokenData(token.token);
          this._initCurrentUser(Ember.Object.create(data['usr-data']));
          success(this.get('user'));
        }.bind(_this4));
      }).fail(function (error) {
        failure(error.responseJSON.message);
      });
    },
    _initCurrentUser: function _initCurrentUser(user) {
      user.fullName = user.firstName + ' ' + user.lastName;
      user.presencePhone = !Ember.isEmpty(user.phoneNumber);
      user.presenceMobile = !Ember.isEmpty(user.mobileNumber);
      user.canSign = !Ember.isEmpty(user.signBase64);
      user.isCollectivityUser = user.entityType === 'COLLECTIVITY';
      user.isAgencyUser = user.entityType === 'AGENCY';
      user.autoCompleteDisplay = user.firstName + ' ' + user.lastName;
      this.set('user', user);
      this.set('isImpersonate', Ember.get(user, 'isImpersonate'));
      this.set('hasAcceptedTc', Ember.get(user, 'hasAcceptedTc'));
      this.set('collectivity', null);
      this.loadCollectivity();
    },
    connect: function connect(credential, header) {
      if (Ember.testing) {
        return Ember.$.get('/api/aflusers/me').then(function (data) {
          this.set('session.isAuthenticated', true);
          this._initCurrentUser(Ember.Object.create(data.aflUser));
          var applicationRoute = Ember.getOwner(this).lookup('route:application');
          applicationRoute.sessionAuthenticated();
        }.bind(this));
      } else {
        return this.get('session').authenticate('authenticator:custom', credential, header);
      }
    },
    getToken: function getToken() {
      if (this.get('session.isAuthenticated')) {
        return this.get('session.data.authenticated.token');
      }
      return '';
    }
  });
});