define('afl/components/crm/general-meeting', ['exports', 'afl/mixins/injected-service'], function (exports, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_injectedService.default, {

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      Ember.$.ajax({
        url: '/api/colcontacts/generalMeetings?id=' + this.collectivityId,
        type: 'GET',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8'
      }).then(function (data) {
        _this.set('holder', data.holder);
        _this.set('substitute', data.substitute);
      });
    }

  });
});