define('afl/models/loan', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Loan = _emberData.default.Model.extend({
    fileNumber: _emberData.default.attr('number'),
    loanNumber: _emberData.default.attr('number'),
    rateLabel: _emberData.default.attr('string'),
    relatedFunding: _emberData.default.attr('string'),
    margin: _emberData.default.attr('number'),
    fundsAvailable: _emberData.default.attr('localdatetime'),
    loanEnd: _emberData.default.attr('localdatetime'),
    amount: _emberData.default.attr('number'),
    remainingAmount: _emberData.default.attr('number'),
    beiIndicator: _emberData.default.attr('boolean', { defaultValue: false }),

    rateType: _emberData.default.attr('string', { defaultValue: 'FIXED' }),
    rate: _emberData.default.attr('number'),
    commissionRate: _emberData.default.attr('number'),
    amortisationMethod: _emberData.default.attr('string', { defaultValue: 'CONSTANT_MATURITY' }),
    amortisationFrequency: _emberData.default.attr('string', { defaultValue: 'YEARLY' }),
    interestFrequency: _emberData.default.attr('string', { defaultValue: 'YEARLY' }),

    // computed
    balance: Ember.computed('amount', 'remainingAmount', function () {
      return this.get('amount') - this.get('remainingAmount');
    })
  });

  exports.default = Loan;
});