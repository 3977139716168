define('afl/models/user-token', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var UserToken = _emberData.default.Model.extend({
    token: _emberData.default.attr('string'),
    otp: _emberData.default.attr('string'),
    expirationDate: _emberData.default.attr('date'),
    userToken: _emberData.default.belongsTo('aflUser')
  });

  exports.default = UserToken;
});