define('afl/components/input/input-file', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    toast: Ember.inject.service(),
    intl: Ember.inject.service(),
    formats: 'pdf doc docx xls xlsx ppt pptx jpg png',
    maxSize: '102400kb',
    maxSizeInText: '100mo',
    runtimes: 'html5 html4',
    file: null,
    errorModalMessage: null,
    successModalMessage: null,

    checkFileType: function checkFileType(file) {
      this.set('errorModalMessage', null);
      var error = file.error;
      if (error) {
        Ember.Logger.error(error);
        switch (error.code) {
          case -600:
            this.get('toast').error(this.get('intl').getMessage('upload.error.fileSize', { size: this.get('maxSizeInText') }));
            break;
          case -601:
            this.get('toast').error(this.get('intl').getMessage('upload.error.fileType', { extension: this.get('formats').toUpperCase() }));
            break;
          default:
            this.get('toast').error(this.get('intl').getMessage('upload.error.defaultError'));
        }
        return false;
      }
      return true;
    },
    extractDocType: function extractDocType(name) {
      var reg = new RegExp("x$", "g");
      return name.split(".").pop().replace(reg, "");
    },
    uploadFile: function uploadFile(file) {
      var _this = this;

      // Ajout du header text/plain pour le support IE9 afin de ne pas download le resultat de la réponse de l'API
      this.set('errorModalMessage', null);
      file.upload({
        url: '/api/upload',
        headers: {
          'X-Auth-Token': 'Bearer ' + this.get('session.data.authenticated.token'),
          Accept: 'text/plain'
        }
      }).then(function (response) {
        var fileResponse = JSON.parse(response.body);
        var document = _this.get('value');
        if (!document) {
          document = _this.get('store').createRecord('document');
        }
        document.set('fileName', fileResponse.fileName);
        document.set('mimeType', _this.extractDocType(fileResponse.fileName));
        document.set('fileRef', fileResponse.ref);
        document.set('fileSize', parseInt(fileResponse.size / 1024));
      }, function (errors) {
        Ember.Logger.info('Error when uploading file', JSON.stringify(errors));
        // Gestion des cas d'erreurs pour ie9 en attente de réponse du ticket sur github
        if (errors.response === null) {
          errors.response = _this.get('intl').getMessage('upload.error.defaultError');
        }
        _this.get('toast').error(errors.response);
      });
    },
    removeFiles: function removeFiles(file) {
      file.uploader.files.forEach(function (file) {
        file.uploader.removeFile(file);
      });
      this.set('value', null);
    },


    actions: {
      onfileadd: function onfileadd(file) {
        if (this.checkFileType(file)) {
          this.uploadFile(file);
        } else {
          this.removeFiles(file);
        }
      },
      download: function download() {
        Ember.Logger.debug("downloadAction in Upload Component");
        this.sendAction('downloadAction');
      }
    }
  });
});