define('afl/models/document', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;


  var Validations = (0, _emberCpValidations.buildValidations)({
    fileRef: (0, _emberCpValidations.validator)('presence', true),
    fileName: (0, _emberCpValidations.validator)('presence', true),
    type: (0, _emberCpValidations.validator)('presence', true),
    aflEntity: (0, _emberCpValidations.validator)('presence', true),
    otherType: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed('model.type', function () {
        return this.get('model.type') !== 'OTHER';
      }).volatile()
    })
  });

  exports.default = Model.extend(Validations, {
    aflEntity: attr('string'),
    aflEntityName: attr('string'),
    clientNumber: attr('string'),
    fileNumber: attr('string'),
    fileSize: attr('string'),
    signatureState: attr('string'),
    editionDate: attr('localdatetime'),
    wording: attr('string'),
    fileRef: attr('string'),
    fileName: attr('string'),
    mimeType: attr('string'),
    documentPieceType: attr('string'),
    depositorType: attr('string'),
    type: attr('string'),
    otherType: attr('string'),
    viewDocument: attr('boolean')
  });
});