define('afl/models/subscribe-document', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SubscribeDocument = _emberData.default.Model.extend({
    sequence: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'),
    dossierReference: _emberData.default.attr('string'),
    sabDosRef: _emberData.default.attr('string'),
    sendingInProgress: _emberData.default.attr('boolean'),
    documentToSignId: _emberData.default.attr('string'),
    documentType: _emberData.default.attr('string'),
    typeCode: _emberData.default.attr('string'),
    isMandatory: _emberData.default.attr('boolean'),
    isUnreceived: _emberData.default.attr('boolean'),
    isWaiting: _emberData.default.attr('boolean'),
    isValidated: _emberData.default.attr('boolean'),
    isRejected: _emberData.default.attr('boolean')
  });

  exports.default = SubscribeDocument;
});