define('afl/authenticators/custom', ['exports', 'ember-simple-auth-token/authenticators/jwt'], function (exports, _jwt) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jwt.default.extend({
    scheduleAccessTokenRefresh: function scheduleAccessTokenRefresh(expiresAt, token) {
      if (this.refreshAccessTokens) {
        expiresAt = this.resolveTime(expiresAt);

        var now = this.getCurrentTime();
        var wait = (expiresAt - now - this.refreshLeeway) * 1000;

        if (!Ember.isEmpty(token) && !Ember.isEmpty(expiresAt) && wait > 0) {
          Ember.run.cancel(this._refreshTokenTimeout);
          delete this._refreshTokenTimeout;
          this._refreshTokenTimeout = Ember.run.later(this, this.refreshAccessToken, token, wait);
        }
      }
    }
  });
});