define('afl/components/entity/collectivity-detail', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    model: null,
    isAgency: false,
    showGrade: true
  });
});