define('afl/models/message', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    category: (0, _emberCpValidations.validator)('inclusion', {
      in: ['SUBSCRIPTION', 'INSTRUCTION', 'MANAGEMENT', 'TECH_ADMIN', 'DOCUMENT', 'OTHER']
    }),
    subject: (0, _emberCpValidations.validator)('inclusion', {
      in: ['SUBSCRIPTION', 'MY_DOCUMENT', 'APPLICATION', 'ST_FUNDING_MGMT', 'LT_FUNDING_MGMT', 'PORTAL_CONNECTION', 'USER_MGMT', 'ANOMALY_TECH_ISSUE', 'OTHER']
    })
  });
  var Message = _emberData.default.Model.extend(Validations, {
    status: _emberData.default.attr('string'),
    fromLabel: _emberData.default.attr('string'),
    toLabel: _emberData.default.attr('string'),
    collectivityId: _emberData.default.attr('number'),
    category: _emberData.default.attr('string'),
    subject: _emberData.default.attr('string'),
    subjectComplement: _emberData.default.attr('string'),
    sendDate: _emberData.default.attr('localdatetime'),
    fundingApplicationRef: _emberData.default.attr('string'),
    messageContent: _emberData.default.attr('string'),
    documentId: _emberData.default.attr('number'),
    documentName: _emberData.default.attr('string')
  });

  Message.reopen({

    isOutgoing: Ember.computed('status', function () {
      return this.get('status') === 'OUTGOING';
    }),

    isTreated: Ember.computed('status', function () {
      return this.get('status') === 'TREATED';
    }),

    isRead: Ember.computed('status', function () {
      return this.get('status') === 'READ';
    }),

    isNotRead: Ember.computed('status', function () {
      return this.get('status') === 'NOT_READ';
    }),

    canReply: Ember.computed('status', function () {
      return this.get('isRead');
    }),

    canBeMarkedAsRead: Ember.computed('isNotRead', function () {
      return this.get('isNotRead');
    }),

    canBeMarkedAsNotRead: Ember.computed('isRead', function () {
      return this.get('isRead');
    }),

    canBeMarkedAsTreated: Ember.computed('isRead', function () {
      return this.get('isRead');
    }),

    canBeMarkedAsNotTreated: Ember.computed('isTreated', function () {
      return this.get('isTreated');
    }),

    canBeErased: Ember.computed('isNotRead', function () {
      return !this.get('isNotRead');
    }),

    canModifySubject: Ember.computed('isRead', function () {
      return this.get('isRead');
    }),

    canBeDownloaded: Ember.computed('isNotRead', 'documentId', function () {
      return !this.get('isNotRead') && this.get('documentId');
    })
  });

  exports.default = Message;
});