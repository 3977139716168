define('afl/serializers/grid-rate', ['exports', 'ember-data', 'afl/serializers/application'], function (exports, _emberData, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      rows: { embedded: 'always' }
    }
  });
});