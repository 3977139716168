define('afl/controllers/agency/news/create', ['exports', 'afl/mixins/injected-service'], function (exports, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_injectedService.default, {
    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.agency.news.create');
    }),

    actions: {
      success: function success() {
        this.transitionToRoute('agency.news.index');
      },

      cancel: function cancel() {
        this.transitionToRoute('agency.news.index');
      }
    }
  });
});