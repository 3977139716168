define('afl/controllers/contact', ['exports', 'afl/mixins/injected-service'], function (exports, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_injectedService.default, {
    currentStep: 'SEARCH',

    searchClass: Ember.computed('currentStep', function () {
      var current = this.get('currentStep');
      if (current === 'SEARCH') {
        return "enabled";
      } else {
        return "validated";
      }
    }),

    formClass: Ember.computed('currentStep', function () {
      var current = this.get('currentStep');
      if (current === 'SEARCH') {
        return "disabled";
      } else if (current === 'FORM') {
        return "enabled";
      } else {
        return "validated";
      }
    }),

    summaryClass: Ember.computed('currentStep', function () {
      var current = this.get('currentStep');
      if (current === 'SUMMARY') {
        return "enabled";
      } else {
        return "disabled";
      }
    }),

    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.contact');
    })
  });
});