define('afl/components/scroll-to', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: 'a',

    classNames: ['scroll-to'],

    // lier la propriété du composant à l'attibut de la balise
    attributeBindings: ['href'],

    // l'ancre par défaut, utilisé si les JS sont désactivés et pour l'accessibilité
    href: Ember.computed('anchor', function () {
      return window.location.href.slice(0, window.location.href.indexOf('#') === -1 ? window.location.href.length + 1 : window.location.href.indexOf('#')) + '#' + this.get('anchor');
    }),

    click: function click(event) {

      // empêcher l'exécution des évènements : il faut les 2, sinon l'affichage n'est pas bon
      event.preventDefault();
      event.stopPropagation();

      // récupération de l'objet jusqu'où scroller
      // utiliser cette méthode pour gérer les ID avec espace
      var element = Ember.$("[id='" + this.get('anchor') + "']");

      if (element) {

        // Récupération de la bannière pour gérer le décallage
        var banner = Ember.$('.banner:first');

        // Calcul de la nuvelle position d'affichage
        var newPosition = element.offset().top - banner.position().top - banner.height();

        // On scrolle jusqu'à l'élément
        Ember.$('body,html').animate({
          scrollTop: newPosition
        }, 0);

        // Donner le focus à l'élément scrollé pour la navigation au clavier
        // et l'enlève en partant pour reprendre la navigation normale
        Ember.$(element).attr('tabindex', -1).on('blur focusout', function () {
          Ember.$(this).removeAttr('tabindex');
        }).focus();
      }
    }
  });
});