define('afl/controllers/agency/entity/detail/contact', ['exports', 'afl/controllers/search', 'afl/mixins/injected-service', 'ember-cli-file-saver/mixins/file-saver'], function (exports, _search, _injectedService, _fileSaver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _search.default.extend(_fileSaver.default, _injectedService.default, {
    status: 'ACTIVE',
    dialogExport: false,

    isAll: Ember.computed('status', function () {
      return Ember.isEmpty(this.get('status'));
    }),
    isActive: Ember.computed('status', function () {
      return this.get('status') === 'ACTIVE';
    }),
    isInactive: Ember.computed('status', function () {
      return this.get('status') === 'INACTIVE';
    }),

    actions: {
      searchFunction: function searchFunction(searchParams) {
        this.set('model', this.store.query('crm-contact', {
          collectivityIdsIn: [this.get('aflEntity.id')],
          status: this.get('status'),
          page: searchParams.page - 1,
          size: searchParams.size,
          sort: searchParams.properties + ',' + searchParams.direction
        }));
      },


      filter: function filter(status) {
        this.set('status', status);
        this.send('searchFunction', {
          page: 1,
          size: this.get('pageSize'),
          direction: this.get('direction'),
          properties: this.get('sortProperty')
        });
      },

      dialogExport: function dialogExport() {
        this.toggleProperty('dialogExport');
      },

      exportResults: function exportResults() {
        var _this = this;

        // En local on récupère le proxy, sinon lien relatif sur le même domaine:port
        var queryParams = Ember.$.param({
          collectivityIdsIn: [this.get('aflEntity.id')],
          status: this.get('status')
        }, true);

        Ember.$.ajax({
          type: 'GET',
          url: '/api/crmcontacts/export?' + queryParams,
          dataType: 'blob', // or 'blob'
          headers: {
            'authorization': this.get('currentUser').getToken()
          },
          processData: false
        }).then(function (content) {
          _this.saveFileAs('liste-contact.csv', content);
        });
      }
    }
  });
});