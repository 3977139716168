define('afl/routes/agency/event/detail/edit', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      return this.modelFor('agency.event.detail');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.store.query('group', {
        entityType: 'AGENCY',
        filtered: false
      }).then(function (groups) {
        controller.set('interactionNatures', groups);
      });
    },


    actions: {
      willTransition: function willTransition() {
        this.controller.get('model').rollbackAttributes();
        return true;
      }
    }
  });
});