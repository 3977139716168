define('afl/routes/agency/collectivity/detail/simulation/detail', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    beforeModel: function beforeModel(transition) {
      var _this = this;

      this._super(transition);
      return Ember.$.get('/api/gridrates/areEnabledGrids').then(function (enable) {
        _this.controllerFor('agency.collectivity.detail.simulation.detail').set('enable', enable);
      });
    },
    model: function model(params) {
      return this.store.find('simulation', params.simulation_id);
    },


    actions: {
      didTransition: function didTransition() {
        this.controller.get('model').rollbackAttributes();
      }
    }
  });
});