define('afl/models/grid-rate-row', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var GridRateRow = _emberData.default.Model.extend({
    label: _emberData.default.attr('string'),
    position: _emberData.default.attr('number'),
    grid: _emberData.default.belongsTo('gridRate'),
    cells: _emberData.default.hasMany('gridRateCell'),

    orderedCells: Ember.computed('cells.@each.position', function () {
      return this.get('cells').sortBy('position');
    })
  });

  exports.default = GridRateRow;
});