define('afl/models/col-contact', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    civility: (0, _emberCpValidations.validator)('presence', true),
    firstName: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('word')],
    lastName: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('word')],
    role: (0, _emberCpValidations.validator)('presence', true),
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('email')],
    phoneNumber: [(0, _emberCpValidations.validator)('phone')],
    mobileNumber: [(0, _emberCpValidations.validator)('mobile')]
  });

  var ColContact = _emberData.default.Model.extend(Validations, {
    person: _emberData.default.belongsTo('crm-person'),
    collectivity: _emberData.default.belongsTo('collectivity'),
    user: _emberData.default.belongsTo('afl-user'),
    role: _emberData.default.attr('string'),
    entityType: _emberData.default.attr('string', { defaultValue: 'collectivity' }),
    civility: _emberData.default.attr('string'),
    elected: _emberData.default.attr('boolean', { defaultValue: false }),
    email: _emberData.default.attr('string'),
    phoneNumber: _emberData.default.attr('string'),
    mobileNumber: _emberData.default.attr('string'),
    isActive: _emberData.default.attr('boolean'),
    hasQualifiedCertificate: _emberData.default.attr('boolean', { defaultValue: false }),
    roles: _emberData.default.attr(),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    collectivityId: Ember.computed.alias('collectivity.id'),
    groups: _emberData.default.hasMany('group'),

    userId: Ember.computed('user', function () {
      // Avoid fetching the user
      return this.belongsTo('user').id();
    }),

    presenceMobile: Ember.computed('mobileNumber', function () {
      return !Ember.isEmpty(Ember.get(this, 'mobileNumber'));
    })

  });

  exports.default = ColContact;
});