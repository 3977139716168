define('afl/routes/collectivity/fundings/long-term/detail', ['exports', 'afl/mixins/injected-service', 'afl/mixins/authenticated-route-mixin'], function (exports, _injectedService, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_injectedService.default, _authenticatedRouteMixin.default, {
    model: function model(params) {
      return this.store.findOneQuery('loan', params.longTerm_id, { collectivity_id: this.get('currentUser.entityId'), loan_number: params.loan_number });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('isMobilisation', false);
      controller.set('entityId', this.get('currentUser.entityId'));
      controller.set('currentFundingId', model.get('id'));
      controller.set('entityId', this.get('currentUser.entityId'));
      controller.set('isMember', this.get('currentUser.collectivity.isCollectivityMember'));
    },


    actions: {
      error: function error() {
        Ember.get(this, 'toast').error(this.get('intl').getMessage('collectivity.fundings.noFundingApplication'));
      },
      sendMessage: function sendMessage() {
        var model = this.modelFor('collectivity.fundings.long-term.detail');
        this.transitionTo('collectivity.mailbox').then(function (newRoute) {
          newRoute.controller.set('showSendMessageForm', true);
          newRoute.controller.set('subject', 'LT_FUNDING_MGMT');
          newRoute.controller.set('fundingApplicationRef', model.get('fileNumber'));
        });
      }
    }
  });
});