define('afl/controllers/agency/person/create', ['exports', 'afl/mixins/injected-service', 'afl/utils/afl-constants'], function (exports, _injectedService, _aflConstants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_injectedService.default, {
    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.agency.person.create');
    }),
    crmPep: (0, _aflConstants.default)('PoliticallyExposedPerson'),

    actions: {
      savePerson: function savePerson() {
        var _this = this;

        // Récupération des personnes approchante celle en cours de création
        this.store.query('crm-person', this.get('model').serialize()).then(function (people) {
          if (Ember.get(people, 'length') > 0) {
            _this.set('availablePeople', people);
            _this.set('displayPersonChoice', true);
          } else {
            _this.send('doSavePerson');
          }
        });
      },
      doSavePerson: function doSavePerson() {
        var _this2 = this;

        this.get('model').save().then(function (person) {
          Ember.get(_this2, 'toast').success(_this2.get('intl').getMessage('crm.people.create.success'));
          _this2.transitionToRoute('agency.person.detail', person);
        }).catch(function (errors) {
          var error = errors.errors[0] || {},
              message = _this2.get('intl').getMessage('crm.people.create.error');

          if (error.detail && error.detail.message) {
            message = error.detail.message;
          }

          Ember.get(_this2, 'toast').error(message);
        });
      },
      cancel: function cancel() {
        this.transitionToRoute('agency.person');
      },
      validationErrors: function validationErrors() {
        Ember.get(this, 'toast').error(this.get('intl').getMessage('crm.people.create.validation'));
      }
    }
  });
});