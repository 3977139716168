define('afl/models/afl-entity', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AflEntity = _emberData.default.Model.extend({
    aflEntityName: _emberData.default.attr('string'),
    aflEntityType: _emberData.default.attr('string'),
    aflEntityStatus: _emberData.default.attr('string'),

    //computed data from server
    postalCode: _emberData.default.attr('string'),
    collectivityStatus: _emberData.default.attr('string'),
    isCollectivityMember: _emberData.default.attr('boolean'),

    type: Ember.computed('aflEntityType', function () {
      var aflEntityType = this.get('aflEntityType');
      switch (aflEntityType) {
        case 'COLLECTIVITY':
          return 'collectivite';
        case 'AGENCY':
          return 'agence';
        case 'PAYING_AGENT':
          return 'agent-payeur';
        default:
          return '';
      }
    }),

    isCollectivity: Ember.computed('aflEntityType', function () {
      return this.get('aflEntityType') === 'COLLECTIVITY';
    })
  });

  exports.default = AflEntity;
});