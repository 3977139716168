define('afl/components/document/document-list', ['exports', 'afl/mixins/injected-service', 'afl/mixins/has-permission', 'afl/components/grid/pagination-component'], function (exports, _injectedService, _hasPermission, _paginationComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _paginationComponent.default.extend(_injectedService.default, _hasPermission.default, {
    entityId: null,
    isMember: false,
    isTiersPage: true,
    fundingIdList: null,
    currentFundingId: null,
    isManagementMode: false,
    ascending: false,
    sortProperty: 'editionDate',
    store: Ember.inject.service(),
    hasDocumentName: true,

    init: function init() {
      this._super.apply(this, arguments);
      this.fundingIdList = [];
      this.send('initFundingList');
      this.send('resetModel');
    },


    documentCategory: Ember.computed('isTiersPage', 'isManagementMode', function () {
      if (this.get('isTiersPage')) {
        return 'TIERS';
      } else {
        if (this.get('isManagementMode')) {
          return 'MANAGEMENT';
        }
        return 'INSTRUCTION';
      }
    }),

    fundingAdditionalTitle: Ember.computed('isTiersPage', 'isManagementMode', 'fundingIdWithDocument', function () {
      if (!this.get('isTiersPage') && this.get('fundingIdWithDocument')) {
        if (this.get('isManagementMode')) {
          return this.get('intl').getMessage("collectivity.documents.funding.managementAddTitle");
        } else {
          return this.get('intl').getMessage("collectivity.documents.funding.instructionAddTitle");
        }
      }
      return "";
    }),

    paginationTitle: Ember.computed('isTiersPage', 'isManagementMode', function () {
      if (!this.get('isTiersPage')) {
        if (this.get('isManagementMode')) {
          return "collectivity.documents.funding.managementPaginationTitle";
        } else {
          return "collectivity.documents.funding.instructionPaginationTitle";
        }
      }
      return "collectivity.documents.title";
    }),

    hasNoResult: Ember.computed('model.@each', 'isFundingPage', 'isFundingInitPage', function () {
      return this.get('model').isFulfilled && Ember.isEmpty(this.get('model')) && !this.get('isFundingInitPage');
    }),

    isFundingInitPage: Ember.computed('isTiersPage', 'currentFundingId', 'fundingIdWithDocument', function () {
      return !this.get('isTiersPage') && this.get('currentFundingId') == null && this.get('fundingIdWithDocument');
    }),

    fundingIdWithDocument: Ember.computed('fundingIdList', 'isTiersPage', function () {
      return this.get('fundingIdList').length > 0 && !this.get('isTiersPage');
    }),

    actions: {
      searchFunction: function searchFunction(searchParams) {
        this.set('model', this.get('store').query('document', {
          collectivity: this.get('entityId'),
          documentCategory: this.get('documentCategory'),
          fileNumber: this.get('currentFundingId'),
          page: searchParams.page,
          signatureState: 'NOT_NEEDED',
          size: searchParams.size,
          direction: searchParams.direction,
          properties: searchParams.properties
        }));
      },
      resetModel: function resetModel() {
        this.send('searchFunction', {
          page: 1,
          size: this.get('pageSize'),
          direction: this.get('direction'),
          properties: this.get('sortProperty')
        });
      },
      managementModeChange: function managementModeChange(managementMode) {
        this.set('isManagementMode', managementMode);
      },
      fundingIdChange: function fundingIdChange(fundingId) {
        this.set('currentFundingId', fundingId);
      },
      initFundingList: function initFundingList() {
        var _this = this;

        Ember.$.get('/api/documents/filenumberlist', {
          collectivity: this.get('entityId')
        }).then(function (data) {
          Ember.run(function () {
            if (Ember.testing && typeof data === 'string') {
              // A modification on pretender (or fakexmlrequest) need that any response must be a string...
              // The problem is that this string is not correctly transform for this case.
              // Therefore, we do the conversion manually
              data = JSON.parse(data);
            }
            if (!_this.get('isDetail')) {
              _this.set('fundingIdList', data);
            }

            if (isNaN(_this.get('currentFundingId') || !data.includes(parseInt(_this.get('currentFundingId'), 10)))) {
              _this.set('currentFundingId', null);
            } else if (_this.get('isDetail')) {
              _this.set('fundingIdList', [_this.get('currentFundingId')]);
            }
          });
        });
      },
      redirectToTiersDocuments: function redirectToTiersDocuments() {
        this.set('isTiersPage', true);
        if (!this.get('isDetail')) {
          this.set('currentFundingId', null);
        }
        this.send('resetModel');
      },
      redirectToFundingDocuments: function redirectToFundingDocuments() {
        this.set('isTiersPage', false);
        if (!this.get('isDetail')) {
          this.set('currentFundingId', null);
          this.set('model', Ember.A());
          this.set('model.isFulfilled', true);
        } else {
          this.send('resetModel');
        }
      },
      resetActiveElement: function resetActiveElement() {
        Ember.$('.funding-document-list').find('li').removeClass('active');
        Ember.$('.funding-document-list').find('a').removeClass('active');
      }
    }
  });
});