define('afl/controllers/collectivity/funding-application/being-analysed/detail', ['exports', 'afl/mixins/injected-service', 'afl/mixins/upload-document'], function (exports, _injectedService, _uploadDocument) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_injectedService.default, _uploadDocument.default, {
    uploadingFile: false,
    documentSelected: null,
    file: null,
    uploadContext: 'INSTRUCTION_PROCESS',

    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.collectivity.fundingApplication.detail');
    }),

    hasNoDocument: Ember.computed('model.subscribeDocuments', function () {
      return this.get('model.subscribeDocuments').toArray().length === 0;
    }),

    hasNoFunding: Ember.computed('model.@each', function () {
      return Ember.isEmpty(this.get('model')) && this.get('model').isFulfilled;
    }),

    oddDocuments: Ember.computed('model.subscribeDocuments.@each', function () {
      return this.get('model.subscribeDocuments').toArray().filter(function (element, index) {
        return index % 2 === 1;
      });
    }),

    evenDocuments: Ember.computed('model.subscribeDocuments.@each', function () {
      return this.get('model.subscribeDocuments').toArray().filter(function (element, index) {
        return index % 2 === 0;
      });
    }),

    actions: {
      uploadDocument: function uploadDocument(model) {
        this.set('uploadingFile', true);
        this.set('documentSelected', model);
      },

      closeUpload: function closeUpload() {
        this.set('uploadingFile', false);
      }
    }
  });
});