define("afl/models/crm-event", ["exports", "ember-data", "ember-cp-validations"], function (exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    title: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 80
    })],
    date: (0, _emberCpValidations.validator)('presence', true),
    interactionMethod: (0, _emberCpValidations.validator)('presence', true),
    interactionNature: (0, _emberCpValidations.validator)('presence', true),
    collectivity: (0, _emberCpValidations.validator)('presence', true)
  });

  var CRMEvent = _emberData.default.Model.extend(Validations, {
    title: _emberData.default.attr('string'),
    date: _emberData.default.attr('localdatetime'),
    interactionMethod: _emberData.default.attr('string'),
    interactionNature: _emberData.default.attr('string'),
    contacts: _emberData.default.hasMany('crm-contact'),
    collectivity: _emberData.default.belongsTo('collectivity'),
    comments: _emberData.default.attr(),
    user: _emberData.default.belongsTo('afl-user'),

    collectivityId: Ember.computed.alias('collectivity.id')
  });

  exports.default = CRMEvent;
});