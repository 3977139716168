define('afl/models/collectivity', ['exports', 'ember-data', 'afl/models/afl-entity', 'afl/mixins/injected-service'], function (exports, _emberData, _aflEntity, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Collectivity = _aflEntity.default.extend(_injectedService.default, {
    siren: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    statusDate: _emberData.default.attr('localdatetime'),
    corporateName: _emberData.default.attr('string'),
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    address3: _emberData.default.attr('string'),
    address4: _emberData.default.attr('string'),
    postalCode: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    category: _emberData.default.attr('string'),
    grade: _emberData.default.attr('number'),
    totalContribution: _emberData.default.attr('number'),
    payingAgentId: _emberData.default.attr('number'),
    isEligible: _emberData.default.attr('boolean'),
    contributions: _emberData.default.hasMany('contribution', { async: true }),
    subscribeDocuments: _emberData.default.hasMany('subscribeDocument', { async: true }),

    isMember: Ember.computed('status', function () {
      var status = this.get('status');
      return status === 'MEMBER' || status === 'SLEEPING_MEMBER' || status === 'ENDING_MEMBER';
    }),

    eligible: Ember.computed('grade', function () {
      return this.get('grade') ? this.get('grade') < 6 : false;
    }),

    autoCompleteDisplay: Ember.computed('corporateName', 'postalCode', 'category', function () {
      var categoryTranslated = '';

      if (this.get('category') != null) {
        categoryTranslated = ' - ' + this.get('intl').getEnum('enums.CollectivityCategory.' + this.get('category'));
      }

      return this.get('corporateName') + ' (' + this.get('postalCode') + ')' + categoryTranslated;
    })
  });

  exports.default = Collectivity;
});