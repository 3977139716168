define('afl/components/input/text-editor', ['exports', 'afl/mixins/injected-service', 'afl/config/environment', 'afl/tinymce/plugins/charcount'], function (exports, _injectedService, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_injectedService.default, {
    classNames: null,
    contentLimit: 1000,
    _options: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.classNames = ['text-editor'];
      this._options = {};
      if (_environment.default.environment !== 'test') {
        this._options = {
          skin_url: '/tinymce/skins/lightgray',
          theme_url: '/tinymce/themes/modern/theme.min.js',
          height: 350,
          min_width: 600,
          menubar: false,
          external_plugins: {
            lists: '/tinymce/plugins/lists/plugin.min.js',
            link: '/tinymce/plugins/link/plugin.min.js',
            textcolor: '/tinymce/plugins/textcolor/plugin.min.js',
            table: '/tinymce/plugins/table/plugin.min.js',
            preview: '/tinymce/plugins/preview/plugin.min.js',
            charcount: 'charcount'
          },
          toolbar: ['fontselect formatselect fontsizeselect | undo redo | preview', 'forecolor bold italic underline strikethrough | outdent indent alignleft aligncenter alignright alignjustify | table  numlist bullist link'],
          language: 'fr_FR',
          language_url: '/assets/tinymce/lang/fr_FR.js',
          default_link_target: '_blank',
          link_title: false,
          target_list: false,
          branding: false,
          table_default_attributes: {
            border: '1',
            width: '50%'
          }
        };
      }
    },

    didInsertElement: function didInsertElement() {
      var component = this;
      var options = this.get('_options');

      Ember.merge(options, {
        setup: function setup(editor) {
          // bind change event
          editor.on('change', function () {
            component.set('value', editor.getContent());
          });
        },
        intl: this.get('intl'),
        contentLimit: this.get('contentLimit')
      });

      Ember.run.scheduleOnce('afterRender', this, function () {
        this.$('textarea').tinymce(options);
      });
    }
  });
});