define('afl/controllers/agency/event/index', ['exports', 'afl/controllers/search', 'afl/mixins/injected-service', 'afl/mixins/has-permission', 'afl/mixins/search-collectivity', 'afl/adapters/application', 'afl/utils/afl-constants', 'ember-cli-file-saver/mixins/file-saver'], function (exports, _search, _injectedService, _hasPermission, _searchCollectivity, _application, _aflConstants, _fileSaver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var EventFilter = Ember.Object.extend({
    title: '',
    minimumDate: null,
    maximumDate: null,
    collectivitySiren: '',
    collectivityType: '',
    collectivityStatus: '',
    interactionMethod: '',
    interactionNature: '',

    toJson: function toJson() {
      return JSON.parse(JSON.stringify(this));
    }
  });

  var ContactFilter = Ember.Object.extend({
    email: '',
    role: '',

    toJson: function toJson() {
      return JSON.parse(JSON.stringify(this));
    }
  });

  exports.default = _search.default.extend(_fileSaver.default, _injectedService.default, _hasPermission.default, _searchCollectivity.default, {
    searchCollectivityCategoryFilter: Ember.computed.alias('event.collectivityType'),
    searchCollectivityIsFiltered: false,
    allCollectivities: true,
    selectedCollectivity: null,
    event: EventFilter.create(),
    contact: ContactFilter.create(),
    contactRoles: (0, _aflConstants.default)('CRMRole'),
    interactionMethods: (0, _aflConstants.default)('CRMInteractionMethod'),
    interactionNatures: null,
    collectivityCategories: (0, _aflConstants.default)('CollectivityCategory'),
    statuses: (0, _aflConstants.default)('CollectivityStatus'),
    dialogExport: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.interactionNatures = [];
    },

    shouldCollapse: Ember.computed('isInit', 'hasNoResult', function () {
      return this.get('isInit') || this.get('hasNoResult');
    }),

    selectedCollectivityChange: Ember.observer('selectedCollectivity', function () {
      var collectivity = this.get('selectedCollectivity');
      if (collectivity) {
        this.set('event.collectivityIdsIn', [collectivity.get('id')]);
      } else {
        this.set('event.collectivityIdsIn', null);
      }
    }),

    _resetFilter: function _resetFilter() {
      this.set('selectedCollectivity', null);
      this.set('event', EventFilter.create());
      this.set('contact', ContactFilter.create());
      this.set('dialogExport', false);
    },
    _completeFilter: function _completeFilter(filter) {
      var event = this.get('event').toJson();
      event.contactSearchBean = this.get('contact').toJson();
      Ember.merge(filter, event);
      return filter;
    },


    actions: {
      searchFunction: function searchFunction(searchParams) {
        var filter = this._completeFilter({
          page: searchParams.page - 1,
          size: searchParams.size,
          sort: searchParams.properties + ',' + searchParams.direction
        });
        this.set('model', this.store.query('crm-event', filter));
      },
      reset: function reset() {
        this._super();
        this._resetFilter();
      },
      dialogExport: function dialogExport() {
        this.toggleProperty('dialogExport');
      },
      exportResults: function exportResults() {
        var _this = this;

        var filter = (0, _application.jsonQueryString)(this._completeFilter({}));
        var queryParams = Ember.$.param(filter, true);

        Ember.$.ajax({
          type: 'GET',
          url: '/api/crmevents/export?' + queryParams,
          dataType: 'blob', // or 'blob'
          headers: {
            'authorization': this.get('currentUser').getToken()
          },
          processData: false
        }).then(function (content) {
          _this.saveFileAs('liste-evenement.csv', content);
        });
      }
    }

  });
});