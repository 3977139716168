define('afl/components/user/user-subscriptions', ['exports', 'afl/mixins/injected-service'], function (exports, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_injectedService.default, {

    actions: {
      userModifiedSubscription: function userModifiedSubscription(subscription) {
        var _this = this;

        var messageKey = void 0,
            endpoint = void 0;
        if (subscription.subscribed) {
          messageKey = this.get('unsubscribeMessage');
          endpoint = 'unsubscribe';
        } else {
          messageKey = this.get('subscribeMessage');
          endpoint = 'subscribe';
        }
        Ember.$.post('/api/aflusers/' + endpoint, {
          notification: subscription.enumValue
        }).then(function () {
          Ember.get(_this, 'toast').success(_this.get('intl').getMessage(messageKey, { notification: subscription.notification }));
        }).fail(function () {
          Ember.get(_this, 'toast').error(_this.get('intl').getMessage('common.unknownError'));
        });
      }
    }
  });
});