define('afl/models/crm-contact', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    person: (0, _emberCpValidations.validator)('presence', true),
    collectivity: (0, _emberCpValidations.validator)('presence', true),
    email: (0, _emberCpValidations.validator)('email'),
    phoneNumber: (0, _emberCpValidations.validator)('phone'),
    mobileNumber: (0, _emberCpValidations.validator)('mobile')
  });

  var CRMContact = _emberData.default.Model.extend(Validations, {
    person: _emberData.default.belongsTo('crm-person'),
    collectivity: _emberData.default.belongsTo('collectivity'),
    function: _emberData.default.attr('string'),
    elected: _emberData.default.attr('boolean', { defaultValue: false }),
    email: _emberData.default.attr('string'),
    phoneNumber: _emberData.default.attr('string'),
    mobileNumber: _emberData.default.attr('string'),
    isActive: _emberData.default.attr('boolean'),
    roles: _emberData.default.attr(),
    subscriptions: _emberData.default.attr(),
    comments: _emberData.default.attr(),
    user: _emberData.default.belongsTo('afl-user'),

    collectivityId: Ember.computed.alias('collectivity.id'),
    personId: Ember.computed.alias('person.id'),

    fullName: Ember.computed('person.fullName', function () {
      return this.get('person.fullName');
    })
  });

  exports.default = CRMContact;
});