define('afl/components/grid/pagination-bar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    meta: Ember.computed('model.{meta,content.meta}', function () {
      return this.get('model.content.meta') || this.get('model.meta');
    }),
    totalPages: Ember.computed.alias('meta.totalPages'),
    totalElements: Ember.computed.alias('meta.totalElements'),
    pageSize: 50,
    sizes: null,

    init: function init() {
      this._super.apply(this, arguments);
      if (Ember.isEmpty(this.size)) {
        this.sizes = [10, 20, 50];
      }
    },

    currentFirstIdx: Ember.computed("meta", "meta.{number,size}", function () {
      return this.get('meta.number') * this.get('meta.size') + 1;
    }),

    currentLastIdx: Ember.computed("meta", "meta.{number,size,numberOfElements}", function () {
      return this.get('currentFirstIdx') + this.get('meta.numberOfElements') - 1;
    }),

    currentPageNumber: Ember.computed("meta", "meta.number", function () {
      return this.get('meta.number') + 1;
    }),

    hasPrevious: Ember.computed("meta", "meta.number", function () {
      return this.get('meta.number') !== 0;
    }),

    hasNext: Ember.computed("meta", "meta.{number,size,numberOfElements}", function () {
      return this.get('currentLastIdx') < this.get('totalElements');
    }),

    firstPages: Ember.computed('currentPageNumber', function () {
      var firstPages = [],
          maxRange = Math.min(3, this.get('currentPageNumber'));
      for (var i = 1; i < maxRange; i++) {
        firstPages.push(i);
      }
      return firstPages;
    }),

    previousPages: Ember.computed('firstPages', 'currentPageNumber', function () {
      var previousPages = [],
          firstPages = this.get('firstPages'),
          current = this.get('currentPageNumber'),
          minRange = Math.max(1, current - 1);

      for (var i = minRange; i < current; i++) {
        if (firstPages.indexOf(i) === -1) {
          previousPages.push(i);
        }
      }
      return previousPages;
    }),

    nextPages: Ember.computed('lastPages', 'totalPages', 'currentPageNumber', function () {
      var nextPages = [],
          lastPages = this.get('lastPages'),
          current = this.get('currentPageNumber'),
          maxRange = Math.min(this.get('totalPages'), current + 1);

      for (var i = current + 1; i <= maxRange; i++) {
        if (lastPages.indexOf(i) === -1) {
          nextPages.push(i);
        }
      }
      return nextPages;
    }),

    lastPages: Ember.computed('totalPages', 'currentPageNumber', function () {
      var lastPages = [],
          current = this.get('currentPageNumber'),
          last = this.get('totalPages');
      if (current < last - 1) {
        lastPages.push(last - 1);
      }
      if (current < last) {
        lastPages.push(last);
      }
      return lastPages;
    }),

    hasPreviousAnonymousPages: Ember.computed('previousPages', function () {
      var previousPages = this.get('previousPages');
      return previousPages.length > 0 && previousPages[0] > 3;
    }),

    hasNextAnonymousPages: Ember.computed('nextPages', 'totalPages', function () {
      var nextPages = this.get('nextPages'),
          totalPages = this.get('totalPages');
      return nextPages.length > 0 && nextPages[nextPages.length - 1] < totalPages - 2;
    }),

    hasMany: Ember.computed('totalElements', function () {
      return this.get("totalElements") > 1;
    }),

    newPage: function newPage(params) {
      this.sendAction('action', params);
    },

    pageSizeChange: Ember.observer('pageSize', function () {
      this.newPage({
        page: 1,
        size: this.get('pageSize') || this.sizes[0]
      });
    }),

    actions: {
      pageSelected: function pageSelected(page) {
        this.newPage({
          page: page,
          size: this.get('pageSize') || this.sizes[0]
        });
      },

      first: function first() {
        if (this.get('currentFirstIdx') !== 1) {
          this.newPage({
            page: 1,
            size: this.get('pageSize') || this.sizes[0]
          });
        }
      },

      previous: function previous() {
        if (this.get('hasPrevious')) {
          this.newPage({
            page: this.get('currentPageNumber') - 1,
            size: this.get('pageSize') || this.sizes[0]
          });
        }
      },

      next: function next() {
        if (this.get('hasNext')) {
          this.newPage({
            page: this.get('currentPageNumber') + 1,
            size: this.get('pageSize') || this.sizes[0]
          });
        }
      },

      last: function last() {
        if (this.get('currentLastIdx') !== this.get('totalElements')) {
          this.newPage({
            page: this.get('totalPages'),
            size: this.get('pageSize') || this.sizes[0]
          });
        }
      }
    }

  });
});