define('afl/routes/collectivity/funding-application/history/detail', ['exports', 'afl/mixins/injected-service', 'afl/mixins/authenticated-route-mixin'], function (exports, _injectedService, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_injectedService.default, _authenticatedRouteMixin.default, {
    model: function model(params) {
      return this.store.findOneQuery('fundingApplication', params.funding_id, {
        source: 'REMOTE',
        collectivity_id: this.get('currentUser.entityId')
      });
    }
  });
});