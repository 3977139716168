define('afl/helpers/display-rate', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute: function compute(params) {
      var intl = this.get('intl'),
          model = params[0];

      if (model.get('rateType') === 'FIXED') {
        var rate = model.get('rate') / 100;
        return intl.getMessage('fmtRate', { rate: rate });
      } else {
        var margin = model.get('margin') / 100;
        if (margin < 0) {
          return intl.getMessage('fmtMarginMinus', { label: model.get('rateLabel'), margin: margin });
        } else {
          return intl.getMessage('fmtMarginPlus', { label: model.get('rateLabel'), margin: margin });
        }
      }
    }
  });
});