define('afl/controllers/agency/collectivity/index', ['exports', 'afl/mixins/search-collectivity', 'afl/controllers/search', 'afl/mixins/injected-service', 'afl/mixins/has-permission', 'afl/utils/afl-constants'], function (exports, _searchCollectivity, _search, _injectedService, _hasPermission, _aflConstants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CollectivityFilter = Ember.Object.extend({
    category: '',
    toJson: function toJson() {
      return JSON.parse(JSON.stringify(this));
    }
  });

  exports.default = _search.default.extend(_injectedService.default, _searchCollectivity.default, _hasPermission.default, {
    searchCollectivityCategoryFilter: Ember.computed.alias('collectivityFilter.category'),
    searchCollectivityIsFiltered: false,
    allCollectivities: true,
    selectedCollectivity: null,
    collectivityFilter: CollectivityFilter.create(),
    statuses: (0, _aflConstants.default)('CollectivityStatus'),

    shouldCollapse: Ember.computed('isInit', 'hasNoResult', function () {
      return this.get('isInit') || this.get('hasNoResult');
    }),

    selectedCollectivityChange: Ember.observer('selectedCollectivity', function () {
      var collectivity = this.get('selectedCollectivity');
      if (collectivity) {
        this.set('collectivityFilter.siren', [collectivity.get('siren')]);
      } else {
        this.set('collectivityFilter.siren', null);
      }
    }),

    _resetFilter: function _resetFilter() {
      this.set('selectedCollectivity', null);
      this.set('collectivityFilter', CollectivityFilter.create());
    },


    actions: {
      searchFunction: function searchFunction(searchParams) {
        var filter = {
          page: searchParams.page,
          size: searchParams.size,
          aflEntityType: 'COLLECTIVITY'
        };
        Ember.merge(filter, this.get('collectivityFilter').toJson());
        this.set('model', this.store.query('afl-entity', filter));
      },
      reset: function reset() {
        this._super();
        this._resetFilter();
      }
    }

  });
});