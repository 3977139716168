define('afl/validators/date', ['exports', 'ember-cp-validations/validators/base', 'moment'], function (exports, _base, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function isDate(param) {
    return (0, _moment.default)(param).isValid();
  }

  function getFormatDate(date, format) {
    return (0, _moment.default)(date).format(format);
  }

  var Date = _base.default.extend({
    intl: Ember.inject.service(),

    validate: function validate(value, options) {
      if (Ember.isEmpty(value)) {
        return true;
      }
      if (Ember.isEmpty(options.format)) {
        options.set('format', 'DD/MM/YYYY');
      }

      if (!isDate(value)) {
        return this.get('intl').t('validations.notADate', this.options);
      }

      if (!Ember.isEmpty(options.after) && value <= this.get(options.after)) {
        return this.get('intl').t('validations.invalidAfterDate', { after: getFormatDate(this.get(options.after), options.format) });
      }

      if (!Ember.isEmpty(options.afterOrEquals) && value < this.get(options.afterOrEquals)) {
        return this.get('intl').t('validations.invalidAfterDate', { after: getFormatDate(this.get(options.afterOrEquals), options.format) });
      }

      if (!Ember.isEmpty(options.before) && value <= this.get(options.before)) {
        return this.get('intl').t('validations.invalidAfterDate', { before: getFormatDate(this.get(options.before), options.format) });
      }

      if (!Ember.isEmpty(options.beforeOrEquals) && value < this.get(options.beforeOrEquals)) {
        return this.get('intl').t('validations.invalidBeforeDate', { before: getFormatDate(this.get(options.beforeOrEquals), options.format) });
      }

      return true;
    }
  });

  Date.reopenClass({
    getDependentsFor: function getDependentsFor(attribute, options) {
      return [options.after, options.afterOrEquals, options.before, options.beforeOrEquals].filter(function (item) {
        return !Ember.isEmpty(item);
      });
    }
  });

  exports.default = Date;
});