define('afl/models/news', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    status: (0, _emberCpValidations.validator)('inclusion', {
      in: ['CREATED', 'PUBLISHED', 'DELETED']
    }),
    publicationBeginDate: (0, _emberCpValidations.validator)('presence', true),
    publicationEndDate: (0, _emberCpValidations.validator)('date', {
      afterOrEquals: 'model.publicationBeginDate'
    }),
    logo: (0, _emberCpValidations.validator)('presence', true),
    newsContent: (0, _emberCpValidations.validator)('presence', true),
    title: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 120
    })],
    summary: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 240
    })]
  });

  var News = _emberData.default.Model.extend(Validations, {
    title: _emberData.default.attr('string'),
    summary: _emberData.default.attr('string'),
    logo: _emberData.default.attr('string'),
    newsContent: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    creationDate: _emberData.default.attr('localdatetime'),
    publicationBeginDate: _emberData.default.attr('localdate'),
    publicationEndDate: _emberData.default.attr('localdate'),
    state: _emberData.default.attr('string'),

    isDeleted: Ember.computed('status', function () {
      return this.get('status') === 'DELETED';
    }),

    isCreated: Ember.computed('status', function () {
      return this.get('status') === 'CREATED';
    }),

    isPublished: Ember.computed('status', function () {
      return this.get('status') === 'PUBLISHED';
    })
  });

  exports.default = News;
});