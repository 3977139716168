define('afl/controllers/collectivity/col-contact/index', ['exports', 'afl/controllers/search', 'afl/mixins/injected-service'], function (exports, _search, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _search.default.extend(_injectedService.default, {
    sortProperties: 'active',
    direction: 'DESC',
    aflEntityId: Ember.computed('currentUser.entityId', function () {
      return this.get('currentUser.entityId');
    }),

    canCreateUser: Ember.computed('entityStatus', 'aflEntityType', 'aflEntityStatus', function () {
      return this.get('entityStatus') !== 'PRE_PROSPECT' && this.get('entityStatus') !== 'PROSPECT' && this.get('aflEntityStatus') !== 'CLOSED';
    }),

    actions: {
      searchFunction: function searchFunction(searchParams) {
        this.set('model', this.store.query('col-contact', {
          id: this.get('aflEntityId'),
          onlyActive: this.get('onlyActive'),
          page: searchParams.page - 1,
          size: searchParams.size
        }));
      },
      toggleOnlyActive: function toggleOnlyActive() {
        this.toggleProperty('onlyActive');
        this.send('searchFunction', {
          page: 1,
          size: this.get('pageSize')
        });
      }
    }
  });
});