define('afl/components/document/document-history', ['exports', 'afl/mixins/has-permission', 'afl/components/grid/pagination-component'], function (exports, _hasPermission, _paginationComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _paginationComponent.default.extend(_hasPermission.default, {

    sortProperty: 'uploadDate',
    ascending: false,
    entityId: null,

    actions: {
      searchFunction: function searchFunction(searchParams) {
        this.sendAction('searchFunctionAction', searchParams);
      }
    }
  });
});