define('afl/controllers/paying-agent/news', ['exports', 'afl/mixins/injected-service'], function (exports, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_injectedService.default, {
    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.payingAgent.news.index');
    })
  });
});