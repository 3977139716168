define('afl/routes/collectivity/col-contact/detail', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      return this.store.find('colContact', params.id);
    },
    setupController: function setupController(controller, model) {
      controller.set('model', this.store.find('colContact', model.id));
    }
  });
});