define('afl/routes/agency/contact-request/detail/edit', ['exports', 'afl/mixins/injected-service', 'afl/mixins/authenticated-route-mixin'], function (exports, _injectedService, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_injectedService.default, _authenticatedRouteMixin.default, {
    model: function model() {
      return this.modelFor('agency.contactRequest.detail');
    },


    actions: {
      submit: function submit() {
        var _this = this;

        this.modelFor('agency.contactRequest.detail.edit').save().then(function () {
          Ember.get(_this, 'toast').success(_this.get('intl').getMessage('agency.contact.contactForm.success'));
          _this.transitionTo('agency.contactRequest.detail');
        }).catch(function () {
          Ember.get(_this, 'toast').error(_this.get('intl').getMessage('agency.contact.contactForm.error'));
        });
      },
      cancel: function cancel() {
        this.transitionTo('agency.contactRequest.detail');
      },
      validationErrors: function validationErrors() {
        Ember.get(this, 'toast').error(this.get('intl').getMessage('agency.contact.contactForm.validation'));
      },
      willTransition: function willTransition() {
        this.modelFor('agency.contactRequest.detail').deepRollback();
        return true;
      }
    }
  });
});