define('afl/components/user/user-create', ['exports', 'afl/mixins/injected-service', 'afl/utils/afl-constants'], function (exports, _injectedService, _aflConstants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_injectedService.default, {
    groups: null,
    displayContactInformations: false,
    displayActiveCheckbox: false,
    groupsSelected: null,
    groupsFromUser: null,
    isEditMe: false,
    dialogConfirmEditMyself: false,
    roleList: (0, _aflConstants.default)('UserRole'),

    // Partie choix du certificat
    certSelectionOpen: false,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('groups', []);
      this.set('groupsSelected', []);
      var groups = this.get('groupsFromUser') ? this.get('groupsFromUser').toArray() : [];
      this.get('groupsSelected').clear();
      groups.forEach(function (element) {
        _this.get('groupsSelected').push(element.get('ref'));
      });
    },


    hasGroups: Ember.computed('groups', function () {
      return this.getWithDefault('groups', []).length > 0;
    }),

    hasCert: Ember.computed('model.canSign', function () {
      return this.get('model.canSign');
    }),

    entityDetailRoute: '',

    successMessageKey: '',
    errorMessageKey: '',
    validationMessageKey: '',

    isMultiple: Ember.computed('model.entityType', function () {
      var entityType = this.get('model.entityType');
      return entityType && entityType.toLowerCase() === 'agency';
    }),

    canAddSigningRole: Ember.computed('model.{entityId,entityType}', 'currentUser.entityId', function () {
      var entityId = this.get('model.entityId'),
          entityType = this.get('model.entityType'),
          connectedEntityId = this.get('currentUser.entityId');
      // Dans l'immédiat: signature uniquement pour les collectivités et seulement si on est en train
      // de modifier un utilisateur de son entité.
      return entityType && entityType.toLowerCase() === 'collectivity' && entityId === connectedEntityId;
    }),

    // update the model signing elements
    _updateSignData: function _updateSignData(signData) {
      this.get('model').set('signCommonName', signData['signCommonName']);
      this.get('model').set('signGivenName', signData['signGivenName']);
      this.get('model').set('signSurName', signData['signSurName']);
      this.get('model').set('signSerialNumber', signData['signSerialNumber']);
      this.get('model').set('signExpirationDate', signData['signExpirationDate']);
      this.get('model').set('signBase64', signData['signBase64']);
    },


    _isValid: Ember.computed('groupsSelected.@each', 'model.presenceMobile', function () {
      var groupsSelected = this.get('groupsSelected'),
          hasMobile = this.get('model.presenceMobile');

      return groupsSelected.indexOf('COLLECTIVITY_FUND') < 0 || hasMobile;
    }),

    actions: {
      saveUser: function saveUser() {
        var _this2 = this;

        var model = this.get('model');
        model.get('groups').clear();
        model.get('groups').addObjects(this.get('groups').filter(function (element) {
          return _this2.get('groupsSelected').indexOf(element.get('ref')) >= 0;
        }));
        // If signer is set to 'no' , clear sign data
        if (!model.get('isSigner')) {
          this._updateSignData({});
        }

        if (this.get('_isValid')) {
          if (!this.get('isEditMe')) {
            model.save().then(function () {
              Ember.get(_this2, 'toast').success(_this2.get('intl').getMessage(_this2.get('successMessageKey')));
              _this2.sendAction('afterSuccess');
            }).catch(function (errors) {
              var error = errors.errors[0] || {},
                  message = _this2.get('intl').getMessage(_this2.get('errorMessageKey'));

              if (error.detail && error.detail.message) {
                message = error.detail.message;
              }

              Ember.get(_this2, 'toast').error(message);
            });
          } else {
            this.toggleProperty('dialogConfirmEditMyself');
          }
        } else {
          Ember.get(this, 'toast').error(this.get('intl').getMessage('common.user.userForm.errorMobileNumber'));
        }
      },
      editMyself: function editMyself() {
        var _this3 = this;

        var model = this.get('model');
        Ember.$.ajax({
          url: "/api/aflusers/me",
          type: "PUT",
          data: JSON.stringify(model.serialize()),
          dataType: 'json',
          contentType: 'application/json'
        }).then(function (user) {
          _this3.set('currentUser.user', user);
          Ember.get(_this3, 'toast').success(_this3.get('intl').getMessage(_this3.get('successMessageKey')));
          _this3.sendAction('afterSuccess');
        }).fail(function (error) {
          Ember.get(_this3, 'toast').error(error.responseJSON.message);
        });
      },
      confirmEditMyself: function confirmEditMyself() {
        this.send('editMyself');
      },
      cancel: function cancel() {
        if (this.get('isEditMe')) {
          this.get('model').rollbackAttributes();
        }
        this.sendAction('afterSuccess');
      },
      validationErrors: function validationErrors() {
        Ember.get(this, 'toast').error(this.get('intl').getMessage(this.get('validationMessageKey')));
      },
      addCert: function addCert() {
        this.set('certSelectionOpen', true);
      },
      editCert: function editCert() {
        this.set('certSelectionOpen', true);
      },
      removeCert: function removeCert() {
        this.set('model.isSigner', false);
        this.set('certSelectionOpen', false);
        this._updateSignData({});
      },
      cancelCertRegister: function cancelCertRegister() {
        Ember.Logger.log("cancelCertRegister Called");
        this.set('model.isSigner', false);
        this.set('certSelectionOpen', false);
      },
      registerCert: function registerCert(cert) {
        Ember.Logger.log("registerCert Called, with cert", cert);

        var signData = {
          signCommonName: cert.alias,
          signBase64: cert.base64certificate,
          signSerialNumber: cert.serialNumber
        };

        // Extraction des infos
        cert.subjectDN.split(",").forEach(function (field) {

          var data = field.split("=");
          var key = data[0].replace(" ", "").toLowerCase();
          var val = data[1];

          if (key === "gn" || key === "givenname") {
            var values = data[1].split("+");
            signData.signGivenName = values[0];
            if (values[1] === "sn" || values[1] === "surname") {
              signData.signSurName = data[2];
            }
          } else if (key === "sn" || key === "surname") {
            signData.signSurName = val;
          } else if (key === "cn") {
            signData.signCommonName = val;
          }
        });

        this._updateSignData(signData);
        this.set('certSelectionOpen', false);
      }
    }
  });
});