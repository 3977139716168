define('afl/routes/agency/user/detail', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      return this.store.find('aflUser', params.user_id);
    },
    setupController: function setupController(controller, model) {
      controller.set('model', this.store.find('aflUser', model.id));

      var aflEntity = this.store.find(model.get('entityType').toLowerCase(), model.get('entityId'));
      controller.set('entity', aflEntity);
    }
  });
});