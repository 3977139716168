define('afl/controllers/agency/user/detail/edit', ['exports', 'afl/mixins/injected-service'], function (exports, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_injectedService.default, {
    groups: null,

    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.agency.user.edit');
    }),

    actions: {
      afterSuccess: function afterSuccess() {
        this.transitionToRoute('agency.user.detail');
      }
    }
  });
});