define('afl/components/input/input-select-object', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    objects: null,
    objectValue: null,
    objectLabel: null,
    selectedValue: null,
    withBlank: false,
    blankLabel: '',
    inputName: 'objects',
    inputId: 'objects',
    inputClass: 'form-control',
    disabled: false,

    selected: Ember.computed('selectedValue', 'objects.[]', {
      get: function get() {
        var _this = this;

        var selectedValue = this.get('selectedValue');
        var objects = this.get('objects');
        if (selectedValue) {
          var found = objects.find(function (e) {
            return Ember.get(e, _this.get('objectValue')) === selectedValue;
          });
          if (found) {
            return {
              value: Ember.get(found, this.get('objectValue')),
              label: Ember.get(found, this.get('objectLabel'))
            };
          }
        }

        return {
          value: '',
          label: this.get('blankLabel')
        };
      },
      set: function set(key, value) {
        if (value.value) {
          this.set('selectedValue', value.value);
        } else {
          this.set('selectedValue', '');
        }
        return value;
      }
    }),

    selection: Ember.computed.reads('selected'),

    content: Ember.computed('objects.@each', 'objectValue', 'objectLabel', 'withBlank', 'blankLabel', function () {
      var objects = this.get('objects'),
          objectValue = this.get('objectValue'),
          objectLabel = this.get('objectLabel');

      var content = [];

      if (this.get('withBlank')) {
        content.push({
          value: '',
          label: this.get('blankLabel')
        });
      }

      objects.forEach(function (item) {
        content.push({
          value: Ember.get(item, objectValue),
          label: Ember.get(item, objectLabel)
        });
      });

      return content;
    })
  });
});