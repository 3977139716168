define('afl/controllers/collectivity/fundings/treasury/detail', ['exports', 'afl/mixins/injected-service', 'afl/transforms/localdate', 'afl/mixins/has-permission'], function (exports, _injectedService, _localdate, _hasPermission) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var localDateTransform = new _localdate.default();

  exports.default = Ember.Controller.extend(_injectedService.default, _hasPermission.default, {
    showDrawingForm: false,
    showRefundForm: false,
    isTiersPage: false,
    currentFundingId: null,
    isManagementMode: true,
    entityId: null,
    isMember: true,

    canRequestDrawing: Ember.computed('model.balance', function () {
      return this.get('model.balance') > 0;
    }),

    canRequestRefund: Ember.computed('model.remainingAmount', 'isMobilisation', function () {
      return this.get('model.remainingAmount') > 0 && !this.get('isMobilisation');
    }),

    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.collectivity.fundings.treasury.detail');
    }),

    _submitRequest: function _submitRequest(date, amount, token, otp, type, successMessage, errorMessage, forbiddenMessage) {
      var _this = this;

      Ember.$.post('/api/loans/' + this.get('model.id') + '/' + this.get('model.loanNumber') + '/events', {
        type: type,
        date: localDateTransform.serialize(date),
        amount: amount,
        token: token,
        is_mobilisation: this.get('isMobilisation'),
        otp: otp,
        collectivity_id: this.get('currentUser.entityId')
      }, function () {
        Ember.run(function () {
          Ember.get(_this, 'toast').success(_this.get('intl').getMessage(successMessage));
          if (type === 'DRAWING') {
            _this.set('showDrawingForm', false);
          } else if (type === 'REFUND') {
            _this.set('showRefundForm', false);
          }
        });
      }).fail(function (error) {
        if (error.status === 403) {
          Ember.get(_this, 'toast').error(_this.get('intl').getMessage(forbiddenMessage));
        } else if (error.status === 500) {
          var sabError = error.responseJSON.message.split(':').get(1);
          if (sabError.length === 0 || !sabError.trim()) {
            Ember.get(_this, 'toast').error(_this.get('intl').getMessage(errorMessage));
          } else {
            Ember.get(_this, 'toast').error(sabError);
          }
        } else if (error.status === 400) {
          Ember.get(_this, 'toast').error(error.responseJSON.message);
        } else {
          Ember.get(_this, 'toast').error(_this.get('intl').getMessage(errorMessage));
        }
      });
    },

    actions: {
      toggleDrawingForm: function toggleDrawingForm() {
        this.set('showRefundForm', false);
        this.toggleProperty('showDrawingForm');
      },

      submitDrawingForm: function submitDrawingForm(params) {
        this._submitRequest(params.date, params.amount, params.token, params.otp, 'DRAWING', 'collectivity.fundings.treasury.drawing.success', 'collectivity.fundings.treasury.drawing.error', 'collectivity.fundings.treasury.drawing.forbidden');
      },

      toggleRefundForm: function toggleRefundForm() {
        this.set('showDrawingForm', false);
        this.toggleProperty('showRefundForm');
      },

      submitRefundForm: function submitRefundForm(params) {
        this._submitRequest(params.date, params.amount, params.token, params.otp, 'REFUND', 'collectivity.fundings.treasury.refund.success', 'collectivity.fundings.treasury.refund.error', 'collectivity.fundings.treasury.refund.forbidden');
      }
    }
  });
});