define('afl/transforms/localdatetime', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.DateTransform.extend({

    deserialize: function deserialize(serialized) {
      // Tronque la timezone afin que Ember.DateTransform créé la date dans la zone UTC
      if (typeof serialized === 'string') {
        serialized = truncateTimezone(serialized);
      }

      return this._super(serialized);
    },

    serialize: function serialize(date) {
      // Récupère la date au format ISO 8601 ("yyyy-mm-DD`T`HH:mm:ss.SSSZ")
      var serialized = this._super(date);

      // Supprime le marqueur de timezone ('Z')
      if (serialized) {
        serialized = serialized.slice(0, -1);
      }

      return serialized;
    }
  });


  // Fonctions utilisées pour la désérialisation

  var timeMarker = 'T';

  /*
   @param isoDate une String représentant une date supportée par Ember.Date.parse()
   Exemples : `2015-01-01T00:01:02`, `2015-01-01T00:01:02-02:00`, `2015-01-01T00:01Z`...
   @return la String isoDate sans information de timezone
   */
  function truncateTimezone(isoDate) {
    var timeMarkerPos = isoDate.indexOf(timeMarker);
    if (timeMarkerPos === -1) {
      return isoDate;
    }

    var isoTime = isoDate.slice(timeMarkerPos);
    var relativeTimezoneMarkerPos = getTimezoneMarkerPosition(isoTime);
    if (relativeTimezoneMarkerPos === -1) {
      return isoDate;
    }

    var cutPosition = timeMarkerPos + relativeTimezoneMarkerPos;
    return isoDate.substr(0, cutPosition);
  }

  var timezoneMarkers = ['Z', '+', '-'];

  /*
   @param isoTime La partie à droite du marqueur `T` (inclus ou non, peu importe)
   Exemples : `T00:01`, `T00:01:02.003Z`, `T00:01:02+0200`...
   @return la position du marqueur de timezone ('Z', '+' ou '-') ou bien -1 si non trouvé
   */
  function getTimezoneMarkerPosition(isoTime) {
    for (var i = 0; i < timezoneMarkers.length; i++) {
      var timezoneMarkerPos = isoTime.indexOf(timezoneMarkers[i]);
      if (timezoneMarkerPos !== -1) {
        return timezoneMarkerPos;
      }
    }
    return -1;
  }
});