define('afl/controllers/agency/event/detail/index', ['exports', 'afl/mixins/has-permission', 'afl/mixins/injected-service'], function (exports, _hasPermission, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_hasPermission.default, _injectedService.default, {
    eventContactUrl: Ember.computed('model.id', function () {
      return '/api/crmevents/' + this.get('model.id') + '/contact';
    }),
    actions: {
      reload: function reload() {
        var _this = this;

        this.get('model').reload().then(function (model) {
          _this.send('updateAvailableContacts', model.get('contacts'));
        });
      }
    }
  });
});