define('afl/components/document/document-create', ['exports', 'afl/utils/afl-constants', 'afl/mixins/search-collectivity', 'lodash'], function (exports, _aflConstants, _searchCollectivity, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_searchCollectivity.default, {
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    allCollectivities: true,
    displayCollectivityInput: true,
    displayModal: false,
    editMode: false,

    displayModalChange: Ember.observer('displayModal', function () {

      if (!this.get('displayModal')) {
        this.rollbackEditDocument();
      } else {
        if (this.get('editMode')) {
          var splittedFileName = this.get('document.fileName').split('.');
          this.set('shortFileName', splittedFileName.slice(0, -1).join('.'));
          this.set('extension', '.' + _lodash.default.last(splittedFileName));
        }
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('collectivityDocumentType', (0, _aflConstants.default)('CollectivityDocumentType'));
    },


    actions: {
      closeModal: function closeModal() {
        this.set('displayModal', false);
        if (this.get('closeCollectivityDocument')) {
          this.get('closeCollectivityDocument')(this.get('document'));
        }
      },
      validationErrors: function validationErrors() {
        this.get('toast').error(this.get('intl').getMessage('collectivityDocument.validationError'));
      },
      validate: function validate() {
        if (this.get('editMode')) {
          this.set('document.fileName', this.get('shortFileName') + this.get('extension'));
        }
        if (this.get('document.type') !== 'OTHER') {
          this.set('document.otherType', null);
        }
        this.get('saveCollectivityDocument')(this.get('document'));
      }
    },

    rollbackEditDocument: function rollbackEditDocument() {
      if (this.get('editMode') && !Ember.isEmpty(this.get('document'))) {
        this.get('document').rollbackAttributes();
        this.set('shortFileName', "");
        this.set('extension', "");
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.rollbackEditDocument();
    }
  });
});