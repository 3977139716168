define('afl/routes/collectivity/user/create', ['exports', 'afl/mixins/authenticated-route-mixin', 'afl/mixins/injected-service'], function (exports, _authenticatedRouteMixin, _injectedService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _injectedService.default, {
    model: function model() {
      return this.store.createRecord('aflUser', {
        entityType: 'collectivity',
        entityId: this.get('currentUser.entityId')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.store.query('group', { entityType: model.get('entityType') }).then(function (groups) {
        controller.set('groups', groups.toArray());
      });
    },


    actions: {
      willTransition: function willTransition() {
        this.controller.get('model').deepRollback();
        return true;
      }
    }
  });
});