define('afl/app', ['exports', 'afl/resolver', 'ember-load-initializers', 'afl/config/environment', 'afl/theme'], function (exports, _resolver, _emberLoadInitializers, _environment, _theme) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  window.Promise = window.Promise || Ember.RSVP.Promise;

  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default,

    ready: function ready() {
      var _this = this;

      Ember.$.ajaxSettings.traditional = true;
      Ember.$.ajaxPrefilter(function (options, originalOptions, request) {
        var session = _this.__container__.lookup('service:session'),
            token = session.get('data.authenticated.token');
        if (token !== undefined) {
          request.setRequestHeader("X-Auth-Token", 'Bearer ' + token);
        }
      });
      Ember.$(document).ajaxSuccess(function (event, request, ajaxOptions /*, data*/) {
        if (!ajaxOptions.url.startsWith("/api/aflusers/impersonate") && ajaxOptions.url !== '/api/polling' && ajaxOptions.url.indexOf(_environment.default['ember-simple-auth-token'].serverTokenRefreshEndpoint) === -1) {
          var currentUser = _this.__container__.lookup('service:current-user');
          currentUser.refresh();
        }
      });

      if (!Ember.testing) {
        Ember.$(document).ajaxComplete(function (event, xhr) {
          if (xhr.status !== 401 && xhr.status !== 403) {
            var sessionStore = _this.__container__.lookup('session:main');
            var now = new Date().getTime();
            var expire_at = new Date(now + _environment.default.APP.sessionTimeout * 1000).getTime();
            sessionStore.setUnknownProperty('authenticated.expires_at', expire_at);
          } else if (xhr.status === 401) {
            var currentUser = _this.__container__.lookup('service:current-user');
            currentUser.logout();
          }
        });
      }

      _theme.default.init();
    }
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  exports.default = App;
});