define('afl/components/input/input-amount', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    inputId: '',
    inputName: '',
    inputValue: '', // component displayed value
    value: '',
    _valueChanged: false,
    _inputValueChanged: false,
    amountNotFormated: true,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('inputValue', this.get('value'));
    },

    valueChanged: Ember.observer('value', function () {
      if (this.get('value') === null) {
        this.set('inputValue', '');
      }
      if (this.get('amountNotFormated')) {
        this.set('inputValue', this.get('value'));
        this.$('#' + this.get('inputId')).inputmask("decimal", { groupSeparator: ' ', prefix: '', autoGroup: true, rightAlign: false });
        this.set('amountNotFormated', false);
      }

      if (this.get('_inputValueChanged')) {
        this.set('_inputValueChanged', false);
      } else {
        this.set('inputValue', this.get('value'));
        this.set('_valueChanged', true);
      }
    }),

    inputValueChanged: Ember.observer('inputValue', function () {
      if (this.get('_valueChanged')) {
        this.set('_valueChanged', false);
      } else {
        if (!Ember.isEmpty(this.get('inputValue'))) {
          // Force string value in order to avoid int value of this.get('inputValue')
          this.set('value', ("" + this.get('inputValue')).replace(/ /g, ""));
          this.set('_inputValueChanged', true);
        }
      }
    }),

    didInsertElement: function didInsertElement() {
      if (!Ember.testing) {
        Ember.run.scheduleOnce('afterRender', this, function () {
          this.$('#' + this.get('inputId')).inputmask("decimal", { groupSeparator: ' ', prefix: '', autoGroup: true, rightAlign: false });
          this.set('amountNotFormated', false);
        });
      }
    }
  });
});