define('afl/components/document/library-component', ['exports', 'afl/mixins/has-permission', 'ember-cli-file-saver/mixins/file-saver'], function (exports, _hasPermission, _fileSaver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_fileSaver.default, _hasPermission.default, {
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    toast: Ember.inject.service(),

    askDelete: false,
    docToDelete: null,
    documents: Ember.A(),
    page: 1,
    size: 50,
    sizes: null,
    property: 'creationDate',
    ascending: false,

    init: function init() {
      this._super.apply(this, arguments);
      if (Ember.isEmpty(this.size)) {
        this.sizes = [10, 20, 50];
      }
    },

    direction: Ember.computed('ascending', function () {
      return Ember.get(this, 'ascending') ? 'asc' : 'desc';
    }),
    noDocumentAvailable: Ember.computed('documents.[]', function () {
      return Ember.isEmpty(Ember.get(this, 'documents'));
    }),

    didInsertElement: function didInsertElement() {
      this._sendSearch();
    },


    actions: {
      search: function search(params) {
        var filter = {
          page: params.page - 1,
          size: params.size,
          sort: params.property + ',' + params.direction
        };
        Ember.set(this, 'documents', Ember.get(this, 'store').query('library-document', filter));
      },
      reload: function reload() {
        Ember.set(this, 'page', 1);
        this._sendSearch();
      },
      newPage: function newPage(params) {
        Ember.setProperties(this, params);
        this._sendSearch();
      },
      sort: function sort(property) {
        Ember.set(this, 'page', 1);
        if (Ember.get(this, 'property') === property) {
          this.toggleProperty('ascending');
        } else {
          Ember.set(this, 'ascending', true);
          Ember.set(this, 'property', property);
        }

        this._sendSearch();
      },
      secure: function secure(document) {
        var _this = this;

        Ember.set(document, 'unsecured', false);
        document.save().then(function () {
          Ember.get(_this, 'toast').success(Ember.get(_this, 'intl').getMessage('libraryDocuments.documentSecured', {
            name: Ember.get(document, 'name')
          }));
        });
      },
      unsecure: function unsecure(document) {
        var _this2 = this;

        Ember.set(document, 'unsecured', true);
        document.save().then(function () {
          Ember.get(_this2, 'toast').success(Ember.get(_this2, 'intl').getMessage('libraryDocuments.documentUnsecured', {
            name: Ember.get(document, 'name')
          }));
        });
      },
      download: function download(document) {
        var _this3 = this;

        Ember.$.ajax({
          type: 'GET',
          url: '/api/download/document/' + Ember.get(document, 'fileInfo.fileRef'),
          dataType: 'blob', // or 'blob'
          headers: {
            'authorization': this.get('currentUser').getToken()
          },
          processData: false
        }).then(function (content) {
          _this3.saveFileAs(document.name, content);
        });
      },
      delete: function _delete(document) {
        Ember.set(this, 'docToDelete', document);
        Ember.set(this, 'askDelete', true);
      },
      confirmDelete: function confirmDelete() {
        var _this4 = this;

        Ember.get(this, 'docToDelete').destroyRecord().then(function () {
          Ember.get(_this4, 'toast').success(Ember.get(_this4, 'intl').getMessage('libraryDocuments.documentDeleted'));
        }).catch(function (errors) {
          Ember.Logger.error('Error deleting existing doc', errors);
          Ember.get(_this4, 'toast').error(Ember.get(_this4, 'intl').getMessage('libraryDocuments.cannotDeleteDocumentMessage'));
        });
      }
    },

    _sendSearch: function _sendSearch() {
      this.send('search', Ember.getProperties(this, 'page', 'size', 'property', 'direction'));
    }
  });
});