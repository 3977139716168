define('afl/routes/paying-agent/collectivity/fundings/long-term/detail', ['exports', 'afl/mixins/injected-service', 'afl/mixins/authenticated-route-mixin'], function (exports, _injectedService, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_injectedService.default, _authenticatedRouteMixin.default, {
    model: function model(params) {
      var collectivityId = this.modelFor('payingAgent.collectivity').get('id');

      return this.store.findOneQuery('loan', params.longTerm_id, { collectivity_id: collectivityId, loan_number: params.loan_number });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var collectivityId = this.modelFor('payingAgent.collectivity').get('id');
      var isCollectivityMember = this.modelFor('payingAgent.collectivity').get('isCollectivityMember');
      controller.set('isMobilisation', false);
      controller.set('collectivityId', collectivityId);
      controller.set('isMember', isCollectivityMember);
      controller.set('currentFundingId', model.get('id'));
    },

    actions: {
      error: function error() {
        Ember.get(this, 'toast').error(this.get('intl').getMessage('collectivity.fundings.noFundingApplication'));
      }
    }
  });
});