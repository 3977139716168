define('afl/tinymce/plugins/charcount', [], function () {
  'use strict';

  /**
   * Plugin pour tinyMCE permettant de compter le nombre de caractères saisie dans le textarea.
   *    - `intl`: intl est à passer dans les options tinyMCE
   */
  /* global tinymce:true */
  tinymce.PluginManager.add('charcount', function (editor) {
    this.getCount = getCount;

    function update() {
      var count = getCount();
      var limit = editor.getParam('contentLimit');
      editor.theme.panel.find('#charcount').text(['{0}: {1}/{2}', editor.getParam('intl').getMessage('common.characters'), count, limit]);
      if (count > limit) {
        editor.theme.panel.find('#statusbar .charcount').addClass('error');
      } else {
        editor.theme.panel.find('#statusbar .charcount').removeClass('error');
      }
    }

    function getCount() {
      return Ember.$('iframe').contents().find('body').text().length;
    }

    editor.on('init', function () {
      var statusbar = editor.theme.panel && editor.theme.panel.find('#statusbar')[0];

      if (statusbar) {
        window.setTimeout(function () {
          statusbar.insert({
            type: 'label',
            name: 'charcount',
            text: ['{0}: {1}/{2}', editor.getParam('intl').getMessage('common.characters'), getCount(), editor.getParam('contentLimit')],
            classes: 'charcount',
            disabled: editor.settings.readonly
          }, 0);

          editor.on('setcontent beforeaddundo keyup', update);
        }, 0);
      }
    });
  });
});