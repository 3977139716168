define('afl/routes/agency/funding-application/detail/index', ['exports', 'afl/mixins/injected-service', 'afl/mixins/authenticated-route-mixin'], function (exports, _injectedService, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_injectedService.default, _authenticatedRouteMixin.default, {
    model: function model() {
      return this.modelFor('agency.fundingApplication.detail');
    },
    activate: function activate() {
      this.controllerFor('agency.fundingApplication.detail.index').set('dialogProcess', false);
      this.controllerFor('agency.fundingApplication.detail.index').set('dialogRefuse', false);
      this.controllerFor('agency.fundingApplication.detail.index').set('dialogDelete', false);
    },
    resetController: function resetController(controller) {
      controller.set('dialogProcess', false);
      controller.set('dialogRefuse', false);
      controller.set('dialogDelete', false);
    },


    actions: {
      update: function update(defer) {
        var _this = this;

        this.modelFor('agency.fundingApplication.detail').save().then(function () {
          // Ferme la modale
          defer.resolve();
          _this.controller.set('errorMessage', '');

          _this.transitionTo('agency.fundingApplication');
        }).catch(function (errors) {
          var error = errors.errors[0] || {};
          var message = _this.get('intl').getMessage('agency.fundingApplication.detail.dialogForm.updateError');
          if (error.detail && error.detail.message) {
            message = error.detail.message;
          }

          if (error.status === 502 && message.toLowerCase().indexOf('sab') > -1) {
            // Si c'est une erreur SAB, alors on doit afficher le message d'erreur dans la modale
            defer.reject();
            _this.controller.set('errorMessage', message);
          } else {
            defer.resolve();
            Ember.get(_this, 'toast').error(message);
            _this.controller.set('errorMessage', '');
          }

          _this.refresh();
        });
      },
      delete: function _delete(defer) {
        var _this2 = this;

        Ember.Logger.debug("delete action called", defer);
        this.controller.get('model').destroyRecord().then(function () {
          Ember.Logger.debug("Deletion success");
          defer.resolve();
          Ember.get(_this2, 'toast').success(_this2.get('intl').getMessage('agency.fundingApplication.detail.dialogForm.deleteSuccess'));
          _this2.controller.set('errorMessage', '');
          _this2.transitionTo('agency.fundingApplication');
        }).catch(function () {
          Ember.Logger.debug("Deletion failure");
          defer.resolve();
          Ember.get(_this2, 'toast').error(_this2.get('intl').getMessage('agency.fundingApplication.detail.dialogForm.deleteError'));
          _this2.controller.set('errorMessage', '');
        });
      }
    }
  });
});