define('afl/components/menu/menu-collectivity', ['exports', 'afl/mixins/afl-menu', 'afl/mixins/has-permission', 'afl/config/environment'], function (exports, _aflMenu, _hasPermission, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_aflMenu.default, _hasPermission.default, {
    // masquage de la partie ligne de trésorerie sur demande client FPLAFLINT-1200
    isTreasuryLineEnable: _environment.default.APP.isTreasuryLineEnable,
    urlManual: _environment.default.collectivityManual.url,

    hasUnreadMessages: Ember.computed('unreadMessages', function () {
      return this.get('unreadMessages') > 0;
    })
  });
});