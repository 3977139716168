define('afl/controllers/agency/news/index', ['exports', 'afl/mixins/has-permission', 'afl/mixins/news-actions', 'afl/controllers/search'], function (exports, _hasPermission, _newsActions, _search) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _search.default.extend(_hasPermission.default, _newsActions.default, {
    filterProperty: null,
    sortProperty: 'publicationBeginDate',
    ascending: false,

    isStateFilterAll: Ember.computed('filterProperty', function () {
      return this.get('filterProperty') === null || this.get('filterProperty') === undefined;
    }),

    isStateFilterPublished: Ember.computed('filterProperty', function () {
      return this.get('filterProperty') === 'PUBLISHED';
    }),

    isStateFilterWaitingToBePublished: Ember.computed('filterProperty', function () {
      return this.get('filterProperty') === 'WAITING_TO_BE_PUBLISHED';
    }),

    isStateFilterCreated: Ember.computed('filterProperty', function () {
      return this.get('filterProperty') === 'CREATED';
    }),

    isStateFilterExpired: Ember.computed('filterProperty', function () {
      return this.get('filterProperty') === 'EXPIRED';
    }),

    actions: {
      searchFunction: function searchFunction(searchParams) {
        this.set('model', this.store.query('news', {
          state: this.get('filterProperty'),
          page: searchParams.page,
          size: searchParams.size,
          direction: searchParams.direction,
          properties: searchParams.properties
        }));
      }
    }
  });
});