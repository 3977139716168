define('afl/controllers/agency/library/create', ['exports', 'afl/mixins/injected-service', 'afl/config/environment'], function (exports, _injectedService, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Logger = Ember.Logger;


  function extractDocType(name) {
    var reg = new RegExp("x$", "g");
    return name.split(".").pop().replace(reg, "");
  }

  exports.default = Ember.Controller.extend(_injectedService.default, {
    formats: _environment.default.libraryUpload.extensions,
    maxSize: _environment.default.libraryUpload.size,
    maxSizeInText: _environment.default.libraryUpload.sizeInText,

    breadCrumb: Ember.computed(function () {
      return Ember.get(this, 'intl').getMessage('breadcrumb.agency.libraryDocuments.create');
    }),

    actions: {
      uploadSuccess: function uploadSuccess(response) {
        Logger.debug("Response received from upload: ", response);
        var object = JSON.parse(response);
        var model = Ember.get(this, 'model');
        Ember.set(model, 'fileSize', parseInt(object.size / 1024));
        Ember.set(model, 'name', object.fileName);
        Ember.set(model, 'fileInfo', {});
        Ember.set(model, 'fileInfo.fileName', object.fileName);
        Ember.set(model, 'fileInfo.fileRef', object.ref);
        Ember.set(model, 'fileInfo.mimeType', extractDocType(object.fileName));
      },
      uploadError: function uploadError(errors) {
        Ember.get(this, 'toast').error(Ember.get(this, 'intl').getMessage('libraryDocuments.cannotUploadDocumentMessage'));
        Ember.set(Ember.get(this, 'model'), 'name', null);
        Logger.error('Error uploading doc', errors);
      },
      save: function save() {
        var _this = this;

        Ember.get(this, 'model').save().then(function () {
          Ember.get(_this, 'toast').success(Ember.get(_this, 'intl').getMessage('libraryDocuments.documentAdded'));
          _this.transitionToRoute('agency.library');
        }).catch(function (errors) {
          Logger.error('Error saving new doc', errors);
          Ember.get(_this, 'toast').error(Ember.get(_this, 'intl').getMessage('libraryDocuments.cannotSaveDocumentMessage'));
        });
      },
      formError: function formError() {
        Ember.get(this, 'toast').error(Ember.get(this, 'intl').getMessage('libraryDocuments.cannotCreateDocumentMessage'));
      },
      cancel: function cancel() {
        this.transitionToRoute('agency.library');
      }
    }
  });
});