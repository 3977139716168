define('afl/validators/siren', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * Luhn algorithm in JavaScript.
   *
   * Explain: Take the siren [732 829 320]
   *          The odd number are summed (7 + 2 + 2 + 3 + 0 = 14)
   *          The even number are multiplied by 2 and then we sum the the digits (6 + (1 + 6) + (1 + 8) + 4 = 26)
   *          The total result must be a multiple of 10 (14 + 26 = 40)
   *          Then:
   *           - if the position is odd, we add the digit
   *           - if the position is even, we add 0 or 2 or 4 or 6 or 8 or 1 or 3 or 5 or 7 or 9
   *
   * @see https://gist.github.com/ShirtlessKirk/2134376 for code
   */
  var luhnCheck = function (arr) {
    return function (ccNum) {
      var len = ccNum.length,
          bit = 1,
          sum = 0,
          val;

      while (len) {
        val = parseInt(ccNum.charAt(--len), 10);
        sum += (bit ^= 1) ? arr[val] : val;
      }

      return sum && sum % 10 === 0;
    };
  }([0, 2, 4, 6, 8, 1, 3, 5, 7, 9]);

  var isSirenValid = function isSirenValid(siren) {
    if (/^[0-9]{9}$/.test(siren)) {
      return luhnCheck(siren);
    }
    return false;
  };

  exports.default = _base.default.extend({
    intl: Ember.inject.service(),

    validate: function validate(value) {
      if (!Ember.isEmpty(value)) {
        return isSirenValid(value) ? true : this.get('intl').getMessage('validations.siren');
      } else {
        return true;
      }
    }
  });
});