define('afl/routes/paying-agent/index', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    activate: function activate() {
      var searchController = this.controllerFor('paying-agent.index');
      searchController.send('searchFunction', {
        page: 1,
        size: searchController.get('pageSize')
      });
    }
  });
});