define('afl/controllers/agency/person/detail/contact/detail/edit', ['exports', 'afl/mixins/injected-service', 'afl/utils/afl-constants'], function (exports, _injectedService, _aflConstants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_injectedService.default, {
    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.agency.contact.edit');
    }),
    userRoles: (0, _aflConstants.default)('UserRole'),

    actions: {
      changeElected: function changeElected() {
        this.get('model').toggleProperty('elected');
      },
      changeActive: function changeActive() {
        this.get('model').toggleProperty('isActive');
      },
      saveContact: function saveContact() {
        var _this = this;

        this.get('model').save().then(function () {
          Ember.get(_this, 'toast').success(_this.get('intl').getMessage('crm.contacts.edit.success'));
          _this.transitionToRoute('agency.contact.detail');
        }).catch(function (errors) {
          var error = errors.errors[0] || {},
              message = _this.get('intl').getMessage('crm.contacts.edit.error');

          if (error.detail && error.detail.message) {
            message = error.detail.message;
          }

          Ember.get(_this, 'toast').error(message);
        });
      },
      cancel: function cancel() {
        this.transitionToRoute('agency.contact.detail');
      },
      validationErrors: function validationErrors() {
        Ember.get(this, 'toast').error(this.get('intl').getMessage('crm.contacts.edit.validation'));
      }
    }
  });
});