define('afl/routes/agency/grid-rate/index', ['exports', 'afl/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    beforeModel: function beforeModel(transition) {
      var _this = this;

      this._super(transition);
      return Ember.$.get('/api/gridrates/areEnabledGrids').then(function (enable) {
        _this.controllerFor('agency.grid-rate.index').set('enable', enable);
      });
    },
    model: function model() {
      return this.modelFor('agency.gridRate');
    }
  });
});