define('afl/models/user-import-detail', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var UserImportDetail = _emberData.default.Model.extend({
    lineNumber: _emberData.default.attr('number'),
    status: _emberData.default.attr('string'),
    cause: _emberData.default.attr('string')
  });

  exports.default = UserImportDetail;
});