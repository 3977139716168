define('afl/controllers/agency/contact-request/detail', ['exports', 'afl/mixins/injected-service', 'afl/mixins/has-permission'], function (exports, _injectedService, _hasPermission) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_injectedService.default, _hasPermission.default, {
    application: Ember.inject.controller(),
    dialogAccept: false,
    dialogRefuse: false,
    dialogFinalize: false,

    modelDidChange: Ember.observer('model.id', function () {
      this.set('dialogAccept', false);
      this.set('dialogFinalize', false);
      this.set('dialogRefuse', false);
    }),

    breadCrumb: Ember.computed(function () {
      return this.get('intl').getMessage('breadcrumb.agency.contact-request.detail');
    }),

    isInEdition: Ember.computed('application.currentRouteName', function () {
      return this.get('application.currentRouteName') === 'agency.contactRequest.detail.edit';
    }),

    actions: {
      acceptContactRequest: function acceptContactRequest() {
        this.toggleProperty('dialogAccept');
      },
      refuseContactRequest: function refuseContactRequest() {
        this.toggleProperty('dialogRefuse');
      },
      finalizeContactRequest: function finalizeContactRequest() {
        this.toggleProperty('dialogFinalize');
      }
    }
  });
});