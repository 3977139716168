define('afl/controllers/paying-agent/collectivity/fundings/treasury/index', ['exports', 'afl/mixins/has-permission', 'afl/controllers/search'], function (exports, _hasPermission, _search) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _search.default.extend(_hasPermission.default, {
    sortProperty: 'fileNumber',
    ascending: false,

    actions: {
      searchFunction: function searchFunction(searchParams) {
        this.set('model', this.store.query('loan', {
          collectivity_id: this.get('collectivity').get('id'),
          loan_type: 'CREDIT_LINE',
          page: searchParams.page,
          size: searchParams.size,
          direction: searchParams.direction,
          properties: searchParams.properties
        }));
      }
    }
  });
});