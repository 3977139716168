define('afl/routes/agency', ['exports', 'afl/mixins/authenticated-route-mixin', 'afl/mixins/has-permission'], function (exports, _authenticatedRouteMixin, _hasPermission) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _hasPermission.default, {
    polling: Ember.inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (this.get('hasContactAccess')) {
        this.get('polling').subscribe('contact-request/count', this.controller, 'pendingContactRequests');
      }

      if (this.get('hasFundAppAccess')) {
        this.get('polling').subscribe('funding-application/count', this.controller, 'pendingFundingApplications');
      }

      if (this.get('hasMailboxAccess')) {
        this.get('polling').subscribe('mailbox/count', this.controller, 'unreadMessages');
      }

      if (this.get('hasCRMAccess')) {
        this.get('polling').subscribe('task/count', this.controller, 'pendingTasks');
      }

      if (this.get('hasDocsAccess')) {
        this.get('polling').subscribe('document/count', this.controller, 'notViewDocumentNumber');
      }

      if (!Ember.testing) {
        this.get('polling').startPolling();
      }
    },
    destroy: function destroy() {
      if (this.get('hasContactAccess')) {
        this.get('polling').unsubscribe('contact-request/count');
      }

      if (this.get('hasFundAppAccess')) {
        this.get('polling').unsubscribe('funding-application/count');
      }

      if (this.get('hasMailboxAccess')) {
        this.get('polling').unsubscribe('mailbox/count');
      }

      if (this.get('hasCRMAccess')) {
        this.get('polling').unsubscribe('task/count');
      }

      if (this.get('hasDocsAccess')) {
        this.get('polling').unsubscribe('document/count');
      }

      if (!Ember.testing) {
        this.get('polling').stopPolling();
      }
    }
  });
});