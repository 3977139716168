define('afl/components/funding/long-term-list', ['exports', 'afl/mixins/has-permission'], function (exports, _hasPermission) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_hasPermission.default, {
    isServerError: false,

    hasNoResult: Ember.computed('model.@each', function () {
      return this.get('model').isFulfilled && Ember.isEmpty(this.get('model'));
    }),

    actions: {
      sendSort: function sendSort(sortProperty) {
        this.sendAction('sortAction', sortProperty);
      },

      sendNewPage: function sendNewPage(params) {
        this.sendAction('newPageAction', params);
      }
    }
  });
});