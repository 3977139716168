define('afl/controllers/paying-agent/collectivity/fundings/treasury/detail/events', ['exports', 'afl/controllers/search'], function (exports, _search) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _search.default.extend({
    collectivityId: '',
    sortProperty: 'requestDate',
    loan: null,

    actions: {
      searchFunction: function searchFunction(searchParams) {
        this.set('model', this.store.query('loan-event', {
          file_id: this.get('loan.id'),
          collectivity_id: this.get('collectivityId'),
          loan_number: this.get('loan.loanNumber'),
          is_mobilisation: this.get('isMobilisation'),
          page: searchParams.page,
          size: searchParams.size,
          direction: searchParams.direction,
          properties: searchParams.properties
        }));
      }
    }
  });
});